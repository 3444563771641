import React, { Component } from "react";

//Import CSS
import "./inputs.css";

//Import internal components
import AppContext from "../../appContext";
import Select from "../../components/selects/select";

//Import external components

//Import images

class TrackingLabChoose extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lab: [],
      labs: [],
    };
    TrackingLabChoose.contextType = AppContext;
    this.handleChange = this.handleChange.bind(this);
    this.changeComboLabUser = this.changeComboLabUser.bind(this);
  }

  componentDidMount() {
    if (this.props.labs.length === 0) {
      this.context.showLoading("Carregando...");
      let labsa = [];
      const requestOptions = {
        method: "POST",
        body: JSON.stringify({
          cnpjs: this.context.userSession.laboratorios,
          papel: this.context.userSession.papel,
          conta: this.context.userSession.conta,
        }),
      };

      fetch(process.env.REACT_APP_LABS_API + "get_labs.php", requestOptions)
        .then(response => {
          return response.json();
        })
        .then(json => {
          if (json.items) {
            for (var i = 0; i < json.items.length; i++) {
              if (json.items[i].Financeiro_c === true) {
                var pushObj = {
                  label: json.items[i].Nome_c,
                  value: json.items[i].CNPJ_c,
                  detailOrder: json.items[i].DetalhesDoPedido_c,
                  phone: json.items[i].Telefone_c,
                  logo: json.items[i].URL_Logo_c,
                  email: json.items[i].Email_c,
                  site: json.items[i].Site_c,
                };
                labsa.push(pushObj);
              }
            }
          }
          if (labsa.length === 1) {
            this.setLab({ nome: labsa[0].nome, cnpj: labsa[0].cnpj }, this.context.hideLoading);
          }

          this.setState({ labs: labsa }, this.context.hideLoading);
          this.props.handleChangeProps(labsa,'laboratorios');
          if (this.context.userSession.conta !== "" && this.context.userSession.papelCategoria === "LABORATORIO") {
            let combo = document.getElementById("lab");
            combo.value = this.context.userSession.conta;
            this.changeComboLabUser(combo);
          }
        });
    }
    else {
      this.setState({labs:this.props.labs})
      

    }
  }

  changeComboLabUser(e) {
    var value = "";
    var label = "";
    var detail = false;
    var params = null;

    this.state.labs.find((item, index) => {
      if (item.value === this.context.userSession.conta) {
        detail = item.detailOrder;
        label = item.label;
        if (item.phone || item.email || item.site || item.logo) {
          params = {
            phone: item.phone,
            logo: item.logo,
            email: item.email,
            site: item.site,
          };
        }
      }
    });

    value = e;

    this.props.onChange(value, label, detail, params);
    this.context.hideLoading();
  }

  handleChange(e) {
    var value = "";
    var label = "";
    var detail = false;
    var params = null;

    if (e.target.value != "default") {
      this.state.labs.find((item, index) => {
        if (item.value === e.target.value) {
          detail = item.detailOrder;
          label = item.label;
          if (item.phone || item.email || item.site || item.logo) {
            params = {
              phone: item.phone,
              logo: item.logo,
              email: item.email,
              site: item.site,
            };
          }
        }
      });

      value = e.target;
    }
    this.props.onChange(value, label, detail, params);
  }

  setLab(e) {
    this.props.onChange(e);
  }

  render() {
    return (
      <Select
        label="Escolha o laboratório *"
        width="100%"
        name="lab"
        id="lab"
        aria="Selecione o laboratório *"
        itens={this.state.labs}
        value={this.props.lab}
        onChange={this.handleChange}
        disabled={false}
        required
      />
    );
  }
}

export default TrackingLabChoose;
