import React, { Component } from "react";
import "./filter-select.css";

class FilterSelect extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { label, name, id, textSelected, itens, value, onChange,width, height, display, displayDiv, justifyContentDiv, alignItemsDiv, flexDirectionDiv, marginRight } = this.props;
    const inputStyle = {
      width: width,
      height: height,
      display: display,
    };
     
    const divStyle = {
      display: displayDiv,
      justifyContent: justifyContentDiv,
      alignItems: alignItemsDiv,
      flexDirection: flexDirectionDiv,
      marginRight: marginRight
    };

    return (
      <div className="divFiltroSelectContainer" style={divStyle}>
        <label className="filtroInputLabel" htmlFor="">
          {label}
        </label>
        <div className="filtroSelectOptionSlected">
          <select id={id} name={name} value={value == undefined ? "" : value} style = {divStyle} onChange={onChange} className="filtroSelect" type="text" onKeyDown={this.props.onKeyDown}>
            <option value="" selected>
              {textSelected}
            </option>
            {itens && itens.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
          <div className="divSetaFiltroSelect">

                      <span className="setaFiltroSelect"></span>

          </div>
        </div>
      </div>
    );
  }
}

export default FilterSelect;
