import React, { Component } from "react";

//Import CSS
import "../financeiro.css";

//Import internal components
import AppContext from "../../appContext";
import TrackingLabChoose from "../financeiro-inputs/tracking-labchoose";
import TrackingInputCNPJ from "../financeiro-inputs/tracking-input-cnpj";
import TrackingSearchButton from "../financeiro-inputs/tracking-search-button";
import TableHead from "./tabela-dropdown/table-head";

//Import external components

import { Redirect } from "react-router-dom";
import Moment from "moment";
import Swal from "sweetalert2";
import { redirectPage } from "../../components/utils/redirectpage";

//Import images

class BuscaFaturas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      laboratorio_nome: "",
      laboratorio_cnpj: "",
      pedido_laboratorio: "",
      pedido_otica: "",
      cnpj_number: this.props.cnpj_number,
      table: null,
      dia_inicio: new Date().setDate(new Date().getDate() - 30),
      dia_fim: new Date(),
      data_inicio: Moment(new Date().setDate(new Date().getDate() - 30)).format("YYYY-MM-DD"),
      data_fim: Moment(new Date()).format("YYYY-MM-DD"),
      resposta_tabela: false,
      loading: false,
      redirectL: false,
      result100: false,
      result404: false,
      result504: false,
      campoCnpjLiberado: false,
      redes: [],
      detailOrder: false,
      open: "",
      arrow: "",
      lab: null,
      status: "",
      statusMessage: null,
      tableExport:[],
    };

    if (localStorage.getItem("token") !== null) {
      BuscaFaturas.contextType = AppContext;
      this.setLabData = this.setLabData.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleChange_data_inicio = this.handleChange_data_inicio.bind(this);
      this.handleChange_data_fim = this.handleChange_data_fim.bind(this);
      this.changeState = this.changeState.bind(this);
      this.integraPedido = this.integraPedido.bind(this);
      this.validarCampos = this.validarCampos.bind(this);

      let role = false;

      role = BuscaFaturas.contextType._currentValue.userSession.modulos.find(element => element === "Financeiro");
      if (!role) {
        this.state = { redirect: true };
      }
    } else {
      this.state = { redirectL: true };
    }
  }

  borderColor(idElement, color = "#f70017") {
    //#f70017  #B5B5B5
    if (typeof idElement === "object") {
      idElement.map(el => {
        if (document.getElementById(el)) {
          return (document.getElementById(el).style.boxShadow = `0 0 0 1px ${color}`);
        }
        return null;
      });
    } else {
      document.getElementById(idElement).style.boxShadow = `0 0 0 1px ${color}`;
    }
  }

  setLabData(e, label, detailOrder, params) {
    if (e.name === "lab") {
      this.props.handleChangeProps(e);
    }
    if (e.value) {
      if (this.context.userSession.papelCategoria === "LABORATORIO") {
        if (e.value === this.context.userSession.conta) {
          this.setState({ cnpj_number: this.state.cnpj_number_aux });
          this.props.handleChangeProps("campoCnpjLiberado", "boolean", true);
        } else {
          this.setState({ cnpj_number_aux: this.props.cnpj_number, cnpj_number: "" });
          this.props.handleChangeProps("campoCnpjLiberado", "boolean", false);
        }
      } else {
        this.props.handleChangeProps("campoCnpjLiberado", "boolean", true);
      }

      if (this.context.userSession.redeOticas) {
        this.context.showLoading();
        this.props.listarRedesOticas(e.value);
      }

      this.setState({
        lab: params,
      });
      this.setState({
        laboratorio_nome: label,
        laboratorio_cnpj: e.value,
        detailOrder: detailOrder,
      });
      this.borderColor("lab", "#B5B5B5");
    } else {
      this.setState({
        laboratorio_nome: "",
        laboratorio_cnpj: "",
        detailOrder: false,
        lab: null,
      });
    }
    var dadosLab = {
      dados: params,
      laboratorio_nome: label || "",
      laboratorio_cnpj: e.value || "",
      detailOrder: detailOrder || "",
    };

    this.props.handleChangeProps(dadosLab, "dadosLab");
  }

  handleChange(e) {
    let state = {};
    const name = e.target.getAttribute("name");
    const { value } = e.target;
    this.props.handleChangeProps(e.target);

    if (e.target.getAttribute("name") === "pedido_laboratorio") {
      if (!Number(value) && value !== "") {
        return;
      }
    }
    state[name] = value ? value : "";
    this.setState(state);
    this.borderColor(name, "#B5B5B5");
  }

  handleChange_data_inicio = date => {
    //trata o formato da data
    const diaInicio = date ? Moment(date).format("YYYY-MM-DD") : "";
    this.setState({
      //seta data para integração
      data_inicio: diaInicio,
      //seta data para exibir no input date
      dia_inicio: date,
    });
  };

  handleChange_data_fim = date => {
    //trata o formato da data
    const diaFim = date ? Moment(date).format("YYYY-MM-DD") : "";
    this.setState({
      //seta data para integração
      data_fim: diaFim,
      //seta data para exibir no input date
      dia_fim: date,
    });
  };

  changeState() {
    this.borderColor(["lab", "cnpj_number"], "#B5B5B5");
    this.borderColor(["data_inicio", "data_fim", "pedido_otica"], "#FFF");
    this.setState({
      loading: true,
      data_tabela: [],
      resposta_tabela: false,
    });

    let valido = this.validarCampos();
    if (valido) {
      this.context.showLoading();
      this.integraPedido();
    }
  }

  validarCampos() {
    if (!Boolean(this.props.laboratorio_dados?.laboratorio_nome)) {
      this.setState({
        loading: false,
      });
      this.context.hideLoading();
      Swal.fire({
        text: "Selecione um laboratório.",
        title: "Aviso",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      this.borderColor("lab");
      return;
    } else if (this.context.userSession.redeOticas && this.props.redeOticaAux && !this.props.cnpj_number) {
      this.setState({
        loading: false,
      });
      this.context.hideLoading();
      Swal.fire({
        text: "Selecione uma ótica.",
        title: "Aviso",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      this.borderColor("cnpj_number");
      return;
    } else if (this.props.input_cnpj && this.props.redeOticaAux && !this.props.cnpj_number && !this.context.userSession.redeOticas) {
      this.setState({
        loading: false,
      });
      this.context.hideLoading();
      Swal.fire({
        text: "O campo CNPJ é obrigatório.",
        title: "Aviso",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      this.borderColor("cnpj_number");
      return;
    }

    if (this.state.data_inicio === "" || this.state.data_fim === "") {
      this.setState({
        loading: false,
      });
      this.context.hideLoading();
      Swal.fire({
        text: " Por favor, informe um período válido para o seu pedido.",
        title: "Aviso",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      this.borderColor(["data_inicio", "data_fim"]);
      return;
    } else if (this.state.data_inicio > this.state.data_fim) {
      this.setState({
        loading: false,
      });
      this.context.hideLoading();
      Swal.fire({
        text: "Período da busca inválido: Data inicial maior que data final.",
        title: "Aviso",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      this.borderColor(["data_inicio", "data_fim"]);
      return;
    } else {
      let ini = new Date(this.state.data_inicio);
      let fim = new Date(this.state.data_fim);
      let diff = (fim.getTime() - ini.getTime()) / 1000 / 3600 / 24;
      if (diff > 60) {
        this.setState({
          loading: false,
        });
        this.context.hideLoading();
        Swal.fire({
          text: "O período de busca não pode ser superior a 60 dias.",
          title: "Aviso",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
        this.borderColor(["data_inicio", "data_fim"]);
        return;
      }
    }
    return true;
  }

  async integraPedido() {
    this.setState({ table: [] });
    const requestOptions = {
      method: "GET",
      headers: {
        access_token: localStorage.getItem("token"),
        client_id: localStorage.getItem("clientid"),
        laboratorio: this.props.laboratorio_dados.laboratorio_cnpj,
        otica: this.props.cnpj_number.replace(/[^0-9]+/g, ""),
      },
    };
    var finalData = [];
    var statuses = ["BAIXADO", "PENDENTE"];
    for (let status of statuses) {
      let data = [];

      const response = await fetch(process.env.REACT_APP_ESSILOR_API + `gestao-financeira-cliente-laboratorio/v1/faturas?status=${status}`, requestOptions);

      if (response.status === 200) {
        const datas = await response.json();

        datas.forEach(fatura => {
          data.push({
            codigo: fatura.codigo,
            parcela: fatura.parcela,
            nDocumento: fatura.numeroDocumento,
            dataEmissao: fatura.dataEmissao,
            dataVencimento: fatura.dataVencimento,
            valor: fatura.valor,
            valorAberto: fatura.valorAberto,
            status: fatura.status,
          });
        });

        finalData = [...finalData, ...data];
      } else {
        Swal.fire({
          text: "Falha de comunicação com o laboratório. Tente novamente mais tarde.",
          title: "Aviso",
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
        break;
      }
    }
    var groupedData = finalData.reduce((accumulator, currentValue) => {
      const documentNumber = currentValue.nDocumento;
      const today = new Date();
      const next15Days = new Date(today);
      next15Days.setDate(today.getDate() + 15);

      const existingGroup = accumulator.find(group => {
        const existingDocumentNumber = group["N° Documento"];

        if (existingDocumentNumber.replace(/(.*?)[./].*/, "$1") === documentNumber.replace(/(.*?)[./].*/, "$1")) {
          return true;
        }
      });

      const parcelaFirstPart = currentValue.parcela.replace(/\D/g, "").substring(0, 2);
      const parcelaSecondPart = currentValue.parcela.replace(/\D/g, "").substring(2);
      const parcela = `${parcelaFirstPart.substring(0, 1)}-${parcelaFirstPart.substring(1)}/${parcelaSecondPart}`;
      const isBaixado = currentValue.status === "BAIXADO";
      const valorNumerico = Math.round(currentValue.valor * 100) / 100;
      const valorAbertoNumerico = isBaixado ? 0 : Math.round(currentValue.valorAberto * 100) / 100;

      let status;
      const dataVencimento = new Date(currentValue.dataVencimento);

      if (dataVencimento < today && valorAbertoNumerico > 0) {
        currentValue.status = "Vencido";
      } else if (dataVencimento <= next15Days && valorAbertoNumerico > 0) {
        currentValue.status = "A Vencer";
      } else if (valorAbertoNumerico > 0) {
        currentValue.status = "Em Aberto";
      } else {
        currentValue.status = "Pago";
      }
      currentValue.parcela = parcela;

      if (existingGroup) {
        existingGroup.detalhes.push(currentValue);

        existingGroup.valor = (parseFloat(valorNumerico) + parseFloat(existingGroup.valor)).toFixed(2);

        if (!isBaixado && existingGroup.status === "BAIXADO") {
          existingGroup.status = "EM ABERTO";
        }
      } else {
        accumulator.push({
          "N° Documento": documentNumber.replace(/(.*?)[./].*/, "$1"),
          "Data Emissão": Moment(currentValue.dataEmissao).format("DD/MM/YYYY"),
          valor: valorNumerico,
          status: status,
          detalhes: [currentValue],
        });
      }
      return accumulator;
    }, []);

    groupedData.forEach(documento => {
      const detalhes = documento.detalhes;
      const temStatusEmAberto = detalhes.some(detalhe => detalhe.status !== "Pago");
      if (temStatusEmAberto) {
        documento.status = "EM ABERTO";
      } else {
        documento.status = "PAGO";
      }
    });
    this.setState({
      table: groupedData,
      APIData: finalData,
      resposta_tabela: true,
      loading: false,
    });
    this.context.hideLoading();
  }

  render() {
    const { redirectL } = this.state;

    if (this.state.redirect) {
      return <Redirect to="/home" />;
    }

    if (redirectL) {
      return <Redirect to="/" />;
    }
    var headers = [];
    var tableData = [];
    if (this.state.table?.length >= 1) {
      headers = Object.keys(this.state.table[0]).filter(header => header !== "detalhes");
      tableData = this.state.table.map(item => ({ ...item }));
    }
    const containerSelectInput = this.props.redeOticaAux === true ? "col-md-5" : "col-md-5 mx-auto";
    return (
      <>
        <div id="main-financeiro">
          <div className="row d-flex justify-content-center ">
            <div className={`col-xs-6 ${containerSelectInput}`}>
              <TrackingLabChoose
                onChange={this.setLabData}
                lab={this.props.laboratorio}
                labs={this.props.laboratorios}
                handleChangeProps={this.props.handleChangeProps}
              />
            </div>

            {this.props.input_cnpj && this.props.redeOticaAux && (
              <div className={`col-xs-6 ${containerSelectInput}`}>
                <TrackingInputCNPJ
                  name="cnpj_number"
                  value={this.props.cnpj_number}
                  onChange={this.handleChange}
                  disabled={!this.props.campoCnpjLiberado}
                  redes={this.props.redes}
                />
              </div>
            )}
          </div>

          <div className="row">
            <TrackingSearchButton onClick={this.changeState} />
          </div>

          <section ref={ref => (this.myRef = ref)} className="mt-4">
            {this.state.table?.length >= 1 && (
              <TableHead headers={headers} data={this.state.table} APIData={this.state.APIData} info={this.props.laboratorio_dados} cnpj_number={this.props.cnpj_number} />
            )}
            {this.state.table !== null && this.state.table?.length === 0 && (
              <div className="noReponse">
                <p className="tracking-filters-label text-center">Nenhum registro encontrado.</p>
              </div>
            )}
          </section>
        </div>
      </>
    );
  }
}

export default BuscaFaturas;
