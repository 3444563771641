import React, { Component } from 'react'

//Import CSS
import 'bootstrap/dist/css/bootstrap.min.css'

//Import internal components

//Import external components
import Card from 'react-bootstrap/Card'

//Import images

class OrderSummaryPerscription extends Component {

	constructor(props) {
		super(props)
		this.state = {

		}
	}

	render() {

		const { order } = this.props

		return (

			<Card.Body>

				<h3 className="x-web-ordering-pedido__summary--text">Receita</h3>

				{(order.prescricao_olhos === 'ambos') &&

					<div className="x-web-ordering-pedido__summary--group">

						<div className="x-web-ordering-pedido__summary--side-left">

							<h3 className="x-web-ordering-pedido__summary--text-sub">Direito</h3>

							<ul className="x-web-ordering-pedido__summary--card left">
								<li className="x-web-ordering-pedido__summary--subitem">Esférico</li>
								<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_direito_esferico || '-'}</li>
							</ul>

							<ul className="x-web-ordering-pedido__summary--card left">
								<li className="x-web-ordering-pedido__summary--subitem">Cilíndrico</li>
								<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_direito_cilindrico || '-'}</li>
							</ul>

							<ul className="x-web-ordering-pedido__summary--card left">
								<li className="x-web-ordering-pedido__summary--subitem">Eixo</li>
								<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_direito_eixo || '-'}</li>
							</ul>

							<ul className="x-web-ordering-pedido__summary--card left">
								<li className="x-web-ordering-pedido__summary--subitem">Adição</li>
								<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_direito_adicao || '-'}</li>
							</ul>

						</div>

						<div className="x-web-ordering-pedido__summary--side-right">

							<h3 className="x-web-ordering-pedido__summary--text-sub">Esquerdo</h3>

							<ul className="x-web-ordering-pedido__summary--card right">
								<li className="x-web-ordering-pedido__summary--subitem">Esférico</li>
								<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_esferico || '-'}</li>
							</ul>

							<ul className="x-web-ordering-pedido__summary--card right">
								<li className="x-web-ordering-pedido__summary--subitem">Cilíndrico</li>
								<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_cilindrico || '-'}</li>
							</ul>

							<ul className="x-web-ordering-pedido__summary--card right">
								<li className="x-web-ordering-pedido__summary--subitem">Eixo</li>
								<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_eixo || '-'}</li>
							</ul>

							<ul className="x-web-ordering-pedido__summary--card right">
								<li className="x-web-ordering-pedido__summary--subitem">Adição</li>
								<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_adicao || '-'}</li>
							</ul>

						</div>

					</div>
				}

				{(order.prescricao_olhos === 'direito') &&

					<div>

						<h3 className="x-web-ordering-pedido__summary--text-sub">Direito</h3>

						<ul className="x-web-ordering-pedido__summary--card shape">
							<li className="x-web-ordering-pedido__summary--subitem">Esférico</li>
							<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_direito_esferico || '-'}</li>
						</ul>

						<ul className="x-web-ordering-pedido__summary--card shape">
							<li className="x-web-ordering-pedido__summary--subitem">Cilíndrico</li>
							<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_direito_cilindrico || '-'}</li>
						</ul>

						<ul className="x-web-ordering-pedido__summary--card shape">
							<li className="x-web-ordering-pedido__summary--subitem">Eixo</li>
							<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_direito_eixo || '-'}</li>
						</ul>

						<ul className="x-web-ordering-pedido__summary--card shape">
							<li className="x-web-ordering-pedido__summary--subitem">Adição</li>
							<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_direito_adicao || '-'}</li>
						</ul>

					</div>
				}

				{(order.prescricao_olhos === 'esquerdo') &&

					<div >

						<h3 className="x-web-ordering-pedido__summary--text-sub">Esquerdo</h3>

						<ul className="x-web-ordering-pedido__summary--card shape">
							<li className="x-web-ordering-pedido__summary--subitem">Esférico</li>
							<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_esferico || '-'}</li>
						</ul>

						<ul className="x-web-ordering-pedido__summary--card shape">
							<li className="x-web-ordering-pedido__summary--subitem">Cilíndrico</li>
							<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_cilindrico || '-'}</li>
						</ul>

						<ul className="x-web-ordering-pedido__summary--card shape">
							<li className="x-web-ordering-pedido__summary--subitem">Eixo</li>
							<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_eixo || '-'}</li>
						</ul>

						<ul className="x-web-ordering-pedido__summary--card shape">
							<li className="x-web-ordering-pedido__summary--subitem">Adição</li>
							<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_adicao || '-'}</li>
						</ul>

					</div>
				}

				{(order.prescricao_prisma === 'S') &&
					<h3 className="x-web-ordering-pedido__summary--text">Prisma
					</h3>}

				{(order.prescricao_prisma === 'S') &&
					(order.prescricao_olhos === 'ambos') &&

					<div className="x-web-ordering-pedido__summary--group">

						<div className="x-web-ordering-pedido__summary--side-left">

							<h3 className="x-web-ordering-pedido__summary--text-sub">Direito</h3>

							<ul className="x-web-ordering-pedido__summary--card left">
								<li className="x-web-ordering-pedido__summary--subitem">Horizontal</li>

								<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_direito_pris_h || '-'}</li>

							</ul>

							<ul className="x-web-ordering-pedido__summary--card left">
								<li className="x-web-ordering-pedido__summary--subitem">Base</li>

								<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_direito_base_h || '-'}</li>
							</ul>

							<ul className="x-web-ordering-pedido__summary--card left">
								<li className="x-web-ordering-pedido__summary--subitem">Vertical</li>

								<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_direito_pris_v || '-'}</li>
							</ul>

							<ul className="x-web-ordering-pedido__summary--card left">
								<li className="x-web-ordering-pedido__summary--subitem">Base</li>

								<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_direito_base_v || '-'}</li>
							</ul>

						</div>

						<div className="x-web-ordering-pedido__summary--side-right">

							<h3 className="x-web-ordering-pedido__summary--text-sub">Esquerdo</h3>

							<ul className="x-web-ordering-pedido__summary--card right">
								<li className="x-web-ordering-pedido__summary--subitem">Horizontal</li>

								<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_pris_h || '-'}</li>
							</ul>

							<ul className="x-web-ordering-pedido__summary--card right">
								<li className="x-web-ordering-pedido__summary--subitem">Base</li>

								<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_base_h || '-'}</li>
							</ul>

							<ul className="x-web-ordering-pedido__summary--card right">
								<li className="x-web-ordering-pedido__summary--subitem">Vertical</li>

								<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_pris_v || '-'}</li>
							</ul>

							<ul className="x-web-ordering-pedido__summary--card right">
								<li className="x-web-ordering-pedido__summary--subitem">Base</li>

								<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_base_v || '-'}</li>
							</ul>

						</div>

					</div>
				}

				{(order.prescricao_prisma === 'S') &&
					(order.prescricao_olhos === 'esquerdo') &&

					<div >

						<h3 className="x-web-ordering-pedido__summary--text-sub">Esquerdo</h3>

						<ul className="x-web-ordering-pedido__summary--card right">
							<li className="x-web-ordering-pedido__summary--subitem">Horizontal</li>

							<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_pris_h || '-'}</li>
						</ul>

						<ul className="x-web-ordering-pedido__summary--card right">
							<li className="x-web-ordering-pedido__summary--subitem">Base</li>

							<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_base_h || '-'}</li>
						</ul>

						<ul className="x-web-ordering-pedido__summary--card right">
							<li className="x-web-ordering-pedido__summary--subitem">Vertical</li>

							<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_pris_v || '-'}</li>
						</ul>

						<ul className="x-web-ordering-pedido__summary--card right">
							<li className="x-web-ordering-pedido__summary--subitem">Base</li>

							<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_base_v || '-'}</li>
						</ul>


					</div>
				}

				{(order.prescricao_prisma === 'S') &&
					(order.prescricao_olhos === 'direito') &&

					<div >

						<h3 className="x-web-ordering-pedido__summary--text-sub">Direito</h3>

						<ul className="x-web-ordering-pedido__summary--card left">
							<li className="x-web-ordering-pedido__summary--subitem">Horizontal</li>

							<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_direito_pris_h || '-'}</li>
						</ul>

						<ul className="x-web-ordering-pedido__summary--card left">
							<li className="x-web-ordering-pedido__summary--subitem">Base</li>

							<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_direito_base_h || '-'}</li>
						</ul>

						<ul className="x-web-ordering-pedido__summary--card left">
							<li className="x-web-ordering-pedido__summary--subitem">Vertical</li>

							<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_direito_pris_v || '-'}</li>
						</ul>

						<ul className="x-web-ordering-pedido__summary--card left">
							<li className="x-web-ordering-pedido__summary--subitem">Base</li>

							<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_direito_base_v || '-'}</li>
						</ul>
					</div>
				}

				<h3 className="x-web-ordering-pedido__summary--text">Medidas do cliente</h3>

				{(order.prescricao_olhos === 'ambos') &&

					<div className="x-web-ordering-pedido__summary--group">

						<div className="x-web-ordering-pedido__summary--side-left">
							<h3 className="x-web-ordering-pedido__summary--text-sub">Direito</h3>

							<ul className="x-web-ordering-pedido__summary--card left">
								<li className="x-web-ordering-pedido__summary--subitem">DNP</li>
								<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_direito_dnp || '-'}</li>
							</ul>

							<ul className="x-web-ordering-pedido__summary--card left">
								<li className="x-web-ordering-pedido__summary--subitem">Altura</li>
								<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_direito_altura || '-'}</li>
							</ul>

							{order.dnpPerto && <ul className="x-web-ordering-pedido__summary--card left">
								<li className="x-web-ordering-pedido__summary--subitem">DNP perto</li>
								<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_direito_dnp_perto || '-'}</li>
							</ul>}


							{order.prescricao_cro_direito && <ul className="x-web-ordering-pedido__summary--card shape">
								<li className="x-web-ordering-pedido__summary--subitem">CRO</li>
								<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_cro_direito || '-'}</li>
							</ul>}



							{order.prescricao_dis_vert_direito && <ul className="x-web-ordering-pedido__summary--card shape">
								<li className="x-web-ordering-pedido__summary--subitem">Distância vértice</li>
								<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_dis_vert_direito || '-'}</li>
							</ul>}

							{order.prescricao_olho_direito_et && <ul className="x-web-ordering-pedido__summary--card shape">
								<li className="x-web-ordering-pedido__summary--subitem">ET</li>
								<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_direito_et || '-'}</li>
							</ul>}

							{order.prescricao_olho_direito_ct && <ul className="x-web-ordering-pedido__summary--card shape">
								<li className="x-web-ordering-pedido__summary--subitem">CT</li>
								<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_direito_ct || '-'}</li>
							</ul>}

							{order.prescricao_olho_direito_base && <ul className="x-web-ordering-pedido__summary--card shape">
								<li className="x-web-ordering-pedido__summary--subitem">Base</li>
								<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_direito_base || '-'}</li>
							</ul>}

						</div>

						<div className="x-web-ordering-pedido__summary--side-right">

							<h3 className="x-web-ordering-pedido__summary--text-sub">Esquerdo</h3>

							<ul className="x-web-ordering-pedido__summary--card right">
								<li className="x-web-ordering-pedido__summary--subitem">DNP</li>
								<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_dnp || '-'}</li>
							</ul>

							<ul className="x-web-ordering-pedido__summary--card right">
								<li className="x-web-ordering-pedido__summary--subitem">Altura</li>
								<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_altura || '-'}</li>
							</ul>

							{order.dnpPerto && <ul className="x-web-ordering-pedido__summary--card right">
								<li className="x-web-ordering-pedido__summary--subitem">DNP perto</li>
								<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_dnp_perto || '-'}</li>
							</ul>}


							{order.prescricao_cro_esquerdo && <ul className="x-web-ordering-pedido__summary--card shape">
								<li className="x-web-ordering-pedido__summary--subitem">CRO</li>
								<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_cro_esquerdo || '-'}</li>
							</ul>}



							{order.prescricao_dis_vert_esquerdo && <ul className="x-web-ordering-pedido__summary--card shape">
								<li className="x-web-ordering-pedido__summary--subitem">Distância vértice</li>
								<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_dis_vert_esquerdo || '-'}</li>
							</ul>}


							{order.prescricao_olho_esquerdo_et && <ul className="x-web-ordering-pedido__summary--card right">
								<li className="x-web-ordering-pedido__summary--subitem">ET</li>
								<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_et || '-'}</li>
							</ul>}

							{order.prescricao_olho_esquerdo_ct && <ul className="x-web-ordering-pedido__summary--card right">
								<li className="x-web-ordering-pedido__summary--subitem">CT</li>
								<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_ct || '-'}</li>
							</ul>}

							{order.prescricao_olho_esquerdo_base && <ul className="x-web-ordering-pedido__summary--card right">
								<li className="x-web-ordering-pedido__summary--subitem">Base</li>
								<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_base || '-'}</li>
							</ul>}

						</div>

					</div>
				}
				{(order.prescricao_olhos === 'esquerdo') &&

					<div>

						<h3 className="x-web-ordering-pedido__summary--text-sub">Esquerdo</h3>

						<ul className="x-web-ordering-pedido__summary--card shape">
							<li className="x-web-ordering-pedido__summary--subitem">DNP</li>
							<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_dnp || '-'}</li>
						</ul>

						<ul className="x-web-ordering-pedido__summary--card shape">
							<li className="x-web-ordering-pedido__summary--subitem">Altura</li>
							<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_altura || '-'}</li>
						</ul>

						{order.dnpPerto && <ul className="x-web-ordering-pedido__summary--card shape">
							<li className="x-web-ordering-pedido__summary--subitem">DNP perto</li>
							<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_dnp_perto || '-'}</li>
						</ul>}


						{order.prescricao_cro_esquerdo && <ul className="x-web-ordering-pedido__summary--card shape">
							<li className="x-web-ordering-pedido__summary--subitem">CRO</li>
							<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_cro_esquerdo || '-'}</li>
						</ul>}



						{order.prescricao_dis_vert_esquerdo && <ul className="x-web-ordering-pedido__summary--card shape">
							<li className="x-web-ordering-pedido__summary--subitem">Distância vértice</li>
							<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_dis_vert_esquerdo || '-'}</li>
						</ul>}


						{order.prescricao_olho_esquerdo_et && <ul className="x-web-ordering-pedido__summary--card shape">
							<li className="x-web-ordering-pedido__summary--subitem">ET</li>
							<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_et || '-'}</li>
						</ul>}

						{order.prescricao_olho_esquerdo_ct && <ul className="x-web-ordering-pedido__summary--card shape">
							<li className="x-web-ordering-pedido__summary--subitem">CT</li>
							<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_ct || '-'}</li>
						</ul>}

						{order.prescricao_olho_esquerdo_base && <ul className="x-web-ordering-pedido__summary--card shape">
							<li className="x-web-ordering-pedido__summary--subitem">Base</li>
							<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_base || '-'}</li>
						</ul>}

					</div>

				}

				{(order.prescricao_olhos === 'direito') &&
					<div>

						<h3 className="x-web-ordering-pedido__summary--text-sub">Direito</h3>

						<ul className="x-web-ordering-pedido__summary--card shape">
							<li className="x-web-ordering-pedido__summary--subitem">DNP</li>
							<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_direito_dnp || '-'}</li>
						</ul>

						<ul className="x-web-ordering-pedido__summary--card shape">
							<li className="x-web-ordering-pedido__summary--subitem">Altura</li>
							<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_direito_altura || '-'}</li>
						</ul>

						{order.control_dnpPerto && <ul className="x-web-ordering-pedido__summary--card shape">
							<li className="x-web-ordering-pedido__summary--subitem">DNP perto</li>
							<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_direito_dnp_perto || '-'}</li>
						</ul>}


						{order.prescricao_cro_direito && <ul className="x-web-ordering-pedido__summary--card shape">
							<li className="x-web-ordering-pedido__summary--subitem">CRO</li>
							<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_cro_direito || '-'}</li>
						</ul>}



						{order.prescricao_dis_vert_direito && <ul className="x-web-ordering-pedido__summary--card shape">
							<li className="x-web-ordering-pedido__summary--subitem">Distância vértice</li>
							<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_dis_vert_direito || '-'}</li>
						</ul>}

						{order.prescricao_olho_direito_et && <ul className="x-web-ordering-pedido__summary--card shape">
							<li className="x-web-ordering-pedido__summary--subitem">ET</li>
							<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_direito_et || '-'}</li>
						</ul>}

						{order.prescricao_olho_direito_ct && <ul className="x-web-ordering-pedido__summary--card shape">
							<li className="x-web-ordering-pedido__summary--subitem">CT</li>
							<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_direito_ct || '-'}</li>
						</ul>}

						{order.prescricao_olho_direito_base && <ul className="x-web-ordering-pedido__summary--card shape">
							<li className="x-web-ordering-pedido__summary--subitem">Base</li>
							<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_direito_base || '-'}</li>
						</ul>}

					</div>
				}

				{(
					order.prescricao_ini_clientes ||
					order.prescricao_dis_leitura ||
					order.prescricao_cvp ||
					order.prescricao_ang_pantoscopico ||
					order.prescricao_curva_arm ||
					order.prescricao_coeficiente ||
					order.prescricao_estabilidade ||
					order.prescricao_dis_prog ||
					order.prescricao_olho_dom
				) &&
					<h3 className="x-web-ordering-pedido__summary--text">Medidas personalizadas</h3>}

				{order.prescricao_ini_clientes &&
					<ul className="x-web-ordering-pedido__summary--card shape">
						<li className="x-web-ordering-pedido__summary--subitem">Iniciais do cliente</li>
						<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_ini_clientes || '-'}</li>
					</ul>
				}

				{order.prescricao_dis_leitura &&
					<ul className="x-web-ordering-pedido__summary--card shape">
						<li className="x-web-ordering-pedido__summary--subitem">Distância de Leitura</li>
						<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_dis_leitura || '-'}</li>
					</ul>
				}

				{order.prescricao_cvp &&
					<ul className="x-web-ordering-pedido__summary--card shape">
						<li className="x-web-ordering-pedido__summary--subitem">CVP</li>
						<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_cvp || '-'}</li>
					</ul>
				}

				{/* {order.prescricao_wmap &&
					<ul className="x-web-ordering-pedido__summary--card shape">
						<li className="x-web-ordering-pedido__summary--subitem">Wmap</li>
						<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_wmap || '-'}</li>
					</ul>
				} */}

				{order.prescricao_ang_pantoscopico &&
					<ul className="x-web-ordering-pedido__summary--card shape">
						<li className="x-web-ordering-pedido__summary--subitem">Ângulo pantoscópico</li>
						<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_ang_pantoscopico || '-'}</li>
					</ul>
				}

				{order.prescricao_curva_arm &&
					<ul className="x-web-ordering-pedido__summary--card shape">
						<li className="x-web-ordering-pedido__summary--subitem">Curvatura da armação</li>
						<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_curva_arm || '-'}</li>
					</ul>
				}

				{order.prescricao_coeficiente &&
					<ul className="x-web-ordering-pedido__summary--card shape">
						<li className="x-web-ordering-pedido__summary--subitem">Coeficiente cabeça-olhos</li>
						<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_coeficiente || '-'}</li>
					</ul>
				}

				{order.prescricao_estabilidade &&
					<ul className="x-web-ordering-pedido__summary--card shape">
						<li className="x-web-ordering-pedido__summary--subitem">Estabilidade</li>
						<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_estabilidade || '-'}</li>
					</ul>
				}

				{order.prescricao_dis_prog &&
					<ul className="x-web-ordering-pedido__summary--card shape">
						<li className="x-web-ordering-pedido__summary--subitem">Distância de progressão</li>
						<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_dis_prog || '-'}</li>
					</ul>
				}

				{order.prescricao_olho_dom &&
					<ul className="x-web-ordering-pedido__summary--card shape">
						<li className="x-web-ordering-pedido__summary--subitem">Olho dominante</li>
						<li className="x-web-ordering-pedido__summary--subitem right">{order.prescricao_olho_dom || '-'}</li>
					</ul>
				}


			</Card.Body>
		)
	}
}

export default OrderSummaryPerscription