import React, { Component, Fragment } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

//Import CSS

//Import internal components

//Import external components
import Select, { components, DropdownIndicatorProps, createFilter } from 'react-select';

//Import images

class AutoCompleteSelect extends Component {

    constructor(props) {
        super(props);

        this.selectRef = React.createRef();

        this.state = {
            options: [],
            isClearable: true,
            isDisabled: false,
            isLoading: false,
            isRtl: false,
            isSearchable: true,
        };

        this.onChange = this.onChange.bind(this);
    }

    componentDidMount() {

    }

    clearValue = () => {
        const node = this.selectRef.current;
        node.select.clearValue();
    };

    setValue = (newValue) => {
        const node = this.selectRef.current;
        node.select.setValue(newValue);
    };

    mountOptions(options) {
        var optionsAux = options.map((item) => {
            return { value: item.codigo, label: item.descricao }
        })

        optionsAux.sort(function (a, b) {
            let x = a.label.toUpperCase(),
                y = b.label.toUpperCase();

            return x == y ? 0 : x > y ? 1 : -1;
        });
        return optionsAux;
    }

    onChange() {

    }

    render() {

        var options = this.mountOptions(this.props.options ?? []);

        const filterConfig = {
            ignoreCase: true,
            ignoreAccents: true,
            trim: true,
            matchFrom: 'any',
            stringify: option => `${option.label} ${option.value}`,
        };

        return (
            <Fragment>
                <div className="" style={{ width: this.props.width, position: 'relative' }}>

                    <label className="auto-complete-input" htmlFor={this.props.name}>
                        {this.props.label}
                    </label>
                    <Select
                        ref={this.selectRef}
                        style={{ width: this.props.width }}
                        className={this.props.className}
                        classNamePrefix="select"
                        defaultValue={this.props.defaultValue}
                        isDisabled={this.props.disabled}
                        name={this.props.name}
                        id={this.props.id}
                        placeholder={this.props.placeholder}
                        onChange={this.props.onChange}
                        options={options}
                        isLoading={false}
                        isClearable={false}
                        isRtl={false}
                        isSearchable={true}
                        filterOption={createFilter(filterConfig)}

                        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
                    />
                    {this.props.tooltip && <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        style={{ zIndex: "11" }}
                        overlay={<Tooltip id="button-tooltip">{this.props.tooltip}</Tooltip>}
                    >
                        <span className="tooltip-shape auto-complete-tooltip-shape">
                        </span>
                    </OverlayTrigger>}
                </div>
            </Fragment>

        )
    }
}

export default AutoCompleteSelect