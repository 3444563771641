import React, { Component } from "react";

import Swal from "sweetalert2";
import "./index.css";
import AppContext from "../../appContext";
import DownloadXLSX from "../../components/utils/download-file/tag-a/automatic-download-user-machine/XLSX";

class Script extends Component {
  constructor(props) {
    super(props);

    this.state = {
      exportData: [],
    };
    this.handle = this.handle.bind(this);
    this.downloadXLSXRef = React.createRef();

    Script.contextType = AppContext;
  }
  async handle() {
    const resultados = [];
    let contadorLab = 0;
    for (const item of this.props.laboratorios) {
      contadorLab += 1;
      const requestOptions = {
        method: "POST",
        body: JSON.stringify({
          cnpj: item.value,
          access_token: localStorage.getItem("token"),
        }),
      };
      try {
        const response = await fetch(process.env.REACT_APP_LABS_API + "get_rpls.php", requestOptions);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        this.context.showLoading("Buscando dados dos laboratórios " + contadorLab + "/" + this.props.laboratorios.length);

        const data = await response.json();

        try {
          await this.processBase(data, "IPREMIOS", item.label);
        } catch (error) {
          console.log(error);
        }
        try {
          await this.processBase(data, "SHOP9", item.label);
        } catch (error) {
          console.log(error);
        }
      } catch (error) {
        console.log(error);
      }
    }
    this.context.showLoading("Gerando o arquivo...");

    setTimeout(async () => {
      this.downloadXLSXRef.downloadXLSX(this.state.exportData);
    }, 1000);
    setTimeout(async () => {
      this.context.hideLoading();
      this.setState({ exportData: [] });
    }, 1000);
  }
  async processBase(data, base, value) {
    const items = data[base].items.rpls;
    const total = items.length;
    let okCount = 0;
    let naoEncontradoCount = 0;
    let duplicadoCount = 0;
    items.forEach(rpl => {
      switch (rpl.status) {
        case "OK":
          okCount++;
          break;
        case "NÃO ENCONTRADO":
          naoEncontradoCount++;
          break;
        case "DUPLICIDADE":
          duplicadoCount++;
          break;
      }
    });

    const okPercentage = ((okCount / total) * 100).toFixed(0) + "%";
    const naoEncontradoPercentage = ((naoEncontradoCount / total) * 100).toFixed(0) + "%";
    const duplicadoPercentage = ((duplicadoCount / total) * 100).toFixed(0) + "%";
    this.state.exportData.push({
      BASE: base,
      LABORATÓRIO: value,
      "DATA EXECUÇÃO": new Date(data[base].items.dataExecucao).toLocaleDateString("pt-BR"),
      OK: okPercentage,
      "NÃO ENCONTRADO": naoEncontradoPercentage,
      DUPLICADO: duplicadoPercentage,
    });
  }
  render() {
    return (
      <div className="containerLink">
        <DownloadXLSX
          download
          onClick={this.handle}
          data={this.state.exportData}
          fileName={"Cadastros - Export"}
          linkName={"Cadastros - Export"}
          ref={ref => (this.downloadXLSXRef = ref)}
        />
      </div>
    );
  }
}

export default Script;
