import React, { Component } from "react";

//Import CSS

//Import internal components
import AppContext from "../appContext";
import Input from "../components/inputs/input";
import Select from "../components/selects/select";
import Password from "../components/inputs/password";
import RadioButton from "../components/inputs/radio-button";
import CheckBox from "../components/inputs/check-box";
import CustomButton from "../components/buttons/button";
import Logout from "../main/header/logout";
import { IDS } from "../variables";
//Import external components
import Alert from "sweetalert2";
import Swal from "sweetalert2";

//Import images
import user from "./img/img-user.png";
import OticaManagement from "../otica-management/otica-management";

class PropfileContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: "",
      name: "",
      surname: "",
      datanasc: "",
      civil: "",
      sexo: "",
      email: "",
      cpf: "",
      rg: "",
      telephone: "",
      cellphone: "",
      cep: "",
      end: "",
      num: "",
      complemento: "",
      bairro: "",
      cidade: "",
      uf: "",
      password: "",
      password1: "",
      foto: "",
      opacity: "my-account__register",
      loading: false,
      reload: false,
      disabled: false,
      imageUploaded: null,
      cnpj: "",
      cnpjValido: false,
      newsletter: false,
      nome_conta: "",
      OticaManagement: null,
      papel_usuario: '',
    };

    PropfileContent.contextType = AppContext;
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeAdress = this.handleChangeAdress.bind(this);
    this.handleCheckBox = this.handleCheckBox.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.upload = this.upload.bind(this);
    this.getProfileImg = this.getProfileImg.bind(this);
    this.containsNumber = this.containsNumber.bind(this);
    this.isUsuarioSemCnpj = this.isUsuarioSemCnpj.bind(this);
    this.getNewRole = this.getNewRole.bind(this);
    this.validarCnpj = this.validarCnpj.bind(this);
    this.logoutExterno = this.logoutExterno.bind(this);
    this.handleCallback = this.handleCallback.bind(this);
  }

  componentDidMount() {
    this.setState({
      opacity: "my-account__register register-opacity",
      loading: true,
    });

    this.context.showLoading();

    this.setState(
      {
        email: this.context.userSession.email,
        papel_usuario: this.context.userSession.papel,
      },
      () => this.getNewRole(),
    );

    var myHeaders = new Headers({
      Authorization: "Bearer " + localStorage.getItem("session_token"),
    });

    const requestOptions = {
      method: "POST",
      body: JSON.stringify({ email: this.context.userSession.email }),
      headers: myHeaders,
    };

    fetch(process.env.REACT_APP_LABS_API + "getUserOEC.php", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(json => {
        let newState = {};

        for (let key in json) {
          if (json[key] == null) {
            newState[key] = "";
          } else {
            if (key === "datanasc") {
              var date = json[key].substr(8, 2) + "/" + json[key].substr(5, 2) + "/" + json[key].substr(0, 4);
              newState[key] = date;
            } else {
              newState[key] = json[key];
            }
          }
        }

        newState.opacity = "my-account__register";

        newState.loading = false;

        const callback = () => {
          this.setState(newState);
        };
        this.context.hideLoading();
        this.getProfileImg(json.id, callback);
      });

    const requestOptionsNome = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ cnpj: this.context.userSession.conta }),
    };

    fetch(process.env.REACT_APP_LABS_API + "get_account_data.php", requestOptionsNome)
      .then(response => {
        return response.json();
      })
      .then(json => {
        this.setState({ nome_conta: json.nome });
      });
    this.context.hideLoading();
  }

  handleChange(e) {
    if (e.target.name === "name") {
      e.target.value = e.target.value.substring(0, 50);
    }
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });
  }

  handleChangeAdress(e) {
    const name = e.target.name;
    const value = e.target.value;
    this.setState({ [name]: value });

    var cep = e.target.value.replace(/[^0-9]/, "");

    if (cep.length == 8) {
      const requestOptions = {
        method: "GET",
      };

      fetch("https://viacep.com.br/ws/" + cep + "/json/", requestOptions)
        .then(response => response.json())
        .then(json => {
          if (json.erro) {
            Alert.fire({
              html: "CEP não encontrado.<br />Preencha as informações do endereço manualmente.",
              icon: "warning",
              title: "Aviso",
              confirmButtonColor: "#00518b",
              customClass: {
                confirmButton: "x-follow-order__filter-btn",
              },
            });
          } else {
            this.setState({
              end: json.logradouro,
              bairro: json.bairro,
              cidade: json.localidade,
              uf: json.uf,
            });
          }
        });
    }
  }

  handleCheckBox(e) {
    const { name, checked, id } = e.target;
    this.setState({ [name]: checked });
  }

  upload(url, file, callbackSuccess = null, callbackError = null) {
    let data = new FormData();

    const imageUploadName = this.state.id;
    data.append(imageUploadName, file);

    fetch(url, {
      method: "POST",
      body: data,
      headers: new Headers({
        Authorization: "Bearer " + localStorage.getItem("session_token"),
      }),
    })
      .then(response => {
        return response.json();
      })
      .then(response => callbackSuccess && callbackSuccess(response))
      .catch(error => callbackError && callbackError(error));
  }

  containsNumber(str) {
    return /\d/.test(str);
  }

  logoutExterno(e) {
    // TK-083-000230 - Redirecionamento p/ página de login
    const logout = this.refs.logout.refs.logout;

    this.context.showLoading();
    setTimeout(() => {
      logout.click();
    }, 100);

    window.scrollTo({ top: 0 });
    this.context.hideLoading();
  }

  handleSubmit(e) {
    e.preventDefault();

    this.validarCnpj(() => {
      if (this.state.cnpj) {
        if (!this.state.cnpjValido) {
          Alert.fire({
            text: "CNPJ não encontrado.",
            icon: "warning",
            title: "Aviso",
            confirmButtonColor: "#00518b",
            customClass: {
              confirmButton: "x-follow-order__filter-btn",
            },
          });

          return null;
        }
      }

      document.getElementById("main-body").style.pointerEvents = "none";
      var datanasc = "";

      if (this.state.datanasc !== "") {
        datanasc = this.state.datanasc.substr(6, 9) + "-" + this.state.datanasc.substr(3, 2) + "-" + this.state.datanasc.substr(0, 2);
      }

      if (
        (this.containsNumber(this.state.password) &&
        this.state.password.length >= 5 &&
        !this.state.password.includes("%") &&
        !this.state.password.includes(" ")) || (this.state.password ==='' && this.state.password1 ==='')
      ) {
        this.setState({
          opacity: "my-account__register register-opacity",
          loading: true,
          disabled: true,
        });
        this.context.showLoading();

        var body = {
          name: this.state.name,
          surname: this.state.surname,
          email: this.state.email,
          telephone: this.state.telephone,
          cellphone: this.state.cellphone,
          datanasc: datanasc,
          civil: this.state.civil,
          cep: this.state.cep,
          end: this.state.end,
          numero: this.state.num,
          complemento: this.state.complemento,
          bairro: this.state.bairro,
          cidade: this.state.cidade,
          sexo: this.state.sexo,
          uf: this.state.uf,
          password: this.state.password1,
          newsletter: this.state.newsletter == "" ? false : this.state.newsletter,
        };

        if (this.state.cnpj) {
          body.cnpj = this.state.cnpj
            .replace(/[^0-9]/g, "")
            .replace("/", "")
            .replace("-", "");

          if (this.state.novo_papel) {
            body.novo_papel = this.state.novo_papel;
          }
        }

        const requestOptions = {
          method: "POST",
          body: JSON.stringify(body),
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("session_token"),
          }),
        };

        if (this.state.imageUploaded) {
          this.upload(
            process.env.REACT_APP_LABS_API + "upload_avatar.php",
            this.state.imageUploaded,
            success => console.log("IMG UPLOAD SUCCESS:  ", success),
            error => console.log("IMG UPLOAD ERROR:  ", error),
          );
        }

        fetch(process.env.REACT_APP_LABS_API + "update_user.php", requestOptions)
          .then(response => {
            return response.json();
          })
          .then(json => {
            if (json.status === 200) {
              document.getElementById("main-body").style.pointerEvents = "";
              this.setState({
                opacity: "my-account__register",
                loading: false,
                disabled: false,
              });

              // this.CreateLeads();
              this.context.hideLoading();

              if (this.isUsuarioSemCnpj() && this.state.cnpjValido) {
                Swal.fire({
                  text: "Realize o login novamente para que as alterações sejam salvas.",
                  icon: "success",
                  title: "Pronto!",
                  allowOutsideClick: false,
                  showCancelButton: false,
                  confirmButtonColor: "#00518b",
                  confirmButtonText: "Ok!",
                }).then(result => {
                  if (result.value) {
                    this.logoutExterno();
                  }
                });
              } else {
                Swal.fire({
                  text: "Informações atualizadas com sucesso!",
                  icon: "success",
                  title: "Pronto!",
                  allowOutsideClick: false,
                  showCancelButton: false,
                  confirmButtonColor: "#00518b",
                  confirmButtonText: "Ok!",
                }).then(result => {
                  if (result.value) {
                    this.setState({
                      reload: true,
                    });
                  }
                });
              }
            } else {
              document.getElementById("main-body").style.pointerEvents = "";
              this.setState({
                opacity: "my-account__register",
                loading: false,
                disabled: false,
              });
              this.context.hideLoading();
              Alert.fire({
                text: "Erro ao atualizar informações!",
                icon: "error",
                title: "Aviso",
                confirmButtonColor: "#00518b",
                customClass: {
                  confirmButton: "x-follow-order__filter-btn",
                },
              });
            }
          });
      } else if (this.state.password !== this.state.password1) {
        document.getElementById("main-body").style.pointerEvents = "";
        Alert.fire({
          text: "A confirmação de senha está incorreta!",
          icon: "warning",
          title: "Aviso",
          confirmButtonColor: "#00518b",
          customClass: {
            confirmButton: "x-follow-order__filter-btn",
          },
        });
      } else {
        document.getElementById("main-body").style.pointerEvents = "";

        Alert.fire({
          html: "<div style='display: flex;flex-direction: column;align-items: center;'>Erro ao atualizar informações.<br>Verifique se sua nova senha está dentro das regras permitidas:<br><br><div style='width: 85%;text-align: left;'> &bull; A senha não pode conter o caractere especial %<br>&bull; A senha deve ter um mínimo de 5 caracteres<br>&bull; A senha deve ter no mínimo 1 caractere numérico<br>&bull; A senha não pode conter espaços em branco</div></div>",
          icon: "error",
          title: "Aviso",
          confirmButtonColor: "#00518b",
          customClass: {
            confirmButton: "x-follow-order__filter-btn",
          },
        });
      }
    });
  }

  handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      var base64 = reader.result.replace("data:image/jpeg;base64,", "");

      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
        foto: base64,
      });
    };

    reader.readAsDataURL(file);

    this.setState({
      imageUploaded: e.target.files[0],
    });
  }

  isUsuarioSemCnpj = () => {
    if (!this.context.userSession.conta && this.state.papel_usuario.includes("Usuário não associado")) {
      return true;
    } else {
      return false;
    }
  };

  getNewRole = () => {
    if (this.state.papel_usuario == "Usuário não associado a Ótica") {
      var id = IDS("Novo Usuário Óptica");
      this.setState({ id_novo_papel: id, novo_papel: "Novo Usuário Óptica" });
    } else if (this.state.papel_usuario == "Usuário não associado a CNPJ") {
      var id = IDS("Recepcionista");
      this.setState({ id_novo_papel: id, novo_papel: "Recepcionista" });
    } else if (this.state.papel_usuario == "Usuário não associado a Laboratório") {
      var id = IDS("Novo Usuário Laboratório");
      this.setState({ id_novo_papel: id, novo_papel: "Novo Usuário Laboratório" });
    } else if (this.state.papel_usuario == "Usuário não associado a Lab Independente") {
      var id = IDS("Novo Usuário Lab Independente");
      this.setState({ id_novo_papel: id, novo_papel: "Novo Usuário Lab Independente" });
    }
  };

  getProfileImg(id, callback) {
    const requestOptions = {
      headers: new Headers({
        Authorization: "Bearer " + localStorage.getItem("session_token"),
      }),
    };

    fetch(process.env.REACT_APP_LABS_API + `upload_avatar.php?profileUser=${id}`, requestOptions)
      .then(response => response.json())
      .then(json => {
        if (json.success) {
          this.setState(
            {
              imagePreviewUrl: process.env.REACT_APP_LABS_ROOT + `${json.path}`,
            },
            () => {
              callback();
            },
          );
        } else {
          callback();
        }
      });
  }

  searchCEP() {
    var cep = this.state.cep.replace(/[^0-9]/, "");
    if (cep.length !== 8) {
      return false;
    }
    const requestOptions = {
      method: "GET",
    };
    fetch("https://viacep.com.br/ws/" + cep + "/json/", requestOptions)
      .then(response => response.json())
      .then(json => {
        if (json.erro) {
          Alert.fire({
            html: "CEP não encontrado!<br />Se tem certeza que o CEP está correto, digite as informações manualmente.",
            icon: "error",
            title: "Aviso",
            confirmButtonColor: "#00518b",
            customClass: {
              confirmButton: "x-follow-order__filter-btn",
            },
          });
        } else {
          this.setState({
            end: json.logradouro,
            bairro: json.bairro,
            cidade: json.localidade,
            uf: json.uf,
          });
        }
      });
  }

  validarCnpj = (callback, remocaoUsuario = false) => {
    if (this.isUsuarioSemCnpj() || remocaoUsuario) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          cnpj: this.state.cnpj
            .replace(/[^0-9]/g, "")
            .replace("/", "")
            .replace("-", ""),
        }),
      };
      fetch(process.env.REACT_APP_LABS_API + "get_account_data.php", requestOptions)
        .then(response => {
          return response.json();
        })
        .then(json => {
          if (json.status != 200) {
            this.setState(
              {
                cnpjValido: false,
              },
              () => callback(),
            );
          } else if (
            json.status === 200 &&
            (!json.tipo ||
              json.tipo.includes("REDE") ||
              json.tipo.includes("BALCONISTA") ||
              json.tipo.includes("OTICA") ||
              json.tipo.includes("FORNECEDOR") ||
              json.tipo.includes("CONSUMIDORES") ||
              json.tipo.includes("DISTRIBUIDOR"))
          ) {
            this.setState(
              {
                cnpjValido: true,
                novo_papel: "Novo Usuário Óptica",
                tipo_cnpj: "OTICA",
              },
              () => callback(),
            );
          } else if (json.tipo.includes("CLINICAS") || json.tipo.includes("MEDICO")) {
            this.setState(
              {
                cnpjValido: true,
                novo_papel: "Recepcionista",
                tipo_cnpj: "MEDICO",
              },
              () => callback(),
            );
          } else if (json.tipo.includes("LAB")) {
            if (json.tipo.includes("LABORATORIOS") || json.tipo.includes("LAB ALLIANCE")) {
              this.setState(
                {
                  cnpjValido: true,
                  novo_papel: "Novo Usuário Lab Independente",
                  tipo_cnpj: "LAB_INDEPENDENTE",
                },
                () => callback(),
              );
            } else {
              this.setState(
                {
                  cnpjValido: true,
                  novo_papel: "Novo Usuário Laboratório",
                  tipo_cnpj: "LABORATORIO",
                },
                () => callback(),
              );
            }
          } else if (json.tipo === "AFILIADOS" || json.tipo.includes("EMPREGADOS")) {
            this.setState(
              {
                cnpjValido: true,
                novo_papel: "Novo Usuário Essilor",
                tipo_cnpj: "ESSILOR",
              },
              () => callback(),
            );
          } else {
            callback();
          }
        });
    } else {
      this.setState(
        {
          cnpjValido: true,
        },
        () => callback(),
      );
    }
  };

  //LGPD
  CreateLeads() {
    // var datanasc = "";
    // if (this.state.datanasc !== "") {
    //   datanasc = this.state.datanasc.substr(6, 9) + "-" + this.state.datanasc.substr(3, 2) + "-" + this.state.datanasc.substr(0, 2);
    // }
    // var cep = this.state.cep.replace("-", "");
    // var ddd = "";
    // var cellphone = "";
    // var cellphoneAux = this.state.cellphone.replace("(", "").replace(")", "").replace(" ", "").replace("-", "").trim();
    // if (cellphoneAux === 11) {
    //   ddd = cellphoneAux.slice(0, -9);
    //   cellphone = cellphoneAux.slice(-9);
    // }
    // if (cellphoneAux > 11) {
    //   ddd = cellphoneAux.slice(0, -10);
    //   cellphone = cellphoneAux.slice(-10);
    // }
    // const requestOptions = {
    //   method: "POST",
    //   body: JSON.stringify({
    //     firstName: this.state.name,
    //     lastName: this.state.surname,
    //     cpf: this.state.cpf,
    //     leadStatus: "EM_ABERTO",
    //     leadOrigin: "CONECTA_HUB",
    //     email: this.state.email,
    //     birthDate: datanasc,
    //     district: this.state.bairro,
    //     cep: cep,
    //     city: this.state.cidade,
    //     addressComplement: this.state.complemento,
    //     patio: this.state.end,
    //     patioType: "",
    //     addressNumber: this.state.num,
    //     addressReference: "",
    //     state: this.state.uf,
    //     ddd: ddd,
    //     phone: cellphone,
    //     leadRecordType: "LEAD_CONECTA",
    //     refuseEmail: this.state.newsletter ? false : true,
    //   }),
    //   // headers: new Headers({
    //   // 	"Authorization": "Bearer " + localStorage.getItem('session_token'),
    //   // })
    // };
    // fetch(process.env.REACT_APP_LABS_API + "create_salesForce_leads.php", requestOptions)
    //   .then(response => {
    //     return response.json();
    //   })
    //   .then(json => {
    //     if (json.status !== 201 && json.status !== 200) {
    //       // console.log(json)
    //     }
    //   });
  }

  AlterarRemoverUsuario = requestOptions => {
    this.context.showLoading();
    requestOptions.headers = new Headers({
      Authorization: "Bearer " + localStorage.getItem("session_token"),
    });

    fetch(process.env.REACT_APP_LABS_API + "update_user_role.php", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.status === 200) {
          this.context.hideLoading();
          Swal.fire({
            html: "Operação concluída com sucesso! <br> Realize o login novamente para que as alterações sejam salvas.",
            icon: "success",
            title: "Pronto!",
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonColor: "#00518b",
            confirmButtonText: "Ok!",
          }).then(result => {
            if (result.value) {
              this.logoutExterno();
            }
          });
        } else if (json.status >= 400) {
          Alert.fire({
            text: "Não foi possível se desvincular da ótica, tente novamente mais tarde ou entre em contato com o administrador do sistema.",
            icon: "warning",
            title: "Aviso",
            confirmButtonColor: "#00518b",
            customClass: {
              confirmButton: "x-follow-order__filter-btn",
            },
          });
        }
      });
  };

  // desvincular usuario
  removerUsuario = (id, empresa) => {
    Swal.fire({
      text: `Tem certeza que deseja se desvincular da ${empresa}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00518b",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then(result => {
      if (result.value) {
        this.validarCnpj(() => {
          const requestOptions = {
            method: "POST",
            body: JSON.stringify({
              idOEC: id,
              removerCnpj: true,
              tipoCnpj: this.state.tipo_cnpj,
            }),
          };

          this.AlterarRemoverUsuario(requestOptions);
        }, true);
      }
    });
  };
  handleCallback(valor) {
    this.setState({ OticaManagement: valor });
    this.props.reloadMultiContas();
  }
  render() {
    const {
      id: IDUser,
      reload,
      name,
      surname,
      datanasc,
      civil,
      sexo,
      email,
      telephone,
      cellphone,
      cep,
      end,
      num,
      complemento,
      bairro,
      cidade,
      uf,
      password,
      password1,
      cnpj,
      papel_usuario,
      nome_conta,
    } = this.state;

    let { imagePreviewUrl } = this.state;
    let $imagePreview = null;

    if (imagePreviewUrl) {
      $imagePreview = <img className="my-account__photo-img-preview" src={imagePreviewUrl} alt="Avatar usuário" />;
    } else {
      $imagePreview = <img className="my-account__photo-img" src={user} alt="Avatar usuário" />;
    }

    if (reload) {
      window.location.href = "/home";
    }

    return (
      <section className="update-user">
        <div className={this.state.opacity}>
          <div className="my-account__profile is-tablet">
            <div id="my-account__profile" className="my-account__photo">
              {$imagePreview}

              <span className="my-account__photo-change">
                <label htmlFor="selecao-arquivo" className="my-account__photo-change-link">
                  Alterar foto
                </label>
                <input id="selecao-arquivo" type="file" name="userImage" onChange={this.handleImageChange.bind(this)} />
              </span>
            </div>

            <form className="my-account__form is-tablet" action="#" autocomplete="off">
              {this.isUsuarioSemCnpj() && (
                <>
                  <h2 className="conecta-h2">Informe o novo CNPJ</h2>
                  <div className="row">
                    <div className="col-12 col-md-6 profileInput">
                      <Input
                        label="CNPJ"
                        width="100%"
                        pattern="/^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/"
                        mask={[/\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/]}
                        type="text"
                        name="cnpj"
                        placeholder="Informe o novo cnpj*"
                        value={cnpj}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="col-12 col-md-6 d-flex">
                      <div className="justify-content-center align-self-center pt-3">
                        <small className="" style={{ color: "red" }}>
                          Informe o CNPJ da sua empresa.
                        </small>
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="row">
                <div className="col-12 col-md-6 profileInput">
                  <Input
                    label="Nome"
                    width="100%"
                    id="nome"
                    title="Preencha seu nome"
                    type="text"
                    placeholder="Nome"
                    maxLength="50"
                    name="name"
                    value={name}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-12 col-md-6 profileInput">
                  <Input
                    label="Sobrenome"
                    width="100%"
                    id="sobrenome"
                    title="Preencha seu sobrenome"
                    type="text"
                    placeholder="Sobrenome"
                    maxLength="100"
                    name="surname"
                    value={surname}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="my-account__form-item">
                <div className="my-account__form-genre">
                  <RadioButton
                    label="Feminino"
                    name="sexo"
                    id="feminino"
                    value="Feminino"
                    checked={sexo == "Feminino" ? true : false}
                    onChange={this.handleChange}
                  />

                  <RadioButton
                    label="Masculino"
                    name="sexo"
                    id="masculino"
                    value="Masculino"
                    checked={sexo == "Masculino" ? true : false}
                    onChange={this.handleChange}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 profileInput">
                  <Input
                    label="Data de nascimento"
                    width="100%"
                    title="Preencha a data de seu aniversário. ex: 01/01/2002"
                    mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
                    pattern="[0-9]{2}/[0-9]{2}/[0-9]{4}"
                    type="text"
                    name="datanasc"
                    placeholder="Aniversário*"
                    value={datanasc}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="col-12 col-md-6 profileInput">
                  <Select
                    label="Estado civil"
                    width="100%"
                    title="Clique nesse campo"
                    name="civil"
                    id="estado-civil"
                    value={civil}
                    onChange={this.handleChange}
                    placeholder="Estado Civil"
                    placeholder_disabled={true}
                    itens={[
                      { label: "Solteiro(a)", value: "Solteiro" },
                      { label: "Casado(a)", value: "Casado" },
                      { label: "Divorciado(a)", value: "Divorciado" },
                      { label: "Viúvo(a)", value: "Viúvo" },
                    ]}
                  />
                </div>
              </div>

              <div className="my-account__form-session">
                <h3 className="conecta-h3">Informações de contato</h3>

                <div className="row">
                  <div className="col-12 col-md-6 profileInput">
                    <Input
                      type="email"
                      label="E-mail"
                      name="email"
                      width="100%"
                      placeholder="E-mail"
                      readOnly
                      disabled
                      value={email}
                      onChange={this.handleChange}
                    />
                  </div>
                  {/* <div className='col-12 col-md-6 profileInput'>
										{
											!this.isUsuarioSemCnpj() &&
											papel_usuario &&
											!papel_usuario.includes('Proprietário') && (
												<div className="my-account_button-left" style={{ paddingTop: "unset" }}>
													<a
														className="my-account__form-reminder tp2"
														href="#/" onClick={() => {
															this.removerUsuario(IDUser, nome_conta)
														}}
													>
														Sair da empresa
													</a>
												</div>
											)
										}
									</div> */}
                </div>

                <div className="row">
                  <div className="col-12 col-md-6 profileInput">
                    <Input
                      type="tel"
                      label="Telefone"
                      name="telephone"
                      width="100%"
                      placeholder="Telefone Fixo"
                      value={telephone}
                      onChange={this.handleChange}
                      mask={["(", /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]}
                    />
                  </div>
                  <div className="col-12 col-md-6 profileInput">
                    <Input
                      type="tel"
                      label="Celular"
                      name="cellphone"
                      width="100%"
                      placeholder="Telefone Celular"
                      value={cellphone}
                      onChange={this.handleChange}
                      mask={["(", /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/]}
                    />
                  </div>
                </div>
              </div>

              <div id="my-account__endereco" className="my-account__form-session">
                <h3 className="conecta-h3">Endereço Residencial</h3>

                <div className="row">
                  <div className="col-12 col-md-6 profileInput">
                    <Input
                      label="CEP"
                      width="100%"
                      data-contact="cep"
                      name="cep"
                      id="cep"
                      title="ex: 00000-000"
                      type="text"
                      guide={false}
                      mask={[/[0-9]/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
                      placeholder="CEP"
                      value={cep}
                      onChange={this.handleChangeAdress}
                    />
                  </div>
                  <div className="col-12 col-md-6 profileInput justify-content-center align-self-center">
                    <div className="my-account__form-cep">
                      <a
                        rel="noopener noreferrer"
                        className="my-account__form-reminder tp2"
                        href="http://www.buscacep.correios.com.br/sistemas/buscacep"
                        target="_blank">
                        Não sei meu CEP
                      </a>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-7 profileInput">
                    <Input
                      label="Endereço"
                      width="100%"
                      name="end"
                      title="Preencha o seu endereço"
                      type="text"
                      placeholder="Endereço"
                      maxLength="200"
                      value={end}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-12 col-md-2 profileInput">
                    <Input
                      label="Nº"
                      width="100%"
                      name="num"
                      title="ex: 555"
                      type="text"
                      placeholder="Nº"
                      maxLength="5"
                      value={num}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-12 col-md-3 profileInput">
                    <Input
                      label="Complemento"
                      width="100%"
                      name="complemento"
                      title="55a"
                      type="text"
                      maxLength="10"
                      placeholder="Complemento"
                      value={complemento}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-md-5 profileInput">
                    <Input
                      label="Bairro"
                      width="100%"
                      name="bairro"
                      title="Preencha o seu bairro"
                      type="text"
                      placeholder="Bairro"
                      maxLength="100"
                      value={bairro}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-12 col-md-5 profileInput">
                    <Input
                      label="Cidade"
                      width="100%"
                      name="cidade"
                      title="Preencha a sua cidade"
                      type="text"
                      placeholder="Cidade"
                      maxLength="60"
                      value={cidade}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-12 col-md-2 profileInput">
                    <Select
                      label="Estado"
                      width="100%"
                      name="uf"
                      title="Clique nesse campo"
                      id="endereco"
                      value={uf}
                      onChange={this.handleChange}
                      placeholder_disabled={true}
                      placeholder="UF"
                      itens={ESTADO}
                    />
                  </div>
                </div>
              </div>

              <div id="my-account__empresas">
                {this.isUsuarioSemCnpj() ? (
                  <div className="mt-5" id="otica">
                    <h3 className="conecta-h3 mb-4">Empresas Vinculadas</h3>
                    <p>Você não está associado a nenhuma empresa no momento.</p>
                  </div>
                ) : (
                  <OticaManagement id="otica" onCallback={this.handleCallback} />
                )}
              </div>

              <div id="my-account__dados" className="my-account__form-session">
                <h3 className="conecta-h3">Senha de Acesso</h3>

                <div className="row">
                  <div className="col-12 col-md-6 profileInput">
                    <Password
                      label="Senha"
                      width="100%"
                      id="password"
                      placeholder="Senha"
                      name="password"
                      value={password}
                      onChange={this.handleChange}
                      autocomplete="new-password"
                    />
                  </div>
                  <div className="col-12 col-md-6 profileInput">
                    <Password
                      label="Confirmar senha"
                      width="100%"
                      name="password1"
                      id="password1"
                      placeholder="Confirmar senha"
                      value={password1}
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
              </div>

              <div className="my-account__form-access-checkbox">
                <CheckBox
                  label="Aceito receber comunicações por e-mail e celular do grupo Essilor e suas marcas"
                  name="newsletter"
                  id="newsletter"
                  value={this.state.newsletter}
                  checked={this.state.newsletter}
                  onClick={this.handleCheckBox}
                />
              </div>

              <CustomButton label="Salvar alterações" width="20.1875rem" disabled={this.state.disabled} onClick={this.handleSubmit} />
            </form>
          </div>
        </div>

        <div className="d-none">
          <Logout ref="logout" />
        </div>
      </section>
    );
  }
}

const ESTADO = [
  { label: "AC", value: "AC" },
  { label: "AL", value: "AL" },
  { label: "AP", value: "AP" },
  { label: "AM", value: "AM" },
  { label: "BA", value: "BA" },
  { label: "CE", value: "CE" },
  { label: "DF", value: "DF" },
  { label: "ES", value: "ES" },
  { label: "GO", value: "GO" },
  { label: "MA", value: "MA" },
  { label: "MG", value: "MG" },
  { label: "MS", value: "MS" },
  { label: "MT", value: "MT" },
  { label: "PA", value: "PA" },
  { label: "PB", value: "PB" },
  { label: "PE", value: "PE" },
  { label: "PI", value: "PI" },
  { label: "PR", value: "PR" },
  { label: "RJ", value: "RJ" },
  { label: "RN", value: "RN" },
  { label: "RO", value: "RO" },
  { label: "RR", value: "RR" },
  { label: "RS", value: "RS" },
  { label: "SC", value: "SC" },
  { label: "SE", value: "SE" },
  { label: "SP", value: "SP" },
  { label: "TO", value: "TO" },
];

export default PropfileContent;
