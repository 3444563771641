import React, { Component } from 'react'
import '../card/lab-card.css'

class LabCard extends Component {
    // https://ourcodeworld.com/articles/read/683/how-to-remove-the-transparent-pixels-that-surrounds-a-canvas-in-javascript
    // https://gist.github.com/remy/784508
    render() {
        const { lab } = this.props;
        return (
            <div className="lab-card">
                <div className="row">
                    <div className="lab-card-image">
                        <img src={lab.lablogo} className="rounded mx-auto d-block" alt="" ref="logo" id="logo" />
                    </div>
                    <div className="col-12 pr-0">
                        <h2 className="lab-card-title">{lab.labname}</h2>
                        <p className="lab-card-paragraph">{lab.adress} - {lab.city}
                            {lab.zip && (<><br />CEP: {lab.zip}</>)}
                            {lab.labEmail && (<><br />E-mail: {lab.labEmail}</>)}
                            {(lab.phone || lab.cellphone || lab.phonealt) && (<>
                                <br />Fone: {lab.phone}
                                {lab.cellphone ? ' | ' : ''}{lab.cellphone}
                                {lab.phonealt ? ' | ' : ''}{lab.phonealt}
                            </>)}
                            {lab.lablink && (<>
                                <br />
                                <a className="lab-card-link" href={lab.lablink} target="_blank">
                                    {lab.lablink}
                                </a>
                            </>)}
                        </p>
                    </div>
                </div>
            </div>
        )
    }
}

export default LabCard;