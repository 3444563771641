import React, { Component } from "react";

//Import CSS
import "./media-upload.css";

//Import internal components
import MediaUploadThumb from "./media-upload-thumb";
import MediaUploadPreview from "./media-upload-preview";

//Import external components
import MediaUploadFile from "./media-upload-file";

//Import images

class MediaUploadScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      preview: false,
      previewUploaded: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.showPreview = this.showPreview.bind(this);
    this.onChangePreview = this.onChangePreview.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  onChangePreview(value) {
    this.setState({ previewUploaded: value });
  }

  showPreview() {
    this.setState({
      preview: true,
    });
  }

  render() {
    return (
      <div>
        <center>
          <label for="recordname">Inserir no campo o código da mídia contido no campo "Midia", no formato MD-0000.</label>
          <br />
          <input
            type="text"
            className="media-upload-input-text"
            id="recordname"
            name="recordname"
            value={this.state.recordname}
            onChange={this.handleChange}></input>
        </center>

        <p>
          <b>Importante:</b> as URLs geradas automaticamente possuem o formato ".png". Se os arquivos carregados tiverem formatos diferentes será necessário
          revisar o formato do arquivo para que carregue corretamente a imagem da mídia.
        </p>
        <hr />
        <div className="media-upload-screen">
          <h1>Upload de miniatura</h1>
          <p>A imagem que irá aparecer no card da mídia. Possibilidade de upload de apenas uma imagem.</p>

          <MediaUploadThumb recordname={this.state.recordname} uploadPreviewActive={this.state.preview} previewUploaded={this.state.previewUploaded} />

          {!this.state.preview && (
            <p>
              Se essa mídia possui um preview diferente da miniatura ou mais de um preview,{" "}
              <span className="show-media-preview" onClick={this.showPreview}>
                clique aqui
              </span>
              .
            </p>
          )}

          {this.state.preview && <hr />}
          {this.state.preview && <h1>Upload de preview</h1>}
          {this.state.preview && (
            <p>
              A imagem que aparece no modal quando se clica em cima da miniatura. Possibilidade de upload de até 5 imagens. Adicionar no campo "URL preview" a
              url de todas as previews carregadas, separadas por vírgula.
            </p>
          )}

          {this.state.preview && <MediaUploadPreview recordname={this.state.recordname} onChangePreview={this.onChangePreview} />}

          <hr />
          <h1>Upload de mídia</h1>
          <p>
            O arquivo que será feito download ou compartilhado. Apenas um arquivo poderá ser enviado, se houver necessidade de diversos arquivos, eles deverão
            ser compactados.
          </p>

          <MediaUploadFile recordname={this.state.recordname} />
        </div>
      </div>
    );
  }
}

export default MediaUploadScreen;
