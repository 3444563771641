import React from "react";
import ImageUploader from "react-images-upload";
import "./upload-logo-otica.css";
import AppContext from "../../appContext";
import Swal from "sweetalert2";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { ExclamationCircleFill } from "react-bootstrap-icons";
import oticaDefault from "./img/otica-default.png";

class UploadLogoOtica extends React.Component {
  constructor(props) {
    super(props);
    this.state = { cnpj: "", pictures: [], preview: "" };
    this.removeRef = React.createRef();
    this.pictureRef = React.createRef();

    UploadLogoOtica.contextType = AppContext;
    this.onDrop = this.onDrop.bind(this);
    this.remove = this.remove.bind(this);
    this.uploadLogo = this.uploadLogo.bind(this);

    this.uploadRef = React.createRef();
    this.imgCircleClick = this.imgCircleClick.bind(this);
  }

  onDrop(pictureFiles, pictureDataURLs) {
    const { getPreview } = this.props;

    this.setState(
      {
        pictures: pictureFiles,
        preview: pictureDataURLs,
      },
      () => {
        this.uploadLogo(process.env.REACT_APP_LABS_API + "upload_logo.php", this.state.pictures[0]);
        if (getPreview) {
          getPreview(pictureDataURLs);
        }
      },
    );
  }

  componentDidMount() {
    const cnpj = this.props.cnpj;
    this.setState(
      {
        cnpj: cnpj ? cnpj : this.context.userSession.conta,
        imgCircle: this.props.imgCircle ? this.props.imgCircle : false,
      },
      () => {
        this.obterLogo(this.state.cnpj);
      },
    );
  }

  componentDidUpdate() {
    const pictureFile = this.pictureRef.current;
    const removeFile = this.removeRef.current;
    if (pictureFile && removeFile) {
      const width = pictureFile.width > 0 ? pictureFile.width + "px" : "100%";
      document.getElementById("removeFile").style.marginLeft = width;
    }
  }

  remove() {
    const { getPreview } = this.props;

    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        cnpj: this.state.cnpj,
        url: "",
      }),
    };

    fetch(process.env.REACT_APP_LABS_API + "create_optics.php", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(json => {
        window.sessionStorage.removeItem("@logo");
        if (json.success) {
          Swal.fire({
            text: "Logo removida.",
            icon: "success",
            title: "Pronto!",
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonColor: "#00518b",
            confirmButtonText: "Ok!",
          });
        } else {
          Swal.fire({
            text: json.message,
            icon: "error",
            title: "Aviso!",
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonColor: "#00518b",
            confirmButtonText: "Ok!",
          });
        }
        this.setState(
          {
            pictures: [],
            preview: "",
          },
          () => {
            if (getPreview) {
              getPreview("x");
            }
          },
        );

        this.context.hideLoading();
      });
  }

  uploadLogo(url, file) {
    this.context.showLoading();
    let data = new FormData();

    const imageUploadName = this.state.cnpj;
    data.append(imageUploadName, file);

    fetch(url, {
      method: "POST",
      body: data,
    })
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.success) {
          this.alterarUrlLogoOtica(json.url);
        } else {
          this.context.hideLoading();
          Swal.fire({
            text: "Não foi possível atualizar a imagem, tente novamente, ou entre em contato com o administrador!",
            icon: "warning",
            title: "Ops! Algo deu errado!",
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonColor: "#00518b",
            confirmButtonText: "Ok!",
          });
        }
      })
      .catch(error => {
        Swal.fire({
          text: "Não foi possível atualizar a imagem, tente novamente, ou entre em contato com o administrador!",
          icon: "warning",
          title: "Ops! Algo deu errado!",
          allowOutsideClick: false,
          showCancelButton: false,
          confirmButtonColor: "#00518b",
          confirmButtonText: "Ok!",
        });
        this.context.hideLoading();
      });
  }

  alterarUrlLogoOtica = urlLogo => {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        cnpj: this.state.cnpj,
        url: process.env.REACT_APP_LABS_ROOT + urlLogo.replace("../", ""),
      }),
    };

    fetch(process.env.REACT_APP_LABS_API + "create_optics.php", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.success) {
          window.sessionStorage.setItem("@logo", true);
          Swal.fire({
            text: "Logo atualizada com sucesso!",
            icon: "success",
            title: "Pronto!",
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonColor: "#00518b",
            confirmButtonText: "Ok!",
          });
        } else {
          Swal.fire({
            text: json.message,
            icon: "error",
            title: "Aviso!",
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonColor: "#00518b",
            confirmButtonText: "Ok!",
          });
        }
        this.context.hideLoading();
      });
  };

  obterLogo(cnpj) {
    fetch(process.env.REACT_APP_LABS_API + `getLogoOtica.php?cnpjOtica=${cnpj}`)
      .then(response => response.json())
      .then(json => {
        if (json.success) {
          window.sessionStorage.setItem("@logo", true);
          this.setState({
            preview: "data:image/png;base64," + `${json.base}`,
            imagePreviewUrl: "data:image/png;base64," + `${json.base}`,
          });
        } else {
          this.setState({
            pictures: [],
            preview: "",
            imagePreviewUrl: "",
          });
        }
      });
  }

  imgCircleClick() {
    const node = this.uploadRef.current;
    node.state.files = [];
    node.state.pictures = [];
    node.triggerFileUpload();
  }

  handleImageChange(e) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      var base64 = reader.result.replace("data:image/jpeg;base64,", "");

      this.setState({
        file: file,
        imagePreviewUrl: reader.result,
        foto: base64,
      });
    };

    if (file) {
      reader.readAsDataURL(file);

      this.setState({
        imageUploaded: e.target.files[0],
      });
    }
    this.onChangePicture(e.target.files[0]);
  }

  onChangePicture(pictureFiles) {
    const { getPreview } = this.props;

    this.setState(
      {
        pictures: pictureFiles,
        preview: pictureFiles,
      },
      () => {
        this.uploadLogo(process.env.REACT_APP_LABS_API + "upload_logo.php", pictureFiles);
        if (getPreview) {
          getPreview(pictureFiles);
        }
      },
    );
  }

  render() {
    let { imagePreviewUrl, preview } = this.state;
    let $imagePreview = null;

    if (imagePreviewUrl) {
      $imagePreview = <img className="otica__photo-img-preview" src={imagePreviewUrl} alt="Logo" />;
    } else {
      $imagePreview = <img className="otica__photo-img" src={oticaDefault} alt="Logo" />;
    }

    return (
      <div className="image-upload" onDrop={this.drop}>
        <p className="upload-content-paragraph">{this.props.label}</p>
        {this.state.imgCircle ? (
          <>
            <img class="otica-menu-img" src={this.state.preview ? this.state.preview : oticaDefault} alt="logo"></img>
            <ImageUploader
              withIcon={false}
              buttonText="Carregar logo"
              onChange={this.onDrop}
              imgExtension={[".jpg", ".gif", ".png"]}
              maxFileSize={8242880}
              accept="image/*"
              withPreview={true}
              withLabel={false}
              className="upload-content d-none"
              buttonClassName="btn-upload"
              ref={this.uploadRef}
            />
          </>
        ) : (
          <>
            {" "}
            {$imagePreview}
            <span className="otica__photo-change">
              <label htmlFor="selecao-arquivo" className="otica__photo-change-link">
                Alterar logo
              </label>
              <input id="selecao-arquivo" type="file" name="userImage" onChange={this.handleImageChange.bind(this)} />
            </span>
          </>
        )}
      </div>
    );
  }
}

export default UploadLogoOtica;
