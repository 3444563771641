import React, { Component } from 'react'
import './web-ordering.css'
import Tutorial from './tutorial'
import LabChoose from './lab-choose'
import { Redirect } from 'react-router'
import { redirectPage } from '../../components/utils/redirectpage'

class WOHome extends Component {

    constructor(props){
        super(props);

        this.state = {
            redirect: false,
        }

        if(localStorage.getItem('token') !== null){
            var token = localStorage.getItem("token");

            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));

            var jwt = JSON.parse(jsonPayload);
            var labs = [];
           
            for(var i = 0;i<jwt.laboratorios.length;i++){
                labs.push(jwt.laboratorios[i]);
            }
            this.state = {
                papel_usuario : jwt.papel,
                otica: labs,
                conta_usuario : jwt.conta
            }

            const role = jwt.modulos.find(element => element === 'Pedidos');
            if(!role){
                this.state = { redirect: true };
            }
        }else {
            redirectPage(localStorage.getItem('token'), '/web-ordering')
        }
    }
	
	render() {
        if(this.state.redirect){
            return <Redirect to="/home" />;
        }

		return (
		<div className="x-body" id="main-body">			
			<main className=".x-web-ordering__main">
                <div className="x-web-ordering__container">
                    <section className="x-web-ordering__left">
                        <LabChoose otica={this.state.otica} conta_usuario={this.state.conta_usuario} papel_usuario={this.state.papel_usuario}></LabChoose>
                        <Tutorial></Tutorial>
                    </section>
                </div>
            </main>
		</div>
		);
  	}
}

export default WOHome;