import React, { Component } from "react";
import PDFOrderP from "./pdf-order-p";

class PDFOrderProdutos extends Component {
  render() {
    const { dados } = this.props;
    const { quantProdutos, produtos } = dados;
    let nColumns = quantProdutos > 1 ? "col-6" : "col-12";
    // <div className={`${nColumns}`}>

    return (
      <div className="pdf-order-dados row">
        <div className="col-12">
          <h1 className="pdf-title">Produtos</h1>
        </div>
        {produtos.map((item, index) => {
          return (
            <div className="row">
              <div className="col-6">
                <p className="pdf-span">{item.descricao}</p>
              </div>
              <div className="col-6">
                <PDFOrderP label="Quantidade" value={item.quantidade} />
              </div>
              <p> </p>
            </div>
          );
        })}
      </div>
    );
  }
}

export default PDFOrderProdutos;
