import React, { Component, useRef } from "react";

//Import CSS

//Import internal components
import AppContext from "../../../appContext";
import Select from "../../../components/selects/select";
import ButtonYN from "../../../components/buttons/button-yn";
import CheckBox from "../../../components/inputs/check-box";
import TextArea from "../../../components/inputs/text-area";
import CustomButton from "../../../components/buttons/button";
import Input from "../../../components/inputs/input";

import Form from "react-bootstrap/Form";
import FormCheck from "react-bootstrap/FormCheck";

import Autocomplete from "../autocomplete";

//Import external components
import Alert from "sweetalert2";
import Swal from "sweetalert2";
import CRMSwitch from "../../../crm/crm-commons/crm-switch";
import AutoCompleteSelect from "../../../components/autocomplete-select/autocomplete-select";

//Import image

class OrderLens extends Component {
  constructor(props) {
    super(props);
    this.autoRef = React.createRef();

    this.state = {
      lente_tp_producao: "",
      lente_tp_produto: "",
      cnpjOtica: "",
      produtos: [],
      codProd: "",
      arValue: [],
      arValue2: [],
      colValue: [],
      corValue: [],
      outrosValue: [],
      auto: true,
      placeholder: "Nenhum resultado encontrado",
      loading: false,
      opacity: "",
      lente_tp_producao: this.props.order.lente_tp_producao,
      lente_tp_produto: this.props.order.lente_tp_produto,
      prodArray: [],
      requiredCorte: null,
      tratamento_informado: null,
      coloracao_informado: null,
      isServicos: true,
      col_disabled: false,
      ar_disabled: false,
      responseRpl: [],
      growmocaoCheck: false,
      cores: [],
      coresArmacao: [],
      tratamento: null,
    };

    OrderLens.contextType = AppContext;

    this.rplRequestOptions = this.rplRequestOptions.bind(this);
    this.rplUrl = this.rplUrl.bind(this);
    this.getRPL = this.getRPL.bind(this);
    this.rplSuccess = this.rplSuccess.bind(this);
    this.setRpl400 = this.setRpl400.bind(this);
    this.setRpl404 = this.setRpl404.bind(this);
    this.setRplError = this.setRplError.bind(this);
    this.rplValidacaoServicos = this.rplValidacaoServicos.bind(this);
    this.rplValidateResponse = this.rplValidateResponse.bind(this);
    this.rplAlert = this.rplAlert.bind(this);
    this.rplSetFields = this.rplSetFields.bind(this);
    this.getCor = this.getCor.bind(this);
    this.handleChangeCor = this.handleChangeCor.bind(this);
    this.handleChangeCorObservacao = this.handleChangeCorObservacao.bind(this);
    this.limpaCorCores = this.limpaCorCores.bind(this);
    this.handleGrowmocao = this.handleGrowmocao.bind(this);
  }

  rplRequestOptions() {
    const { order } = this.props;

    if (order.redeOticas && order.dados_empresa_faturamento_cnpj) {
      return {
        method: "GET",
        headers: {
          access_token: localStorage.getItem("token"),
          laboratorio: order.laboratorio,
          otica: order.dados_empresa_faturamento_cnpj,
        },
      };
    } else {
      return {
        method: "GET",
        headers: {
          access_token: localStorage.getItem("token"),
          laboratorio: order.laboratorio,
        },
      };
    }
  }

  rplUrl(item) {
    if (item.valorServico) {
      return (
        process.env.REACT_APP_ESSILOR_API +
        "gestao-produtos-laboratorio/v1/produtos/" +
        item.rpl +
        "/parametros?tipoId=RPL&tipoProducao=" +
        item.valorProducao +
        "&servicoAssociado=" +
        item.valorServico
      );
    } else {
      return (
        process.env.REACT_APP_ESSILOR_API + "gestao-produtos-laboratorio/v1/produtos/" + item.rpl + "/parametros?tipoId=RPL&tipoProducao=" + item.valorProducao
      );
    }
  }

  getRPL(index, length) {
    var indexAux = index + 1;
    this.context.showLoading("Validando produtos (" + indexAux + "/" + length + ")...");
    const { order } = this.props;
    var requestOptions = this.rplRequestOptions();
    var par = order.pares[index];

    if (par) {
      let url = this.rplUrl(par);

      fetch(url, requestOptions).then(response => {
        if (response.status === 200) {
          return response.json().then(json => {
            this.rplSuccess(json, par, () => {
              if (indexAux < length) {
                this.getRPL(index + 1, length);
              } else {
                this.rplValidateResponse();
              }
            });
          });
        } else if (response.status === 404) {
          this.setRpl404(par, () => {
            if (indexAux < length) {
              this.getRPL(index + 1, length);
            } else {
              this.rplValidateResponse();
            }
          });
        } else if (response.status === 422) {
          this.setRpl422(par, () => {
            if (indexAux < length) {
              this.getRPL(index + 1, length);
            } else {
              this.rplValidateResponse();
            }
          });
        } else if (response.status === 504) {
          this.setRplError(
            () => {
              if (indexAux < length) {
                this.getRPL(index + 1, length);
              } else {
                this.rplValidateResponse();
              }
            },
            504,
            "Não foi possível contatar o sistema do laboratório. Tente novamente mais tarde.",
          );
        } else {
          this.setRplError(() => {
            if (indexAux < length) {
              this.getRPL(index + 1, length);
            } else {
              this.rplValidateResponse();
            }
          });
        }
      });
    }
  }

  rplSuccess(json, item, callback) {
    const { order } = this.props;
    var response;
    var reponseAux;

    response = {
      status: 200,
      json: json,
      par: item.id_par,
      rpl: item.rpl,
      descricao: item.descricaoServico,
    };
    let coresArmacao = [];
    if (json.armacao?.cor?.dominio?.length >= 1) {
      json.armacao.cor.dominio.map(item => {
        coresArmacao.push({ label: item.descricao, value: item.codigo });
      });
    }
    this.props.handleOrderChange({ json_parametros: json, cores_armacao: coresArmacao });

    reponseAux = this.state.responseRpl;
    reponseAux.push(response);
    this.setState({ responseRpl: reponseAux }, () => {
      callback();
    });
  }

  setRpl400(item, callback) {
    const { order } = this.props;

    var response = {
      status: 400,
      json: null,
      par: item.id_par,
      descricao: item.descricao,
      rpl: item.rpl,
      descricaoServico: item.descricaoServico ? item.descricaoServico : item.tipoProducao === "LENTES_PRONTA" ? "" : "Tratamento/coloração do produto",
      codServico: item.tipoProducao === "LENTES_PRONTA" ? item.servicoAssociado : item.codigoRefServicos,
      tipoProducao: item.tipoProducao,
    };

    var reponseAux = this.state.responseRpl;
    reponseAux.push(response);
    this.setState({ responseRpl: reponseAux }, () => {
      callback();
    });
  }

  setRpl422(item, callback) {
    const { order } = this.props;

    var response = {
      status: 422,
      json: null,
      par: item.id_par,
      rpl: item.rpl,
      descricao: item.descricao,
      descricaoServico: item.descricaoServico ? item.descricaoServico : item.tipoProducao === "LENTES_PRONTA" ? "" : "Tratamento/coloração do produto",
      codServico: item.tipoProducao === "LENTES_PRONTA" ? item.servicoAssociado : item.codigoRefServicos,
      tipoProducao: item.tipoProducao,
    };

    var reponseAux = this.state.responseRpl;
    reponseAux.push(response);
    this.setState({ responseRpl: reponseAux }, () => {
      callback();
    });
  }

  setRpl404(item, callback) {
    const { order } = this.props;
    var response = {
      status: 404,
      json: null,
      par: item.id_par,
      descricao: item.descricao,
      descricaoServico: item.descricaoServico ? item.descricaoServico : item.tipoProducao === "LENTES_PRONTA" ? "" : "Tratamento/coloração do produto",
      rpl: item.rpl,
      codServico: item.tipoProducao === "LENTES_PRONTA" ? item.servicoAssociado : item.codigoRefServicos,
      tipoProducao: item.tipoProducao,
    };

    var reponseAux = this.state.responseRpl;
    reponseAux.push(response);
    this.setState({ responseRpl: reponseAux }, () => {
      callback();
    });
  }

  setRplError(callback, status = 500, mensagem = "Erro interno. Tente novamente mais tarde.") {
    const { order } = this.props;

    var response = {
      status: status,
      json: null,
      par: "",
      descricao: "",
      mensagem: mensagem,
    };

    var reponseAux = this.state.responseRpl;
    reponseAux.push(response);
    this.setState({ responseRpl: reponseAux }, () => {
      callback();
    });
  }

  rplValidacaoServicos(servico, json, item, index, callback) {
    if (servico.length !== 0) {
      if (
        item.codigoRefServicos !== "33" &&
        item.codigoRefServicos !== "99" &&
        servico[0].tipo !== "COLORACAO" &&
        json.tipoProducao !== "SURFACAGEM_DIGITAL" &&
        json.tipoProducao !== "SURFACAGEM_TRADICIONAL"
      ) {
        if (Object.keys(servico).length === 1) {
          if (servico[0].tipo !== "ANTIRREFLEXO" && servico[0].tipo !== "COLORACAO") {
            this.setRpl400(item, callback);
            return false;
          }
        }
      }
    } else {
      this.setRpl400(item, callback);
      return false;
    }

    return true;
  }

  rplValidateResponse() {
    const { order } = this.props;
    let msg =
      order.promo_code !== ""
        ? `Os seguintes itens do cupom promocional <b>${order.promo_code}</b> não foram encontrados no laboratório:`
        : "Os seguintes itens do cupom promocional não foram encontrados no laboratório:";

    if (
      this.state.responseRpl.some(element => element.status === 400) ||
      this.state.responseRpl.some(element => element.status === 404) ||
      this.state.responseRpl.some(element => element.status === 422) ||
      this.state.responseRpl.some(element => element.status === 500) ||
      this.state.responseRpl.some(element => element.status === 504)
    ) {
      var text;
      if (this.state.responseRpl.some(element => element.status !== 500 && element.status !== 504)) {
        let div = document.createElement("div");
        let p = document.createElement("p");
        p.innerHTML = msg;
        div.appendChild(p);
        let ul = document.createElement("ul");
        ul.setAttribute("id", "ul-rpl");
        let br = document.createElement("br");
        div.appendChild(br);

        text = div.outerHTML;

        var count = 0;

        if (this.state.responseRpl.filter(e => e.status !== 200).length <= 1) {
          this.state.responseRpl.forEach((item, index) => {
            if (item.status !== 200) {
              text = "<b>";
              var li = document.createElement("LI");
              var cods = document.createElement("p");

              switch (item.status) {
                case 400:
                  text +=
                    item.tipoProducao === "LENTES_PRONTA"
                      ? item.descricao
                      : item.descricao + (item.descricaoServico !== "" ? " + " + item.descricaoServico : "");

                  cods =
                    item.tipoProducao === "LENTES_PRONTA"
                      ? ` (cód. ${item.rpl} ` + (item.codServico ? `+ cód. ${item.codServico})` : ")")
                      : ` (cód. ${item.rpl}` + (item.descricaoServico !== "" ? ` + cód. ${item.codServico}) ` : ")");
                  break;
                case 404:
                  text +=
                    item.tipoProducao === "LENTES_PRONTA"
                      ? item.descricao
                      : item.descricao + (item.descricaoServico !== "" ? " + " + item.descricaoServico : "");

                  cods =
                    item.tipoProducao === "LENTES_PRONTA"
                      ? ` (cód. ${item.rpl} ` + (item.codServico !== "" ? `+ cód. ${item.codServico})` : ")")
                      : ` (cód. ${item.rpl}` + (item.descricaoServico !== "" ? ` + cód. ${item.codServico}) ` : ")");

                  break;
                case 422:
                  text +=
                    item.tipoProducao === "LENTES_PRONTA"
                      ? item.descricao
                      : item.descricao + (item.descricaoServico !== "" ? " + " + item.descricaoServico : "");

                  cods =
                    item.tipoProducao === "LENTES_PRONTA"
                      ? ` (cód. ${item.rpl} ` + (item.codServico !== "" ? `+ cód. ${item.codServico})` : ")")
                      : ` (cód. ${item.rpl}` + (item.descricaoServico !== "" ? ` + cód. ${item.codServico}) ` : ")");

                  break;
                default:
                  break;
              }

              if (text) {
                let div = document.createElement("div");
                let p = document.createElement("p");
                p.innerHTML = text + "</b>";
                div.appendChild(p);
                // var textnode = document.createTextNode();
                li.appendChild(div);
                li.innerHTML = li.innerHTML + " <p style='text-align: center'>" + cods + "</p> <br/>";
                li.style.textAlign = "left";
                li.setAttribute("class", "li-rpl");
                ul.appendChild(li);
              }

              div.appendChild(ul);
              this.rplAlert(div);
            }
          });
        } else {
          this.state.responseRpl.forEach((item, index, array) => {
            text = "";
            count++;

            var li = document.createElement("LI");
            var cods = document.createElement("p");

            switch (item.status) {
              case 400:
                text = item.par + "º par: " + item.descricao;

                cods = ` (cód. ${item.rpl} ` + (item.codServico !== "" ? `+ cód. ${item.codServico})` : ")");
                break;
              case 404:
                text =
                  item.tipoProducao === "LENTES_PRONTA"
                    ? item.par + "º par: " + item.descricao
                    : item.par + "º par: " + item.descricao + (item.descricaoServico !== "" ? " + " + item.descricaoServico : "");

                cods =
                  item.tipoProducao === "LENTES_PRONTA"
                    ? ` (cód. ${item.rpl} ` + (item.codServico !== "" ? `+ cód. ${item.codServico})` : ")")
                    : ` (cód. ${item.rpl}` + (item.codServico !== "" ? ` + cód. ${item.codServico}) ` : ")");
                break;
              case 422:
                text =
                  item.tipoProducao === "LENTES_PRONTA"
                    ? item.par + "º par: " + item.descricao
                    : item.par + "º par: " + item.descricao + (item.descricaoServico !== "" ? " + " + item.descricaoServico : "");

                cods =
                  item.tipoProducao === "LENTES_PRONTA"
                    ? ` (cód. ${item.rpl} ` + (item.codServico !== "" ? `+ cód. ${item.codServico})` : ")")
                    : ` (cód. ${item.rpl}` + (item.codServico !== "" ? ` + cód. ${item.codServico}) ` : ")");
                break;
              default:
                break;
            }

            if (text) {
              let div = document.createElement("div");
              let p = document.createElement("p");
              p.style.fontWeight = "bolder";
              p.innerHTML = text;
              div.appendChild(p);
              li.appendChild(div);
              li.innerHTML = li.innerHTML + "<p style='text-align: center'>" + cods + "</p> <br/>";
              li.style.textAlign = "left";
              li.setAttribute("class", "li-rpl");
              ul.appendChild(li);
            }

            if (count === array.length) {
              div.appendChild(ul);
              this.rplAlert(div);
            }
          });
        }
      } else {
        if (this.state.responseRpl.some(element => element.status === 504)) {
          this.rplAlert(this.state.responseRpl.find(element => element.status === 504).mensagem);
        } else {
          this.rplAlert(this.state.responseRpl.find(element => element.status === 500).mensagem);
        }
      }
    } else if (this.state.responseRpl.some(element => element.status === 200)) {
      this.rplSetFields(this.state.responseRpl.find(element => element.rpl === order.rpl).json);
    }
  }

  rplAlert(text, icon = "warning", prev = true) {
    this.setState({ responseRpl: [] });
    this.context.hideLoading();
    Swal.fire({
      html: text,
      icon: icon,
      title: "Aviso",
      width: "600px",
      confirmButtonColor: "#00518b",
      customClass: {
        confirmButton: "x-follow-order__filter-btn",
      },
    }).then(result => {
      if (prev) {
        this.handleClickPrev();
      }
    });
  }

  rplSetFields(json) {
    const { order } = this.props;

    this.setState({ responseRpl: [] });
    this.context.showLoading("");

    document.getElementById("lente_tp_produto").value = order.tipo_produto;
    document.getElementById("lente_tp_producao").value = order.tipo_producao;

    if (order.rpl) {
      if (json.receita.adicao.preenchimento == "NAO_ACEITA") {
        this.props.handleOrderChange({
          prescricao_olho_direito_adicao: "",
          prescricao_olho_esquerdo_adicao: "",
        });
      }
      if (json.receita.altura.preenchimento == "NAO_ACEITA") {
        this.props.handleOrderChange({
          prescricao_olho_direito_altura: "",
          prescricao_olho_esquerdo_altura: "",
        });
      }
      if (json.receita.dnp.preenchimento == "NAO_ACEITA") {
        this.props.handleOrderChange({
          prescricao_olho_direito_dnp: "",
          prescricao_olho_esquerdo_dnp: "",
        });
      }
    }
    this.setState({
      opacity: "",
      loading: false,
    });

    document.getElementById("lente_tp_produto").value = json.tipoProduto;
    document.getElementById("lente_tp_producao").value = json.tipoProducao;
    document.getElementById("lente_esc_produto").value = json.descricao;
    document.getElementById("lente_esc_produto").readOnly = true;

    let produto = "";
    switch (json.tipoProduto) {
      case "BIFOCAL":
        produto = "Bifocal";
        break;
      case "MULTIFOCAL":
        produto = "Multifocal";
        break;
      case "VISAO_SIMPLES":
        produto = "Visão Simples";
        break;
      case "VISAO_INTERMEDIARIA":
        produto = "Visão Intermediária";
        break;
      default:
        produto = "";
    }

    let producao = "";
    switch (json.tipoProducao) {
      case "SURFACAGEM_DIGITAL":
        producao = "Surfaçagem Digital";
        break;
      case "SURFACAGEM_TRADICIONAL":
        producao = "Surfaçagem Tradicional";
        break;
      case "LENTES_PRONTA":
        producao = "Lente Pronta";
        break;
      default:
        producao = "";
    }

    /*   if (json.iniciaisPaciente.preenchimento != "NAO_ACEITA") {
      if (order.dados_nome_cliente) {
        if (order.dados_nome_cliente.split(" ").length > 4) {
          let first_three_letters = order.dados_nome_cliente.match(/\b\w/g).join("").substring(0, 3);
          let last_letter = order.dados_nome_cliente.split(" ")[order.dados_nome_cliente.split(" ").length - 1].toString().match(/\b\w/g);

          var initials = first_three_letters + last_letter;
        } else {
          var initials = order.dados_nome_cliente.match(/\b\w/g).join("").substring(0, 4).toUpperCase();
        }
        this.props.handleOrderChange({
          prescricao_ini_clientes: initials.toUpperCase(),
        });
      }
    }*/

    this.setSummaryControl(json);

    this.props.handleOrderChange({
      lente_tp_produto: json.tipoProduto,
      lente_tp_produto_cod: json.tipoProduto,
      lente_tp_produto_value: produto,
      lente_tp_producao: json.tipoProducao,
      lente_tp_producao_cod: json.tipoProducao,
      lente_tp_producao_value: producao,

      lente_esc_produto_nome: json.descricao,
    });

    this.setState({
      codProd: json.codigo,
    });

    this.context.hideLoading();
    this.props.handleOrderChange({
      json_parametros: json,
      lente_esc_produto: json.codigo,
    });
    this.insertAddServices(json);

    let servico = json.servicos.filter((item, i) => {
      return item.codigoRef === order.codigoRefServicos;
    });
    if (order.pedidoColorirNaOtica == false) {
      if (Object.keys(servico).length != 0 || order.codigoRefServicos == "") {
        if (order.codigoRefServicos != "") {
          if (
            (order.codigoRefServicos == "33" || order.codigoRefServicos == "99") &&
            servico[0].tipo === "COLORACAO" &&
            (json.tipoProducao == "SURFACAGEM_DIGITAL" || json.tipoProducao == "SURFACAGEM_TRADICIONAL")
          ) {
            /*   console.log("1")
            this.setState({
              coloracao_informado: false,
              col_disabled: false,
            });
*/
            let ar = json.servicos.filter(item => {
              return item.tipo === "ANTIRREFLEXO" && item.ref === "02";
            });

            if (Object.keys(ar).length == 1) {
              this.props.handleOrderChange({
                lente_servicos: ar[0].value,
                lente_servicos_value: ar[0].label,
                lente_servicos_cod: ar[0].value,
              });
              this.setState({
                ar_disabled: true,
              });
            }
          } else {
            if (Object.keys(servico).length == 1) {
              if (servico[0].tipo === "ANTIRREFLEXO") {
                this.props.handleOrderChange({
                  lente_servicos: servico[0].codigo,
                  lente_servicos_value: servico[0].descricao,
                  lente_servicos_cod: servico[0].codigo,
                });
                this.setState({
                  tratamento_informado: true,
                  coloracao_informado: false,
                  ar_disabled: true,
                  col_disabled: true,
                });
              } else if (servico[0].tipo === "COLORACAO") {
                this.props.handleOrderChange({
                  lente_coloracao: servico[0].codigo,
                  lente_coloracao_value: servico[0].descricao,
                  lente_coloracao_cod: servico[0].codigo,
                });
                this.setState({
                  tratamento_informado: false,
                  coloracao_informado: true,
                  ar_disabled: true,
                });
                if (this.props.order.colorirNaOtica) {
                  this.setState({
                    col_disabled: false,
                  });
                } else {
                  this.setState({
                    col_disabled: true,
                  });
                }
              }
            } else {
              if (servico[0].tipo === "ANTIRREFLEXO") {
                this.setState({
                  col_disabled: true,
                });
              }

              if (servico[0].tipo === "COLORACAO") {
                this.setState({
                  ar_disabled: true,
                });
              }
            }
          }
        } else {
          this.setState({
            ar_disabled: true,
            col_disabled: true,
          });
        }
      } else {
        this.setState({
          ar_disabled: true,
          col_disabled: true,
        });
      }
    }
    this.insertAddFrame(json);
  }

  componentDidMount() {
    const { order } = this.props;

    if (order.rpl) {
      if (order.pares[0].id_pedido_otica === "ORDER_UNUSED") {
        this.getRPL(0, order.pares.length);
      } else {
        this.getRPL(1, order.pares.length);
      }
    }

    this.validateService();

    this.props.handleOrderChange({
      limparPrisma: this.limparPrisma,
      limparPrismaEsquerdo: this.limparPrismaEsquerdo,
      limparPrismaDireito: this.limparPrismaDireito,
    });

    this.setState({ cnpjOtica: this.context.userSession.conta }, () => {
      this.autoClick();
    });

    if (this.props.order.lente_servicos || this.props.order.lente_coloracao) {
      if (this.props.order.lente_servicos && !this.props.order.lente_coloracao) {
        this.setState({ tratamento_informado: true });
        this.setState({ coloracao_informado: false });
      } else if (!this.props.order.lente_servicos && this.props.order.lente_coloracao) {
        this.setState({ tratamento_informado: false });
        this.setState({ coloracao_informado: true });
      } else {
        this.setState({ tratamento_informado: false });
        this.setState({ coloracao_informado: false });
      }
    } else if (this.props.order.pedidoColorirNaOtica) {
      this.setState({ coloracao_informado: true });
    } else {
      this.setState({ tratamento_informado: false });
      this.setState({ coloracao_informado: false });
    }

    if (this.props.order.lente_montagem) {
      if (this.props.order.lente_montagem === "S") {
        this.setState({ requiredCorte: true });
      } else {
        this.setState({ requiredCorte: false });
      }
    }

    if (order.outrosValue !== undefined) {
      if (order.outrosValue.length !== 0) {
        var OUTROS = { label: this.props.order.growmocao_value, value: this.props.order.growmocao_cod, ref: this.props.order.growmocao_cod };
        this.setState({
          growmocaoCheck: this.props.order.growmocao,
          outrosValue: OUTROS,
          isServicos: true,
        });
      } else {
        this.setState({ growmocaoCheck: false });
      }
    } else {
      this.setState({ growmocaoCheck: false });
    }
    if (order.arValue !== undefined) {
      if (order.arValue.length !== 0) {
        this.setState({ arValue: order.arValue });
      }
    }
    if (order.colValue !== undefined) {
      if (order.colValue.length !== 0) {
        this.setState({ colValue: order.colValue });
        if (order.lente_coloracao) {
          this.getCor(order.lente_coloracao_cod);
        }
      }
    }

    if (order.corValue !== undefined) {
      if (order.corValue.length !== 0) {
        this.setState(state => {
          const corValue = state.corValue.push(order.corValue);
          return {
            corValue,
          };
        });
      }
    }

    this.props.handleOrderChange({
      isLentePronta: this.isLentePronta,
      lenteProntaComMontagem: this.lenteProntaComMontagem,
      lenteProntaSemMontagem: this.lenteProntaSemMontagem,
    });
  }

  isLentePronta() {
    if (this.lente_tp_producao == "LENTES_PRONTA") {
      return true;
    }
    return false;
  }

  lenteProntaComMontagem() {
    if (this.isLentePronta()) {
      if (this.lente_montagem == "S") {
        return true;
      }
    }
    return false;
  }

  lenteProntaSemMontagem() {
    if (this.isLentePronta()) {
      if (this.lente_montagem == "N") {
        return true;
      }
    }
    return false;
  }

  handleClickNext = () => {
    if (this.props.order.promo_code) {
      let invalido = false;
      const tratamento = document.getElementById("lente_servicos");
      const coloracao = document.getElementById("lente_coloracao");
      if (tratamento?.options.selectedIndex !== 0) this.state.tratamento_informado = true;
      if (coloracao?.options.selectedIndex !== 0) this.state.coloracao_informado = true;

      if (
        (tratamento?.options.length > 1 && !this.state.ar_disabled && !this.state.tratamento_informado) ||
        (coloracao?.options.length > 1 && !this.state.col_disabled && !this.state.coloracao_informado)
      ) {
        if (tratamento && !this.state.ar_disabled && !this.state.tratamento_informado) {
          tratamento.style.boxShadow = "0 0 0 1px #f70017";
          tratamento.style.borderRadius = "4px";
          invalido = true;
        }

        if (coloracao && !this.state.col_disabled && !this.state.coloracao_informado && this.props.order.pedidoColorirNaOtica == false) {
          coloracao.style.boxShadow = "0 0 0 1px #f70017";
          coloracao.style.borderRadius = "4px";
          invalido = true;
        }

        if (invalido == true) {
          Alert.fire({
            text: "Campos obrigatórios pendentes!",
            icon: "warning",
            title: "Aviso",
            confirmButtonColor: "#00518b",
            customClass: {
              confirmButton: "x-follow-order__filter-btn",
            },
          });

          return;
        }
      }
    }

    if (this.props.order.lente_esc_produto !== "" && this.props.order.lente_esc_produto !== undefined) {
      if (document.querySelector('input[name="quantidade"]').value <= 0) {
        document.querySelector('input[name="quantidade"]').style.boxShadow = "0 0 0 1px #f70017";

        Alert.fire({
          text: "A quantidade não pode ser menor que 1.",
          icon: "warning",
          title: "Aviso",
          confirmButtonColor: "#00518b",
          customClass: {
            confirmButton: "x-follow-order__filter-btn",
          },
        });
        return;
      }

      if (this.props.order.cor_obrigatoria && !this.props.order.cor && this.props.order.pedidoColorirNaOtica == false) {
        Alert.fire({
          text: "A seleção de uma cor é obrigatória!",
          icon: "warning",
          title: "Aviso",
          confirmButtonColor: "#00518b",
          customClass: {
            confirmButton: "x-follow-order__filter-btn",
          },
        });

        return;
      }

      if (document.getElementById("lente_corte") && this.props.order.pedidoColorirNaOtica == false) {
        if (document.getElementById("lente_corte").required && document.getElementById("lente_corte").value === "") {
          document.getElementById("lente_corte").style.boxShadow = "0 0 0 1px #f70017";

          var selectFields = document.getElementsByTagName("SELECT");

          for (var item in selectFields) {
            if (selectFields[item].required && selectFields[item].value === "") {
              selectFields[item].style.boxShadow = "0 0 0 1px #f70017";
            }
          }

          Alert.fire({
            text: "Campos obrigatórios pendentes!",
            icon: "warning",
            title: "Aviso",
            confirmButtonColor: "#00518b",
            customClass: {
              confirmButton: "x-follow-order__filter-btn",
            },
          });
        } else {
          this.props.onNext(3, "right");
        }
      } else {
        this.props.onNext(3, "right");
      }
    } else {
      if (this.props.order.lente_esc_produto === "" || this.props.order.lente_esc_produto === undefined) {
        if (document.getElementById("lente_esc_produto")) {
          document.getElementById("lente_esc_produto").style.boxShadow = "0 0 0 1px #f70017";
        }
      }
      if (document.getElementById("lente_corte")) {
        if (document.getElementById("lente_corte").required && document.getElementById("lente_corte").value === "") {
          document.getElementById("lente_corte").style.boxShadow = "0 0 0 1px #f70017";
        }
      }

      var selectFields = document.getElementsByTagName("SELECT");

      for (var item in selectFields) {
        if (selectFields[item].required && selectFields[item].value === "") {
          selectFields[item].style.boxShadow = "0 0 0 1px #f70017";
        }
      }

      Alert.fire({
        text: "Campos obrigatórios pendentes!",
        icon: "warning",
        title: "Aviso",
        confirmButtonColor: "#00518b",
        customClass: {
          confirmButton: "x-follow-order__filter-btn",
        },
      });
      return;
    }
  };

  handleClickPrev = () => {
    this.props.onNext(1, "left");
  };

  handleChange = evt => {
    if ((evt.target.name = "pedidoColorirNaOtica")) {
      if (this.props.order.pedidoColorirNaOtica != true) {
        this.setState({
          col_disabled: true,
        });
      } else {
        this.setState({
          col_disabled: false,
        });
      }
    }
    this.props.handleOrderChange({ [evt.target.name]: evt.target.value });
  };

  autoClick = async () => {
    const { order } = this.props;

    if (
      this.state.lente_tp_producao !== "" &&
      this.state.lente_tp_produto !== "" &&
      order.lente_tp_producao !== undefined &&
      order.lente_tp_produto !== undefined
    ) {
      var link = "";
      this.setState({ placeholder: "Carregando..." });

      if (this.state.lente_tp_producao !== "" && this.state.lente_tp_produto !== "") {
        link =
          process.env.REACT_APP_ESSILOR_API +
          "gestao-produtos-laboratorio/v1/produtos?tipoProducao=" +
          this.state.lente_tp_producao +
          "&tipoProduto=" +
          this.state.lente_tp_produto;
      } else if (order.lente_tp_producao !== undefined && order.lente_tp_produto !== undefined) {
        link =
          process.env.REACT_APP_ESSILOR_API +
          "gestao-produtos-laboratorio/v1/produtos?tipoProducao=" +
          order.lente_tp_producao +
          "&tipoProduto=" +
          order.lente_tp_produto;
      }
      let cnpj = this.props.order.dados_empresa_faturamento_cnpj
        ? this.props.order.dados_empresa_faturamento_cnpj
            .replace(/[^0-9]/g, "")
            .replace("/", "")
            .replace("-", "")
        : "";
      var requestOptions = {};
      if ((this.props.order.redeOticas && this.props.order.dados_empresa_faturamento_cnpj) || this.context.userSession.papelCategoria === "ESSILOR") {
        requestOptions = {
          method: "GET",
          headers: {
            otica: cnpj,
            access_token: localStorage.getItem("token"),
            laboratorio: order.laboratorio,
          },
        };
      } else {
        requestOptions = {
          method: "GET",
          headers: {
            access_token: localStorage.getItem("token"),
            laboratorio: order.laboratorio,
          },
        };
      }

      await fetch(link, requestOptions)
        .then(response => {
          if (response.status === 200) {
            return response.json();
          } else {
            return [];
          }
        })
        .then(json => {
          if (order.lente_esc_produto) {
          }

          this.setState({ produtos: json, placeholder: `${json.length} resultados encontrados` });
        });
    } else {
      this.setState({ produtos: [], placeholder: "Nenhum resultado encontrado" });
    }
  };

  handleChangeAutocomplete = (item, aux) => {
    const { order } = this.props;

    // document.getElementById("lente_esc_produto").style.boxShadow =
    // 	"0 0 0 1px #B5B5B5";
    var cod = "";
    var value = "";

    if (aux.action === "set-value") {
      cod = item;
      value = order.lente_esc_produto_nome;
    } else {
      cod = item.value;
      value = item.label;

      // for (var item in this.state.produtos) {
      // 	if (
      // 		value ==
      // 		this.state.produtos[item].descricao
      // 			.trim()
      // 			.replace(/\s\s+/g, " ")
      // 	) {
      // 		cod = this.state.produtos[item].codigo;
      // 	}
      // }

      this.props.handleOrderChange({ lente_esc_produto: cod });
      this.props.handleOrderChange({ lente_esc_produto_nome: value });

      //limpando os valores de serviço
      this.props.handleOrderChange({
        lente_servicos_value: "",
        lente_servicos_cod: "",
        lente_servicos: "",
        lente_coloracao_value: "",
        lente_coloracao_cod: "",
        lente_coloracao: "",
        lente_corte: "",
        lente_corte_cod: "",
        lente_corte_value: "",
        tratamento: "",
      });
      //limpando os valores de serviço
      this.setState({
        tratamento_informado: false,
        coloracao_informado: false,
      });

      this.limparParametros();
      this.limpaCorCores();

      this.setState({
        codProd: cod,
      });
      this.context.showLoading("Carregando serviços...");
      this.loadAddServices(cod);
    }
  };

  limparParametrosLentePronta() {
    this.props.handleOrderChange({
      prescricao_ini_clientes: "",
      prescricao_olho_direito_dnp_perto: "",
      prescricao_olho_esquerdo_dnp_perto: "",
      prescricao_dis_vert_direito: "",
      prescricao_dis_vert_esquerdo: "",
      prescricao_cro_direito: "",
      prescricao_cro_esquerdo: "",
      prescricao_ang_pantoscopico: "",
      prescricao_curva_arm: "",
      prescricao_dis_leitura: "",
      prescricao_coeficiente: "",
      prescricao_estabilidade: "",
      prescricao_dis_prog: "",
      prescricao_cvp: "",
      prescricao_olho_dom: "",
      prescricao_olho_dom_value: "",
      prescricao_olho_direito_altura: "",
      prescricao_olho_esquerdo_altura: "",
      prescricao_olho_direito_dnp: "",
      prescricao_olho_esquerdo_dnp: "",
      frame_tipo_arm: "",
      frame_tipo_arm_value: "",
      frame_info_arm_vertical: "",
      frame_info_arm_horizontal: "",
      frame_info_arm_diametro: "",
      frame_info_arm_ponte: "",
      frame_modelo_v2: "",
      lente_corte: "",
      descricao: "",
      frame_modelo: "",
    });

    this.limparPrisma();
  }

  limparParametros = () => {
    this.props.handleOrderChange({
      prescricao_ini_clientes: "",
      prescricao_olho_direito_dnp_perto: "",
      prescricao_olho_esquerdo_dnp_perto: "",
      prescricao_dis_vert_direito: "",
      prescricao_dis_vert_esquerdo: "",
      prescricao_cro_direito: "",
      prescricao_cro_esquerdo: "",
      prescricao_ang_pantoscopico: "",
      prescricao_curva_arm: "",
      prescricao_dis_leitura: "",
      prescricao_coeficiente: "",
      prescricao_estabilidade: "",
      prescricao_dis_prog: "",
      prescricao_cvp: "",
      prescricao_olho_dom: "",
      prescricao_olho_dom_value: "",
    });

    if (this.state.lente_tp_produto === "VISAO_SIMPLES") {
      this.props.handleOrderChange({
        prescricao_olho_direito_adicao: "",
        prescricao_olho_esquerdo_adicao: "",
      });
    }
  };

  limparPrisma = () => {
    this.limparPrismaDireito();
    this.limparPrismaEsquerdo();
  };

  limparPrismaDireito = () => {
    this.props.handleOrderChange({
      prescricao_olho_direito_pris_h: "",
      prescricao_olho_direito_base_h: "",
      prescricao_olho_direito_pris_v: "",
      prescricao_olho_direito_base_v: "",
      prescricao_olho_direito_pris_h_value: "",
      prescricao_olho_direito_base_h_value: "",
      prescricao_olho_direito_pris_v_value: "",
      prescricao_olho_direito_base_v_value: "",
    });
  };

  limparPrismaEsquerdo = () => {
    this.props.handleOrderChange({
      prescricao_olho_esquerdo_pris_h: "",
      prescricao_olho_esquerdo_base_h: "",
      prescricao_olho_esquerdo_pris_v: "",
      prescricao_olho_esquerdo_base_v: "",
      prescricao_olho_esquerdo_pris_h_value: "",
      prescricao_olho_esquerdo_base_h_value: "",
      prescricao_olho_esquerdo_pris_v_value: "",
      prescricao_olho_esquerdo_base_v_value: "",
    });
  };

  loadAddServices = cod => {
    const { order } = this.props;

    var requestOptions = {};
    if (this.props.order.redeOticas && this.props.order.dados_empresa_faturamento_cnpj) {
      requestOptions = {
        method: "GET",
        headers: {
          access_token: localStorage.getItem("token"),
          laboratorio: order.laboratorio,
          otica: this.props.order.dados_empresa_faturamento_cnpj,
        },
      };
    } else {
      requestOptions = {
        method: "GET",
        headers: {
          access_token: localStorage.getItem("token"),
          laboratorio: order.laboratorio,
        },
      };
    }

    fetch(process.env.REACT_APP_ESSILOR_API + "gestao-produtos-laboratorio/v1/produtos/" + cod + "/parametros", requestOptions).then(response => {
      if (response.status === 200) {
        return response.json().then(json => {
          if (order.rpl) {
            if (json.receita.adicao.preenchimento == "NAO_ACEITA") {
              this.props.handleOrderChange({
                prescricao_olho_direito_adicao: "",
                prescricao_olho_esquerdo_adicao: "",
              });
            }
            if (json.receita.altura.preenchimento == "NAO_ACEITA") {
              this.props.handleOrderChange({
                prescricao_olho_direito_altura: "",
                prescricao_olho_esquerdo_altura: "",
              });
            }
            if (json.receita.dnp.preenchimento == "NAO_ACEITA") {
              this.props.handleOrderChange({
                prescricao_olho_direito_dnp: "",
                prescricao_olho_esquerdo_dnp: "",
              });
            }
          }

          this.setSummaryControl(json);

          this.setState({
            opacity: "",
            loading: false,
          });
          let coresArmacao = [];
          if (json.armacao?.cor?.dominio?.length >= 1) {
            json.armacao.cor.dominio.map(item => {
              coresArmacao.push({ label: item.descricao, value: item.codigo });
            });
          }
          this.context.hideLoading();
          this.props.handleOrderChange({ json_parametros: json, cores_armacao: coresArmacao });
          this.insertAddServices(json);
          this.insertAddFrame(json);
        });
      } else if (response.status >= 400 && response.status < 499) {
        this.props.handleOrderChange({ lente_esc_produto: "" });
        this.props.handleOrderChange({ lente_esc_produto_nome: "" });
        this.rplAlert("Erro interno. Tente novamente mais tarde.", "warning");
      } else if (response.status === 504) {
        this.props.handleOrderChange({ lente_esc_produto: "" });
        this.props.handleOrderChange({ lente_esc_produto_nome: "" });
        this.rplAlert("A requisição atingiu o tempo máximo de processamento. Tente novamente mais tarde.", "error");
      } else {
        this.props.handleOrderChange({ lente_esc_produto: "" });
        this.props.handleOrderChange({ lente_esc_produto_nome: "" });
        this.rplAlert("Erro interno. Tente novamente mais tarde.", "error");
      }
    });
  };

  setSummaryControl(json) {
    if (json.dnpPerto.preenchimento != "NAO_ACEITA") this.props.handleOrderChange({ control_dnpPerto: true });

    if (json.iniciaisPaciente.preenchimento != "NAO_ACEITA")
      this.props.handleOrderChange({
        control_prescricao_ini_clientes: true,
      });

    if (json.distanciaLeitura.preenchimento != "NAO_ACEITA")
      this.props.handleOrderChange({
        control_prescricao_dis_leitura: true,
      });

    if (json.cvp.preenchimento != "NAO_ACEITA") this.props.handleOrderChange({ control_prescricao_cvp: true });

    if (json.cro.preenchimento != "NAO_ACEITA") this.props.handleOrderChange({ control_prescricao_cro: true });

    if (json.anguloPantoscopico.preenchimento != "NAO_ACEITA")
      this.props.handleOrderChange({
        control_prescricao_ang_pantoscopico: true,
      });

    if (json.anguloCurvatura.preenchimento != "NAO_ACEITA")
      this.props.handleOrderChange({
        control_prescricao_curva_arm: true,
      });

    if (json.coeficienteHE.preenchimento != "NAO_ACEITA")
      this.props.handleOrderChange({
        control_prescricao_coeficiente: true,
      });

    if (json.coeficienteST.preenchimento != "NAO_ACEITA")
      this.props.handleOrderChange({
        control_prescricao_estabilidade: true,
      });

    if (json.distanciaProgressao.preenchimento != "NAO_ACEITA") this.props.handleOrderChange({ control_prescricao_dis_prog: true });

    if (json.distanciaVertice.preenchimento != "NAO_ACEITA") this.props.handleOrderChange({ control_prescricao_dis_vert: true });

    if (json.olhoDominante.preenchimento != "NAO_ACEITA") this.props.handleOrderChange({ control_prescricao_olho_dom: true });

    if (json.armacao.modelo.preenchimento != "NAO_ACEITA") this.props.handleOrderChange({ control_modelo: true });

    if (json.armacao.horizontal.preenchimento != "NAO_ACEITA") this.props.handleOrderChange({ control_horizontal: true });

    if (json.armacao.vertical.preenchimento != "NAO_ACEITA") this.props.handleOrderChange({ control_vertical: true });

    if (json.armacao.ponte.preenchimento != "NAO_ACEITA") this.props.handleOrderChange({ control_ponte: true });

    if (json.armacao.diametroMaior.preenchimento != "NAO_ACEITA") this.props.handleOrderChange({ control_diagonal: true });
  }

  insertAddFrame = json => {
    var armacao = [];

    if (json.armacao) {
      json.armacao.tipo.dominio.map(item => {
        armacao.push({ label: item.codigo + " - " + item.descricao, value: item.codigo });
      });
    }

    this.props.handleOrderChange({ frame: armacao });
  };

  insertAddServices = json => {
    this.setState({
      arValue: [],
      colValue: [],
      corValue: [],
      outrosValue: [],
    });

    this.props.handleOrderChange({ arValue: [] });
    this.props.handleOrderChange({ colValue: [] });
    this.props.handleOrderChange({ corValue: [] });
    this.props.handleOrderChange({ outrosValue: [] });

    var AR = [];
    var COL = [];
    var COR = [];
    var OUTROS = [];
    if (json.servicos.length > 0) {
      json.servicos.map(item => {
        if (item.tipo === "ANTIRREFLEXO") {
          AR.push({
            label: item.descricao,
            value: item.codigo,
            ref: item.codigoRef,
          });
        } else if (item.tipo === "COLORACAO") {
          COL.push({
            label: item.descricao,
            value: item.codigo,
            ref: item.codigoRef,
          });
        } else if (item.tipo === "MONTAGEM") {
          COR.push({
            label: item.descricao,
            value: item.codigo,
            ref: item.codigoRef,
          });
        } else if (item.tipo === "OUTROS" && parseInt(item.codigo) === 7003) {
          //Adicionar validação de laboratorio igual a grown
          OUTROS.push({ label: item.descricao, value: item.codigo, ref: item.codigoRef });
        }
      });

      let itens = "";
      let itensCOL = "";
      if (this.props.order.codigoRefServicos) {
        /*  
          if (itens.length === 1 && this.state.promo_code) {
            this.setState({
              coloracaoLabel: itens,
            });
          }
        
        if (obj?.corValue && obj?.corValue.length === 1 && this.state.promo_code) {
          this.setState({
            lente_corteLabel: obj.corValue,
          });
        }
*/

        if (
          (this.props.order.codigoRefServicos == "33" || this.props.order.codigoRefServicos == "99") &&
          (json.tipoProducao == "SURFACAGEM_DIGITAL" || json.tipoProducao == "SURFACAGEM_TRADICIONAL")
        ) {
          itens = AR.map((item, index) => ({ ...item, index })).filter(item => item.ref === "02");

          itensCOL = COL.map((item, index) => ({ ...item, index })).filter(item => item.ref == this.props.order.codigoRefServicos);
        } else {
          itens = AR.map((item, index) => ({ ...item, index })).filter(item => item.ref === this.props.order.codigoRefServicos);
          itensCOL = COL.map((item, index) => ({ ...item, index })).filter(item => item.ref === this.props.order.codigoRefServicos);
        }
        if (itens.length === 1 && this.props.order.promo_code) {
          this.props.handleOrderChange({
            lente_servicos: itens[0]?.value,
            lente_servicos_value: itens[0]?.label,
            lente_servicos_cod: itens[0]?.value,
          });
          this.setState({
            tratamento_informado: true,
            ar_disabled: true,
          });
        }

        if (itensCOL.length === 1 && this.props.order.promo_code && !this.props.order.pedidoColorirNaOtica) {
          this.getCor(itensCOL[0]?.value);
          this.props.handleOrderChange({
            lente_coloracao: itensCOL[0]?.value,
            lente_coloracao_cod: itensCOL[0]?.value,
            lente_coloracao_value: itensCOL[0]?.label,
          });
          this.setState({
            coloracao_informado: true,
          });
          if (this.props.order.colorirNaOtica) {
            this.setState({
              col_disabled: false,
            });
          } else {
            this.setState({
              col_disabled: true,
            });
          }
        }
      } else if (this.props.order.promo_code) {
        itens = [];
        itensCOL = [];
      } else {
        itens = AR;
        itensCOL = COL;
      }
      this.setState({
        arValue: itens,
        colValue: itensCOL,
        corValue: COR,
        outrosValue: OUTROS,
        isServicos: true,
      });

      this.props.handleOrderChange({
        lente_montagem: "S",
        lente_montagem_value: "Sim",
      });

      this.props.handleOrderChange({ arValue: itens });
      this.props.handleOrderChange({ colValue: itensCOL });
      this.props.handleOrderChange({ corValue: COR });
      this.props.handleOrderChange({ outrosValue: OUTROS });
    } else {
      this.setState({
        isServicos: false,
      });

      this.props.handleOrderChange({
        lente_montagem: "S",
        lente_montagem_value: "Sim",
      });
    }
  };

  validateService() {
    if (this.props.order.arValue && this.props.order.colValue && this.props.order.corValue) {
      this.setState({
        isServicos: true,
      });
    } else {
      this.setState({
        isServicos: false,
      });
    }
  }

  handleGrowmocao = evt => {
    if (evt.target.name === "growmocao") {
      if (evt.target.checked) {
        this.props.handleOrderChange({
          [evt.target.name]: evt.target.checked,
          [evt.target.name + "_value"]: "GROWNMOÇÃO + WEB",
          [evt.target.name + "_cod"]: "7003",
        });
      } else {
        this.props.handleOrderChange({
          [evt.target.name]: evt.target.checked,
          [evt.target.name + "_value"]: "",
          [evt.target.name + "_cod"]: "",
        });
      }
    }
  };

  handleSelectChange = evt => {
    document.getElementById(evt.target.id).style.boxShadow = "0 0 0 1px #B5B5B5";

    this.props.handleOrderChange({
      [evt.target.name]: evt.target.value,
      [evt.target.name + "_value"]: evt.target.options[evt.target.selectedIndex].text,
      [evt.target.name + "_cod"]: evt.target.options[evt.target.selectedIndex].value,
    });

    if (evt.target.name === "lente_servicos") {
      if (evt.target.value) {
        this.setState({
          tratamento_informado: true,
          // coloracao_informado: false, #WARNING VALIDAR REGRA
        });
      } else {
        this.setState({
          tratamento_informado: false,
          // coloracao_informado: false, #WARNING VALIDAR REGRA
        });
      }
    }

    if (evt.target.name === "lente_coloracao") {
      this.limpaCorCores();
      this.getCor(evt.target.value);
      if (evt.target.value) {
        this.setState({
          tratamento_informado: false,
          coloracao_informado: true,
        });
      } else {
        this.setState({
          tratamento_informado: false,
          coloracao_informado: false,
        });
      }
    }

    let newState = {};
    newState[evt.target.name] = evt.target.value;

    // if (
    // 	evt.target.name == 'lente_tp_producao' &&
    // 	evt.target.value == 'LENTES_PRONTA'
    // ) {
    // 	this.limparParametrosLentePronta()
    // }

    if (evt.target.name === "lente_tp_producao" || evt.target.name === "lente_tp_produto") {
      this.limpaCorCores();

      if (evt.target.value !== "LENTES_PRONTA") {
        this.props.handleOrderChange({ quantidade: 1 });
        document.querySelector('input[name="quantidade"]').value = 1;
      }
      this.props.handleOrderChange({ lente_esc_produto: undefined });
      this.props.handleOrderChange({ lente_esc_produto_nome: undefined });
      this.props.handleOrderChange({ json_parametros: undefined });

      newState["produtos"] = [];
      newState["auto"] = false;
      newState["arValue"] = [];
      newState["colValue"] = [];
      newState["corValue"] = [];

      this.setState(newState, this.autoClick);
    }
  };

  handleChangeCor(e) {
    const { order } = this.props;
    var itemAux = order.cores.filter(item => {
      return parseInt(item.codigo) === parseInt(e.target.value);
    });
    const name = itemAux[0].descricao;
    const codigo = e.target.value;

    this.props.handleOrderChange({
      cor: codigo,
      cor_value: name,
      cor_cod: codigo,
    });
  }

  handleChangeCorObservacao(e) {
    this.props.handleOrderChange({
      cor: e.target.value,
      cor_value: e.target.value,
      cor_cod: e.target.value,
    });
  }

  limpaCorCores() {
    this.props.handleOrderChange({
      cores: [],
      cor_obrigatoria: false,
      isCorObservacao: false,
      cor: "",
      cor_value: "",
      cor_cod: "",
    });

    this.setState({ cores: [], cor_obrigatoria: false, isCorObservacao: false });
  }

  getCor(cod) {
    const { order } = this.props;

    var requestOptions = {
      method: "GET",
      headers: {
        access_token: localStorage.getItem("token"),
        laboratorio: order.laboratorio,
      },
    };

    fetch(process.env.REACT_APP_ESSILOR_API + `gestao-produtos-laboratorio/v1/servicos/${cod}`, requestOptions).then(response => {
      if (response.status === 200) {
        return response.json().then(json => {
          if (json.cor.dominio.length > 0) {
            this.setState({ cores: json.cor.dominio, cor_obrigatoria: json.cor.preenchimento === "OBRIGATORIO", isCorObservacao: false });
            this.props.handleOrderChange({
              cores: json.cor.dominio,
              cor_obrigatoria: json.cor.preenchimento === "OBRIGATORIO",
              isCorObservacao: false,
            });
          } else {
            this.setState({ cores: json.cor.dominio, cor_obrigatoria: json.cor.preenchimento === "OBRIGATORIO", isCorObservacao: true });
            this.props.handleOrderChange({
              cores: json.cor.dominio,
              cor_obrigatoria: json.cor.preenchimento === "OBRIGATORIO",
              isCorObservacao: true,
            });
          }
        });
      } else {
        this.setState({ cores: [], cor_obrigatoria: false, isCorObservacao: true });
        this.props.handleOrderChange({
          cores: [],
          cor_obrigatoria: false,
          isCorObservacao: true,
        });
      }
    });
  }

  redo = () => {
    this.setState({ auto: true });
  };

  handleBlurAutocomplete = obj => {
    this.props.handleOrderChange(obj);
  };

  render() {
    const { order } = this.props;
    var prodArray = [];
    let itensColoração = [];
    this.state.produtos.map(item => {
      prodArray.push(item);
    });

    var autocomplete = null;
    if (this.state.auto) {
      autocomplete = (
        <>
          <AutoCompleteSelect
            ref={this.autoRef}
            options={this.state.produtos}
            className=""
            width="100%"
            name="lente_esc_produto"
            id="autocomplete"
            onChange={this.handleChangeAutocomplete}
            defaultValue={order.lente_esc_produto ? [{ value: order.lente_esc_produto, label: order.lente_esc_produto_nome }] : ""}
            placeholder={this.state.placeholder}
            onBlur={this.handleBlurAutocomplete}
            autocomplete="lente_esc_produto"
            tooltip="A consulta pode ser feita pelo código ou pelo nome do produto."
            label="Escolha o produto*"></AutoCompleteSelect>
        </>
      );
    } else {
      autocomplete = null;
      this.redo();
    }

    var selectAR = null;
    if (
      (this.props.order.IsCor_AR && this.state.arValue.length !== 0) ||
      (!this.props.order.IsCor_AR && this.state.arValue.length !== 0 && !this.state.coloracao_informado) ||
      (this.state.arValue.length !== 0 && order.promo_code)
    ) {
      selectAR = (
        <Select
          label="Tratamento"
          width="33.33%"
          name="lente_servicos"
          aria="Selecione o serviço"
          itens={order.arValue}
          value={order.lente_servicos_cod}
          onChange={this.handleSelectChange}
          disabled={this.state.ar_disabled}
        />
      );
    }

    var selectCOL = null;
    if (
      (this.props.order.IsCor_AR && this.state.colValue.length !== 0) ||
      (!this.props.order.IsCor_AR && this.state.colValue.length !== 0 && !this.state.tratamento_informado) ||
      (this.state.colValue.length !== 0 && order.promo_code)
    ) {
      var coloracaoLabel = "";
      let col_disabled = this.state.col_disabled;
      if (order.pedidoColorirNaOtica == false) {
        coloracaoLabel = order.lente_coloracao;
        col_disabled = this.state.col_disabled;
      } else if (order.pedidoColorirNaOtica == true) {
        col_disabled = true;
      }
      if (this.state.colValue.length === 1 && order.promo_code && !order?.promo_n_par == 2) {
        col_disabled = true;
      }
      selectCOL = (
        <Select
          label="Coloração"
          name="lente_coloracao"
          aria="Selecione o serviço"
          itens={this.state.colValue}
          width={order.colorirNaOtica && order?.promo_n_par == 2 ? "" : "33.33%"}
          value={coloracaoLabel}
          onChange={this.handleSelectChange}
          disabled={col_disabled}
        />
      );
    }

    var selectCOR = null;

    if (this.state.corValue.length !== 0) {
      var itens = "";
      let label = "Corte";
      let required = false;
      if (order.corValue == undefined) {
        itens = this.state.corValue;
      } else {
        itens = order.corValue;
      }
      if (this.state.requiredCorte) {
        let msg = "Caso não deseje pedir montagem, mantenha este campo em branco.";
        let show = true;
        itens.map((item, i) => {
          if (item.label.toLowerCase() === "sem corte") {
            msg = null;
            show = false;
          }
        });
        var lente_corte = order.lente_corte;
        if (!order.lente_corte && order?.lente_corteLabel) {
          lente_corte = order?.lente_corteLabel[0]?.value;
        }

        if (order.CorteMontagemObrigatorio) {
          label = "Corte*";
          required = true;
          msg = "";
        }

        selectCOR = (
          <Select
            label={label}
            width="33.3%"
            name="lente_corte"
            aria="Selecione o serviço"
            itens={itens}
            value={lente_corte}
            onChange={this.handleSelectChange}
            showTooltip={show}
            tooltipMsg={msg}
            required={required}
          />
        );
      }
    }

    var growmocao = null;

    var selectCORES = null;
    var selectCORESItens = order.cores ? order.cores : this.state.cores;
    var pedidoColorirNaOtica = order.pedidoColorirNaOtica;
    if (
      (this.props.order.IsCor_AR && this.state.colValue.length !== 0) ||
      (!this.props.order.IsCor_AR &&
        this.state.colValue.length !== 0 &&
        !this.state.tratamento_informado &&
        (this.state.coloracao_informado || order.colorirNaOtica)) ||
      (this.state.colValue.length !== 0 && order.promo_code && this.state.coloracao_informado)
    ) {
      selectCORES = (
        <>
          {order.lente_coloracao && selectCORESItens && selectCORESItens.length > 0 && !pedidoColorirNaOtica && (
            <>
              <div className="row mt-3">
                <h2 className="order-title">{`Cor da Coloração ${order.cor_obrigatoria ? "*" : ""}`}</h2>
                <p className="x-web-ordering-pedido__lens intro__form--subtitle title-mobile t-lens">Selecione a cor a ser aplicada na lente</p>
                <Form>
                  <div key={`inline-radio`} className="mb-3 checkboxOne">
                    {selectCORESItens.map((item, index) => {
                      return (
                        <Form.Check inline type="radio" id={item.descricao} className="text-center order-lens-coloracao-cor">
                          <Form.Check.Input
                            type="radio"
                            // name={item.descricao}
                            name="cor"
                            checked={parseInt(item.codigo) === parseInt(order.cor)}
                            value={item.codigo}
                            bsPrefix="colorInputRadio"
                            onChange={this.handleChangeCor}
                            required={order.cor_obrigatoria}
                          />
                          <Form.Check.Label bsPrefix="colorLabelRadio">
                            <img src={`data:image/png;base64,${item.imagem}`} alt={item.descricao} />
                            <small className="descricaoIMG">{item.descricao}</small>
                          </Form.Check.Label>
                        </Form.Check>
                      );
                    })}
                  </div>
                </Form>
              </div>
            </>
          )}
          {order.lente_coloracao && selectCORESItens && selectCORESItens.length === 0 && order.isCorObservacao && !pedidoColorirNaOtica && (
            <>
              <div>
                {/* <h2 className="order-title">
								{`Cor da Coloração ${order.cor_obrigatoria ? '*' : ''}`}
							</h2> */}
                <div>
                  <Input
                    name="cor"
                    type="text"
                    label="Observação da Coloração"
                    width="100%"
                    aria="Observação da Coloração"
                    tooltip="Informações adicionais para a coloração da lente."
                    value={order.cor}
                    onChange={this.handleChangeCorObservacao}
                  />
                </div>
              </div>
            </>
          )}
        </>
      );
    }

    //#WARNING GROWMOÇÃO
    if (this.props.order.IsGrowmocao) {
      if (this.state.outrosValue.length !== 0) {
        var itens = "";

        if (order.outrosValue.length === 0) {
          itens = this.state.outrosValue;
        } else {
          itens = order.outrosValue;
        }

        growmocao = <CRMSwitch label={itens[0].label} name="growmocao" id="growmocao" value={this.props.order.growmocao} onClick={this.handleGrowmocao} />;
      }
    }

    return (
      <section className={`x-web-ordering-pedido__lens ${this.props.order.direction == "right" ? "right" : "left"} content`}>
        <div className={`order-main-container ${this.state.opacity}`}>
          <div className="x-web-ordering-pedido__lens intro__form--container">
            <h2 className="order-title">Produto</h2>
            <p className="order-paragraph right">*Campos obrigatórios</p>
          </div>

          <div className="x-web-ordering-pedido__lens--body">
            <div className="x-web-ordering-pedido__lens--row">
              <Select
                label="Tipo de produção*"
                width="50%"
                name="lente_tp_producao"
                aria="Selecione o tipo de produção"
                itens={[
                  {
                    label: "Surfaçagem Digital",
                    value: "SURFACAGEM_DIGITAL",
                  },
                  {
                    label: "Surfaçagem Tradicional",
                    value: "SURFACAGEM_TRADICIONAL",
                  },
                  {
                    label: "Lente Pronta",
                    value: "LENTES_PRONTA",
                  },
                ]}
                value={order.lente_tp_producao}
                onChange={this.handleSelectChange}
                disabled={order.rpl ? true : false}
                required
              />

              <Select
                label="Tipo do produto*"
                width="50%"
                name="lente_tp_produto"
                aria="Selecione o tipo de produto"
                itens={[
                  { label: "Bifocal", value: "BIFOCAL" },
                  {
                    label: "Multifocal",
                    value: "MULTIFOCAL",
                  },
                  {
                    label: "Visão Simples",
                    value: "VISAO_SIMPLES",
                  },
                  {
                    label: "Visão Intermediária",
                    value: "VISAO_INTERMEDIARIA",
                  },
                ]}
                value={order.lente_tp_produto}
                onChange={this.handleSelectChange}
                disabled={order.rpl ? true : false}
                required
              />
            </div>

            <div className="x-web-ordering-pedido__lens--row mt-4">
              {/* Campo lista dinâmica */}
              {!order.rpl && autocomplete}

              {order.rpl && <Input label="Produto" name="lente_esc_produto" type="text" value={order.lente_esc_produto_nome} width="100%" readOnly />}
              <div className="d-none">
                <Input
                  name="quantidade"
                  type="number"
                  label="Quantidade"
                  aria="Preencha a quantidade"
                  autocomplete="off"
                  value={order.quantidade}
                  disabled={order.lente_tp_producao !== "LENTES_PRONTA"}
                  onChange={this.handleChange}
                  min={1}
                  max={999}
                  width="33%"
                  step="1"
                  tooltip="Para pedidos de lente pronta em atacado, preencher com um valor maior que 1."
                />
              </div>
            </div>

            <br />
            {this.state.isServicos && (order.quantidade === "1" || order.quantidade === 1) && (
              <>
                <h2 className="order-title">Serviços adicionais</h2>

                <div className="x-web-ordering-pedido__lens--services-container d-flex align-items-center">
                  {selectAR}

                  {(this.props.order.codigoRefServicos == "33" || this.props.order.codigoRefServicos == "99") &&
                  this.state.colValue.length !== 0 &&
                  order.colorirNaOtica &&
                  order?.promo_n_par == 2 ? (
                    <div className="col-4 select-chackbox-container">
                      {selectCOL}
                      <div className="col-12">
                        <CheckBox
                          label={"Colorir na Ótica"}
                          id={"p-bold"}
                          onClick={this.handleChange}
                          name="pedidoColorirNaOtica"
                          value={true}
                          checked={this.props.order.pedidoColorirNaOtica}
                        />
                      </div>
                    </div>
                  ) : (
                    selectCOL
                  )}
                  {selectCOR}
                  {growmocao}
                </div>

                {selectCORES}
              </>
            )}
          </div>

          <div className="order-buttons">
            <div className="order-prev-button">
              <CustomButton label="Anterior" width="11.125rem" icon_arrow_left onClick={this.handleClickPrev} />
            </div>

            <div className="order-next-button">
              <CustomButton label="Próximo" width="11.125rem" icon_arrow_right onClick={this.handleClickNext} />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default OrderLens;
