async function getAccountData(parametros) {
  var cnpj = parametros.cnpj
    .replace(/[^0-9]/g, "")
    .replace("/", "")
    .replace("-", "");

  const body = {
    email: parametros.email,
    cnpj: cnpj,
    papel: "Novo Usuário Óptica",
    idUsuario: parametros.user_id,
  };

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ cnpj }),
  };

  return fetch(process.env.REACT_APP_LABS_API + "get_account_data.php", requestOptions)
    .then(response => {
      return response.json();
    })
    .then(json => {
        return json
      
    });
}
export { getAccountData };
