import React, { Component } from 'react'

//Import CSS
import './radio-button.css'

//Import internal components

//Import external components

//Import images

class RadioButton extends Component {


	render() {

      	return (
            <div className="radio-button-container" style={{width:this.props.width}} >
				<input
					type="radio"
					name={this.props.name}
					id={this.props.id}
					value={this.props.value}
					onChange={this.props.onChange}
					checked={this.props.checked}
				/>
                <label className="radio-button-label" htmlFor={this.props.id}>
                    {this.props.label}
                </label>

            </div>
		)
  	}
}

export default RadioButton