import React, { Component } from "react";
import AppContext from "../../../appContext";
import AutoCompleteSelect from "../../../components/autocomplete-select/autocomplete-select";
import CustomButton from "../../../components/buttons/button";
import Input from "../../../components/inputs/input";
import Select from "../../../components/selects/select";
import Swal from "sweetalert2";
import {validateFields} from "../../../components/utils/Validate-Fields"

import "./styles.css";

class ProductForm extends Component {
  constructor(props) {
    super(props);
    this.autoRef = React.createRef();

    this.state = {
      openProduto: true,
      openCadastral: false,
      openLSA: false,
      openBase: false,
      placeholder: "Nenhum resultado encontrado",
      optionsProducao: [
        { label: "Surfaçagem Digital", value: "SURFACAGEM_DIGITAL" },
        { label: "Surfaçagem Tradicional", value: "SURFACAGEM_TRADICIONAL" },
        { label: "Lente Pronta", value: "LENTES_PRONTA" },
      ],
      tipo_producao: "",
      tipo_producao2: "",
      optionsProduto: [
        { label: "Bifocal", value: "BIFOCAL" },
        { label: "Multifocal", value: "MULTIFOCAL" },
        { label: "Visão Simples", value: "VISAO_SIMPLES" },
        { label: "Visão Intermediária", value: "VISAO_INTERMEDIARIA" },
      ],
      tipo_produto: "",
      rpl: "",
      associado: "",
      produtos: [],
      produto: "",
      laboratorio: "",
      laboratorio_cnpj: "",
    };

    ProductForm.contextType = AppContext;
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeAutocomplete = this.handleChangeAutocomplete.bind(this);
    this.getProdutos = this.getProdutos.bind(this);
    this.getService = this.getService.bind(this);
    this.validateFields = this.validateFields.bind(this);

    this.handleEnterKeyPress = (key) => {
      if (key.code === 'Enter') {
        this.getService();
      }
    }
  }

  componentDidMount() {
    if (this.context.userSession.modulos.includes("Consulta Produtos por Lab")) {
    // this.getLabs();
    } else {
      this.setState({ laboratorio_cnpj: this.context.userSession.conta });
    }

    document.addEventListener('keypress', this.handleEnterKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener('keypress', this.handleEnterKeyPress);
  }

  resetarStateForm() {
    this.setState({
      openProduto: true,
      openCadastral: false,
      openLSA: false,
      openBase: false,
      placeholder: "Nenhum resultado encontrado",
      optionsProducao: [
        { label: "Surfaçagem Digital", value: "SURFACAGEM_DIGITAL" },
        { label: "Surfaçagem Tradicional", value: "SURFACAGEM_TRADICIONAL" },
        { label: "Lente Pronta", value: "LENTES_PRONTA" },
      ],
      tipo_producao: "",
      tipo_producao2: "",
      optionsProduto: [
        { label: "Bifocal", value: "BIFOCAL" },
        { label: "Multifocal", value: "MULTIFOCAL" },
        { label: "Visão Simples", value: "VISAO_SIMPLES" },
        { label: "Visão Intermediária", value: "VISAO_INTERMEDIARIA" },
      ],
      tipo_produto: "",
      rpl: "",
      associado: "",
      produtos: [],
      produto: "",
      laboratorio: "",
      laboratorio_cnpj: "",
    });
  }



  getProdutos = async () => {
    if (this.state.tipo_producao !== "" && this.state.tipo_produto !== "") {
      this.setState({ placeholder: "Carregando..." });

      var url = `${process.env.REACT_APP_ESSILOR_API}gestao-produtos-laboratorio/v1/produtos?tipoProducao=${this.state.tipo_producao}&tipoProduto=${this.state.tipo_produto}`;

      var requestOptions = (requestOptions = {
        method: "GET",
        headers: {
          access_token: localStorage.getItem("token"),
          laboratorio: this.state.laboratorio_cnpj,
          callBy: "conecta produtos",
        },
      });

      await fetch(url, requestOptions)
        .then(response => {
          if (response.status === 200) {
            return response.json();
          } else {
            return [];
          }
        })
        .then(json => {
          this.setState({ produtos: json, placeholder: `${json.length} resultados encontrados` });
        });
    } else {
      this.setState({ produtos: [], placeholder: "Nenhum resultado encontrado" });
    }
  };

  handleChange(e) {
    if (e.target.name === "tipo_producao" || e.target.name === "tipo_produto") {
      this.setState({ produto: "", produtos: [], tipo_producao2: "", associado: "", rpl: "" });
      const node = this.autoRef.current;
      node.clearValue();
    }

    if (e.target.name === "tipo_producao2" || e.target.name === "associado" || e.target.name === "rpl") {
      this.setState({ produto: "", produtos: [], tipo_producao: "", tipo_produto: "" });
      const node = this.autoRef.current;
      node.clearValue();
    }

    if (e.target.name === "laboratorio_cnpj") {
      this.resetarStateForm();
      this.setState({ [e.target.name]: e.target.value });
      const node = this.autoRef.current;
      node.clearValue();
    }
    this.setState({ [e.target.name]: e.target.value }, this.getProdutos);
  }

  handleChangeAutocomplete(item) {
    if (item) {
      this.setState({ produto: item.value });
    }
  }

  validateFields() {


    const campos =[
      //primeira sessao
      { id: "laboratorio_cnpj", mensagem: "Laboratório", tipo :""},
      { id: "tipo_producao", mensagem: "Tipo de produção", tipo :"", session :"1" },
      { id: "tipo_produto", mensagem: "Tipo do produto", tipo :"", session :"1" },
      { id: "autocomplete", mensagem: "Escolha o produto", tipo :"autoSelect", session :"1"},
      //segunda sessão
      { id: "tipo_producao2", mensagem: "Tipo de produção", tipo :"", session :"2"},
      { id: "rpl", mensagem: "RPL", tipo :"", session :"2" },
      { id: "associado", mensagem: "Serviço associado", tipo :"", session :"2" },
    ]
    let mensagens = {ChooseMessege: "Você só pode usar uma seção por vez para fazer a consulta.", ErroMessege : "É necessário preencher todos os campos obrigatórios de uma das seções."};
    const camposValidados = validateFields(campos,true,mensagens);
    if (camposValidados) return camposValidados;
    
   /* var retorno = true;
    var mensagem = "";
    if (retorno && !this.state.laboratorio_cnpj) {
      retorno = false;
      mensagem = "É necessário preencher o campo Laboratório.";
    }

    if (
      retorno &&
      (!this.state.tipo_producao || !this.state.tipo_produto || !this.state.produto) &&
      (!this.state.rpl || !this.state.tipo_producao2 || (!this.state.associado && this.state.tipo_producao2 !== "LENTES_PRONTA"))
    ) {
      retorno = false;
      mensagem = "É necessário preencher todos os campos obrigatórios de uma das seções.";
    }

    if (
      retorno &&
      this.state.tipo_producao &&
      this.state.tipo_produto &&
      this.state.produto &&
      (this.state.rpl || this.state.tipo_producao2 || this.state.associado)
    ) {
      retorno = false;
      mensagem = "Você só pode usar uma seção por vez para fazer a consulta.";
    }

    if (
      retorno &&
      this.state.rpl &&
      this.state.tipo_producao2 &&
      this.state.associado &&
      (this.state.tipo_producao || this.state.tipo_produto || this.state.produto)
    ) {
      retorno = false;
      mensagem = "Você só pode usar uma seção por vez para fazer a consulta.";
    }

    if (!retorno) {
      Swal.fire({
        html: mensagem,
        icon: "warning",
        title: "Aviso",
        width: "600px",
        confirmButtonColor: "#00518b",
        customClass: {
          confirmButton: "x-follow-order__filter-btn",
        },
      }).then(result => {});
    }*/

    //return retorno;
  }

  getService = () => {
    if (this.validateFields()) {
      this.props.cleanSeach();
      this.context.showLoading("Buscando produto...");
      var requestOptions = {
        method: "GET",
        headers: {
          access_token: localStorage.getItem("token"),
          laboratorio: this.state.laboratorio_cnpj,
          callBy: "conecta produtos",
        },
      };

      var url = `${process.env.REACT_APP_ESSILOR_API}gestao-produtos-laboratorio/v1/produtos/`;

      if (this.state.rpl) {
        if (this.state.tipo_producao2 === "LENTES_PRONTA") {
          url = url + `${this.state.rpl}/parametros?tipoId=RPL&tipoProducao=${this.state.tipo_producao2}`;
        } else {
          url = url + `${this.state.rpl}/parametros?tipoId=RPL&tipoProducao=${this.state.tipo_producao2}&servicoAssociado=${this.state.associado}`;
        }
      } else {
        url = url + `${this.state.produto}/parametros`;
      }

      fetch(url, requestOptions).then(response => {
        if (response.status === 200) {
          return response.json().then(json => {
            var result = {
              nome: json.descricao,
              tipoProducao: this.deParaProducao(json.tipoProducao),
              tipoProduto: this.deParaProduto(json.tipoProduto),
              codigo: json.codigo,
              rpl: json.rpl,
              servico: json.servicos.filter(ele => {
                if (this.state.associado) {
                  return ele.codigoRef === this.state.associado;
                } else {
                  return ele.tipo === "COLORACAO" || ele.tipo === "ANTIRREFLEXO";
                }
              }),
            };

            this.props.setResult(result);
            this.context.hideLoading();
          });
        } else if (response.status >= 400 && response.status < 499) {
          return response.json().then(json => {
            this.props.setError(json[0].mensagem);
            this.context.hideLoading();
          });
        } else if (response.status === 504) {
          this.context.hideLoading();
          Swal.fire({
            html: "A requisição atingiu o tempo máximo de processamento. Tente novamente mais tarde.",
            icon: "warning",
            title: "Aviso",
            width: "600px",
            confirmButtonColor: "#00518b",
            customClass: {
              confirmButton: "x-follow-order__filter-btn",
            },
          }).then(result => {});
        } else {
          this.context.hideLoading();
          Swal.fire({
            html: "Erro interno. Tente novamente mais tarde.",
            icon: "error",
            width: "600px",
            confirmButtonColor: "#00518b",
            customClass: {
              confirmButton: "x-follow-order__filter-btn",
            },
          }).then(result => {});
        }
      });
    }
  };

  deParaProducao(tipoProducao) {
    switch (tipoProducao) {
      case "SURFACAGEM_DIGITAL":
        return "Surfaçagem Digital";
        break;
      case "SURFACAGEM_TRADICIONAL":
        return "Surfaçagem Tradicional";
        break;
      case "LENTES_PRONTA":
        return "Lente Pronta";
        break;
      default:
        return "";
        break;
    }
  }

  deParaProduto(tipoProduto) {
    switch (tipoProduto) {
      case "BIFOCAL":
        return "Bifocal";
        break;
      case "MULTIFOCAL":
        return "Multifocal";
        break;
      case "VISAO_SIMPLES":
        return "Visão Simples";
        break;
      case "VISAO_INTERMEDIARIA":
        return "Visão Intermediária";
        break;
      default:
        return "";
        break;
    }
  }

  render() {
    return (
      <div className="row">
        <div className="col-12">
          <div className="row">
            {this.context.userSession.modulos.includes("Consulta Produtos por Lab") && (
              <>
                <div className="col-12">
                  <Select
                    label="Laboratório*"
                    width="100%"
                    name="laboratorio_cnpj"
                    aria="Selecione um laboratório"
                    itens={this.props.laboratorios}
                    value={this.state.laboratorio_cnpj}
                    onChange={this.handleChange}
                    placeholder="Selecione um laboratório"
                    placeholder_dont_display={true}
                    required
                  />
                </div>
              </>
            )}
            <div className="col-12 col-md-6">
              <Select
                label="Tipo de produção*"
                width="100%"
                name="tipo_producao"
                aria="Selecione o tipo de produção"
                itens={this.state.optionsProducao}
                value={this.state.tipo_producao}
                onChange={this.handleChange}
                required
              />
            </div>
            <div className="col-12 col-md-6">
              <Select
                label="Tipo do produto*"
                width="100%"
                name="tipo_produto"
                aria="Selecione o tipo de produto"
                itens={this.state.optionsProduto}
                value={this.state.tipo_produto}
                onChange={this.handleChange}
                required
              />
            </div>
            <div className="col-12  mt-4">
              <AutoCompleteSelect
                ref={this.autoRef}
                options={this.state.produtos}
                className=""
                width="100%"
                name="produto"
                id="autocomplete"
                onChange={this.handleChangeAutocomplete}
                defaultValue={this.state.produto}
                placeholder={this.state.placeholder}
                autocomplete="lente_esc_produto"
                tooltip="A consulta pode ser feita pelo código ou pelo nome do produto."
                label="Escolha o produto*"></AutoCompleteSelect>
            </div>
          </div>
        </div>
        <div className="col-12 text-center">
          <span className="crm-ou">OU</span>
        </div>
        <div className="col-12">
          <div className="row">
            <div className="col-12">
              <Select
                label="Tipo de produção*"
                width="100%"
                name="tipo_producao2"
                aria="Selecione o tipo de produção"
                itens={this.state.optionsProducao}
                value={this.state.tipo_producao2}
                onChange={this.handleChange}
                required
              />
            </div>
            <div className="col-12 col-md-6">
              <Input label="RPL*" name="rpl" type="text" value={this.state.rpl} width="100%" onChange={this.handleChange} />
            </div>
            {this.state.tipo_producao2 !== "LENTES_PRONTA" && (
              <div className="col-12 col-md-6">
                <Input label="Serviço associado*" name="associado" type="text" value={this.state.associado} width="100%" onChange={this.handleChange} />
              </div>
            )}
            <div className="col-12 my-4">
              <CustomButton label="Consultar" width="20.1875rem" icon_search={true} icon_arrow_right={true} onClick={this.getService} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductForm;
