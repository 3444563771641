import React, { Component } from "react";

//Import CSS

//IMport internal components
import AppContext from "../appContext";
import Banner from "./index-banner";
import Input from "../components/inputs/input";
import Password from "../components/inputs/password";

//Import external components
import Alert from "sweetalert2";
import Cookies from "universal-cookie";
import Moment from "moment";
import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";

//Import images
import logobanner from "./img/logo-banner-2021.png";

class Top extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      submitted: false,
      loading: false,
      error: "",
      //false para fazer login
      redirect: false,
      redirectC: false,
      opacity: "",
      disabled: false,
    };

    this.emailRef = React.createRef();
    Top.contextType = AppContext;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeEmailRecovery = this.changeEmailRecovery.bind(this);
  }

  // setCookie(token) {
  // 	fetch(process.env.REACT_APP_LABS_ROOT + 'node/cookie?token=' + token, {
  // 		method: 'GET',
  // 		headers: {
  // 			'Content-Type': 'application/json',
  // 		},
  // 	}).then((response) => {
  // 		return response.text()
  // 	}).then((jsonText) => {
  // 		console.log(jsonText);
  // 	})
  // }

  convertJWT(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );

    var jwt = JSON.parse(jsonPayload);

    return jwt;
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  changeEmailRecovery() {
    var input = document.getElementById("email-cpf").value.toLowerCase().trim();
    var email = this.state.email.toLowerCase().trim();
    localStorage.setItem("emailLogin", email ? email : input);
    window.location.href = "/resetpassword";
  }

  handleSubmit(e) {
    e.preventDefault();
    localStorage.removeItem("emailLogin");
    var email = this.state.email.toLowerCase().trim();

    document.getElementById("main-body").style.pointerEvents = "none";
    document.getElementById("overlay_login").style.display = "block";

    this.setState({
      submitted: true,
      disabled: true,
    });
    const { password } = this.state;

    // stop here if form is invalid
    if (!(email && password)) {
      return;
    }

    this.setState({ loading: true });
    this.context.showLoading("Entrando...");

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ usuario: email, senha: password }),
    };

    fetch(process.env.REACT_APP_LABS_API + "user_auth.php", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(jsonText => {
        if (jsonText.status === "erro") {
          if (jsonText.code === 400) {
            Alert.fire({
              text: "Usuário ou senha incorretos. Verifique suas credenciais e tente novamente.",
              icon: "warning",
              title: "Aviso",
              confirmButtonColor: "#00518b",
              customClass: {
                confirmButton: "x-follow-order__filter-btn",
              },
            });
            document.getElementById("overlay_login").style.display = "none";
            document.getElementById("main-body").style.pointerEvents = "";
            this.setState({
              loading: false,
              opacity: "",
              disabled: false,
            });
            this.context.hideLoading();
          } else {
            Alert.fire({
              text: "Erro interno. Favor tentar novamente mais tarde.",
              icon: "warning",
              title: "Aviso",
              confirmButtonColor: "#00518b",
              customClass: {
                confirmButton: "x-follow-order__filter-btn",
              },
            });
            document.getElementById("overlay_login").style.display = "none";
            document.getElementById("main-body").style.pointerEvents = "";
            this.setState({
              loading: false,
              opacity: "",
              disabled: false,
            });
            this.context.hideLoading();
            return;
          }
        } else {
          let json = jsonText;

          if (typeof jsonText === "string" || jsonText instanceof String) {
            json = JSON.parse(jsonText);
          }

          localStorage.setItem("isUsuarioSemCnpj", true);
          localStorage.setItem("token", json.access_token);
          localStorage.setItem("session_token", json.session_token);
          localStorage.setItem("grant_token", json.grant_token);
          const data = this.convertJWT(json.access_token);
          const cnpj = this.convertJWT(json.access_token).conta;

          document.getElementById("main-body").style.pointerEvents = "";
          document.getElementById("overlay_login").style.display = "none";
          this.setState({
            redirect: true,
            loading: false,
            opacity: "",
            disabled: false,
          });
          this.context.hideLoading();

          window.location.href = "/home";
        }
      });
  }

  handleClickPixel() {
    ReactPixel.track("Lead");
  }

  componentDidMount() {
    localStorage.removeItem("token");
    localStorage.removeItem("emailLogin");
    localStorage.removeItem("session_token");
    window.scrollTo(0, 0);
  }

  render() {
    const { email, password, redirect, redirectC } = this.state;
    const EssilorLoader = AppContext;

    if (redirect) {
      if (!JSON.parse(window.localStorage.getItem("@redirect"))) {
        window.location.href = "/home";
      } else {
        window.location.href = JSON.parse(window.localStorage.getItem("@redirect"));
      }
    }

    if (redirectC) {
      window.location.href = "/change";
    }

    function closeLogin() {
      var element = document.getElementById("loginMobile");
      element.classList.remove("is--active");
      var element1 = document.getElementById("loginGeral");
      element1.classList.remove("has--no-scroll");
      document.querySelector(".login-banner__main").style.display = "";
    }

    return (
      <section>
        <div className={`login-top`} id="loginGeral">
          <Banner></Banner>

          <div className="login-signin__wrapper">
            <div className={`login-signin js--login-mobile`} id="loginMobile">
              <div className="login-signin__container">
                <div className="login-signin__logo">
                  <a href="/" className="login-signin__logo-link">
                    <img alt="" src={logobanner} className="login-signin__logo-image" />
                  </a>
                </div>

                <h3 className="login-signin__title">Conecte-se</h3>

                <div className="login-signin__back-button js--close-login" onClick={closeLogin}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="21.31" height="14.994" viewBox="0 0 21.31 14.994">
                    <path
                      id="np_arrow_2410576_000000"
                      className="cls-1"
                      d="M39,44.133l6.709-5.525a.807.807,0,0,0,.1-1.116.824.824,0,0,0-1.116-.1L39.287,41.84V23.786a.789.789,0,0,0-1.579,0V41.84l-5.414-4.452a.827.827,0,0,0-1.116.1.8.8,0,0,0,.1,1.116l6.709,5.525a.825.825,0,0,0,1.011,0Z"
                      transform="translate(44.307 -31.001) rotate(90)"
                      fill="#0066b3"
                    />
                  </svg>
                </div>

                <form className="login-signin__form js--login-form" onSubmit={this.handleSubmit}>
                  <div className="login-signin__form-row" ref={this.emailRef}>
                    <Input
                      autoFocus={true}
                      label="E-mail*"
                      type="email"
                      width="100%"
                      title="email@email.com.br"
                      name="email"
                      pattern="([a-zA-Z0-9._%+-]+@[A-Za-z0-9.-]+\.[a-zA-Z]{2,3}$)"
                      id="email-cpf"
                      placeholder="email@email.com.br"
                      required={true}
                      value={email}
                      onChange={this.handleChange}
                    />
                  </div>

                  <div className="login-signin__form-row">
                    <Password
                      label="Senha"
                      width="100%"
                      name="password"
                      id="password"
                      placeholder="Senha"
                      required={true}
                      type="password"
                      value={password}
                      onChange={this.handleChange}
                    />

                    <br />
                  </div>
                  <div className="login-signin__form-div-button">
                    <button className="login-signin__form-button is--blue" type="submit" title="Entrar" disabled={this.state.disabled}>
                      Entrar
                    </button>
                  </div>
                  <div className="mb-3 text-center">
                    <a onClick={this.changeEmailRecovery} className="login-signin__form-link">
                      Esqueci a senha
                    </a>
                  </div>
                </form>

                {/* <div className='login-signin__social-network'>
										<p className='login-signin__social-network--title'>Ou faça login usando</p>
										<div className='login-signin__social-network--buttons'>
											<div className='login-signin__social-network--row'>
												<button className="login-signin__social-network--button" type="submit" title="Entrar">
													<svg xmlns="http://www.w3.org/2000/svg" width="20.313" height="20.313"
														viewBox="0 0 20.313 20.313">
														<path id="facebook" className="cls-1"
															d="M17.578,0H2.739A2.737,2.737,0,0,0,0,2.737V17.576a2.737,2.737,0,0,0,2.737,2.737h7.318l.012-7.259H8.184a.445.445,0,0,1-.445-.443l-.009-2.34a.445.445,0,0,1,.445-.447h1.882V7.564A3.694,3.694,0,0,1,14,3.511h1.921a.445.445,0,0,1,.445.445V5.929a.445.445,0,0,1-.445.445H14.743c-1.273,0-1.519.6-1.519,1.492V9.825h2.8a.445.445,0,0,1,.442.5l-.277,2.34a.445.445,0,0,1-.442.393H13.236l-.012,7.259h4.354a2.737,2.737,0,0,0,2.737-2.737V2.737A2.737,2.737,0,0,0,17.578,0Z"
															transform="translate(-0.002)"
															fill="#475993"
															/>
													</svg>
													Facebook</button>
												<button className="login-signin__social-network--button" type="submit" title="Entrar">
													<svg xmlns="http://www.w3.org/2000/svg" id="search" width="20.313"
														height="20.313" viewBox="0 0 20.313 20.313">
														<path id="Caminho_2" data-name="Caminho 2" className="cls-1"
															d="M4.5,145.058l-.707,2.64-2.584.055a10.174,10.174,0,0,1-.075-9.484h0l2.3.422,1.008,2.287a6.061,6.061,0,0,0,.057,4.081Z"
															transform="translate(0 -132.782)"
															fill="#fbbb00"
															></path>
														<path id="Caminho_3" data-name="Caminho 3" className="cls-2"
															d="M271.384,208.176a10.153,10.153,0,0,1-3.621,9.818h0l-2.9-.148-.41-2.56a6.053,6.053,0,0,0,2.6-3.091h-5.431v-4.018h9.756Z"
															transform="translate(-251.248 -199.917)"
															fill="#518ef8"
															></path>
														<path id="Caminho_4" data-name="Caminho 4" className="cls-3"
															d="M45.813,315.21h0a10.16,10.16,0,0,1-15.3-3.107l3.291-2.694a6.04,6.04,0,0,0,8.7,3.093Z"
															transform="translate(-29.299 -297.134)"
															fill="#28b446"
															></path>
														<path id="Caminho_5" data-name="Caminho 5" className="cls-4"
															d="M44.128,2.338l-3.29,2.694a6.04,6.04,0,0,0-8.9,3.162L28.625,5.486h0a10.159,10.159,0,0,1,15.5-3.147Z"
															transform="translate(-27.488)"
															fill="#f14336"
															></path>
													</svg>
													Google
												</button>
											</div>
											<div className='login-signin__social-network--row'>
												<button className="login-signin__social-network--button" type="submit" title="Entrar">
													<svg xmlns="http://www.w3.org/2000/svg" width="20.943" height="17.421"
														viewBox="0 0 20.943 17.421">
														<path id="twitter" className="cls-1"
															d="M20.611,36.518A8.293,8.293,0,0,1,19.167,37a4.5,4.5,0,0,0,1.188-1.893h0a.236.236,0,0,0-.345-.275h0a8.333,8.333,0,0,1-2.208.912.563.563,0,0,1-.138.017.585.585,0,0,1-.387-.148,4.515,4.515,0,0,0-2.988-1.128,4.828,4.828,0,0,0-1.43.221,4.374,4.374,0,0,0-2.948,3.152A4.817,4.817,0,0,0,9.8,39.526a.162.162,0,0,1-.041.127.167.167,0,0,1-.125.056H9.623a11.839,11.839,0,0,1-8.137-4.347h0a.236.236,0,0,0-.387.03h0a4.52,4.52,0,0,0,.735,5.488,4.046,4.046,0,0,1-1.03-.4h0a.236.236,0,0,0-.351.2h0A4.521,4.521,0,0,0,3.09,44.85h-.1a4.063,4.063,0,0,1-.761-.073h0a.236.236,0,0,0-.269.3h0A4.525,4.525,0,0,0,5.538,48.16,8.332,8.332,0,0,1,.872,49.577H.349a.346.346,0,0,0-.338.261.358.358,0,0,0,.171.4A12.393,12.393,0,0,0,6.41,51.912a12.563,12.563,0,0,0,5.322-1.129,11.752,11.752,0,0,0,3.894-2.918,12.792,12.792,0,0,0,2.384-3.993,12.614,12.614,0,0,0,.81-4.37v-.069a.768.768,0,0,1,.288-.6,8.924,8.924,0,0,0,1.8-1.971h0a.236.236,0,0,0-.292-.347Z"
															transform="translate(0 -34.491)"
															fill="#55abee"
															/>
													</svg>Twitter</button>
												<button className="login-signin__social-network--button" type="submit" title="Entrar">
													<svg xmlns="http://www.w3.org/2000/svg" width="18.071" height="18.071"
														viewBox="0 0 18.071 18.071">
														<path id="linkedin" className="cls-1"
															d="M16.437,0H1.635A1.635,1.635,0,0,0,0,1.635v14.8a1.635,1.635,0,0,0,1.635,1.635h14.8a1.635,1.635,0,0,0,1.635-1.635V1.635A1.635,1.635,0,0,0,16.437,0ZM5.592,15.6a.476.476,0,0,1-.476.476H3.091a.476.476,0,0,1-.476-.476V7.115a.476.476,0,0,1,.476-.476H5.116a.476.476,0,0,1,.476.476ZM4.1,5.839A1.924,1.924,0,1,1,6.028,3.915,1.924,1.924,0,0,1,4.1,5.839Zm12.071,9.8a.437.437,0,0,1-.437.437H13.564a.437.437,0,0,1-.437-.437V11.661c0-.594.174-2.6-1.552-2.6-1.339,0-1.611,1.375-1.665,1.992v4.593a.437.437,0,0,1-.437.437H7.37a.437.437,0,0,1-.437-.437V7.077a.437.437,0,0,1,.437-.437h2.1a.437.437,0,0,1,.437.437v.741A2.983,2.983,0,0,1,12.715,6.5c3.48,0,3.46,3.251,3.46,5.037v4.108Z"
															fill="#0077b7"
															/>
													</svg>
													LinkedIn</button>
											</div>
										</div>
									</div> */}
                <div className="login-signin__sign-up">
                  <p className="login-signin__sign-up--text">Ainda não tem uma conta?</p>

                  <ReactGA.OutboundLink
                    eventLabel="cadastre-se"
                    to="/signup"
                    target="_self"
                    className="login-signin__sign-up--link"
                    onClick={this.handleClickPixel}>
                    Cadastre-se aqui
                  </ReactGA.OutboundLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="overlay_login" className="overlay_login"></div>
      </section>
    );
  }
}

export default Top;
