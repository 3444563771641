import React, { Component } from 'react'

class Terms extends Component {

  	render(){
    	return (
            <section className='signup-popup' >
                <div className='signup-popup__container js--popup' id='popup'>
                    <div className='signup-popup__close js--popup-close' onClick={this.props.onClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" className='close-mobile js--popup-close-mobile' width="21.31" height="14.994" viewBox="0 0 21.31 14.994">
                            <path className="a"
                            d="M39,44.133l6.709-5.525a.807.807,0,0,0,.1-1.116.824.824,0,0,0-1.116-.1L39.287,41.84V23.786a.789.789,0,0,0-1.579,0V41.84l-5.414-4.452a.827.827,0,0,0-1.116.1.8.8,0,0,0,.1,1.116l6.709,5.525a.825.825,0,0,0,1.011,0Z"
                            transform="translate(44.307 -31.001) rotate(90)"
                            fill="#0066b3"
                            />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" className='close-desk' width="17.089" height="17.119" viewBox="0 0 17.089 17.119">

                            <path className="a" d="M20.862,36.982a1.453,1.453,0,0,0,1.012.434,1.4,1.4,0,0,0,1.012-.434l6.072-6.072,6.072,6.072a1.453,1.453,0,0,0,1.012.434,1.4,1.4,0,0,0,1.012-.434,1.432,1.432,0,0,0,0-2.053l-6.072-6.072,6.072-6.072A1.452,1.452,0,0,0,35,20.731L28.929,26.8l-6.043-6.014a1.452,1.452,0,1,0-2.053,2.053L26.9,28.915l-6.043,6.043a1.4,1.4,0,0,0,0,2.024Z"
                            transform="translate(-20.399 -20.297)"
                            fill="#b5b5b5"
                            />
                        </svg>
                    </div>
                    <h3 className='signup-popup__title'>TERMOS E CONDIÇÕES DE USO</h3>
                    <div className='signup-popup__text'>
                        <div className='signup-popup__paragraph text-left'>
                        <p>A seguir apresentamos a você (Usuário) os Termos de Uso e Condições de Navegação, documento que relaciona as principais regras que devem ser observadas por todos que acessam este site ou utilizam suas ferramentas. Ao visitar ou utilizar este site pressupõe-se a leitura e concordância com todos os termos abaixo. Para os fins deste documento, devem se considerar as seguintes definições e descrições para seu melhor entendimento:</p>
                        <p>Essilor: Sociedades direta ou indiretamente controladas pela Essilor International (Compagnie Générale D'Optique S.A)</p>
                        <p>Portal: Designa o endereço eletrônico <a href="www.essilorconecta.com.br">www.essilorconecta.com.br</a> e seus subdomínios;</p>

                        <p>Anti-Spam: Sistema que evita correspondências não desejadas, como publicidade em massa, pelo bloqueio de mensagens ou as movendo para pasta específica.</p>

                        <p>Aplicativo Spider: Programa desenvolvido para obter informações de modo automatizado na Internet, navegando pela web (teia) como se fosse uma spider (aranha).</p>

                        <p>Conta de acesso: Credencial de um visitante cadastrado que permite o acesso à área restrita e às funcionalidades exclusivas, que é definida pelo nome de usuário e senha.</p>

                        <p>Layout: Conjunto compreendido entre aparência, design e fluxos do Portal.</p>

                        <p>Link: Terminologia para endereço de Internet.</p>

                        <p>Logs: Registros de atividades do usuário efetuadas no Portal.</p>

                        <p>Usuário: Pessoas que acessam ou interagem com as atividades oferecidas pelo Portal.</p>

                        <p>Webmastering: Compreende a criação, programação, desenvolvimento, controle e disponibilidade de páginas de internet.</p>

                        <h2>1 – Acesso e Restrições de funcionalidade</h2>

                        <p>
                        1.1. Este Portal é dedicado ao atendimento de seus Usuários para proporcionar uma experiência completa para aquisição de produtos da Essilor e saber mais sobre cada um deles de forma interativa e dinâmica.<br />
                        1.2. Não é permitido aos Usuários acessarem as áreas de programação de Portal, seu banco de dados ou qualquer outro conjunto de informações que faça parte da atividade de Webmastering, ficando sujeito quem o fizer à legislação penal brasileira e obrigado a reparar os danos que causar.<br />
                        1.3. Também não é autorizado realizar ou permitir engenharia reversa, nem traduzir, decompilar, copiar, modificar, reproduzir, alugar, sublicenciar, publicar, divulgar, transmitir, emprestar, distribuir ou, de outra maneira, dispor das ferramentas de consulta deste Portal e de suas funcionalidades.<br />
                        1.4. Neste Portal é proibida a utilização, de Aplicativos Spider, ou de mineração de dados, de qualquer tipo ou espécie, além de outro aqui não tipificado, mas que atue de modo automatizado, tanto para realizar operações massificadas ou para quaisquer outras finalidades, sob aplicação da legislação penal brasileira e de reparar os danos que decorrerem desta utilização.<br />
                        1.4.1. Este Portal foi desenvolvido para a utilização de seres humanos a fim de possibilitar a aquisição de produtos oferecidos pela Essilor e o conhecimento mais aprofundado sobre cada um deles, sendo escuso qualquer outro uso deste ambiente que não esses.<br />
                        1.5. É ônus do Usuário arcar com os meios necessários para navegar neste Portal, incluindo os requisitos para o acesso à Internet.<br />
                        1.6.O acesso a recursos e funcionalidades do site podem exigir determinados requisitos, como o fornecimento de dados pessoais, idade mínima e outros indicados conforme a usuários não estejam de acordo com o exigido, deverão suspender sua navegação, sob pena de ser caracterizada má-fé.
                        </p>

                        <h2>2 – Informações gerais sobre o site e seu funcionamento</h2>

                        <p>
                        2.1.Este Portal é apresentado ao público da web na maneira como está disponível, podendo passar por constantes aprimoramentos e atualizações, não ficando obrigada a Essilor a manter uma estrutura ou layout, se não por sua própria conveniência.<br />
                        2.2.A Essilor envida seus esforços para a disponibilidade contínua e permanente do Portal, ficando sujeito, todavia, a eventos extraordinários, como desastres naturais, falhas ou colapsos nos sistemas centrais de comunicação e acesso à internet ou fatos de terceiro, que fogem de sua esfera de vigilância e responsabilidade.<br />
                        2.3.A Essilor não se responsabiliza por qualquer dano, prejuízo ou perda no equipamento dos Usuários causados por falhas no sistema, no servidor ou na conexão à Internet, ainda que decorrentes de condutas de terceiros, inclusive por ações de softwares maliciosos como vírus, cavalos de tróia, e outros que possam, de algum modo, danificar o equipamento ou a conexão dos e Usuários em decorrência do acesso, utilização ou navegação neste Portal, bem como a transferência de dados, arquivos, imagens, textos, áudios ou vídeos contidos no mesmo.<br />
                        2.4.Os usuários não possuem qualquer direito para exigir a disponibilidade do Portal conforme melhor lhes convêm, tampouco poderão pleitear indenização ou reparação de danos em caso deste Portal permanecer fora do ar, independente da motivação.<br />
                        2.5.A Essilor não se responsabiliza pelos atos praticados por Usuários em seu Portal, devendo cada um ser imputado de acordo com a qualidade do uso da plataforma.
                        </p>

                        <h2>3 – Comunicação da Essilor com Usuários</h2>

                        <p>
                        3.1.A Essilor utilizará como principal canal de comunicação com seus Usuários o link “Fale Conosco” e o envio de comunicados via e-mail, mas poderá utilizar outros meios para comunicação desde que os dados do Usuário informados corretamente.<br />
                        3.1.1. A responsabilidade pelo recebimento dos comunicados é exclusiva dos Usuários, por isso é indispensável que sempre forneçam os dados corretos e precisos à Essilor, além de os manterem atualizados.<br />
                        3.1.2. É igualmente de suas alçadas deixarem seus sistemas de Anti-Spam configurados de modo que não interfiram no recebimento dos comunicados e materiais da Essilor, não sendo aceitável nenhuma escusa caso não tenha tido acesso a algum e-mail em virtude desse bloqueio ou filtro similar.
                        </p>

                        <h2>4 – Privacidade dos Usuários no Portal</h2>

                        <p>
                        4.1.A Essilor possui documento próprio, denominado Política de Privacidade, que regula o tratamento dado às informações de cadastro e demais informações e dados coletados neste Portal.<br />
                        4.2. A Política de Privacidade é parte integrante e inseparável dos Termos de Uso e Condições de Navegação deste Portal, e pode ser acessada no link encontrado em seu rodapé.<br />
                        4.3. Caso alguma disposição da Política de Privacidade conflite com qualquer outra do presente documento, deverá prevalecer o descrito na norma mais específica.
                        </p>

                        <h2>5 – Obrigações do Portal</h2>

                        <p>
                        5.1. A Essilor se obriga com seus Usuários a:<br />
                        5.1.1. Manter o ambiente digital seguro, salvo por ato destrutivo de terceiro que vá além dos esforços empenhados, hipótese que não se responsabilizará por danos oriundos dessa prática danosa.<br />
                        5.1.2. Preservar a funcionalidade do site, com links não quebrados, utilizando layout que respeita a usabilidade e navegabilidade, facilitando a navegação sempre que possível.<br />
                        5.1.3. Exibir as funcionalidades de maneira clara, completa, precisa e suficiente de modo que exista a exata percepção das operações realizadas.<br />
                        5.1.4. Garantir, por meio do estado da técnica disponível, o sigilo e confidencialidade dos dados inseridos nas funcionalidades oferecidas em seu Portal, somente sendo acessíveis a quem o Usuário declinar, além de si próprio.
                        </p>

                        <h2>6 – Obrigações dos Usuários</h2>

                        <p>
                        6.1. Os Usuários se obrigam a realizar uma navegação com retidão ética, sempre respeitando as condições que regem a utilização do Portal.<br />
                        6.2. Ao fornecer dados e informações à Essilor, os Usuários se obrigam a fazê-lo sempre com compromisso de veracidade e autenticidade, sob pena da aplicação das penas da lei, de indenizar a quem causar dano e de ter a conta de acesso do presente Portal excluída.<br />
                        6.3. Na incidência de danos ao Portal ou a terceiros, o responsável se compromete a arcar com todas as obrigações de indenizar o sujeito lesado, não devendo a Essilor responder por tais prejuízos.<br />
                        6.4. Os Usuários devem utilizar os recursos do presente Portal para as finalidades que foram constituídas, sob pena da aplicação das penas da lei, de indenizarem a quem causarem dano e de ter a conta de acesso do presente Portal excluída.<br />
                        6.5. Manter o sigilo dos dados de sua conta de acesso em relação a terceiros, não divulgando ou compartilhando sua senha a quem quer que seja, sob pena da exclusão da conta e ter de ressarcir os danos que sobrevierem dessa divulgação indevida.<br />
                        6.5.1. Caso o Usuário suspeite que a confidencialidade de sua senha foi quebrada, este deverá proceder sua troca o mais rápido possível. Se não conseguir acessar a Internet, deverá buscar atendimento dentre os canais disponíveis.<br />
                        6.5.2. Em caso de suspeita de comprometimento de segurança do Portal, existência de inconsistência ou problema de fluxos, o usuário poderá reportar o ocorrido através do link “Fale Conosco”.
                        </p>

                        <h2>7 - Direitos Autorais e de Propriedade Intelectual do Portal</h2>

                        <p>
                        7.1. O uso comercial da expressão Essilor, como marca, nome empresarial ou nome de domínio, além dos conteúdos das telas de Portal, assim como os programas, bancos de dados, redes e seus arquivos são de propriedade da Essilor e estão protegidos pelas leis e tratados internacionais de direito autoral, marcas, patentes, modelos e desenhos industriais.<br />
                        7.2. Ao acessar este Portal, os Usuários declaram que irão respeitar todos os direitos de propriedade intelectual e os decorrentes da proteção de marcas, patentes e/ou desenhos industriais, depositados ou registrados em nome da Essilor e todos os direitos referentes a terceiros que porventura estejam, ou estiveram, de alguma forma, disponíveis no Portal. O simples acesso ao Portal não confere a estes qualquer direito ao uso dos nomes, títulos, palavras, frases, marcas, patentes, desenhos industriais, obras literárias, artísticas, lítero-musicais, imagens, dados e informações, dentre outras, que nele estejam ou estiveram disponíveis.<br />
                        7.3. A reprodução dos conteúdos descritos anteriormente está proibida, salvo com prévia autorização por escrito da Essilor ou caso se destinem ao uso exclusivamente pessoal e sem que em nenhuma circunstância os Usuários adquiram qualquer direito sobre os mesmos.<br />
                        7.4. É permitido fazer somente o arquivo temporário deste Portal e de seu conteúdo, sendo vedada sua utilização para finalidades comerciais, publicitárias ou qualquer outra que contrarie a realidade para o qual foi concebido, conforme definido neste documento. Restam igualmente proibidas a reprodução, distribuição e divulgação, total ou parcial, dos textos, figuras, gráficos que compõem o presente Portal, sem prévia e expressa autorização da Essilor, sendo permitida somente a impressão de cópias para uso e arquivo pessoal, sem que sejam separadas as partes que permitam dar o fiel e real entendimento de seu conteúdo e objetivo.<br />
                        7.5. Os Usuários assumem toda e qualquer responsabilidade, de caráter civil e/ou criminal, pela utilização indevida das informações, textos, gráficos, marcas, obras, imagens, enfim, de todo e qualquer direito de propriedade intelectual ou industrial deste Portal.<br />
                        7.6. Qualquer outro tipo de utilização de material autorizado, inclusive para fins editoriais, comerciais ou publicitários, só poderá ser feito mediante prévio e expresso consentimento da Essilor. Os Usuários estão cientes, através deste item, que o uso comercial não autorizado poderá incorrer em infrações cíveis e criminais, por estar infringindo a lei dos direitos autorais.<br />
                        7.7. Qualquer reutilização do material autorizado deverá ser objeto de uma nova autorização da Essilor.<br />
                        7.8. A autorização para utilização do material solicitado não poderá ser transferida a terceiros, mesmo que vinculados ao sujeito autorizado por alguma razão.<br />
                        7.9. Qualquer utilização não contemplada na mencionada autorização será considerada como uma violação dos direitos de autor e sujeita às sanções cabíveis na Lei nº 9.610, de 19 de fevereiro de 1998, que protege os direitos autorais no Brasil.<br />
                        7.10. A utilização das funcionalidades oferecidas se dará na forma de prestação de serviço.
                        </p>

                        <p>Atualização: 27/11/2019.</p>
                        </div>
                    </div>
                </div>
            </section>
    	);
  	}
}

export default Terms;