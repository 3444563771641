import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import {  OverlayTrigger, Tooltip } from 'react-bootstrap'

class Autocomplete extends Component {
  static propTypes = {
    suggestions: PropTypes.instanceOf(Array)
  };

  static defaultProps = {
    suggestions: []
  };

  constructor(props) {
    super(props);


    this.state = {
      // The active selection's index
      activeSuggestion: 0,
      // The suggestions that match the user's input
      filteredSuggestions: [],
      // Whether or not the suggestion list is shown
      showSuggestions: false,
      // What the user has entered
      userInput: this.props.defaultValue
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ userInput: "" });
    }
  }

  // Event fired when the input value is changed
  onChange = e => {
    const { suggestions } = this.props;
    const userInput = e.currentTarget.value;

    // Filter our suggestions that don't contain the user's input
    var filteredSuggestions = [];
    suggestions.map((suggestion) => {
      if (
        suggestion.descricao.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        || suggestion.codigo.toLowerCase() == userInput.toLowerCase()
      ) {
        filteredSuggestions.push(suggestion.descricao);
      }
    });

    //    =>
    //   // 
    // );

    filteredSuggestions.sort();

    // Update the user input and filtered suggestions, reset the active
    // suggestion and make sure the suggestions are shown
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value,
      nameInput: e.currentTarget.name
    });
  };

  // Event fired when the user clicks on a suggestion
  onClick = e => {
    // Update the user input and reset the rest of the state
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText
    });
    this.props.onChange(this.state.nameInput, e.currentTarget.innerText);
  };

  onBlur = e => {
    if (e.currentTarget.value === '') {
      this.props.onBlur({ [e.currentTarget.name]: '' })
    } else {

    }
  }

  // Event fired when the user presses a key down
  onKeyDown = e => {
    const { activeSuggestion, filteredSuggestions } = this.state;

    // User pressed the enter key, update the input and close the
    // suggestions
    if (e.keyCode === 13) {
      e.preventDefault();
      if (filteredSuggestions.length === 0) {
        return;
      } else {
        this.setState({
          activeSuggestion: 0,
          showSuggestions: false,
          userInput: filteredSuggestions[activeSuggestion]
        });
        this.props.onChange(this.state.nameInput, filteredSuggestions[activeSuggestion]);
      }
    }
    // User pressed the up arrow, decrement the index
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      onBlur,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput
      }
    } = this;

    // const { order } = this.props;

    // let styleArrow;

    let suggestionsListComponent;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        suggestionsListComponent = (
          <ul className="suggestions" ref={this.setWrapperRef}>
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }

              return (
                <li
                  className={className}
                  key={suggestion}
                  onClick={onClick}
                >
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      } else {
        suggestionsListComponent = (
          <div className="no-suggestions" ref={this.setWrapperRef}>
            <em>Valor não encontrado!</em>
          </div>
        );
      }
    }

    // if(this.props.stylearrow){
    //   styleArrow = <span className={this.props.stylearrow}></span>
    // }
    // else{
    //     styleArrow = <span className="is--arrow-2"></span>
    // }

    return (
      <Fragment>
        {this.props.label &&
          <label className="x-web-ordering-pedido__dados--item is--label" htmlFor={this.props.name}>
            {this.props.label}
          </label>
        }
        <input className={this.props.className}
          type="text"
          id={this.props.autocomplete}
          name={this.props.name}
          aria-label={this.props.aria}
          onChange={onChange}
          onKeyDown={onKeyDown}
          value={userInput}
          onBlur={onBlur}
          autoComplete="off"
          required={this.props.required}
          disabled={this.props.suggestions.length === 0 ? true : false}
          placeholder={this.props.suggestions.length === 0 || this.props.placeholder === "" ? this.props.placeholder : this.props.suggestions.length + ' resultados encontrados'}
        />
        {this.props.tooltip && <OverlayTrigger
          placement="bottom"
          delay={{ show: 250, hide: 400 }}
          overlay={<Tooltip id="button-tooltip">{this.props.tooltip}</Tooltip>}
        >
          <span className="tooltip-shape">
          </span>
        </OverlayTrigger>}
        {/* {styleArrow} */}
        {suggestionsListComponent}
      </Fragment>
    );
  }
}

export default Autocomplete;
