import React, { Component } from 'react'
import { Modal } from 'react-bootstrap'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { QuestionCircleFill } from 'react-bootstrap-icons';
import AppContext from '../../appContext'

class RolesManagementTableHeader extends Component {

  constructor(props) {
    super(props)

    this.state = {
      showModal: false
    }

    RolesManagementTableHeader.contextType = AppContext
  }

  setShow = (show, showButton) => {
    this.setState({ showModal: show, showButton: showButton })
  }

  render() {
    const handleClose = () => this.setShow(false, false)
    const handleShow = () => this.setShow(true, true)
    const { isInativar } = this.props;

    return (
      <thead className="thead-dark">
        <tr>
          <th class="align-middle roles-management-table-header-th">Usuário</th>
          <th class="align-middle roles-management-table-header-th" >E-mail</th>
          <th class="align-middle roles-management-table-header-th">CPF</th>
          <th class="align-middle roles-management-table-header-th">CNPJ</th>
          <th class="align-middle roles-management-table-header-th">
            Perfil de acesso
            {
              (
                this.context.userSession.papel === "Proprietário de Óptica" ||
                this.context.userSession.papel === "Gerente de Óptica"
              ) && (

                <span variant="primary" onClick={handleShow} className="ms-2" style={{ cursor: "pointer", color: "#0394db" }}>
                  <QuestionCircleFill />
                </span>
              )}
          </th>
          <th class="align-middle">
            Desvincular da Empresa
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={<Tooltip id="button-tooltip">Remove o usuário da empresa. O usuário fica sem CNPJ até informar uma nova empresa.</Tooltip>}
            >
              <span variant="primary" className="ms-2" style={{ cursor: "pointer", color: "#0394db" }}>
                <QuestionCircleFill />
              </span>
            </OverlayTrigger>

          </th>
          {isInativar && (
            <th class="align-middle">Inativar Usuário

              <OverlayTrigger
                placement="bottom"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip id="button-tooltip">Inativa o usuário da base do Conecta.</Tooltip>}
              >
                <span variant="primary" className="ms-2" style={{ cursor: "pointer", color: "#0394db" }}>
                  <QuestionCircleFill />
                </span>
              </OverlayTrigger>
            </th>
          )}
        </tr>

        <Modal centered show={this.state.showModal} onHide={handleClose} animation={false} >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              Informações
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ul class="list-group list-group-flush">
              <li class="list-group-item"><strong>Novo Usuário Óptica</strong> - Usuário sem módulos disponíveis. Gerente deve promovê-lo a Vendedor ou Balconista.</li>
              <li class="list-group-item"><strong>Marketing </strong> - Usuário responsável pelo Marketing da empresa.</li>
              <li class="list-group-item"><strong>Balconista</strong> - Vendedor sem visibilidade de orçamento ao final do pedido realizado.</li>
              <li class="list-group-item"><strong>Vendedor de Óptica</strong> - Vendedor com visibilidade de orçamento ao final do pedido realizado.</li>
              <li class="list-group-item"><strong>Gerente de Óptica</strong> - Gerente da empresa. Tem permissão para mudar os papéis dos funcionários.</li>
              <li class="list-group-item"><strong>Proprietário de Óptica</strong> - Proprietário da empresa. Tem permissão para mudar os papéis dos funcionários.</li>
            </ul>
          </Modal.Body>
        </Modal>
      </thead>
    )
  }
}

export default RolesManagementTableHeader
