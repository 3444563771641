import React, { Component } from 'react'
import PDFOrderP from './pdf-order-p';

class PDFOrderPrisma extends Component {

    render() {
        const { prisma, hasOlhoDireito, hasOlhoEsquerdo } = this.props;

        return (
            <div className="row">
                <div className="col-12">
                    <h3 className="pdf-subtitle-bold">Prisma</h3>
                </div>

                {hasOlhoDireito &&
                    (
                        <div className="col">
                            <h3 className="pdf-subtitle-bold">Direito</h3>
                            <PDFOrderP label='Horizontal' value={prisma.direito.horizontal} />
                            <PDFOrderP label='Base' value={prisma.direito.base_horizontal} />
                            <PDFOrderP label='Vertical' value={prisma.direito.vertical} />
                            <PDFOrderP label='Base' value={prisma.direito.base_vertical} />
                        </div>
                    )
                }

                {
                    hasOlhoEsquerdo &&
                    (
                        <div className='col'>
                            <h3 className="pdf-subtitle-bold">Esquerdo</h3>
                            <PDFOrderP label='Horizontal' value={prisma.esquerdo.horizontal} />
                            <PDFOrderP label='Base' value={prisma.esquerdo.base_horizontal} />
                            <PDFOrderP label='Vertical' value={prisma.esquerdo.vertical} />
                            <PDFOrderP label='Base' value={prisma.esquerdo.base_vertical} />
                        </div>
                    )
                }

            </div >
        )
    }
}

export default PDFOrderPrisma