import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class OrderStage extends Component {
	

    constructor(props){
        super(props)
        this.state = {...props}
    }

    selectItem(stage){
        if (stage > this.props.stage){
            this.props.onChange(stage, 'right')
        }
        if (stage < this.props.stage){
            this.props.onChange(stage, 'left')
        }
    }

	render() {
		return (
        <div>
            <ul className="x-web-ordering-pedido-menu slider-nav">
                <li className={`x-web-ordering-pedido-menu__item ${this.props.stage == 1 ? 'slick-current slick-active' : ''}`} onClick={this.selectItem.bind(this, 1)}>
                    <Link to="/order-detail" className="x-web-ordering-pedido-menu__link is--l1" href="#">
                        <p className="link-text"> Dados do pedido </p>
                    </Link>
                </li>
                <li className={`x-web-ordering-pedido-menu__item ${this.props.stage == 2 ? 'slick-current slick-active' : ''}`} onClick={this.selectItem.bind(this, 2)}>
                    <a className="x-web-ordering-pedido-menu__link is--l2 js--link-nav" href="#">
                        <p className="link-text">Especificações da lente</p>
                    </a>
                </li>
                <li className={`x-web-ordering-pedido-menu__item ${this.props.stage == 3 ? 'slick-current slick-active' : ''}`} onClick={this.selectItem.bind(this, 3)}>
                    <a className="x-web-ordering-pedido-menu__link is--l3 js--link-nav" href="#">
                        <p className="link-text">Prescrição</p>
                    </a>
                </li>
                <li className={`x-web-ordering-pedido-menu__item ${this.props.stage == 4 ? 'slick-current slick-active' : ''}`} onClick={this.selectItem.bind(this, 4)}>
                    <a className="x-web-ordering-pedido-menu__link is--l4 js--link-nav" href="#">
                        <p className="link-text">Armação e montagem</p>
                    </a>
                </li>
            </ul>
            
        </div>
		);
  	}
}

export default OrderStage;