import React, { Component } from "react";

//Import CSS
import "./select-multi-options.css";

import Select from "react-select";

class SelectAuto extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    selectedOption: null,
  };

  handleChange = (selectedOption) => {
    this.props.filter(selectedOption);
  };

  render() {
 
 
    return (
      <>
        <label className="filtroInputLabel" >
          {this.props.label}
        </label>
        <div className="w-100">
          <Select className="" isMulti  options={this.props.options} onChange={this.handleChange} />
        </div>
      </>
    );
  }
}

export default SelectAuto;
