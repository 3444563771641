import React from 'react'
import loadgif from './img/icon-load.gif'

const EssilorLoading = ({ loading, message, load_img }) => {
  return loading ? (
    <div className="overlay-content">
      <div className="wrapper">
        <div className="content-loading">
          <div>
            {load_img ? load_img : <img src={loadgif} className="image" alt="loading..." />}
          </div>
          {message && (
            <div>
              <h2 className="message">{message}</h2>
            </div>
          )}
        </div>
      </div>
    </div>
  ) : null
}

export default EssilorLoading
