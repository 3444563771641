import React, { Component } from "react";
import "./precos-e-servicos-select.css";

class PrecosEServicosSelect extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { label, name, id, textSelected, itens, value, onChange } = this.props;

    return (
      <div className="">
        <label className="x-downloads__search--label" htmlFor="">
          {label}
        </label>
        <div className="x-downloads__search--position-select">
          <select id={id} name={name} value={value} onChange={onChange} className="x-downloads__search--select" type="text" onKeyDown={this.props.onKeyDown}>
            <option value="" selected>
              {textSelected}
            </option>
            {itens.map((item, index) => (
              <option key={index} value={item}>
                {item}
              </option>
            ))}
          </select>
          <span className="x-downloads__search--arrow"></span>
        </div>
      </div>
    );
  }
}

export default PrecosEServicosSelect;
