import React, { Component } from 'react'

//Import CSS
import './password.css'

//Import internal components
import * as valueList from './password-lists'

//Import external components

//Import images

const EYE = valueList.eye
const EYE_CROSSED = valueList.eye_crossed

class Password extends Component {

	constructor(props) {
		super(props)

		this.state = {
			visible: false
		}

		this.handleEye = this.handleEye.bind(this)
	}

	handleEye () {
		if (this.state.visible)
			this.setState ({
				visible: false
			})
		else {
			this.setState ({
				visible: true
			})
		}
	}

	render() {

		return (
			<div className="input-password" style={{ width: this.props.width }}>

				<label className="input-password-label" htmlFor={this.props.name}>
					{this.props.label}
				</label>

				<input
					type={this.state.visible ? 'text' : 'password'}
					name={this.props.name}
					id={this.props.id}
					aria={this.props.aria}
					placeholder={this.props.placeholder}
					required={this.props.required}
					readOnly={this.props.readOnly}
					disabled={this.props.disabled}
					autocomplete={this.props.autocomplete}

					onInvalid={this.props.onInvalid}
					onInput={this.props.onInput}

					onkeypress={this.props.onkeypress}
					onblur={this.props.onblur}
					onChange={this.props.onChange}
					onClick={this.props.onClick}
					onFocus={this.props.onFocus}
					onMouseLeave={this.props.onMouseLeave}
					onMouseOut={this.props.onMouseOut}
				/>

				{!this.state.visible &&
					<span className="eye-position" onClick={this.handleEye}>
						{EYE_CROSSED}
					</span>
				}

				{this.state.visible &&
					<span className="eye-position" onClick={this.handleEye}>
						{EYE}
					</span>
				}
			</div>
		)
	}
}

export default Password
