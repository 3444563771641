import React, { Component } from "react";

//Import CSS

//Import internal components
import AppContext from "../../appContext";
import CustomButton from "../../components/buttons/button";

//Import external components
import { Redirect } from "react-router-dom";
import Alert from "sweetalert2";

//Import image

class Logout extends Component {
	constructor(props) {
		super(props);

		this.state = {
			logout: false,
			loading: false,
		};

		Logout.contextType = AppContext;
		this.logout = this.logout.bind(this);
	}

	logout(e) {
		e.preventDefault();
		if (document.getElementById("myNav"))
			document.getElementById("myNav").style.display = "block";
		if (document.getElementById("headerBtn"))
			document.getElementById("headerBtn").style.visibility = "hidden";
		if (document.getElementById("headerBtn1"))
			document.getElementById("headerBtn1").style.visibility = "hidden";
		if (document.getElementById("main-body"))
			document.getElementById("main-body").style.pointerEvents = "none";
		if (localStorage.getItem("token") != null) {
			const requestOptions = {
				method: "DELETE",
				body: JSON.stringify({ token: localStorage.getItem("token") }),
			};
			this.context.showLoading("Saindo...");
			fetch(
				process.env.REACT_APP_LABS_API + "delete_token.php",
				requestOptions
			)
				.then((response) => {
					return response.json();
				})
				.then((json) => {
					if (json.status === 204) {
						this.setState({ logout: true });
						localStorage.removeItem("token");
						localStorage.removeItem("client");
						this.context.hideLoading();
					} else {
						this.context.hideLoading();
						Alert.fire({
							text: "Erro ao efetuar Logout.",
							icon: "error",
							title: "Aviso",
							confirmButtonColor: "#00518b",
							customClass: {
								confirmButton: "x-follow-order__filter-btn",
							},
						});
						document.getElementById("myNav").style.display = "none";
						document.getElementById("headerBtn").style.visibility =
							"visible";
						document.getElementById("headerBtn1").style.visibility =
							"visible";
						document.getElementById(
							"main-body"
						).style.pointerEvents = "";
					}
				});
		} else {
			this.setState({ logout: true });
		}
	}

	render() {
		const { logout } = this.state;
		const { logoutExterno } = this.props;

		if (logout) {
			return <Redirect to="/" />;
		}
		return (
			<div className="div-width-logout">
				<a
					href="/#"
					className="header__menu-list-link"
					onClick={this.logout}
					ref="logout"
				>
					<CustomButton
						width="100%"
						height="2.3125rem"
						orange_button
						marginTop="0"
						label="Sair"
					/>
				</a>
			</div>
		);
	}
}

export default Logout;
