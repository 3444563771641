import React, { Component, useContext } from "react";
import AppContext from "../../appContext";

import "./cadastral.css";

//Import external components
import { Pie } from "react-chartjs-2";
import DataTable from "react-data-table-component";
import { Accordion, Card, Button, useAccordionButton } from "react-bootstrap";
import { Dash, Plus } from "react-bootstrap-icons";
import AccordionContext from "react-bootstrap/AccordionContext";
import FilterInput from "../../components/Filtro/ColapseFilter/Input/filter-input";
import BaseColapse from "../../components/Filtro/ColapseFilter/baseColapse";
import FilterSelectMulti from "../../components/Filtro/ColapseFilter/Select/select-multi-options";
import FilterSelect from "../../components/Filtro/ColapseFilter/Select/filter-select";

import { LiaDownloadSolid } from "react-icons/lia";
class Cadastral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flag_download: "",
      selected_option: 3,
      openProduto: true,
      openCadastral: false,
      openLSA: false,
      openBase: false,
      labs: [],
      datatable: [],
      searchTerm: "",
      filteredData: {
        IPREMIOS: {
          filtroRPL: "",
          filtroDescricaoOpticlick: "",
          filtroDescricaoTratamento: "",
          filtroTipoProducao: "",
          filtroCodigoLaboratorio: "",
          filtroCodigoTratamento: "",
          filtroStatus: "",
          items: {
            rpls: this.props?.TableDataRPL?.IPREMIOS?.items?.rpls,
          },
        },
        SHOP9: {
          filtroRPL: "",
          filtroLSA: "",
          filtroDescricaoOpticlick: "",
          filtroDescricaoTratamento: "",
          filtroTipoProducao: "",
          filtroCodigoLaboratorio: "",
          filtroCodigoTratamento: "",
          filtroStatus: "",
          items: {
            rpls: this.props?.TableDataRPL?.SHOP9?.items?.rpls,
          },
        },
      },
    };
    Cadastral.contextType = AppContext;
    this.handleFilter = this.handleFilter.bind(this);
    this.cleanFilters = this.cleanFilters.bind(this);
    this.handleClickDownload = this.handleClickDownload.bind(this);
    this.downloadCSV = this.downloadCSV.bind(this);
    this.convertArrayOfObjectsToCSV = this.convertArrayOfObjectsToCSV.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (this.props.TableDataRPL.SHOP9 !== prevProps.TableDataRPL.SHOP9) {
      this.cleanFilters("SHOP9");
      this.setState(prevState => ({
        filteredData: {
          ...prevState.filteredData,
          SHOP9: {
            items: this.props.TableDataRPL.SHOP9.items,
          },
        },
      }));
    }

    if (this.props.TableDataRPL.IPREMIOS !== prevProps.TableDataRPL.IPREMIOS) {
      this.cleanFilters("IPREMIOS");
      this.setState(prevState => ({
        filteredData: {
          ...prevState.filteredData,
          IPREMIOS: {
            items: this.props.TableDataRPL.IPREMIOS.items,
          },
        },
      }));
    }
  }

  handleFilter(tabelaNome) {
    if (tabelaNome == "IPREMIOS") {
      const rpls = this.props.TableDataRPL.IPREMIOS?.items.rpls;

      let IPREMIO = {
        rpls: [],
      };

      let shouldInclude = false;
      for (let i = 0; i < rpls.length; ++i) {
        const rpl = rpls[i];
        const rplValue = rpl?.rpl?.toLowerCase();
        const descricaoOpticlickValue = rpl?.descricaoOpticlick?.toLowerCase();
        const descricaoTratamentoValue = rpl?.descricaoTratamento?.toLowerCase();
        const statusValue = rpl?.status?.toLowerCase();
        const tipoProducaoValue = rpl?.tipoProducao?.toLowerCase();
        const codigoValue = rpl?.produto.codigo?.toLowerCase();
        const codigoTratamentoValue = rpl?.codigoTratamento.toLowerCase();

        const rplFilter = this.state.filteredData?.IPREMIOS?.filtroRPL?.toLowerCase();
        const descricaoOpticlickFilter = this.state.filteredData?.IPREMIOS?.filtroDescricaoOpticlick?.toLowerCase();
        const descricaoTratamentoFilter = this.state.filteredData?.IPREMIOS?.filtroDescricaoTratamento?.toLowerCase();
        const statusFilter = this.state.filteredData?.IPREMIOS?.filtroStatus?.toLowerCase();
        const tipoProducaoFilter = this.state.filteredData?.IPREMIOS?.filtroTipoProducao?.toLowerCase();
        const codigoFilter = this.state.filteredData?.IPREMIOS?.filtroCodigoLaboratorio?.toLowerCase();
        const codigoTratamentoFilter = this.state.filteredData?.IPREMIOS?.filtroCodigoTratamento?.toLocaleLowerCase();

        shouldInclude =
          (rplFilter ? rplValue?.includes(rplFilter) : true) &&
          (descricaoOpticlickFilter ? descricaoOpticlickValue?.includes(descricaoOpticlickFilter) : true) &&
          (descricaoTratamentoFilter ? descricaoTratamentoValue?.includes(descricaoTratamentoFilter) : true) &&
          (statusFilter
            ? statusFilter
                .split(",")
                .map(filter => filter.trim())
                .some(filter => statusValue.includes(filter))
            : true) &&
          (tipoProducaoFilter ? tipoProducaoValue?.includes(tipoProducaoFilter) : true) &&
          (codigoFilter ? codigoValue?.includes(codigoFilter) : true) &&
          (codigoTratamentoFilter ? codigoTratamentoValue?.includes(codigoTratamentoFilter) : true);

        if (shouldInclude) {
          IPREMIO.rpls.push(rpl);
        }
      }
      this.setState(prevState => ({
        filteredData: {
          ...prevState.filteredData,
          IPREMIOS: {
            ...prevState.filteredData.IPREMIOS,
            items: IPREMIO,
          },
        },
      }));
    } else {
      const rpls = this.props.TableDataRPL.SHOP9?.items?.rpls;
      let SHOP = {
        rpls: [],
      };
      for (let i = 0; i < rpls.length; ++i) {
        const rpl = rpls[i];
        const rplValue = rpl?.rpl?.toLowerCase();
        const descricaoOpticlickValue = rpl?.descricaoOpticlick?.toLowerCase();
        const descricaoTratamentoValue = rpl?.descricaoTratamento?.toLowerCase();
        const statusValue = rpl?.status?.toLowerCase();
        const tipoProducaoValue = rpl?.tipoProducao?.toLowerCase();
        const codigoValue = rpl?.produto.codigo?.toLowerCase();
        const LSAValue = rpl?.lsa?.toLowerCase();
        const codigoTratamentoValue = rpl?.codigoTratamento?.toLowerCase();

        const rplFilter = this.state.filteredData?.SHOP9?.filtroRPL?.toLowerCase();
        const descricaoOpticlickFilter = this.state.filteredData?.SHOP9?.filtroDescricaoOpticlick?.toLowerCase();
        const descricaoTratamentoFilter = this.state.filteredData?.SHOP9?.filtroDescricaoTratamento?.toLowerCase();

        const tipoProducaoFilter = this.state.filteredData?.SHOP9?.filtroTipoProducao?.toLowerCase();
        const codigoFilter = this.state.filteredData?.SHOP9?.filtroCodigoLaboratorio?.toLowerCase();
        const lsaFilter = this.state.filteredData?.SHOP9?.filtroLSA?.toLowerCase();
        const codigoTratamentoFilter = this.state.filteredData?.SHOP9?.filtroCodigoTratamento?.toLocaleLowerCase();
        const statusFilter = this.state.filteredData?.SHOP9?.filtroStatus?.toLowerCase();
        const shouldInclude =
          (!rplFilter || rplValue?.includes(rplFilter)) &&
          (!descricaoOpticlickFilter || descricaoOpticlickValue?.includes(descricaoOpticlickFilter)) &&
          (!descricaoTratamentoFilter || descricaoTratamentoValue?.includes(descricaoTratamentoFilter)) &&
          (!tipoProducaoFilter || tipoProducaoValue?.includes(tipoProducaoFilter)) &&
          (!codigoFilter || codigoValue?.includes(codigoFilter)) &&
          (!lsaFilter || LSAValue?.includes(lsaFilter)) &&
          (!codigoTratamentoFilter || codigoTratamentoValue?.includes(codigoTratamentoFilter)) &&
          (!statusFilter ||
            statusFilter
              .split(",")
              .map(filter => filter.trim())
              .some(filter => statusValue.includes(filter)));

        if (shouldInclude) {
          SHOP.rpls.push(rpl);
        }
      }
      this.setState(prevState => ({
        filteredData: {
          ...prevState.filteredData,
          SHOP9: {
            ...prevState.filteredData.SHOP9,
            items: SHOP,
          },
        },
      }));
    }
  }

  cleanFilters(tabela) {
    if (tabela == "IPREMIOS") {
      this.setState(prevState => ({
        filteredData: {
          ...prevState.filteredData,
          IPREMIOS: {
            ...prevState.IPREMIOS,
            filtroRPL: "",
            filtroDescricaoOpticlick: "",
            filtroDescricaoTratamento: "",
            filtroTipoProducao: "",
            filtroCodigoLaboratorio: "",
            filtroStatus: "",
            filtroCodigoTratamento: "",
            items: {
              ...prevState.items,
              rpls: this.props.TableDataRPL.IPREMIOS?.items?.rpls,
            },
          },
        },
      }));
    } else {
      this.setState(prevState => ({
        filteredData: {
          ...prevState.filteredData,
          SHOP9: {
            ...prevState.SHOP9,
            filtroRPL: "",
            filtroLSA: "",
            filtroDescricaoOpticlick: "",
            filtroDescricaoTratamento: "",
            filtroTipoProducao: "",
            filtroCodigoLaboratorio: "",
            filtroStatus: "",
            filtroCodigoTratamento: "",
            items: {
              ...prevState.items,
              rpls: this.props.TableDataRPL.SHOP9?.items?.rpls,
            },
          },
        },
      }));
    }
  }

  handleClickDownload(tabela) {
    this.downloadCSV(tabela);
  }
  convertArrayOfObjectsToCSV(array) {
    let result;
    const columnDelimiter = ";";
    const lineDelimiter = "\n";
    const keys = Object.keys(array[0]);

    result = keys.join(columnDelimiter) + lineDelimiter;

    array.forEach(item => {
      let ctr = 0;
      keys.forEach(key => {
        if (ctr > 0) result += columnDelimiter;
        if (typeof item[key] === "object" && item[key] !== null) {
          // Trata valores de objeto vazios como strings vazias
          result += "";
        } else {
          result += item[key];
        }
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  downloadCSV(tabela) {
    const link = document.createElement("a");
    var filename = "";
    let csv = "";
    if (tabela === "shop9") {
      filename = "Situação Cadastral - Shop9.csv";
      csv = "\uFEFF" + this.convertArrayOfObjectsToCSV(this.state.filteredData?.SHOP9?.items?.rpls);
    } else {
      filename = "Situação Cadastral - Essilor Promo.csv";
      csv = "\uFEFF" + this.convertArrayOfObjectsToCSV(this.state.filteredData?.IPREMIOS?.items?.rpls);
    }
    if (csv == null) return;

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  render() {
    let classeDaColuna = "";
    let cardDivClasse = "";
    if (this.props.TableDataRPL.IPREMIOS?.message || this.props.TableDataRPL.SHOP9?.message) {
      classeDaColuna = "col-12 col12PieData ";
      cardDivClasse = "cardDivClasse";
    } else {
      classeDaColuna = "col-6 text-center col6PieData";

      cardDivClasse = "cardDivClass";
    }
    const optionsTipoProducao = ["SURFACAGEM_DIGITAL", "SURFACAGEM_TRADICIONAL", "LENTES_PRONTA"];

    const optionsStatus = ["OK", "DUPLICIDADE", "NÃO ENCONTRADO"];
    const options = {
      legend: {
        display: true,
        position: "bottom",
      },
      tooltips: {
        callbacks: {
          label: function (tooltipItem, data) {
            const value = data.datasets[0].data[tooltipItem.index];
            const total = data.datasets[0].data.reduce((acc, current) => acc + current, 0);
            const percentage = ((value / total) * 100).toFixed(2);
            return `${data.labels[tooltipItem.index]}: ${value} (${percentage}%)`;
          },
        },
      },
    };

    const paginationComponentOptions = {
      rowsPerPageText: "Resultados por página",
      rangeSeparatorText: "de",
      selectAllRowsItem: true,
      selectAllRowsItemText: "Todos",
    };

    const labelOKSHOP9 = "OK ";
    const labelNEncontradoSHOP9 = "NÃO ENCONTRADO ";
    const labelDuplicadoSHOP9 = "DUPLICIDADE ";

    const dataPieSHOP9 = {
      labels: [labelOKSHOP9, labelNEncontradoSHOP9, labelDuplicadoSHOP9],
      datasets: [
        {
          data: [
            this.props?.TableDataRPL?.SHOP9?.quantidadeEncontrado,
            this.props?.TableDataRPL?.SHOP9?.quantidadeNEncontrado,
            this.props?.TableDataRPL?.SHOP9?.quantidadeDuplicada,
          ],
          backgroundColor: ["rgba(14,82,140,1)", "#cf5252", "rgba(255,203,186,1)"],
          hoverBackgroundColor: ["rgba(14,82,140,1)", "#cf5252", "rgba(255,203,186,1)"],
        },
      ],
    };

    const columnsShop9 = [
      {
        name: "LSA",
        selector: "lsa",
        sortable: true,
        reorder: true,
        width: "col col-lg-10",
      },
      {
        name: "RPL",
        selector: "rpl",
        sortable: true,
        reorder: true,
        width: "col col-lg-10",
      },

      {
        name: "Descrição Opticlick",
        selector: "descricaoOpticlick",
        sortable: true,
        reorder: true,
        width: "col col-lg-10",
      },
      {
        name: "Cód. do Produto no Laboratório",
        selector: "produto.codigo",
        sortable: true,
        reorder: true,
        width: "col col-lg-10",
      },
      {
        name: "Cód. do Tratamento",
        selector: "codigoTratamento",
        sortable: true,
      },
      {
        name: "Descrição Tratamento",
        selector: "descricaoTratamento",
        sortable: true,
        reorder: true,
        width: "col col-lg-10",
      },
      {
        name: "Tipo Produção",
        selector: "tipoProducao",
        sortable: true,
        reorder: true,
        width: "col col-lg-10",
      },

      {
        name: "Status",
        selector: "status",
        sortable: true,
        sortable: true,
        reorder: true,
        width: "col col-lg-10",
        cell: row => <div style={{ color: row.status === "NÃO ENCONTRADO" || row.status === "DUPLICIDADE" ? "#e8131e" : "" }}>{row.status}</div>,
      },
    ];

    const labelOK = "OK";
    const labelNEncontrado = "NÃO ENCONTRADO";
    const labelDuplicado = "DUPLICIDADE";

    const dataPieIpremios = {
      labels: [labelOK, labelNEncontrado, labelDuplicado],
      datasets: [
        {
          data: [
            this.props?.TableDataRPL?.IPREMIOS?.quantidadeEncontrado,
            this.props?.TableDataRPL?.IPREMIOS?.quantidadeNEncontrado,
            this.props?.TableDataRPL?.IPREMIOS?.quantidadeDuplicada,
          ],
          backgroundColor: ["rgba(14,82,140,1)", "#cf5252", "rgba(255,203,186,1)"],
          hoverBackgroundColor: ["rgba(14,82,140,1)", "#cf5252", "rgba(255,203,186,1)"],
        },
      ],
    };
    const columnsIpremios = [
      {
        name: "RPL",
        selector: "rpl",
        sortable: true,
      },

      {
        name: "Descrição Opticlick",
        selector: "descricaoOpticlick",
        sortable: true,
      },
      {
        name: "Cód. do Produto no Laboratório",
        selector: "produto.codigo",
        sortable: true,
      },
      {
        name: "Cód. do Tratamento",
        selector: "codigoTratamento",
        sortable: true,
      },
      {
        name: "Descrição Tratamento",
        selector: "descricaoTratamento",
        sortable: true,
      },
      {
        name: "Tipo Produção",
        selector: "tipoProducao",
        sortable: true,
      },

      {
        name: "Status",
        selector: "status",
        sortable: true,
        sortable: true,
        reorder: true,
        width: "col col-lg-10",
        cell: row => <div style={{ color: row.status === "NÃO ENCONTRADO" || row.status === "DUPLICIDADE" ? "#e8131e" : "" }}>{row.status}</div>,
      },
    ];

    function ContextAwareToggle({ children, eventKey, callback }) {
      const currentEventKey = useContext(AccordionContext);
      const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));
      return (
        <div className="row">
          <div className="col-12 headerColapse" onClick={decoratedOnClick}>
            <div className="col-10">{children}</div>
            {currentEventKey.activeEventKey && (
              <span className="float-end" onClick={decoratedOnClick}>
                {" "}
                <Dash color="royalblue" size={24} />{" "}
              </span>
            )}
            {!currentEventKey.activeEventKey && (
              <span className="float-end" onClick={decoratedOnClick}>
                {" "}
                <Plus color="royalblue" size={24} />{" "}
              </span>
            )}
          </div>
        </div>
      );
    }

    const actionsSHOP9 = (
      <button className="CleanAnchordownload" onClick={() => this.handleClickDownload("shop9")}>
        Exportar Lista
      </button>
    );
    const actionsIPREMIOS = (
      <a className="CleanAnchordownload" onClick={() => this.handleClickDownload("ipremios")}>
        Exportar Lista
      </a>
    );
    return (
      <div className="row">
        <div className="col-12">
          {(!this.props.TableDataRPL.IPREMIOS?.message || !this.props.TableDataRPL.SHOP9?.message) && (
            <BaseColapse buttonName="Gráficos" width="100%" colapse={false}>
              {this.props.TableDataRPL.IPREMIOS.items && (
                <div className={`${classeDaColuna}`}>
                  <Card className={`${cardDivClasse}`}>
                    <Card.Header className="w-100 d-flex justify-content-between align-items-end headerCardPieChart">
                      <p className="text-muted h4">Essilor Promo</p>

                      <p className="h5 cadatralPieData">{this.props.TableDataRPL.IPREMIOS?.items?.dataExecucao}</p>
                    </Card.Header>
                    <Card.Body className="d-flex justify-content-center align-items-center">
                      <Pie data={dataPieIpremios} options={options} />
                    </Card.Body>
                  </Card>
                </div>
              )}
              {this.props.TableDataRPL.SHOP9.items && (
                <div className={`${classeDaColuna}`}>
                  <Card className={`${cardDivClasse}`}>
                    <Card.Header className="w-100 d-flex justify-content-between align-items-end headerCardPieChart">
                      <p className="text-muted h4">Shop9</p>
                      <p className="h5 cadatralPieData">{this.props.TableDataRPL.SHOP9?.items?.dataExecucao}</p>
                    </Card.Header>
                    <Card.Body className="d-flex justify-content-center align-items-center">
                      <Pie data={dataPieSHOP9} options={options} />
                    </Card.Body>
                  </Card>
                </div>
              )}
            </BaseColapse>
          )}{" "}
          {this.props.TableDataRPL.IPREMIOS.items && (
            <>
              <BaseColapse buttonName="Filtros" colapse={true}>
                <div className="row cadastralFiltroRow1">
                  <div className="col-6">
                    <FilterInput
                      label="RPL"
                      name="download_search_folder"
                      value={this.state.filteredData?.IPREMIOS?.filtroRPL}
                      onChange={event => {
                        const novoValor = event.target.value;
                        this.setState(
                          prevState => ({
                            filteredData: {
                              ...prevState.filteredData,
                              IPREMIOS: {
                                ...prevState.filteredData.IPREMIOS,
                                filtroRPL: novoValor,
                              },
                            },
                          }),
                          () => {
                            this.handleFilter("IPREMIOS");
                          },
                        );
                      }}
                      flexDirectionDiv="row"
                      alignItemsDiv="center"
                      justifyContentDiv="flex-end"
                      displayDiv="flex"
                    />
                  </div>
                
                  <div className="col-6">
                    <FilterInput
                      label="Descrição Tratamento"
                      name="download_search_folder"
                      value={this.state.filteredData?.IPREMIOS.filtroDescricaoTratamento}
                      onChange={event => {
                        const novoValor = event.target.value;
                        this.setState(
                          prevState => ({
                            filteredData: {
                              ...prevState.filteredData,
                              IPREMIOS: {
                                ...prevState.filteredData.IPREMIOS,
                                filtroDescricaoTratamento: novoValor,
                              },
                            },
                          }),
                          () => {
                            this.handleFilter("IPREMIOS");
                          },
                        );
                      }}
                      flexDirectionDiv="row"
                      alignItemsDiv="center"
                      justifyContentDiv="flex-end"
                      displayDiv="flex"
                    />
                  </div>
                </div>
                <div className="row cadastralFiltroRow1">
                <div className="col-6">
                    <FilterInput
                      label="Descrição Opticlick"
                      name="download_search_folder"
                      value={this.state.filteredData?.IPREMIOS?.filtroDescricaoOpticlick}
                      onChange={event => {
                        const novoValor = event.target.value;
                        this.setState(
                          prevState => ({
                            filteredData: {
                              ...prevState.filteredData,
                              IPREMIOS: {
                                ...prevState.filteredData.IPREMIOS,
                                filtroDescricaoOpticlick: novoValor,
                              },
                            },
                          }),
                          () => {
                            this.handleFilter("IPREMIOS");
                          },
                        );
                      }}
                      flexDirectionDiv="row"
                      alignItemsDiv="center"
                      justifyContentDiv="flex-end"
                      displayDiv="flex"
                    />
                  </div>
                  
                  <div className="col-6">
                    <FilterSelect
                      label="Tipo Produção"
                      name="download_search_folder"
                      value={this.state.filteredData?.IPREMIOS?.filtroTipoProducao}
                      itens={optionsTipoProducao}
                      onChange={event => {
                        const novoValor = event.target.value;
                        this.setState(
                          prevState => ({
                            filteredData: {
                              ...prevState.filteredData,
                              IPREMIOS: {
                                ...prevState.filteredData.IPREMIOS,
                                filtroTipoProducao: novoValor,
                              },
                            },
                          }),
                          () => {
                            this.handleFilter("IPREMIOS");
                          },
                        );
                      }}
                      flexDirectionDiv="row"
                      alignItemsDiv="baseline"
                      justifyContentDiv="flex-end"
                      displayDiv="flex"
                    />
                  </div>
                </div>
                <div className="row cadastralFiltroRow1">
                <div className="col-6">
                    <FilterInput
                      label="Cód. do Produto no Laboratório"
                      name="download_search_folder"
                      value={this.state.filteredData?.IPREMIOS?.filtroCodigoLaboratorio}
                      onChange={event => {
                        const novoValor = event.target.value;
                        this.setState(
                          prevState => ({
                            filteredData: {
                              ...prevState.filteredData,
                              IPREMIOS: {
                                ...prevState.filteredData.IPREMIOS,
                                filtroCodigoLaboratorio: novoValor,
                              },
                            },
                          }),
                          () => {
                            this.handleFilter("IPREMIOS");
                          },
                        );
                      }}
                      flexDirectionDiv="row"
                      alignItemsDiv="center"
                      justifyContentDiv="flex-end"
                      displayDiv="flex"
                    />
                  </div>
                  <div className="col-6">
                    <FilterSelect
                      label="Status"
                      name="download_search_folder"
                      value={this.state.filteredData?.IPREMIOS?.filtroStatus}
                      itens={optionsStatus}
                      onChange={event => {
                        const novoValor = event.target.value;
                        this.setState(
                          prevState => ({
                            filteredData: {
                              ...prevState.filteredData,
                              IPREMIOS: {
                                ...prevState.filteredData.IPREMIOS,
                                filtroStatus: novoValor,
                              },
                            },
                          }),
                          () => {
                            this.handleFilter("IPREMIOS");
                          },
                        );
                      }}
                      flexDirectionDiv="row"
                      alignItemsDiv="baseline"
                      justifyContentDiv="flex-end"
                      displayDiv="flex"
                    />
                  </div>
                </div>
                <div className="row cadastralFiltroRow1">
                <div className="col-6">
                    <FilterInput
                      label="Cód. do Tratamento"
                      name="download_search_folder"
                      value={this.state.filteredData?.IPREMIOS?.filtroCodigoTratamento}
                      onChange={event => {
                        const novoValor = event.target.value;
                        this.setState(
                          prevState => ({
                            filteredData: {
                              ...prevState.filteredData,
                              IPREMIOS: {
                                ...prevState.filteredData.IPREMIOS,
                                filtroCodigoTratamento: novoValor,
                              },
                            },
                          }),
                          () => {
                            this.handleFilter("IPREMIOS");
                          },
                        );
                      }}
                      flexDirectionDiv="row"
                      alignItemsDiv="center"
                      justifyContentDiv="flex-end"
                      displayDiv="flex"
                    />
                  </div>

                  <div className="col-6 mt-1">
                    <div className="containerFilterButtonClean">
                      <a className="CleanAnchorFilter" onClick={() => this.cleanFilters("IPREMIOS")}>
                        Limpar Filtros
                      </a>
                    </div>
                  </div>
                </div>
              </BaseColapse>
              <Accordion defaultActiveKey="1">
                <Card>
                  <Card.Header>
                    <ContextAwareToggle eventKey="1">Produtos Essilor Promo</ContextAwareToggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <DataTable
                        columns={columnsIpremios}
                        data={this.state.filteredData?.IPREMIOS?.items?.rpls}
                        dense={true}
                        pagination={true}
                        striped={true}
                        noDataComponent={<h4>Sem Resultados</h4>}
                        paginationComponentOptions={paginationComponentOptions}
                        defaultExpanded={true}
                        actions={actionsIPREMIOS}
                      />
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </>
          )}
        </div>

        {this.props.TableDataRPL.SHOP9.items && (
          <div className="col-12 my-3">
            <Accordion defaultActiveKey="1">
              <BaseColapse buttonName="Filtros" colapse={true}>
                <div className="row cadastralFiltroRow1">
                  <div className="col-6">
                    <FilterInput
                      label="LSA"
                      name="download_search_folder"
                      value={this.state.filteredData?.SHOP9?.filtroLSA}
                      onChange={event => {
                        const novoValor = event.target.value;
                        this.setState(
                          prevState => ({
                            filteredData: {
                              ...prevState.filteredData,
                              SHOP9: {
                                ...prevState.filteredData?.SHOP9,
                                filtroLSA: novoValor,
                              },
                            },
                          }),
                          () => {
                            this.handleFilter("SHOP9");
                          },
                        );
                      }}
                      flexDirectionDiv="row"
                      alignItemsDiv="center"
                      justifyContentDiv="flex-end"
                      displayDiv="flex"
                    />
                  </div>
                  <div className="col-6">
                    <FilterInput
                      label="Cód. do Tratamento"
                      name="download_search_folder"
                      value={this.state.filteredData?.SHOP9?.filtroCodigoTratamento}
                      onChange={event => {
                        const novoValor = event.target.value;
                        this.setState(
                          prevState => ({
                            filteredData: {
                              ...prevState.filteredData,
                              SHOP9: {
                                ...prevState.filteredData.SHOP9,
                                filtroCodigoTratamento: novoValor,
                              },
                            },
                          }),
                          () => {
                            this.handleFilter("SHOP9");
                          },
                        );
                      }}
                      flexDirectionDiv="row"
                      alignItemsDiv="center"
                      justifyContentDiv="flex-end"
                      displayDiv="flex"
                    />
                  </div>
                  
                </div>
                <div className="row cadastralFiltroRow1">
                <div className="col-6">
                    <FilterInput
                      label="RPL"
                      name="download_search_folder"
                      value={this.state.filteredData?.SHOP9?.filtroRPL}
                      onChange={event => {
                        const novoValor = event.target.value;
                        this.setState(
                          prevState => ({
                            filteredData: {
                              ...prevState.filteredData,
                              SHOP9: {
                                ...prevState.filteredData?.SHOP9,
                                filtroRPL: novoValor,
                              },
                            },
                          }),
                          () => {
                            this.handleFilter("SHOP9");
                          },
                        );
                      }}
                      flexDirectionDiv="row"
                      alignItemsDiv="center"
                      justifyContentDiv="flex-end"
                      displayDiv="flex"
                    />
                  </div>
                 
                  <div className="col-6">
                    <FilterInput
                      label="Descrição Tratamento"
                      name="download_search_folder"
                      value={this.state.filteredData?.SHOP9.filtroDescricaoTratamento}
                      onChange={event => {
                        const novoValor = event.target.value;
                        this.setState(
                          prevState => ({
                            filteredData: {
                              ...prevState.filteredData,
                              SHOP9: {
                                ...prevState.filteredData.SHOP9,
                                filtroDescricaoTratamento: novoValor,
                              },
                            },
                          }),
                          () => {
                            this.handleFilter("SHOP9");
                          },
                        );
                      }}
                      flexDirectionDiv="row"
                      alignItemsDiv="center"
                      justifyContentDiv="flex-end"
                      displayDiv="flex"
                    />
                  </div>
                </div>
                <div className="row cadastralFiltroRow1">
                <div className="col-6">
                    <FilterInput
                      label="Descrição Opticlick"
                      name="download_search_folder"
                      value={this.state.filteredData?.SHOP9?.filtroDescricaoOpticlick}
                      onChange={event => {
                        const novoValor = event.target.value;
                        this.setState(
                          prevState => ({
                            filteredData: {
                              ...prevState.filteredData,
                              SHOP9: {
                                ...prevState.filteredData?.SHOP9,
                                filtroDescricaoOpticlick: novoValor,
                              },
                            },
                          }),
                          () => {
                            this.handleFilter("SHOP9");
                          },
                        );
                      }}
                      flexDirectionDiv="row"
                      alignItemsDiv="center"
                      justifyContentDiv="flex-end"
                      displayDiv="flex"
                    />
                  </div>
                 
                  <div className="col-6">
                    <FilterSelect
                      label="Tipo Produção"
                      name="download_search_folder"
                      itens={optionsTipoProducao}
                      value={this.state.filteredData?.SHOP9?.filtroTipoProducao}
                      onChange={event => {
                        const novoValor = event.target.value;
                        this.setState(
                          prevState => ({
                            filteredData: {
                              ...prevState.filteredData,
                              SHOP9: {
                                ...prevState.filteredData.SHOP9,
                                filtroTipoProducao: novoValor,
                              },
                            },
                          }),
                          () => {
                            this.handleFilter("SHOP9");
                          },
                        );
                      }}
                      flexDirectionDiv="row"
                      alignItemsDiv="baseline"
                      justifyContentDiv="flex-end"
                      displayDiv="flex"
                    />
                  </div>
                </div>
                <div className="row cadastralFiltroRow1">
                <div className="col-6">
                    <FilterInput
                      label="Cód. do Produto no Laboratório"
                      name="download_search_folder"
                      value={this.state.filteredData?.SHOP9?.filtroCodigoLaboratorio}
                      onChange={event => {
                        const novoValor = event.target.value;
                        this.setState(
                          prevState => ({
                            filteredData: {
                              ...prevState.filteredData,
                              SHOP9: {
                                ...prevState.filteredData.SHOP9,
                                filtroCodigoLaboratorio: novoValor,
                              },
                            },
                          }),
                          () => {
                            this.handleFilter("SHOP9");
                          },
                        );
                      }}
                      flexDirectionDiv="row"
                      alignItemsDiv="center"
                      justifyContentDiv="flex-end"
                      displayDiv="flex"
                    />
                  </div>
                  
                  <div className="col-6">
                    <FilterSelect
                      label="Status"
                      name="download_search_folder"
                      value={this.state.filteredData?.SHOP9?.filtroStatus}
                      itens={optionsStatus}
                      onChange={event => {
                        const novoValor = event.target.value;
                        this.setState(
                          prevState => ({
                            filteredData: {
                              ...prevState.filteredData,
                              SHOP9: {
                                ...prevState.filteredData.SHOP9,
                                filtroStatus: novoValor,
                              },
                            },
                          }),
                          () => {
                            this.handleFilter("SHOP9");
                          },
                        );
                      }}
                      flexDirectionDiv="row"
                      alignItemsDiv="baseline"
                      justifyContentDiv="flex-end"
                      displayDiv="flex"
                    />
                  </div>
                </div>
                <div className="row w-100">
                  <div className="col-6 w-100 mt-2">
                    <div className="containerFilterButtonClean">
                      <a className="CleanAnchorFilter" onClick={() => this.cleanFilters("SHOP9")}>
                        Limpar Filtros
                      </a>
                    </div>
                  </div>
                </div>
              </BaseColapse>
              <Card>
                <Card.Header>
                  <ContextAwareToggle eventKey="1">Produtos Shop9</ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <DataTable
                      defaultExpanded={true}
                      columns={columnsShop9}
                      data={this.state.filteredData?.SHOP9?.items?.rpls}
                      dense={true}
                      pagination={true}
                      striped={true}
                      noDataComponent={<h4>Sem Resultados</h4>}
                      paginationComponentOptions={paginationComponentOptions}
                      actions={actionsSHOP9}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </div>
        )}
      </div>
    );
  }
}

export default Cadastral;
