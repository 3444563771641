import React, { Component } from "react";
import { Badge } from "react-bootstrap";
import AppContext from "../../appContext";
import CustomButton from "../../components/buttons/button";
import "./../pedido-atacado.css";
import moment from "moment";
import { Redirect } from "react-router";
import Swal from "sweetalert2";
import Pdf from "./pdf/TERMOS E CONDIÇÕES DE VENDA DE PRODUTOS ESSILOR PARA ÓTICAS.pdf";

class PedidoAtacadoEtapaCinco extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pedidoOtica: "",
      prazo: "",
      empresa_faturamento: "",
      empresa_faturamento_cnpj: "",
      pedido: "",
      labName: "",
      produtos: [],
      orcamento: {
        valorLiquido: 0,
        prazo: "",
      },
      redirect: false,
      jsonBody: {},
      observacao: "",
      redeOticas: false,
      otica: {},
      orderSummary: {
        pedidoOtica: "",
        prazo: "",
        empresa_faturamento: "",
        empresa_faturamento_cnpj: "",
        pedido: "",
        labName: "",
        produtos: [],
        orcamento: {
          valorLiquido: 0,
          prazo: "",
        },
        redirect: false,
        jsonBody: {},
        observacao: "",
        redeOticas: false,
        vendedor: {
          nome: "",
          cpf: "",
        },
        otica: {},
      },
    };

    PedidoAtacadoEtapaCinco.contextType = AppContext;
    this.corrigirPedido = this.corrigirPedido.bind(this);
  }

  componentDidMount() {
    const { pedidoOtica, prazo, empresa_faturamento, empresa_faturamento_cnpj, pedido, labName, produtos, orcamento, jsonBody, observacao, redeOticas, otica } =
      this.props;

    this.setState({
      pedidoOtica: pedidoOtica,
      prazo: prazo,
      empresa_faturamento: empresa_faturamento,
      empresa_faturamento_cnpj: empresa_faturamento_cnpj,
      pedido: pedido,
      labName: labName,
      produtos: produtos,
      orcamento: orcamento,
      redirect: false,
      jsonBody: jsonBody,
      observacao: observacao,
      redeOticas: redeOticas,
      otica: otica,
      orderSummary: {
        pedidoOtica: pedidoOtica,
        prazo: prazo,
        empresa_faturamento: empresa_faturamento,
        empresa_faturamento_cnpj: empresa_faturamento_cnpj,
        pedido: pedido,
        labName: labName,
        produtos: produtos,
        orcamento: orcamento,
        redirect: false,
        jsonBody: jsonBody,
        observacao: observacao,
        redeOticas: redeOticas,
        vendedor: {
          nome: this.context.userSession.nome + " " + this.context.userSession.sobrenome,
          cpf: this.context.userSession.cpf,
        },
        otica: otica,
      },
    });
  }

  corrigirPedido() {
    this.props.corrigirPedido();
  }

  enviarPedido = async () => {
    this.context.showLoading("Enviando pedido...");
    var url = `${process.env.REACT_APP_ESSILOR_API}gestao-pedidos-laboratorio/v1/pedidos-atacado`;

    var body = {
      idPedidoOtica: this.state.pedidoOtica,
      observacao: this.state.observacao,
      produtos: this.state.produtos,
      vendedor: {
        nome: this.context.userSession.nome + " " + this.context.userSession.sobrenome,
        cpf: this.context.userSession.cpf,
      },
    };
    if (this.props.codigoParticipante) {
      body.programaLaboratorio = {
        codigoParticipante: this.props.codigoParticipante,
      };
    }
    let requestOptions = [];
    if ((this.props?.redes?.length > 0 && this.context.userSession.redeOticas)) {
      requestOptions = requestOptions = {
        method: "POST",
        headers: {
          access_token: localStorage.getItem("token"),
          client_id: localStorage.getItem("clientid"),
          laboratorio: this.props?.empresa_faturamento_cnpj,
          otica: this.props?.dados_empresa_faturamento_cnpj,
        },
        body: JSON.stringify(body),
      };
    } else {
      requestOptions = requestOptions = {
        method: "POST",
        headers: {
          access_token: localStorage.getItem("token"),
          client_id: localStorage.getItem("clientid"),
          laboratorio: this.state.empresa_faturamento_cnpj,
        },
        body: JSON.stringify(body),
      };
    }

    await fetch(url, requestOptions)
      .then(response => {
        if (response.status === 201) {
          return response.json();
        } else if (response.status >= 400 && response.status < 499) {
          return response.json().then(json => {
            this.context.hideLoading();
            Swal.fire({
              html: json[0].mensagem,
              icon: "warning",
              title: "Aviso",
              width: "600px",
              confirmButtonColor: "#00518b",
              customClass: {
                confirmButton: "x-follow-order__filter-btn",
              },
            }).then(result => {});
          });
        } else if (response.status === 504) {
          this.context.hideLoading();
          Swal.fire({
            html: "A requisição atingiu o tempo máximo de processamento. Tente novamente mais tarde.",
            icon: "warning",
            title: "Aviso",
            width: "600px",
            confirmButtonColor: "#00518b",
            customClass: {
              confirmButton: "x-follow-order__filter-btn",
            },
          }).then(result => {});
        } else {
          this.context.hideLoading();
          Swal.fire({
            html: "Erro interno. Tente novamente mais tarde.",
            icon: "error",
            width: "600px",
            confirmButtonColor: "#00518b",
            customClass: {
              confirmButton: "x-follow-order__filter-btn",
            },
          }).then(result => {});
        }
      })
      .then(json => {
        if (json) {
          this.setState({ jsonBody: json, redirect: true });
        }
      });
    this.context.hideLoading();
  };

  render() {
    let existeValorLiquido = this.state.orcamento.valorLiquido != undefined ? true : false;
    var valor = parseFloat(this.state.orcamento.valorLiquido ? this.state.orcamento.valorLiquido : 0).toLocaleString("pt-br", {
      style: "currency",
      currency: "BRL",
    });

    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: "/confirmed-atacado",
            state: {
              jsonBody: this.state.jsonBody,
              pedidoOtica: this.state.pedidoOtica,
              prazo: this.state.prazo,
              empresa_faturamento: this.state.empresa_faturamento,
              empresa_faturamento_cnpj: this.state.empresa_faturamento_cnpj,
              pedido: this.state.pedido ? this.state.pedido : this.state.jsonBody.id,
              labName: this.state.labName,
              produtos: this.state.produtos,
              orcamento: this.state.orcamento,
              newOrder: "/atacado",
              observacao: this.state.observacao,
              redeOticas: this.state.redeOticas,
              orderSummary: this.state.orderSummary,
              otica: this.state.otica,
            },
          }}
        />
      );
    }

    return (
      <main className="x-confirm-order__main">
        <div className="x-confirm-order__container">
          <section className="x-confirm-order__left">
            <div className="x-confirm-order__dados--container2">
              <div className="x-confirm-order__dados--intro">
                <h1 className="x-confirm-order__dados--title">Confirme seu pedido</h1>
                <span className="x-confirm-order__dados--square"></span>
                <p className="x-confirm-order__dados--paragraph">Olá, {this.context.userSession.nome ? this.context.userSession.nome : ""}.</p>
                {!this.context.userSession.modulos.includes("Pedidos Simulação") ? (
                  <p className="x-confirm-order__dados--paragraph">
                    Seu pedido foi validado e está pronto para ser enviado ao laboratório.
                    <br />
                    Revise atentamente o resumo do pedido ao lado e clique em confirmar pedido para concluí-lo.
                  </p>
                ) : (
                  <p className="x-confirm-order__dados--paragraph">
                    Simulação de pedido concluída.
                    <br />
                  </p>
                )}
              </div>
              <div className="x-confirm-order__list">
                {existeValorLiquido && (
                  <ul className="x-confirm-order__dados--list">
                    <li className="x-confirm-order__dados--item is--large">Valor total do pedido</li>
                    <li className="x-confirm-order__dados--item blue">{valor}</li>
                  </ul>
                )}
                <ul className="x-confirm-order__dados--list">
                  <li className="x-confirm-order__dados--item is--large">Data de saída estimada</li>
                  <li className={"x-confirm-order__dados--item blue"}>{moment(this.state.orcamento.prazo).format("DD/MM/YYYY")}</li>
                </ul>
              </div>
              <div className="x-confirm-order__dados--intro">
                {!this.context.userSession.modulos.includes("Pedidos Simulação") && (
                  <>
                    <p className="x-confirm-order__dados--paragraph_small">
                      Ao clicar em confirmar o pedido, você concorda com o envio das informações que constam no resumo lateral e com os{" "}
                      <a href={Pdf} target="_blank">
                        termos e condições de compra
                      </a>
                      .
                    </p>
                  </>
                )}
              </div>
              {/* <a className="x-confirm-order__dados--view" href="/order" target="_blanck">Pré-visualizar</a> */}
              <div className="row atacado-buttons atacado-buttons-confirm">
                <div className="col-md-3 col-l-3 col-s-12 atacado-prev-button">
                  <CustomButton
                    label="Corrigir"
                    width="13.125rem"
                    icon_arrow_left
                    orange_button
                    onClick={() => {
                      this.setState({ hasOrcamento: false, disableFields: false }, () => {
                        this.corrigirPedido();
                      });
                    }}
                  />
                </div>
                {!this.context.userSession.modulos.includes("Pedidos Simulação") && (
                  <>
                    <div className="col-md-3 col-l-3 col-s-12 atacado-next-button confirm" id="crm-button-blue-atacado" style={{ marginLeft: "0px" }}>
                      <CustomButton
                        label="Confirmar pedido"
                        width="13.125rem"
                        icon_arrow_right
                        onClick={() => {
                          this.enviarPedido();
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
          </section>
        </div>
        {/* <div className={`${this.state.open == true ? "show-termoscompra" : "hide-termoscompra"}`}>
          <div className="termoscompra-close" onClick={this.hideTermosCompra}>
            <svg xmlns="http://www.w3.org/2000/svg" className="close-desk" width="17.089" height="17.119" viewBox="0 0 17.089 17.119">
              <path
                className="a"
                d="M20.862,36.982a1.453,1.453,0,0,0,1.012.434,1.4,1.4,0,0,0,1.012-.434l6.072-6.072,6.072,6.072a1.453,1.453,0,0,0,1.012.434,1.4,1.4,0,0,0,1.012-.434,1.432,1.432,0,0,0,0-2.053l-6.072-6.072,6.072-6.072A1.452,1.452,0,0,0,35,20.731L28.929,26.8l-6.043-6.014a1.452,1.452,0,1,0-2.053,2.053L26.9,28.915l-6.043,6.043a1.4,1.4,0,0,0,0,2.024Z"
                transform="translate(-20.399 -20.297)"
                fill="#b5b5b5"
              />
            </svg>
          </div>
          <TermosCompra />
        </div> */}
      </main>
    );
  }
}

export default PedidoAtacadoEtapaCinco;
