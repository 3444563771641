import React, { Component } from "react";

//Import CSS
import "../financeiro.css";

//Import internal components
import AppContext from "../../appContext";
import TrackingLabChoose from "../financeiro-inputs/tracking-labchoose";
import TrackingInputCNPJ from "../financeiro-inputs/tracking-input-cnpj";
import TrackingSearchButton from "../financeiro-inputs/tracking-search-button";
import TrackingTableHead from "../financeiro-table/tracking-table-head";

//Import external components
import { Redirect } from "react-router-dom";
import Moment from "moment";
import Swal from "sweetalert2";
import { redirectPage } from "../../components/utils/redirectpage";
import RadioButton from "../../components/inputs/radio-button";

//Import images

class BuscaStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      laboratorio_nome: "",
      laboratorio_cnpj: "",
      pedido_laboratorio: "",
      pedido_otica: "",
      cnpj_number: this.props.cnpj_number,
      table: [],
      dia_inicio: new Date().setDate(new Date().getDate() - 30),
      dia_fim: new Date(),
      data_inicio: Moment(new Date().setDate(new Date().getDate() - 30)).format("YYYY-MM-DD"),
      data_fim: Moment(new Date()).format("YYYY-MM-DD"),
      resposta_tabela: false,
      loading: false,

      redeOticaAux: this.props.redeOticaAux,
      detailOrder: false,
      lab: null,
      status: "",
      statusMessage: null,
    };

    if (localStorage.getItem("token") !== null) {
      BuscaStatus.contextType = AppContext;
      this.setLabData = this.setLabData.bind(this);
      this.handleChange = this.handleChange.bind(this);
      this.handleChange_data_inicio = this.handleChange_data_inicio.bind(this);
      this.handleChange_data_fim = this.handleChange_data_fim.bind(this);
      this.changeState = this.changeState.bind(this);
      this.integraPedido = this.integraPedido.bind(this);
      this.validarCampos = this.validarCampos.bind(this);
    }
  }

  borderColor(idElement, color = "#f70017") {
    //#f70017  #B5B5B5
    if (typeof idElement === "object") {
      idElement.map(el => {
        if (document.getElementById(el)) {
          return (document.getElementById(el).style.boxShadow = `0 0 0 1px ${color}`);
        }
        return null;
      });
    } else {
      document.getElementById(idElement).style.boxShadow = `0 0 0 1px ${color}`;
    }
  }

  setLabData(e, label, detailOrder, params) {
    if (e.name === "lab") {
      this.props.handleChangeProps(e);
    }

    if (e.value) {
      if (this.context.userSession.papelCategoria === "LABORATORIO") {
        if (e.value === this.context.userSession.conta) {
          this.setState({ cnpj_number: this.state.cnpj_number_aux });
          this.props.handleChangeProps("campoCnpjLiberado", "boolean", true);
        } else {
          this.setState({ cnpj_number_aux: this.props.cnpj_number, cnpj_number: "" });
          this.props.handleChangeProps("campoCnpjLiberado", "boolean", false);
        }
      } else {
        this.props.handleChangeProps("campoCnpjLiberado", "boolean", true);
      }

      if (this.context.userSession.redeOticas) {
        this.context.showLoading("Buscando...");

        this.props.listarRedesOticas(e.value);
      }

      this.setState({
        lab: params,
      });

      this.setState({
        laboratorio_nome: label,
        laboratorio_cnpj: e.value,
        detailOrder: detailOrder,
      });
      this.borderColor("lab", "#B5B5B5");
    } else {
      this.setState({
        laboratorio_nome: "",
        laboratorio_cnpj: "",
        detailOrder: false,
        lab: null,
      });
    }

    var dadosLab = {
      dados: params,
      laboratorio_nome: label || "",
      laboratorio_cnpj: e.value || "",
      detailOrder: detailOrder || "",
    };

    this.props.handleChangeProps(dadosLab, "dadosLab");
  }

  handleChange(e) {
    let state = {};
    const name = e.target.getAttribute("name");
    const { value } = e.target;
    this.props.handleChangeProps(e.target);
    //valida o formato do numero do pedido laboratorio
    //pedido otica pode ser qualquer coisa, então não tem validação
    if (e.target.getAttribute("name") === "pedido_laboratorio") {
      if (!Number(value) && value !== "") {
        return;
      }
    }
    //seta o numero do pedido no state
    state[name] = value ? value : "";
    this.setState({ status: value }, () => {
      if (value === "PENDENTE") {
        this.setState({ statusMessage: 'O resultado da busca com status "Em aberto" contempla as parcelas pendentes de pagamento, incluindo as já vencidas.' });
      } else if (value === "BAIXADO") {
        this.setState({ statusMessage: 'O resultado da busca com status "Pago" contempla as parcelas dos últimos 120 dias.' });
      } else if (value === "AVENCER") {
        this.setState({ statusMessage: 'O resultado da busca com status "A vencer" contempla as parcelas vencendo em 15 dias.' });
      }
    });
    this.setState(state);
    this.borderColor(name, "#B5B5B5");
  }

  handleChange_data_inicio = date => {
    //trata o formato da data
    const diaInicio = date ? Moment(date).format("YYYY-MM-DD") : "";
    this.setState({
      //seta data para integração
      data_inicio: diaInicio,
      //seta data para exibir no input date
      dia_inicio: date,
    });
  };

  handleChange_data_fim = date => {
    //trata o formato da data
    const diaFim = date ? Moment(date).format("YYYY-MM-DD") : "";
    this.setState({
      //seta data para integração
      data_fim: diaFim,
      //seta data para exibir no input date
      dia_fim: date,
    });
  };

  changeState() {
    this.borderColor(["lab", "cnpj_number"], "#B5B5B5");
    this.borderColor(["data_inicio", "data_fim", "pedido_otica"], "#FFF");
    this.setState({
      loading: true,
      data_tabela: [],
      resposta_tabela: false,
    });

    let valido = this.validarCampos();
    if (valido) {
      this.context.showLoading("Buscando...");
      this.integraPedido();
    }
  }

  validarCampos() {
    if (!this.props.laboratorio_dados.laboratorio_nome) {
      this.setState({
        loading: false,
      });
      this.context.hideLoading();
      Swal.fire({
        text: "Selecione um laboratório.",
        title: "Aviso",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      this.borderColor("lab");
      return;
    } else if (this.context.userSession.redeOticas && this.props.redeOticaAux && !this.props.cnpj_number) {
      this.setState({
        loading: false,
      });
      this.context.hideLoading();
      Swal.fire({
        text: "Selecione uma ótica.",
        title: "Aviso",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      this.borderColor("cnpj_number");
      return;
    } else if (this.props.input_cnpj && this.props.redeOticaAux && !this.props.cnpj_number && !this.context.userSession.redeOticas) {
      this.setState({
        loading: false,
      });
      this.context.hideLoading();
      Swal.fire({
        text: "O campo CNPJ é obrigatório.",
        title: "Aviso",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      this.borderColor("cnpj_number");
      return;
    } else if (this.state.status != "PENDENTE" && this.state.status != "BAIXADO" && this.state.status != "AVENCER") {
      this.setState({
        loading: false,
      });
      this.context.hideLoading();
      Swal.fire({
        text: "Preencha o status da Parcela.",
        title: "Aviso",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      this.borderColor("status");
      return;
    }

    if (this.state.data_inicio === "" || this.state.data_fim === "") {
      this.setState({
        loading: false,
      });
      this.context.hideLoading();
      Swal.fire({
        text: " Por favor, informe um período válido para o seu pedido.",
        title: "Aviso",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      this.borderColor(["data_inicio", "data_fim"]);
      return;
    } else if (this.state.data_inicio > this.state.data_fim) {
      this.setState({
        loading: false,
      });
      this.context.hideLoading();
      Swal.fire({
        text: "Período da busca inválido: Data inicial maior que data final.",
        title: "Aviso",
        icon: "warning",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      });
      this.borderColor(["data_inicio", "data_fim"]);
      return;
    } else {
      let ini = new Date(this.state.data_inicio);
      let fim = new Date(this.state.data_fim);
      let diff = (fim.getTime() - ini.getTime()) / 1000 / 3600 / 24;
      if (diff > 60) {
        this.setState({
          loading: false,
        });
        this.context.hideLoading();
        Swal.fire({
          text: "O período de busca não pode ser superior a 60 dias.",
          title: "Aviso",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
        this.borderColor(["data_inicio", "data_fim"]);
        return;
      }
    }
    return true;
  }

  integraPedido() {
    var status = this.state.status !== "AVENCER" ? this.state.status : "PENDENTE";
    var table = [];
    const requestOptions = {
      method: "GET",
      headers: {
        access_token: localStorage.getItem("token"),
        client_id: localStorage.getItem("clientid"),
        laboratorio: this.props.laboratorio_dados.laboratorio_cnpj,
        otica: this.props.cnpj_number.replace(/[^0-9]+/g, ""),
      },
    };
    fetch(process.env.REACT_APP_ESSILOR_API + `gestao-financeira-cliente-laboratorio/v1/faturas?status=${status}`, requestOptions)
      .then(response => {
        return response.json();
      })
      .then(response => {
        let data = [];
        let today = new Date();
        let next15Days = new Date();
        next15Days.setDate(next15Days.getDate() + 15);

        if (this.state.status !== "AVENCER") {
          response.forEach(fatura => {
            let dataVencimento = new Date(fatura.dataVencimento);
            var statusFatura = fatura.status;
            if (dataVencimento >= today && dataVencimento <= next15Days) {
              statusFatura = "AVENCER";
            }
            data.push({
              codigo: fatura.codigo,
              parcela: fatura.parcela.substring(0, 2) + "/" + fatura.parcela.substring(2),
              nDocumento: fatura.numeroDocumento,
              dataEmissao: fatura.dataEmissao,
              dataVencimento: fatura.dataVencimento,
              valor: fatura.valor,
              valorAberto: fatura.valorAberto,
              status: statusFatura,
            });
          });
          data.sort((a, b) => {
            if (Moment(a.dataVencimento) < Moment(b.dataVencimento)) return -1;
            if (Moment(a.dataVencimento) > Moment(b.dataVencimento)) return 1;
            return 0;
          });
        } else {
          response.forEach(fatura => {
            let dataVencimento = new Date(fatura.dataVencimento);
            var statusFatura = fatura.status;
            if (dataVencimento >= today && dataVencimento <= next15Days) {
              statusFatura = "AVENCER";
              data.push({
                codigo: fatura.codigo,
                parcela: fatura.parcela.substring(0, 2) + "/" + fatura.parcela.substring(2),
                nDocumento: fatura.numeroDocumento,
                dataEmissao: fatura.dataEmissao,
                dataVencimento: fatura.dataVencimento,
                valor: fatura.valor,
                valorAberto: fatura.valorAberto,
                status: fatura.status,
              });
            }
            table.push({
              codigo: fatura.codigo,
              parcela: fatura.parcela.substring(0, 2) + "/" + fatura.parcela.substring(2),
              nDocumento: fatura.numeroDocumento,
              dataEmissao: fatura.dataEmissao,
              dataVencimento: fatura.dataVencimento,
              valor: fatura.valor,
              valorAberto: fatura.valorAberto,
              status: statusFatura,
            });
          });
          data.sort((a, b) => {
            if (Moment(a.dataVencimento) < Moment(b.dataVencimento)) return -1;
            if (Moment(a.dataVencimento) > Moment(b.dataVencimento)) return 1;
            return 0;
          });
        }
        this.setState({
          data_tabela: data,
          table: table,
          resposta_tabela: true,
          loading: false,
        });
        this.context.hideLoading();
      })
      .catch(() => {
        this.setState({
          data_tabela: [],
          resposta_tabela: false,
          loading: false,
        });
        this.context.hideLoading();
        //TK-083-000169 - Adicionado a mensagem abaixo em caso de falha na integração.
        Swal.fire({
          text: "Falha de comunicação com o laboratório. Tente novamente mais tarde.",
          title: "Aviso",
          icon: "error",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        });
      });
  }

  render() {
    const { redirectL } = this.state;

    if (this.state.redirect) {
      return <Redirect to="/home" />;
    }

    if (redirectL) {
      return <Redirect to="/" />;
    }

    let itensStatus = [
      { label: "EM ABERTO", value: "PENDENTE", key: "PENDENTE" },
      { label: "PAGO", value: "BAIXADO", key: "BAIXADO" },
      { label: "AVENCER", value: "AVENCER", key: "AVENCER" },
    ];
    const containerSelectInput = this.props.redeOticaAux === true ? "col-md-5" : "col-md-5 mx-auto";
    const containerSelectInpiutCenter = this.props.redeOticaAux === true ? "col-md-5" : "col-md-5 mx-auto";
    return (
      <>
        <div className="row d-flex justify-content-center ">
          <div className={`col-xs-6 ${containerSelectInput}`}>
            <TrackingLabChoose
              onChange={this.setLabData}
              lab={this.props.laboratorio}
              labs={this.props.laboratorios}
              handleChangeProps={this.props.handleChangeProps}
            />
          </div>
          {this.props.input_cnpj && this.props.redeOticaAux && (
            <div className={`col-xs-6 ${containerSelectInput}`}>
              <TrackingInputCNPJ
                name="cnpj_number"
                value={this.props.cnpj_number}
                onChange={this.handleChange}
                disabled={!this.props.campoCnpjLiberado}
                redes={this.props.redes}
              />
            </div>
          )}
        </div>

        <div className="row mt-4 d-flex justify-content-center ">
          <div className={`col-7 mx-auto`}>
            <label className="financeiro-label-status">Status da Parcela *</label>
            <div className="financeiro-container-status-radios" id="status">
              <RadioButton
                label="A vencer"
                name="status"
                onChange={event => this.handleChange(event)}
                id="aVencer"
                value="AVENCER"
                checked={this.state.status == "AVENCER" ? true : false}
              />
              <RadioButton
                label="Em aberto"
                name="status"
                onChange={event => this.handleChange(event)}
                id="pendente"
                value="PENDENTE"
                checked={this.state.status == "PENDENTE" ? true : false}
              />
              <RadioButton
                label="Pago"
                name="status"
                onChange={event => this.handleChange(event)}
                id="pago"
                value="BAIXADO"
                checked={this.state.status == "BAIXADO" ? true : false}
              />
            </div>
          </div>
          <div className={`col-7 mx-auto tooltipFixoColuna `}>
            {this.state.statusMessage && (
              <div className="tooltipDivFinanceiro">
                <div className="tooltipFixo">
                  <spam>i</spam>
                </div>
                <p>{this.state.statusMessage}</p>
              </div>
            )}
          </div>
        </div>

        <div className="row">
          <TrackingSearchButton onClick={this.changeState} />
        </div>

        <section ref={ref => (this.myRef = ref)} className="mt-4">
          {this.state.resposta_tabela &&
            (this.state.data_tabela.length > 0 ? (
              <TrackingTableHead
                laboratorio_nome={this.props.laboratorio_dados.laboratorio_nome}
                laboratorio_cnpj={this.props.laboratorio_dados.laboratorio_cnpj}
                detailOrder={this.props.laboratorio_dados.detailOrder}
                otica_cnpj={
                  this.props.cnpj_number ? this.props.cnpj_number.replace(".", "").replace(".", "").replace("-", "").replace("/", "") : this.props.cnpj_number
                }
                usuario_logado={this.context.userSession.conta}
                data_tabela={this.state.data_tabela}
                status={this.state.status}
                table={this.state.table}
              />
            ) : (
              <div className="noReponse">
                <p className="tracking-filters-label text-center">Nenhum registro encontrado.</p>
              </div>
            ))}
        </section>
      </>
    );
  }
}

export default BuscaStatus;
