import React, { Component } from "react";

//Import CSS
import "./table.css";

//Import internal components
import TrackingTableLineDetail from "./tracking-table-line-details";

//Import external components
import Moment from "moment";

//Import images

class TrackingTableLine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetails: false,
      status: "",
      arrow: "",
    };
    this.toggleDetail = this.toggleDetail.bind(this);
  }

  componentDidMount() {
    const status = this.props.item.statusAtual;
    if (status.match(/faturado/gi)) {
      this.setState({
        status: "is--green",
      });
    } else if (status.match(/cancelado/gi)) {
      this.setState({
        status: "is--red",
      });
    } else {
      this.setState({
        status: "is--blue",
      });
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.offset !== this.props.offset) {
      this.setState({ showDetails: false, arrow: "" });
    }
  }
  toggleDetail(e) {
    this.setState({ [e.target.id]: e.target.value }, () => {
      if (this.state.arrow) {
        this.setState({
          showDetails: !this.state.showDetails,
          arrow: "",
        });
      } else {
        this.setState({
          showDetails: !this.state.showDetails,
          arrow: "inverse",
        });
      }
    });
  }

  render() {
    return (
      <tbody className="tracking-main-table-body" key={this.props.index}>
        <tr data-result={this.props.index} onClick={this.toggleDetail}>
          <td>{this.props.item.id}</td>
          <td className="hide-table-column hide-table-column-tablet">
            {this.props.item.idPedidoOtica !== "" && typeof this.props.item.idPedidoOtica !== "undefined" ? this.props.item.idPedidoOtica : "-"}
          </td>
          <td>{this.props.item.dataEntrada && Moment(this.props.item.dataEntrada).format("DD/MM/YYYY")}</td>
          <td className="hide-table-column">{this.props.item.dataEntregaPrevista && Moment(this.props.item.dataEntregaPrevista).format("DD/MM/YYYY")}</td>
          <td className="hide-table-column">
            <div className="tracking-main-table-status">
              <span className={`tracking-main-table-status-button ${this.state.status}`}>{this.props.item.statusAtual}</span>
              <span className={`tracking-main-table-status-arrow ${this.state.arrow}`}></span>
            </div>
          </td>
        </tr>
        <tr className="hide-table-row" data-result={this.props.index} onClick={this.toggleDetail}>
          <td className="status-row" colspan="2">
            <div className="tracking-main-table-status">
              <span className={`tracking-main-table-status-button ${this.state.status}`}>{this.props.item.statusAtual}</span>
              <span className={`tracking-main-table-status-arrow ${this.state.arrow}`}></span>
            </div>
          </td>
        </tr>
        <tr className="tracking-main-table-row-details">
          {this.state.showDetails && (
            <td colspan="5" className={`tracking-main-table-row-details-td ${!this.state.showDetails}`} data-details={this.props.index}>
              <TrackingTableLineDetail
                laboratorio_cnpj={this.props.laboratorio_cnpj}
                detailOrder={this.props.detailOrder}
                otica_cnpj={this.props.otica_cnpj}
                pedido_laboratotio={this.props.item.id}
                pedido_otica={this.props.item.idPedidoOtica}
                nome_consumidor={this.props.item.consumidor ? this.props.item.consumidor.nome : ""}
                produto_direito={this.props.item.produto ? this.props.item.produto.olhoDireito : ""}
                produto_esquerdo={this.props.item.produto ? this.props.item.produto.olhoEsquerdo : ""}
                data_prevista={this.props.item.dataEntregaPrevista && Moment(this.props.item.dataEntregaPrevista).format("DD/MM/YYYY")}
                data_faturamento={this.props.item.dataFaturamento && Moment(this.props.item.dataFaturamento).format("DD/MM/YYYY")}
                nota_fiscal={this.props.item.notaFiscal ? this.props.item.notaFiscal.numero : ""}
                laboratorio_nome={this.props.laboratorio_nome}
                tipoPedido={this.props.item.tipoPedido ? this.props.item.tipoPedido : ""}
              />
            </td>
          )}
        </tr>
      </tbody>
    );
  }
}

export default TrackingTableLine;
