import React, { Component } from "react";

//Import CSS
// import 'bootstrap/dist/css/bootstrap.min.css'

//Import internal components
import OrderSummaryDetail from "./1_order_detail";
import OrderSummaryLens from "./2_order_lens";
import OrderSummaryPerscription from "./3_order_perscription";
import OrderSummaryFrame from "./4_order_frame";

//Import external components
import Accordion from "react-bootstrap/Accordion";
import Card from "react-bootstrap/Card";

//Import images
import pedidoslogo from "../../img/pedidos-logo.jpg";

class OrderSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: "",
      arrow: "",
      stage: this.props.order.stage,
    };

    this.openSummary = this.openSummary.bind(this);
  }

  componentWillUpdate = async nextProps => {
    const { order } = this.props;
    if (nextProps.order.stage !== order.stage) {
      await this.setState({ stage: nextProps.order.stage });
    }
  };

  openSummary(e) {
    this.setState({ [e.target.id]: e.target.value }, () => {
      if (this.state.open) {
        this.setState({ open: "", arrow: "" });
      } else {
        this.setState({ open: "show-summary", arrow: "active-arrow" });
      }
    });
  }

  render() {
    const { order } = this.props;
    const that = this;

    function click(e, item) {
      e.preventDefault();
      e.target.classList.toggle("active");
      if (e.target.classList.contains("active")) {
        that.setState({ stage: e.target.dataset.stage });
      } else {
        that.setState({ stage: 0 });
      }
    }

    function CustomToggle(props) {
      return (
        <li className="x-web-ordering-pedido__summary--item">
          <a
            href="/#"
            className={`x-web-ordering-pedido__summary--link js--menu-pedido ${parseInt(that.state.stage) === parseInt(props.eventKey) ? "active" : ""}`}
            data-stage={props.eventKey}
            onClick={click}>
            {props.children}
          </a>
        </li>
      );
    }

    return (
      <div className={`x-web-ordering-pedido__summary ${this.state.open}`}>
        <span className={`x-web-ordering-pedido__summary--arrow ${this.state.arrow}`} onClick={this.openSummary}></span>

        <div className="x-web-ordering-pedido__summary--container">
          <div className="x-web-ordering-pedido__summary--intro">
            <img src="https://ec2.beta.essilorconecta.com.br/modulos/pedidos.png" width="45%" className="x-web-ordering-pedido__summary--icon" alt="Logo Pedidos" />
            <div className="row">
              <div className="col-12">
                <h1 className="x-web-ordering-pedido__summary--title">Resumo do pedido</h1>
                <p className="x-web-ordering-pedido__summary--paragraph">
                  <b>Laboratório: </b>
                  {order.labName}
                </p>
              </div>
              <div className="logolab col-12">
                <img ref="imagem" src={order.labLogo} width="178" height="120" />
              </div>
            </div>
            <br />
            <p className="x-web-ordering-pedido__summary--paragraph mt-3">
              <b>Dúvidas? Entre em contato com o laboratório:</b>
              {order.labTelefone && (
                <>
                  <br />
                  Telefone: {order.labTelefone}
                </>
              )}
              {order.labEmail && (
                <>
                  <br />
                  Email: {order.labEmail}
                </>
              )}
              {order.labSite && (
                <>
                  <br />
                  Site: {order.labSite}
                </>
              )}
            </p>
          </div>
          <ul className="x-web-ordering-pedido__summary--body" style={{ paddingLeft: 0 }}>
            <Accordion activeKey={this.state.stage.toString()}>
              <Card
                style={{ backgroundColor: "rgba(243, 243, 245, 1)", borderLeft: 0, borderRight: 0, borderBottom: "1px solid", borderBottomColor: "#D8D8DA" }}>
                <Card.Header as={Card.Header} eventKey="1" style={{ borderBottom: 0, backgroundColor: "rgba(243, 243, 245, 1)" }}>
                  <CustomToggle eventKey="1">Dados do pedido</CustomToggle>
                </Card.Header>

                <Accordion.Collapse eventKey="1">
                  <OrderSummaryDetail order={this.props.order} />
                </Accordion.Collapse>
              </Card>

              <Card
                style={{ backgroundColor: "rgba(243, 243, 245, 1)", borderLeft: 0, borderRight: 0, borderBottom: "1px solid", borderBottomColor: "#D8D8DA" }}>
                <Card.Header as={Card.Header} eventKey="2" style={{ borderBottom: 0, backgroundColor: "rgba(243, 243, 245, 1)" }}>
                  <CustomToggle eventKey="2">Especificações da lente</CustomToggle>
                </Card.Header>

                <Accordion.Collapse eventKey="2">
                  <OrderSummaryLens order={this.props.order} />
                </Accordion.Collapse>
              </Card>

              <Card
                style={{ backgroundColor: "rgba(243, 243, 245, 1)", borderLeft: 0, borderRight: 0, borderBottom: "1px solid", borderBottomColor: "#D8D8DA" }}>
                <Card.Header as={Card.Header} eventKey="3" style={{ borderBottom: 0, backgroundColor: "rgba(243, 243, 245, 1)" }}>
                  <CustomToggle eventKey="3">Prescrição</CustomToggle>
                </Card.Header>

                <Accordion.Collapse eventKey="3">
                  <OrderSummaryPerscription order={this.props.order} />
                </Accordion.Collapse>
              </Card>

              <Card style={{ backgroundColor: "rgba(243, 243, 245, 1)", borderLeft: 0, borderBottom: 0, borderRight: 0 }}>
                <Card.Header as={Card.Header} eventKey="4" style={{ borderBottom: 0, backgroundColor: "rgba(243, 243, 245, 1)" }}>
                  <CustomToggle eventKey="4">Dados da Armação</CustomToggle>
                </Card.Header>

                <Accordion.Collapse eventKey="4">
                  <OrderSummaryFrame order={this.props.order} />
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </ul>
        </div>
      </div>
    );
  }
}

export default OrderSummary;
