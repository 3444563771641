import React, { Component } from 'react'

//Import CSS

//Import internal components
import Input from '../inputs/input'
import Password from '../inputs/password'
import RadioButton from '../inputs/radio-button'
import Switch from '../inputs/switch'
import TextArea from '../inputs/text-area'
import CheckBox from '../inputs/check-box'

//Import external components

//Import images

const input = `<Input
    label='Nome'
    type='text'
    name='name'
    id='name'
    autocomplete='off'
    defaultValue='Nome'
    required={false}
    width='500px'
    aria='texto'
    readOnly={true}
    onChange={this.onChange}
    onClick={this.onClick}
    onFocus={this.onFocus}
/>`

const text_area = `<TextArea
    label='Text Area'
    width='300px'
    autoComplete='off'
    defaultValue='text-area'
    name='text-area-name'
    id='text-area-id'
    aria='text-area'
    required={false}
    readOnly={false}
    disabled={false}
	tooltip='text-area'
    onkeypress={this.onkeypress}
    onblur={this.onblur}
    onChange={this.onChange}
    onClick={this.onClick}
    onFocus={this.onFocus}
/>`

const input_password = `<Password
    label='Senha'
    name='senha_teste'
    id='senha_teste'
    aria='aria'
    placeholder='placeholder'
    width='200px'
    required={false}
    readOnly={false}
    disabled={false}
    onkeypress={this.props.onkeypress}
    onblur={this.props.onblur}
    onChange={this.props.onChange}
    onClick={this.props.onClick}
    onFocus={this.props.onFocus}
    onMouseLeave={this.props.onMouseLeave}
    onMouseOut={this.props.onMouseOut}
/>`

const radio_button = `<RadioButton
	label='Radio Button'
	width='200%'
	name='radio-button'
	id='radio-button'
	value='radio-button'
	onChange={this.onChange}
	checked={this.checked}
/>
`

const check_box = `<CheckBox
    label='Check Box'
    width='300px'
    name='check-box'
    id='check-box'
    value='check-box'
    onClick={this.onClick}
    onChange={this.onChange}
    checked={this.checked}
/>
`

const switch_custom = `<Switch
    label='Switch'
    tooltip='Switch'
    id='switch'
    name='switch'
    onClick={this.props.onClick}
    onChange={this.props.onChange}
    checked={this.props.value}
    disabled={false}
/>
`

class DocInputs extends Component {

	constructor(props) {

		super(props)
		this.state = {
			input,
			text_area,
			input_password,
			check_box,
			radio_button,
			switch_custom
		}

		this.handleChangeShow = this.handleChangeShow.bind(this)
	}

	handleChangeShow(evt) {
		if (!this.state[evt.target.id]) {
			this.setState({ [evt.target.id]: true })
		}
		else {
			this.setState({ [evt.target.id]: false })
		}
	}

	render() {

		return (
			<div>

				<h3 className='conecta-h3'>Input</h3>

				<p className='doc-paragraph'>
					Input field generic component, can be used as many types as described below on props details.
					Can be used as a masked input.
				</p>

				<p className='doc-info-box' id='input_code' onClick={this.handleChangeShow}>Code</p>
				{this.state.input_code &&
					<pre>{this.state.input}</pre>
				}

				<br />

				<p className='doc-info-box' id='input_props' onClick={this.handleChangeShow}>Props</p>
				{this.state.input_props &&
					<div>
						<table>
							<thead>
								<th>Prop</th>
								<th>Type</th>
								<th>Default</th>
								<th>Description</th>
							</thead>
							<tbody>
								<tr> <td>label</td> <td>string</td> <td></td> <td></td> </tr>
								<tr> <td>type</td> <td>text, number, date, datetime-local, email, tel, url</td> <td></td> <td></td> </tr>
								<tr> <td>width</td> <td></td> <td>100%</td> <td>px, rem, %, etc.</td> </tr>
								<tr> <td>name</td> <td></td> <td></td> <td></td> </tr>
								<tr> <td>id</td> <td></td> <td>name</td> <td>If no 'id' is selected, 'id' will be the same as the 'name' property.</td> </tr>
								<tr> <td>value</td> <td></td> <td></td> <td></td> </tr>
								<tr> <td>autoComplete</td> <td>true, false</td> <td>false</td> <td></td> </tr>
								<tr> <td>placeholder</td> <td>string</td> <td></td> <td></td> </tr>
								<tr> <td>className</td> <td>string</td> <td></td> <td></td> </tr>
								<tr> <td>required</td> <td>true, false</td> <td>false</td> <td></td> </tr>
								<tr> <td>readOnly</td> <td>true, false</td> <td>false</td> <td></td> </tr>
								<tr> <td>disabled</td> <td>true, false</td> <td>false</td> <td></td> </tr>
								<tr> <td>mask</td> <td>array</td> <td></td> <td>Write the field mask as needed. i.e.: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]</td> </tr>
								<tr> <td>pattern</td> <td></td> <td></td> <td></td> </tr>
								<tr> <td>title</td> <td></td> <td></td> <td></td> </tr>
								<tr> <td>tooltip</td> <td>string</td> <td></td> <td>Write the tooltip text on this prop when needed.</td> </tr>
								<tr> <td>minlength</td> <td></td> <td></td> <td>Minimum number of caracters that can be written on the field.</td> </tr>
								<tr> <td>maxlength</td> <td></td> <td></td> <td>Maximum number of caracters that can be written on the field.</td> </tr>
								<tr> <td>min</td> <td>number</td> <td></td> <td>Only for input type number. Defines the lowest value accepted.</td> </tr>
								<tr> <td>max</td> <td>number</td> <td></td> <td>Only for input type number. Defines the highest value accepted.</td> </tr>
								<tr> <td>step</td> <td>number</td> <td></td> <td>Only for input type number. Defines the number interval.</td> </tr>
								<tr> <td>onClick</td> <td></td> <td></td> <td></td> </tr>
								<tr> <td>onFocus</td> <td></td> <td></td> <td></td> </tr>
								<tr> <td>onBlur</td> <td></td> <td></td> <td>Only works without mask.</td> </tr>
								<tr> <td>onChange</td> <td></td> <td></td> <td></td> </tr>
								<tr> <td>onKeypress</td> <td></td> <td></td> <td></td> </tr>
								<tr> <td>onMouseLeave</td> <td></td> <td></td> <td></td> </tr>
								<tr> <td>onMouseOut</td> <td></td> <td></td> <td></td> </tr>
								<tr> <td>onInvalid</td> <td></td> <td></td> <td></td> </tr>
								<tr> <td>onInput</td> <td></td> <td></td> <td></td> </tr>
								<tr> <td>onPaste</td> <td></td> <td></td> <td></td> </tr>
							</tbody>
						</table>
					</div>
				}

				<br />

				<p className='doc-info-box' id='input_variations' onClick={this.handleChangeShow}>Example</p>
				{this.state.input_variations &&
					<div>
						<div>
							<Input
								label='Texto'
								type='text'
								name='id1'
								id='id1'
								aria='teste'
								autocomplete='off'
								defaultValue=''
								required={false}
								width='25%'
								onChange={this.onChange}
								onClick={this.onClick}
								onFocus={this.onFocus}
								tooltip='Teste'
							/>

							<Input
								label='Texto'
								type='text'
								name='id2'
								id='id2'
								autocomplete='off'
								defaultValue=''
								required={false}
								width='25%'
								onChange={this.onChange}
								onClick={this.onClick}
								onFocus={this.onFocus}
							/>
						</div>

						<div>

							<Input
								label='Número'
								type='number'
								name='id3'
								id='id3'
								autocomplete='off'
								defaultValue=''
								required={false}
								width='25%'
								onChange={this.onChange}
								onClick={this.onClick}
								onFocus={this.onFocus}
								tooltip='Teste'
							/>

							<Input
								label='Número'
								type='number'
								name='id4'
								id='id4'
								autocomplete='off'
								defaultValue=''
								required={false}
								width='25%'
								onChange={this.onChange}
								onClick={this.onClick}
								onFocus={this.onFocus}
							/>
						</div>

						<div>
							<Input
								label='Data'
								type='date'
								name='id5'
								id='id5'
								defaultValue=''
								required={false}
								width='25%'
								onChange={this.onChange}
								onClick={this.onClick}
								onFocus={this.onFocus}
								tooltip='Teste'
							/>

							<Input
								label='Data'
								type='date'
								name='id6'
								id='id6'
								defaultValue=''
								required={false}
								width='25%'
								onChange={this.onChange}
								onClick={this.onClick}
								onFocus={this.onFocus}
							/>
						</div>

						<div>
							<Input
								label='Data tempo'
								type='datetime-local'
								name='id7'
								id='id7'
								defaultValue=''
								required={false}
								width='25%'
								onChange={this.onChange}
								onClick={this.onClick}
								onFocus={this.onFocus}
								tooltip='Teste'
							/>

							<Input
								label='Data tempo'
								type='datetime-local'
								name='id8'
								id='id8'
								defaultValue=''
								required={false}
								width='25%'
								onChange={this.onChange}
								onClick={this.onClick}
								onFocus={this.onFocus}
							/>
						</div>

						<div>
							<Input
								label='E-mail'
								type='email'
								name='id9'
								id='id9'
								autocomplete='off'
								defaultValue=''
								required={false}
								width='25%'
								onChange={this.onChange}
								onClick={this.onClick}
								onFocus={this.onFocus}
								tooltip='Teste'
							/>

							<Input
								label='E-mail'
								type='email'
								name='id10'
								id='id10'
								autocomplete='off'
								defaultValue=''
								required={false}
								width='25%'
								onChange={this.onChange}
								onClick={this.onClick}
								onFocus={this.onFocus}
							/>
						</div>

						<div>
							<Input
								label='Telefone'
								type='tel'
								name='id11'
								id='id11'
								autocomplete='off'
								defaultValue=''
								required={false}
								width='25%'
								mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
								onChange={this.onChange}
								onClick={this.onClick}
								onFocus={this.onFocus}
								tooltip='Teste'
							/>

							<Input
								label='Telefone'
								type='tel'
								name='id12'
								id='id12'
								autocomplete='off'
								defaultValue=''
								required={false}
								width='25%'
								mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
								onChange={this.onChange}
								onClick={this.onClick}
								onFocus={this.onFocus}
							/>
						</div>

						<div>
							<Input
								label='URL'
								type='url'
								name='id15'
								id='id15'
								autocomplete='off'
								defaultValue=''
								required={false}
								width='25%'
								onChange={this.onChange}
								onClick={this.onClick}
								onFocus={this.onFocus}
								tooltip='Teste'
							/>

							<Input
								label='URL'
								type='url'
								name='id16'
								id='id16'
								autocomplete='off'
								defaultValue=''
								required={false}
								width='25%'
								onChange={this.onChange}
								onClick={this.onClick}
								onFocus={this.onFocus}
							/>
						</div>

					</div>
				}

				<br />
				<br />

				<h3 className='conecta-h3'>Text area</h3>

				<p className='doc-paragraph'>Generic text area component.</p>

				<p className='doc-info-box' id='text_area_code' onClick={this.handleChangeShow}>Code</p>
				{this.state.text_area_code &&
					<pre>{this.state.text_area}</pre>
				}

				<br />

				<p className='doc-info-box' id='text_area_props' onClick={this.handleChangeShow}>Props</p>
				{this.state.text_area_props &&
					<div>
						<table>
							<thead>
								<th>Prop</th>
								<th>Type</th>
								<th>Default</th>
								<th>Description</th>
							</thead>
							<tbody>
								<tr>
									<td>label</td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>width</td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>autoComplete</td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>defaultValue</td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>name</td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>id</td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>aria</td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>required</td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>readOnly</td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>disabled</td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>tooltip</td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>onkeypress</td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>onblur</td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>onChange</td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>onClick</td>
									<td></td>
									<td></td>
									<td></td>
								</tr>
								<tr>
									<td>onFocus</td>
									<td></td>
									<td></td>
									<td></td>
								</tr>

							</tbody>
						</table>
					</div>
				}

				<br />

				<p className='doc-info-box' id='text_area_variations' onClick={this.handleChangeShow}>Example</p>
				{this.state.text_area_variations &&
					<div>
						<TextArea
							label='Text Area'
							width='300px'
							autoComplete='off'
							defaultValue='text-area'
							name='text-area-name'
							id='text-area-id'
							aria='text-area'
							tooltip='text-area'
							required={false}
							readOnly={false}
							disabled={false}
							onkeypress={this.onkeypress}
							onblur={this.onblur}
							onChange={this.onChange}
							onClick={this.onClick}
							onFocus={this.onFocus}
						/>
					</div>
				}

				<br />
				<br />

				<h3 className='conecta-h3'>Password Input</h3>

				<p className='doc-paragraph'>Generic password input field.</p>

				<p className='doc-info-box' id='password_input_code' onClick={this.handleChangeShow}>Code</p>
				{this.state.password_input_code &&
				<pre>{this.state.input_password}</pre>}

				<br />

				<p className='doc-info-box' id='password_input_props' onClick={this.handleChangeShow}>Props</p>
				{this.state.password_input_props &&
				<div>
					<table>
						<thead>
							<th>Prop</th>
							<th>Type</th>
							<th>Default</th>
							<th>Description</th>
						</thead>
						<tbody>
							<tr>
								<td>label</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>name</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>id</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>aria</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>placeholder</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>width</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>required</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>readOnly</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>disabled</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>autocomplete</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>onInvalid</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>onInput</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>onkeypress</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>onblur</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>onChange</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>onClick</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>onFocus</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>onMouseLeave</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>onMouseOut</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
						</tbody>
					</table>
				</div>}

				<br />

				<p className='doc-info-box' id='password_input_variations' onClick={this.handleChangeShow}>Example</p>
				{this.state.password_input_variations &&
				<div>
					<Password
						label='Senha'
						name='senha_teste'
						id='senha_teste'
						aria='aria'
						placeholder='placeholder'
						width='200px'
						required={false}
						readOnly={false}
						disabled={false}
						autocomplete='off'
						onInvalid={this.onInvalid}
						onInput={this.onInput}
						onkeypress={this.onkeypress}
						onblur={this.onblur}
						onChange={this.onChange}
						onClick={this.onClick}
						onFocus={this.onFocus}
						onMouseLeave={this.onMouseLeave}
						onMouseOut={this.onMouseOut}
					/>
				</div>}

				<br />
				<br />

				<h3 className='conecta-h3'>Check Box</h3>

				<p className='doc-paragraph'>Generic check box component.</p>

				<p className='doc-info-box' id='check_box_code' onClick={this.handleChangeShow}>Code</p>
				{this.state.check_box_code &&
				<pre>{this.state.check_box}</pre>}

				<br />

				<p className='doc-info-box' id='check_box_props' onClick={this.handleChangeShow}>Props</p>
				{this.state.check_box_props &&
				<div>
					<table>
						<thead>
							<th>Prop</th>
							<th>Type</th>
							<th>Default</th>
							<th>Description</th>
						</thead>
						<tbody>
							<tr>
								<td>label</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>width</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>name</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>id</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>value</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>onClick</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>onChange</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>checked</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
						</tbody>
					</table>
				</div>
				}

				<br />

				<p className='doc-info-box' id='check_box_variations' onClick={this.handleChangeShow}>Example</p>
				{this.state.check_box_variations &&
				<div>
					<CheckBox
						label='Check Box'
						width='300px'
						name='check-box'
						id='check-box'
						value='check-box'
						onClick={this.onClick}
						onChange={this.onChange}
						checked={this.checked}
					/>
				</div>}

				<br />
				<br />

				<h3 className='conecta-h3'>Radio Button</h3>

				<p className='doc-paragraph'>Generic rodio button component.</p>

				<p className='doc-info-box' id='radio_button_code' onClick={this.handleChangeShow}>Code</p>
				{this.state.radio_button_code && <pre>{this.state.radio_button}</pre>}

				<br />

				<p className='doc-info-box' id='radio_button_props' onClick={this.handleChangeShow}>Props</p>
				{this.state.radio_button_props &&
				<div>
					<table>
						<thead>
							<th>Prop</th>
							<th>Type</th>
							<th>Default</th>
							<th>Description</th>
						</thead>
						<tbody>
							<tr>
								<td>label</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>width</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>name</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>id</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>value</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>onChange</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>checked</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
						</tbody>
					</table>
				</div>
				}

				<br />

				<p className='doc-info-box' id='radio_button_variations' onClick={this.handleChangeShow}>Example</p>
				{this.state.radio_button_variations &&
				<div>
					<RadioButton
						label='Radio Button'
						width='200%'
						name='radio-button'
						id='radio-button'
						value='radio-button'
						onChange={this.onChange}
						checked={this.checked}
					/>
				</div>}

				<br />
				<br />

				<h3 className='conecta-h3'>Switch</h3>

				<p className='doc-paragraph'>Generic switch component.</p>

				<p className='doc-info-box' id='switch_code' onClick={this.handleChangeShow}>Code</p>
				{this.state.switch_code && <pre>{this.state.switch_custom}</pre>}

				<br />

				<p className='doc-info-box' id='switch_props' onClick={this.handleChangeShow}>Props</p>
				{this.state.switch_props &&
				<div>
					<table>
						<thead>
							<th>Prop</th>
							<th>Type</th>
							<th>Default</th>
							<th>Description</th>
						</thead>
						<tbody>
							<tr>
								<td>label</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>tooltip</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>id</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>name</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>onClick</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>onChange</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>checked</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
							<tr>
								<td>disabled</td>
								<td></td>
								<td></td>
								<td></td>
							</tr>
						</tbody>
					</table>
				</div>
				}

				<br />

				<p className='doc-info-box' id='switch_variations' onClick={this.handleChangeShow}>Example</p>
				{this.state.switch_variations &&
					<div>
						<Switch
							label='Switch'
							tooltip='Switch'
							id='switch'
							name='switch'
							onClick={this.props.onClick}
							onChange={this.props.onChange}
							checked={this.props.value}
							disabled={false}
						/>
					</div>
				}

			</div>
		)
	}
}

export default DocInputs
