import React, { Component } from 'react'
import PDFOrderP from './pdf-order-p';

class PDFOrderMedidas extends Component {

    render() {
        const { medidas, hasOlhoDireito, hasOlhoEsquerdo } = this.props;

        return (
            <div className="row">
                <div className="col-12">
                    <h3 className="pdf-subtitle-bold">Medidas do Cliente</h3>
                </div>

                {hasOlhoDireito &&
                    (
                        <div className="col">
                            <h3 className="pdf-subtitle-bold">Direito</h3>
                            {medidas.direito.dnp && <PDFOrderP label='DNP' value={medidas.direito.dnp} />}
                            {medidas.direito.altura && <PDFOrderP label='Altura' value={medidas.direito.altura} />}
                            {medidas.direito.dnp_perto && <PDFOrderP label='DNP de perto' value={medidas.direito.dnp_perto} />}
                            {medidas.direito.cro && <PDFOrderP label='CRO' value={medidas.direito.cro} />}
                            {medidas.direito.vertice && <PDFOrderP label='Distância vértice' value={medidas.direito.vertice} />}
                            {medidas.direito.et && <PDFOrderP label='ET' value={medidas.direito.et} />}
                            {medidas.direito.ct && <PDFOrderP label='CT' value={medidas.direito.ct} />}
                            {medidas.direito.base && <PDFOrderP label='Base' value={medidas.direito.base} />}
                        </div>
                    )
                }

                {
                    hasOlhoEsquerdo &&
                    (
                        <div className='col'>
                            <h3 className="pdf-subtitle-bold">Esquerdo</h3>
                            {medidas.esquerdo.dnp && <PDFOrderP label='DNP' value={medidas.esquerdo.dnp} />}
                            {medidas.esquerdo.altura && <PDFOrderP label='Altura' value={medidas.esquerdo.altura} />}
                            {medidas.esquerdo.dnp_perto && <PDFOrderP label='DNP de perto' value={medidas.esquerdo.dnp_perto} />}
                            {medidas.esquerdo.cro && <PDFOrderP label='CRO' value={medidas.esquerdo.cro} />}
                            {medidas.esquerdo.vertice && <PDFOrderP label='Distância vértice' value={medidas.esquerdo.vertice} />}
							{medidas.esquerdo.et && <PDFOrderP label='ET' value={medidas.esquerdo.et} />}
                            {medidas.esquerdo.ct && <PDFOrderP label='CT' value={medidas.esquerdo.ct} />}
                            {medidas.esquerdo.base && <PDFOrderP label='Base' value={medidas.esquerdo.base} />}
                        </div>
                    )
                }

            </div >
        )
    }
}

export default PDFOrderMedidas