import React, { Component } from "react";

import "./precos-e-servicos-search-input.css";

class PrecosEServicosSearchInput extends Component {
  constructor(props) {
    super();
  }

  render() {
    const { label, name, id, value, onChange } = this.props;

    return (
      <div>
        <label className="x-downloads__search--label" htmlFor="">
          {label}
        </label>
        <div className="x-downloads__search--position-input">
          <input type="text" id={id} name={name} value={value} onChange={onChange} className="x-downloads__search--input" />
        </div>
      </div>
    );
  }
}

export default PrecosEServicosSearchInput;
