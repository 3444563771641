import React, { Component } from 'react'
import './media-center-card.css'
import '../media-center.css'
import MediaCenterModal from '../modal/media-center-modal'
import MediaCenterModalPreview from '../preview/modal-preview'
import AppContext from '../../appContext'
import Moment from 'moment'

class MediaCenterCard extends Component {
  constructor(props) {
    super(props)

    this.state = {
      show: false,
      show_preview: false,
	    user_id: '',
    }

    MediaCenterCard.contextType = AppContext
    this.downloadFile = this.downloadFile.bind(this)
    this.openPreview = this.openPreview.bind(this)
    this.saveLog = this.saveLog.bind(this)
  }

  openPreview() {
    this.setState({ show_preview: true })
  }

  downloadFile() {
    this.saveLog()
    window.open(this.props.url_midia,'_blank')
  }

  setShow = (show) => {
    this.setState({ show: show })
    if (show) {
      this.saveLog()
    }
  }

  setShowPreview = (show_preview) => {
    this.setState({ show_preview: show_preview })
  }

  saveLog() {
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify({
        user_id: this.context.user_id,
        midia_id: this.props.midia_id,
        midia_nome: this.props.nome,
        conta: this.context.userSession.conta,
        documento: this.context.userSession.cpf,
        evento: this.props.tipo,
        data: Moment(new Date()).format('YYYY-MM-DD HH:mm'),
      }),
    }

    fetch(
      process.env.REACT_APP_LABS_API + 'create_log_media.php',
      requestOptions,
    )
      .then((response) => {
        return response.json()
      })
      .then((json) => {
        //console.log(json.status)
      })
  }

  render() {
    const {customizavel} = this.props;

    const handleClose = () => this.setShow(false)
    const handleShow = () => this.setShow(true)

    const handleClosePreview = () => this.setShowPreview(false)
    const handleShowPreview = () => this.setShowPreview(true)

    return (
      <div className="col-12 col-md-4 col-lg-3">
        <div className="media-center-card">
          <div className="media-center-card-field flex-column flex-row--mobile">
            <div className="media-center-card-bg">
              <img
                className="media-center-card-image"
                src={this.props.url_miniatura}
                alt=""
                onClick={handleShowPreview}
              />
              <p className="card-recordname">{this.props.record_name}</p>
            </div>
            <small className="media-center-card-text text display-contents--mobile">
              {this.props.nome}
            </small>
          </div>

          <div className="media-center-card-field">
            <button
              className="media-center-card-button"
              onClick={
                this.props.tipo === 'DOWNLOAD' ? this.downloadFile : handleShow
              }
            >
              <small className="media-center-card-text is-text--lg">
                {this.props.tipo === 'DOWNLOAD'
                  ? 'Baixar arquivo'
                  : 'Compartilhar'}
              </small>

              {this.props.tipo === 'DOWNLOAD' ? (
                <svg
                  className="media-center-card-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 30 30"
                >
                  <g
                    id="Group_90"
                    data-name="Group 90"
                    transform="translate(30) rotate(90)"
                  >
                    <circle
                      id="Ellipse_9"
                      data-name="Ellipse 9"
                      cx="15"
                      cy="15"
                      r="15"
                      fill="#fff"
                    />
                    <path
                      id="np_arrow_2957554_000000"
                      d="M6.479,10.7l4.832-9.431A.876.876,0,0,0,9.993.188L6.246,3.134a.822.822,0,0,1-1.059,0L1.414.188A.877.877,0,0,0,.1,1.273L4.928,10.7a.879.879,0,0,0,1.551,0Z"
                      transform="translate(10.48 21.5) rotate(-90)"
                      fill="#0394db"
                    />
                  </g>
                </svg>
              ) : (
                ''
              )}
            </button>
            {this.props.tipo !== 'DOWNLOAD' && (
              <div>
                <MediaCenterModal
                  nome={this.props.nome}
                  show={this.state.show}
                  image={this.props.url_midia}
                  text={this.props.texto_sugerido}
                  handleClose={handleClose}
                  customizavel={customizavel}
                  use_logo={true}
                />

                <MediaCenterModalPreview
                  nome={this.props.nome}
                  url_miniatura={this.props.url_miniatura}
                  url_preview={this.props.url_preview}
                  previewList={this.props.previewList}
                  show_preview={this.state.show_preview}
                  handleClosePreview={handleClosePreview}
                  use_logo={true}
                />
              </div>
            )}

            {this.props.tipo === 'DOWNLOAD' && (
              <MediaCenterModalPreview
                nome={this.props.nome}
                url_miniatura={this.props.url_miniatura}
                url_preview={this.props.url_preview}
                show_preview={this.state.show_preview}
                previewList={this.props.previewList}
                handleClosePreview={handleClosePreview}
                use_logo={false}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default MediaCenterCard
