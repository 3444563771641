import React, { Component } from "react";

import "./precos-e-servicos-card.css";
import AppContext from "../../appContext";

import { FaFileWord, FaFileExcel, FaFilePowerpoint, FaFilePdf, FaFileArchive, FaFileImage, FaFileAlt, FaFile } from "react-icons/fa";

import Swal from "sweetalert2";

class PrecosEServicosFileCard extends Component {
  constructor(props) {
    super();

    PrecosEServicosFileCard.contextType = AppContext;

    this.state = { extensionImg: "" };
    this.state = { extensionImgValue: "" };

    this.downloadFile = this.downloadFile.bind(this);

  }

  componentDidMount() {
    
  }

  downloadFile() {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        access_token: localStorage.getItem("token"),
        folder: this.props.file.folder,
        file: this.props.file.filename,
      }),
    };

    this.context.showLoading("Carregando arquivo...");

    fetch(process.env.REACT_APP_LABS_API + `download_file_financial.php`, requestOptions)
      .then(res => {
        return res.blob();
      })
      .then(blob => {
        this.context.hideLoading();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = this.props.file.filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(err => {
        this.context.hideLoading();
        Swal.fire({
          icon: "error",
          title: "Erro ao fazer o download do arquivo. Por favor, tente novamente mais tarde.",
          confirmButtonColor: "#00518b",
          customClass: {
            confirmButton: "x-follow-order__filter-btn",
          },
        });
      });
  }

  render() {
    var  filename = this.props.file.filename;
    var  ultimoPonto = filename.lastIndexOf(".");
    var  extensao = filename.substring(ultimoPonto + 1);
    switch (extensao) {
      case "pdf":
        break;
      case "docx":
        break;
      case "doc":
        break;
      case "txt":
        break;
      case "pptx":
        break;
      case "ppt":
        break;
      case "xlsx":
        break;
      case "xls":
        break;
      case "zip":
        break;
      case "rar":
        break;
      case "png":
        break;
      case "jpeg":
        break;
      case "jpg":
        break;
      case "gif":
        break;
      default:
        extensao = "";
        break;
    }
    
    return (
      <div>
        <div className="precos-e-servicos-file-card">
          <div className="precos-e-servicos-file-card-field flex-column flex-row--mobile">
            <div className="precos-e-servicos-file-card-bg">

              {(extensao === "docx" || extensao === "doc") && <FaFileWord size={100} color={"#295394"} />}
              {(extensao === "xlsx" || extensao === "xls") && <FaFileExcel size={100} color={"#2D7930"} />}
              {(extensao === "pdf") && <FaFilePdf size={100} color={"#FA0F00"} />}
              {(extensao === "pptx" || extensao === "ppt") && <FaFilePowerpoint size={100} color={"#CB4424"} />}
              {(extensao === "rar" || extensao === "zip") && <FaFileArchive size={100} color={"#F7BF39"} />}
              {(extensao === "png" || extensao === "jpeg" || extensao === "jpg" || extensao === "gif") && <FaFileImage size={100} color={"#4E8BED"} />}
              {(extensao === "txt") && <FaFileAlt size={100} color={"#101E36"} />}
              {(extensao === "") && <FaFile size={100} color={"#101E36"} />}
            </div>

            <small className="precos-e-servicos-file-card-text text display-contents--mobile">{this.props.file.filename || ""}</small>
          </div>

          <div className="precos-e-servicos-file-card-field">
            <button className="precos-e-servicos-file-card-button" onClick={this.downloadFile}>
              <small className="precos-e-servicos-file-card-text is-text--lg">Baixar arquivo</small>
              <svg className="precos-e-servicos-file-card-svg" xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30">
                <g id="Group_90" data-name="Group 90" transform="translate(30) rotate(90)">
                  <circle id="Ellipse_9" data-name="Ellipse 9" cx="15" cy="15" r="15" fill="#fff" />
                  <path
                    id="np_arrow_2957554_000000"
                    d="M6.479,10.7l4.832-9.431A.876.876,0,0,0,9.993.188L6.246,3.134a.822.822,0,0,1-1.059,0L1.414.188A.877.877,0,0,0,.1,1.273L4.928,10.7a.879.879,0,0,0,1.551,0Z"
                    transform="translate(10.48 21.5) rotate(-90)"
                    fill="#0394db"
                  />
                </g>
              </svg>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default PrecosEServicosFileCard;
