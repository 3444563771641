import React, { Component } from 'react'

//Import CSS

//Import internal components
import CustomButton from '../buttons/button'
import ButtonYN from '../buttons/button-yn'
import ButtonRL from '../buttons/button-rightleft'

//Import external components

//Import images

const button = `<CustomButton
 label='Botão'
 orange_button={false}
 width='200px'
 height='3rem'
 onClick={this.onClick}
 icon_search={true}
 icon_arrow_left={false}
 icon_arrow_right={true}
/>
`

const button_yn = `<ButtonYN
 label='Teste'
 name='buttonyn_teste'
 id_yes='id_yes_teste'
 id_no='id_no_teste'
 onClick={this.onClick}
 defaultChecked_yes={true}
 defaultChecked_no={false}
/>
`

const button_rl = `<ButtonRL
 name='buttonrl_teste'
 id_both='id_both_teste'
 id_right='id_right_teste'
 id_left='id_left_teste'
 onClick={this.onClick}
 defaultChecked_both={true}
 defaultChecked_right={false}
 defaultChecked_left={false}
/>
`

class DocButtons extends Component {

	state = { button, button_yn, button_rl }

	render() {

		return (
			<div>

				<h3 className='conecta-h3'>Button</h3>

				<p className='doc-paragraph'>
					Generic button component.
					It can be blue or orange, with or wihtout icons and it has width and height customizable.
				</p>

				<p className='doc-paragraph'><b>Orange button:</b> true ou false</p>

				<p className='doc-paragraph'><b>Icons:</b> true ou false</p>

				<p className='doc-paragraph'><b>Width e/ou hieght:</b> px, rem, %, etc.</p>

				<p className='doc-paragraph'><b>Code:</b></p>

				<pre>{this.state.button}</pre>

				<br />

				<p className='doc-paragraph'><b>Variations:</b></p>

				<CustomButton
					label='Botão'
					orange_button={false}
					width='300px'
					height='3rem'
					onClick={this.onClick}
					icon_search={false}
					icon_arrow_left={false}
					icon_arrow_right={false}
				/>

				<CustomButton
					label='Botão'
					orange_button={false}
					width='300px'
					height='3rem'
					onClick={this.onClick}
					icon_search={true}
					icon_arrow_left={false}
					icon_arrow_right={true}
				/>

				<CustomButton
					label='Botão'
					orange_button={false}
					width='300px'
					height='3rem'
					onClick={this.onClick}
					icon_search={false}
					icon_arrow_left={true}
					icon_arrow_right={true}
				/>

				<CustomButton
					label='Botão'
					orange_button={false}
					width='300px'
					height='3rem'
					onClick={this.onClick}
					icon_search={false}
					icon_arrow_left={false}
					icon_arrow_right={true}
				/>

				<CustomButton
					label='Botão'
					orange_button={false}
					width='300px'
					height='3rem'
					onClick={this.onClick}
					icon_search={true}
					icon_arrow_left={false}
					icon_arrow_right={false}
				/>

				<CustomButton
					label='Botão'
					orange_button={true}
					width='300px'
					height='3rem'
					onClick={this.onClick}
					icon_search={false}
					icon_arrow_left={false}
					icon_arrow_right={false}
				/>

				<CustomButton
					label='Botão'
					orange_button={true}
					width='300px'
					height='3rem'
					onClick={this.onClick}
					icon_search={true}
					icon_arrow_left={false}
					icon_arrow_right={true}
				/>

				<CustomButton
					label='Botão'
					orange_button={true}
					width='300px'
					height='3rem'
					onClick={this.onClick}
					icon_search={false}
					icon_arrow_left={true}
					icon_arrow_right={true}
				/>

				<CustomButton
					label='Botão'
					orange_button={true}
					width='300px'
					height='3rem'
					onClick={this.onClick}
					icon_search={true}
					icon_arrow_left={false}
					icon_arrow_right={false}
				/>

				<CustomButton
					label='Botão'
					orange_button={true}
					width='300px'
					height='3rem'
					onClick={this.onClick}
					icon_search={false}
					icon_arrow_left={false}
					icon_arrow_right={true}
				/>

				<br />
				<br />

				<h3 className='conecta-h3'>Two options button</h3>

				<p className='doc-paragraph'>Two options generic button component.</p>

				<p className='doc-paragraph'><b>Code:</b></p>

				<pre>{this.state.button_yn}</pre>

				<br />

				<p className='doc-paragraph'><b>Variations:</b></p>

				<ButtonYN
					label='Teste'
					name='buttonyn_teste'
					id_yes='id_yes_teste'
					id_no='id_no_teste'
					onClick={this.onClick}
					defaultChecked_yes={true}
					defaultChecked_no={false}
				/>

				<br />
				<br />

				<h3 className='conecta-h3'>Three options button</h3>

				<p className='doc-paragraph'>Three options genmeric button component</p>

				<p className='doc-paragraph'><b>Code:</b></p>

				<pre>{this.state.button_rl}</pre>

				<br />

				<p className='doc-paragraph'><b>Variations:</b></p>

				<ButtonRL
					name='buttonrl_teste'
					id_both='id_both_teste'
					id_right='id_right_teste'
					id_left='id_left_teste'
					onClick={this.onClick}
					defaultChecked_both={true}
					defaultChecked_right={false}
					defaultChecked_left={false}
				/>

			</div>
		)
	}
}

export default DocButtons
