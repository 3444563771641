import React, { Component } from "react";
import AppContext from "../../appContext";
import Back from "../../main/back";

//Import CSS
import "./modulo-papel.css";
//Import internal components

//Import external components
import Table from "react-bootstrap/Table";
import { CheckAll } from "react-bootstrap-icons";

//Import image

class ModuloPapel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      header: [],
      body: [],
    };

    this.setHeader = this.setHeader.bind(this);
    this.setBody = this.setBody.bind(this);
    this.getTable = this.getTable.bind(this);
    ModuloPapel.contextType = AppContext;
  }

  componentDidMount() {
    this.getTable();
  }

  getTable() {
    this.context.showLoading("Carregando...");
    var session_token = localStorage.getItem("session_token");
    const requestOptions = {
      method: "GET",
    };

    requestOptions.headers = new Headers({
      Authorization: "Bearer " + session_token,
    });

    fetch(process.env.REACT_APP_LABS_API + `get_roles_modules.php?categoria=${this.context.userSession.papelCategoria}`, requestOptions)
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.status === "sucesso") {
          this.setHeader(json.table.header, () => {
            this.setBody(json.table.body);
          });
        }
      });
  }

  setHeader(header, roolback) {
    header.sort(this.compare);
    //remove os itens não desejados
    const arrItensRemover = ["Novo Usuário Óptica"];
    const headerAlterado = header.filter(el => !arrItensRemover.includes(el.nome));
    this.setState(
      {
        header: headerAlterado,
      },
      roolback,
    );
  }

  compare(a, b) {
    if (a.Nivel < b.Nivel) {
      return -1;
    }
    if (a.Nivel > b.Nivel) {
      return 1;
    }
    return 0;
  }

  setBody(bodyAux) {
    let body = [];

    bodyAux.map(el => {
      var row = {
        logo: el.URL_Logo_c,
        isLogo: el.URL_Logo_c ? true : false,
        nome: el.recordName,
      };

      if (el.papeis) {
        var papeisAux = this.state.header;
        papeisAux.map(elem => {
          if (
            el.papeis.some(item => {
              return item.codigo === elem.codigo;
            })
          ) {
            row[elem.Nivel] = true;
          }
        });
        body.push(row);
      }
    });

    body.sort(this.compareBody);

    //remove os itens não desejados
    const arrItensRemover = ["Gestão Laboratórios", "Gestão Papéis", "Relacionamento Digital"];
    const bodyAlterado = body.filter(el => !arrItensRemover.includes(el.nome));
    this.setState({ body: bodyAlterado }, this.context.hideLoading);
  }

  compareBody(a, b) {
    if (a.isLogo !== b.isLogo) {
      return a.isLogo ? -1 : 1;
    } else {
      return a.nome > b.nome ? 1 : -1;
    }
  }

  render() {
    return (
      <main className="container p-5">
        <Back></Back>
        <div className="mt-3">
          <Table hover className="text-center tableFixHead">
            <thead>
              <tr className="table-light">
                <th></th>
                {this.state.header.map(elem => {
                  return <th>{elem.nome}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {this.state.body.map(elem => {
                return (
                  <tr>
                    <td>{elem.logo ? <img src={elem.logo} alt={elem.nome} width="100" /> : elem.nome} </td>
                    {this.state.header.map((item, index, array) => {
                      return <td>{elem[(index + 1).toString()] ? <CheckAll color="green" size={24} /> : ""}</td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </main>
    );
  }
}

export default ModuloPapel;
