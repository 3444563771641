function filterByCriteria(data, criteria) {
    if (criteria.length >= 1) {
      return Object.values(data).filter(item => {
        return criteria.every(criterion => {
          return Object.keys(criterion).every(key => {
            const values = criteria
              .filter(c => c[key] !== undefined)
              .map(c => c[key]);
  
            if (key === "data") {
              return values.some(value => item[key].startsWith(value));
            }

            return values.some(value => item[key].includes(value));
          });
        });
      });
    } else {
      return data;
    }
  }

export { filterByCriteria };
