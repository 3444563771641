
import React, { Component } from 'react'

//Import CSS
import './crm-switch.css'

//Import internal components

//Import external components
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap'

//Import images


class CRMSwitch extends Component {

	constructor(props) {
		super(props)
		this.state = {

		}

	}

	componentDidMount() {

	}

	render() {

		return (
			<Form>
				<div className="crm-switch-container">
					{this.props.obrigatorio && (

						<OverlayTrigger
							placement="bottom"
							delay={{ show: 250, hide: 400 }}
							overlay={<Tooltip id="button-tooltip">Essa jornada não pode ser desativada</Tooltip>}
						>
							<div className="crm-switch-obrigatorio">
								<Form.Check
									label=""
									type="switch"
									id={this.props.id}
									name={this.props.name}
									onClick={this.props.onClick}
									onChange={this.props.onChange}
									checked={this.props.value}
									disabled={this.props.disabled ? this.props.disabled : false}
								/>
							</div>
						</OverlayTrigger>
					)}

					{!this.props.obrigatorio && (
						<div className="crm-switch">
							<Form.Check
								label=""
								type="switch"
								id={this.props.id}
								name={this.props.name}
								onClick={this.props.onClick}
								onChange={this.props.onChange}
								checked={this.props.value}
								disabled={this.props.disabled ? this.props.disabled : false}
							/>
						</div>
					)}

					<div className="">
						{this.props.label_bold && <p className="crm-switch-lable-bold">{this.props.label_bold}</p>}
						{this.props.label && <p className="crm-switch-lable">{this.props.label}</p>}
					</div>

				</div>
			</Form>
		)
	}
}

export default CRMSwitch