import React, { Component } from "react";

//Import CSS
import "./media-upload.css";

//Import internal components
import AppContext from "../appContext";

//Import external components
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ImageUploader from "react-images-upload";
import Swal from "sweetalert2";

//Import images

class MediaUploadPreview extends Component {
  constructor(props) {
    super(props);

    this.state = {
      preview_1: "",
      preview_2: "",
      preview_3: "",
      preview_4: "",
      preview_5: "",
      img_name: "",
      pictures: [],
      hide: true,
    };

    MediaUploadPreview.contextType = AppContext;
    this.onDrop_1 = this.onDrop_1.bind(this);
    this.onDrop_2 = this.onDrop_2.bind(this);
    this.onDrop_3 = this.onDrop_3.bind(this);
    this.onDrop_4 = this.onDrop_4.bind(this);
    this.onDrop_5 = this.onDrop_5.bind(this);
    this.remove_1 = this.remove_1.bind(this);
    this.remove_2 = this.remove_2.bind(this);
    this.remove_3 = this.remove_3.bind(this);
    this.remove_4 = this.remove_4.bind(this);
    this.remove_5 = this.remove_5.bind(this);
    this.uploadLogo = this.uploadLogo.bind(this);
    this.addMore = this.addMore.bind(this);
    this.removeMidia = this.removeMidia.bind(this);
  }

  componentDidMount() {
    // this.obterLogo(this.props.recordname)
  }

  onDrop_1(pictureFiles, pictureDataURLs) {
    this.setState(
      {
        img_name: this.props.recordname,
        id: "-1",
        pictures: pictureFiles,
        preview_1: pictureDataURLs,
      },
      () => {
        if (!this.state.preview_2 && !this.state.preview_3 && !this.state.preview_4 && !this.state.preview_5) {
          this.removeMidia(this.props.recordname);
        }

        this.uploadLogo(process.env.REACT_APP_LABS_API + "upload_media_img.php", this.state.pictures[0]);
      },
    );
  }

  onDrop_2(pictureFiles, pictureDataURLs) {
    this.setState(
      {
        img_name: this.props.recordname,
        id: "-2",
        pictures: pictureFiles,
        preview_2: pictureDataURLs,
      },
      () => {
        this.uploadLogo(process.env.REACT_APP_LABS_API + "upload_media_img.php", this.state.pictures[0]);
      },
    );
  }

  onDrop_3(pictureFiles, pictureDataURLs) {
    this.setState(
      {
        img_name: this.props.recordname,
        id: "-3",
        pictures: pictureFiles,
        preview_3: pictureDataURLs,
      },
      () => {
        this.uploadLogo(process.env.REACT_APP_LABS_API + "upload_media_img.php", this.state.pictures[0]);
      },
    );
  }

  onDrop_4(pictureFiles, pictureDataURLs) {
    this.setState(
      {
        img_name: this.props.recordname,
        id: "-4",
        pictures: pictureFiles,
        preview_4: pictureDataURLs,
      },
      () => {
        this.uploadLogo(process.env.REACT_APP_LABS_API + "upload_media_img.php", this.state.pictures[0]);
      },
    );
  }

  onDrop_5(pictureFiles, pictureDataURLs) {
    this.setState(
      {
        img_name: this.props.recordname,
        id: "-5",
        pictures: pictureFiles,
        preview_5: pictureDataURLs,
      },
      () => {
        this.uploadLogo(process.env.REACT_APP_LABS_API + "upload_media_img.php", this.state.pictures[0]);
      },
    );
  }

  remove_1() {
    this.setState(
      {
        pictures: [],
        preview_1: "",
      },
      this.removeMidia(this.props.recordname, this.props.recordname + "-1"),
    );
  }

  remove_2() {
    this.setState(
      {
        pictures: [],
        preview_2: "",
      },
      this.removeMidia(this.props.recordname, this.props.recordname + "-2"),
    );
  }

  remove_3() {
    this.setState(
      {
        pictures: [],
        preview_3: "",
      },
      this.removeMidia(this.props.recordname, this.props.recordname + "-3"),
    );
  }

  remove_4() {
    this.setState(
      {
        pictures: [],
        preview_4: "",
      },
      this.removeMidia(this.props.recordname, this.props.recordname + "-4"),
    );
  }

  remove_5() {
    this.setState(
      {
        pictures: [],
        preview_5: "",
      },
      this.removeMidia(this.props.recordname, this.props.recordname + "-5"),
    );
  }

  removeMidia(folder, arquivo = "") {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        folder: folder,
        arquivo: arquivo,
        tipo: "preview",
      }),
    };

    fetch(process.env.REACT_APP_LABS_API + "remove_preview.php", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (!this.state.preview_1 && !this.state.preview_2 && !this.state.preview_3 && !this.state.preview_4 && !this.state.preview_5) {
          this.props.onChangePreview(false);
        }
        this.context.hideLoading();
      });
  }

  uploadLogo(url, file) {
    if (this.props.recordname !== undefined) {
      this.context.showLoading();
      let data = new FormData();
      let pasta = "preview/" + this.state.img_name;
      const imageUploadName = [this.state.img_name, pasta.toString(), this.state.id];
      data.append(imageUploadName, file);

      fetch(url, {
        method: "POST",
        body: data,
      })
        .then(response => {
          return response.json();
        })

        .then(json => {
          if (json.success) {
            this.sendURL();
            Swal.fire({
              text: "Preview da mídia carregada com sucesso!",
              icon: "success",
              title: "Pronto!",
              allowOutsideClick: false,
              showCancelButton: false,
              confirmButtonColor: "#00518b",
              confirmButtonText: "Ok!",
            });
            this.context.hideLoading();
          } else {
            Swal.fire({
              text: "Não foi possível atualizar a imagem, tente novamente ou entre em contato com o administrador.",
              icon: "warning",
              title: "Ops! Algo deu errado!",
              allowOutsideClick: false,
              showCancelButton: false,
              confirmButtonColor: "#00518b",
              confirmButtonText: "Ok!",
            });
            this.context.hideLoading();
          }
        });
    } else {
      this.setState({
        pictures: [],
        preview_1: "",
        preview_2: "",
        preview_3: "",
        preview_4: "",
        preview_5: "",
      });
      Swal.fire({
        text: "Preencha o campo com o código da mídia.",
        icon: "warning",
        title: "Ops! Algo deu errado!",
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonColor: "#00518b",
        confirmButtonText: "Ok!",
      });
    }
  }

  addMore() {
    if (this.state.media2 == undefined) {
      this.setState({
        media2: true,
      });
    }
    if (this.state.media2 == true && this.state.media3 == undefined) {
      this.setState({
        media3: true,
      });
    }
    if (this.state.media2 == true && this.state.media3 == true && this.state.media4 == undefined) {
      this.setState({
        media4: true,
      });
    }
    if (this.state.media2 == true && this.state.media3 == true && this.state.media4 == true && this.state.media5 == undefined) {
      this.setState({
        media5: true,
        hide: false,
      });
    }
  }

  sendURL() {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        record_name: this.state.img_name,
        url: process.env.REACT_APP_LABS_ROOT,
        field: "URLImagemPreview_c",
        folder: "preview/" + this.state.img_name,
        isPreview: true,
      }),
    };

    fetch(process.env.REACT_APP_LABS_API + "update_img_url.php", requestOptions)
      .then(response => {
        return response.text();
      })
      .then(json => {
        if (
          this.state.preview_1 !== "" ||
          this.state.preview_2 !== "" ||
          this.state.preview_3 !== "" ||
          this.state.preview_4 !== "" ||
          this.state.preview_5 !== ""
        ) {
          this.props.onChangePreview(true);
        }
      });
  }

  obterLogo(img_name) {
    fetch(process.env.REACT_APP_LABS_API + `get_media_img.php?img_name=${img_name}`)
      .then(response => response.json())

      .then(json => {
        if (json.success) {
          this.setState({
            preview: "data:image/png;base64," + `${json.base}`,
          });
        } else {
          this.setState({
            pictures: [],
            preview: "",
          });
        }
      });
  }

  render() {
    return (
      <div className="media-upload-img-container">
        <div className="media-upload-input">
          {!this.state.preview_1 && (
            <ImageUploader
              withIcon={false}
              buttonText="Carregar preview 1"
              onChange={this.onDrop_1}
              imgExtension={[".jpg", ".gif", ".png", ".gif"]}
              maxFileSize={5242880}
              withPreview={true}
              withLabel={false}
              className="media-upload-box"
              buttonClassName="btn-upload"
            />
          )}

          {this.state.preview_1 && (
            <div className="picturesWrapper">
              <div className="pictureContent">
                <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">Remover preview 1</Tooltip>}>
                  <div className="media-upload-removeFile" onClick={this.remove_1}>
                    X
                  </div>
                </OverlayTrigger>

                <div className="pictureContainer">
                  <img className="pictureFile" src={this.state.preview_1} alt="" />
                </div>
              </div>
            </div>
          )}
        </div>

        {this.state.media2 && (
          <div className="media-upload-input">
            {!this.state.preview_2 && (
              <ImageUploader
                withIcon={false}
                buttonText="Carregar preview 2"
                onChange={this.onDrop_2}
                imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                maxFileSize={5242880}
                withPreview={true}
                withLabel={false}
                className="media-upload-box"
                buttonClassName="btn-upload"
              />
            )}

            {this.state.preview_2 && (
              <div className="picturesWrapper">
                <div className="pictureContent">
                  <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">Remover preview 2</Tooltip>}>
                    <div className="media-upload-removeFile" onClick={this.remove_2}>
                      X
                    </div>
                  </OverlayTrigger>

                  <div className="pictureContainer">
                    <img className="pictureFile" src={this.state.preview_2} alt="" />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {this.state.media3 && (
          <div className="media-upload-input">
            {!this.state.preview_3 && (
              <ImageUploader
                withIcon={false}
                buttonText="Carregar preview 3"
                onChange={this.onDrop_3}
                imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                maxFileSize={5242880}
                withPreview={true}
                withLabel={false}
                className="media-upload-box"
                buttonClassName="btn-upload"
              />
            )}

            {this.state.preview_3 && (
              <div className="picturesWrapper">
                <div className="pictureContent">
                  <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">Remover preview 3</Tooltip>}>
                    <div className="media-upload-removeFile" onClick={this.remove_3}>
                      X
                    </div>
                  </OverlayTrigger>

                  <div className="pictureContainer">
                    <img className="pictureFile" src={this.state.preview_3} alt="" />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {this.state.media4 && (
          <div className="media-upload-input">
            {!this.state.preview_4 && (
              <ImageUploader
                withIcon={false}
                buttonText="Carregar preview 4"
                onChange={this.onDrop_4}
                imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                maxFileSize={5242880}
                withPreview={true}
                withLabel={false}
                className="media-upload-box"
                buttonClassName="btn-upload"
              />
            )}

            {this.state.preview_4 && (
              <div className="picturesWrapper">
                <div className="pictureContent">
                  <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">Remover preview 4</Tooltip>}>
                    <div className="media-upload-removeFile" onClick={this.remove_4}>
                      X
                    </div>
                  </OverlayTrigger>

                  <div className="pictureContainer">
                    <img className="pictureFile" src={this.state.preview_4} alt="" />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {this.state.media5 && (
          <div className="media-upload-input">
            {!this.state.preview_5 && (
              <ImageUploader
                withIcon={false}
                buttonText="Carregar preview 5"
                onChange={this.onDrop_5}
                imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                maxFileSize={5242880}
                withPreview={true}
                withLabel={false}
                className="media-upload-box"
                buttonClassName="btn-upload"
              />
            )}

            {this.state.preview_5 && (
              <div className="picturesWrapper">
                <div className="pictureContent">
                  <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">Remover preview 5</Tooltip>}>
                    <div className="media-upload-removeFile" onClick={this.remove_5}>
                      X
                    </div>
                  </OverlayTrigger>

                  <div className="pictureContainer">
                    <img className="pictureFile" src={this.state.preview_5} alt="" />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        {this.state.hide && (
          <p className="media-upload-add" onClick={this.addMore}>
            +
          </p>
        )}
      </div>
    );
  }
}

export default MediaUploadPreview;
