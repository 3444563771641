import React, { Component } from "react";
import "./index.css";

class CustomSelect extends Component {
  state = {
    personName: [],
    isDropdownOpen: false,
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = event => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ isDropdownOpen: false });
    }
  };

  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  handleToggleDropdown = () => {
    this.setState(prevState => ({ isDropdownOpen: !prevState.isDropdownOpen }));
  };

  handleChange = option => {
    this.setState(
      prevState => {
        const isSelected = prevState.personName.some(item => item.name === option.name && item.year === option.year);
        const personName = isSelected
          ? prevState.personName.filter(item => !(item.name === option.name && item.year === option.year))
          : [...prevState.personName, option];
        return { personName };
      },
      () => {
        const newData = this.state.personName.map(option => {
          const mes = option.mesNumber < 10 ? `0${option.mesNumber}` : option.mesNumber;
          return `${option.year}-${mes}`;
        });
        var data = newData;
        this.props.filter(data, this.props.label);
      },
    );
  };

  handleClear = () => {
    this.setState({ personName: [] });
  };

  render() {
    const { personName, isDropdownOpen } = this.state;
    const optionsToUse = this.props.options
      ? this.props.options
      : [
          { name: "Mai", year: 2022 },
          { name: "Abril", year: 2022 },
        ];
    let header = '';
    return (
      <div className="custom-select-container" ref={this.setWrapperRef}>
        {personName.length >= 1 && (<p className="p-custom-select-container">{this.props.label}</p>)}
        <div className="select-box"   onClick={this.handleToggleDropdown}>
        {personName.length == 0 &&(<p className="p-select-box">{this.props.label}</p>)}
          <div className="chips-container">
            {personName.map(value => (
              <div key={`${value.name}-${value.year}`} className="chip">
                {value.name} - {value.year}
              </div>
            ))}
          </div>
          <div className="dropdown-arrow"></div>
        </div>
        {isDropdownOpen && (
          <div className="dropdown-menu-select">
            {optionsToUse.length > 0 &&
              optionsToUse.map(option => {
                const isSelected = personName.some(item => item.name === option.name && item.year === option.year);
                if (header == option.year) {
                  return (
                    <div
                      key={`${option.name}-${option.year}`}
                      className={`dropdown-item ${isSelected ? "selected" : ""}`}
                      onClick={() => this.handleChange(option)}>
                      {option.name}
                    </div>
                  );
                } else {
                  header = option.year;
                  return (
                    <div key={option.year} className="group">
                      <div className="group-header">{header}</div>
                      <div
                        key={`${option.name}-${option.year}`}
                        className={`dropdown-item ${isSelected ? "selected" : ""}`}
                        onClick={() => this.handleChange(option)}>
                        {option.name}
                      </div>
                    </div>
                  );
                }
              })}
          </div>
        )}
      </div>
    );
  }
}

export default CustomSelect;
