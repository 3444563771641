import React, { Component } from "react";

class ResultCard extends Component {
  constructor(props) {
    super(props);
  }

  formatCNPJ(cnpj) {
    return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  }

  formatDate(date) {
    const dateSplit = date.split("-");
    return `${dateSplit[2]}/${dateSplit[1]}/${dateSplit[0]}`;
  }

  render() {
    return (
      <div className="result-card-main">
        <table className="table table-sm table-striped">
          <thead>
            <tr>
              <th scope="col" colSpan="2">
                Resultado
              </th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td className="td-label">cupom:</td>
			  <td className="td-value" style={{ fontWeight: "bold" }}>{this.props.data.id}</td>
            </tr>
            <tr>
              <td className="td-label">laboratorio:</td>
              <td className="td-value">{this.formatCNPJ(this.props.data.laboratorio)}</td>
            </tr>
            <tr>
              <td className="td-label">otica:</td>
              <td className="td-value">{this.formatCNPJ(this.props.data.otica)}</td>
            </tr>
            <tr>
              <td className="td-label">nome campanha:</td>
			  <td className="td-value" style={{ fontWeight: "bold" }}>{this.props.data.campanha.nome}</td>
            </tr>
            <tr>
              <td className="td-label">data de criação:</td>
              <td className="td-value">{this.formatDate(this.props.data.dataCriacao)}</td>
            </tr>
            <tr>
              <td className="td-label">status cupom:</td>
              <td className="td-value">{`${this.props.data.status.codigo} - ${this.props.data.status.descricao}`}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
}

export default ResultCard;
