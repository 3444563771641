import React, { Component } from 'react'
import especialista from './img/carousel/banner_especialista.png'
import vantagens from './img/carousel/banner_vantagens.png'
import vxdobro from './img/carousel/banner_home_260x225_vx_dobro.jpg'

class Carousel extends Component {


  	render(){

    	return (
            <section className='login-bottom'>
                <h1 className='login-information__title'>
                    <strong className='login-information__title-accent'>
                        Fique ainda </strong>mais conectado
                </h1>
                <div className="block">
                    <div className='banner banner_1'>
                        <a href="https://especialista.essilorconecta.com.br/login?type=cnpj">
                            <img alt="" src={especialista} alt="" />
                        </a>
                    </div>

                    <div className='banner banner_2'>
                        <a href="https://vantagens.essilorconecta.com.br/">
                            <img alt="" src={vantagens} alt="" />
                        </a>
                    </div>

{/* Aguardando nova imagem */}
                    {/* <div className='banner banner_3'>
                        <a href="https://proessilor.com.br/opticas/promocoes/">
                            <img alt="" src={vxdobro} alt="" />
                        </a>
                    </div> */}
                </div>
                {/* <div className='login-information'>
                    <div className='login-information__container'>

                        <h1 className='login-information__title'><strong className='login-information__title-accent'>Fique
                                ainda</strong> mais conectado</h1>

                        <div className='login-slider__card'>

                            <div className='login-slider__card-container slick-promo'>
                                <a href="https://especialista.essilorconecta.com.br/login?type=cnpj">
                                    <img src={especialista} />
                                </a>
                            </div>

                            <div className='login-slider__card-container slick-promo'>
                                <a href="https://vantagens.essilorconecta.com.br/">
                                    <img src={vantagens} />
                                </a>
                            </div>

                            <div className='login-slider__card-container slick-promo'>
                                <a href="https://proessilor.com.br/opticas/promocoes/">
                                    <img src={vxdobro} />
                                </a>
                            </div> */}

                            {/* <div className='login-slider__card-container slick-promo'></div>

                            <div className='login-slider__card-container slick-glasses'>
                                <h5 className='login-slider__card-title'>Lorem ipsum</h5>
                                <p className='login-slider__card-paragraph'>Lorem ipsum dolor sit amet, consetetur sadipscing
                                    elitr, sed diam nonumy eirmod tempor invidunt ut labore et</p>
                                <a href='#' className='login-slider__card-button is--orange'>Saiba mais</a>
                            </div>

                            <div className='login-slider__card-container slick-promo'></div>

                            <div className='login-slider__card-container slick-glasses'>
                                <h5 className='login-slider__card-title'>Lorem ipsum</h5>
                                <p className='login-slider__card-paragraph'>Lorem ipsum dolor sit amet, consetetur sadipscing
                                    elitr, sed diam nonumy eirmod tempor invidunt ut labore et</p>
                                <a href='#' className='login-slider__card-button is--orange'>Saiba mais</a>
                            </div>

                            <div className='login-slider__card-container slick-promo'></div>

                            <div className='login-slider__card-container slick-glasses'>
                                <h5 className='login-slider__card-title'>Lorem ipsum</h5>
                                <p className='login-slider__card-paragraph'>Lorem ipsum dolor sit amet, consetetur sadipscing
                                    elitr, sed diam nonumy eirmod tempor invidunt ut labore et</p>
                                <a href='#' className='login-slider__card-button is--orange'>Saiba mais</a>
                            </div>

                            <div className='login-slider__card-container slick-promo'></div>

                            <div className='login-slider__card-container slick-glasses'>
                                <h5 className='login-slider__card-title'>Lorem ipsum</h5>
                                <p className='login-slider__card-paragraph'>Lorem ipsum dolor sit amet, consetetur sadipscing
                                    elitr, sed diam nonumy eirmod tempor invidunt ut labore et</p>
                                <a href='#' className='login-slider__card-button is--orange'>Saiba mais</a>
                            </div>

                            <div className='login-slider__card-container slick-promo'></div> */}

                        {/* </div>
                    </div>
                </div> */}
            </section>
    	);
  	}
}

export default Carousel;