import React, { Component } from 'react'
import './faq.css'
import Header from '../main/header/header'
import Questions from './questions'
import Footer from '../main/footer'
// import Back from '../main/back'
import guideGP from './files/Guide_Gestao_Papeis.pdf'
import guiaPedidos from './files/Guide_Conecta_Pedidos.pdf';
import guiaTracking from './files/Guide_Conecta_Tracking.pdf';

class Faq extends Component {

    constructor(props) {
        super(props)

        this.state = {
            faq: [
                {
                    session: "Conhecendo Essilor Conecta",
                    position: 1,
                    questions: [
                        {
                            question: `O que é o Essilor Conecta?`,
                            answer: `O <strong>Essilor Conecta</strong> é o portal que concentra todos os serviços e ferramentas da <strong>Essilor</strong> para o mercado óptico. Com um único acesso você poderá acessar diferentes ferramentas de maneira simples e eficiente.`,
                            position: 1
                        },
                        {
                            question: `Quem pode se cadastrar no Essilor Conecta?`,
                            answer: `Podem se cadastrar no <strong>Essilor Conecta</strong> todos os profissionais do ramo óptico que atualmente trabalham para uma empresa cliente da <strong>Essilor</strong> e seus laboratórios, como proprietários, gerentes e vendedores de loja.`,
                            position: 2
                        },
                        {
                            question: `A quais sistemas posso me conectar dentro do Essilor Conecta?`,
                            answer: `O portfólio de serviços do <strong>Essilor Conecta</strong> não para de crescer. No momento estão disponíveis para usuários cadastrados no <strong>Essilor Conecta o Essilor Promo, Conecta Vantagens, Varilux Especialista, Conecta Pedidos, Conecta Tracking, Marketing Center</strong>, entre outros.`,
                            position: 3
                        },
                    ]
                },
                {
                    session: "Acesso ao Essilor Conecta",
                    position: 2,
                    questions: [
                        {
                            question: `Esqueci minha senha de acesso. Como recuperá-la?`,
                            answer: `Na página de login, você poderá recuperar sua senha clicando no link <strong>Esqueci a senha</strong>. Basta entrar com seu email cadastrado, e enviaremos a você um link para redefinir sua senha.`,
                            position: 1
                        },
                        {
                            question: `O sistema diz que meu email não está cadastrado no Essilor Conecta, mas eu sei que fiz o cadastro. O que devo fazer?`,
                            answer: `É possível que você tenha feito o cadastro com outro email no <strong>Essilor Conecta</strong>. Para verificação, informe seu CPF ao proprietário/gerente da loja onde você trabalha. Caso você seja o proprietário/gerente da ótica, informe seu CPF ao Atendimento Essilor. 
                            Esta verificação poderá ser feita pelo proprietário/gerente de ótica ou Atendimento Essilor acessando o painel <strong>Gestão de Papéis</strong> no menu superior <strong>Minha Conta</strong>, onde é possível visualizar todos os funcionários da loja cadastrados no <strong>Essilor Conecta.</strong>`,
                            position: 2
                        },
                        {
                            question: `Posso alterar meu email de acesso?`,
                            answer: `Não. O email é sua forma de acesso à plataforma Essilor Conecta, e é único, não podendo ser alterado.`,
                            position: 3
                        },
                        {
                            question: `Me cadastrei no Essilor Conecta, mas não tenho acesso aos módulos. O que devo fazer?`,
                            answer: `Sempre que um usuário ingressa em uma ótica dentro do <strong>Essilor Conecta</strong>, ele entra no sistema com o perfil <strong>Novo Usuário</strong>, que tem o acesso restrito a alguns módulos. 
                            Somente o proprietário/gerente da loja poderá gerenciar os funcionários de sua empresa. Para ter acesso aos módulos, você deverá solicitar ao proprietário/gerente da sua empresa para alterar seu perfil de acesso através da opção <strong>Gestão de Papéis</strong>.
                            Caso você seja o proprietário da ótica, poderá solicitar a alteração do seu perfil de acesso ao <strong>Atendimento Essilor</strong>.`,
                            position: 4
                        },
                        {
                            question: `Sou um proprietário/gerente de ótica e gostaria de gerenciar os perfis dos meus funcionários dentro do Conecta. Como posso fazer isso?`,
                            answer: `Como proprietário/gerente de uma ótica, você poderá gerenciar todos os funcionários da sua loja indo na seção <strong>Gestão de Papéis</strong>, acessível no menu superior <strong>Minha Conta</strong> (ícone do avatar).
                            Dentro do <strong>Gestão de Papéis</strong> você poderá alterar o perfil de acesso dos seus funcionários (dando-lhes acesso aos módulos dentro do Essilor Conecta) e desvinculá-los da sua empresa, caso tenham sido desligados, clicando no botão <strong>Desvincular da Empresa</strong>.
                            Caso ainda tenha alguma dúvida, baixe nosso <strong> 
                            <a
                            type="button"
                            href=${guideGP}
                            download
                            target="_blank" rel="noopener noreferrer"
                            >
                            Guia da Gestão de Papéis
                            </a>
                            </strong>`,
                            position: 5
                        },
                        {
                            question: `Fui admitido em outra ótica. Como posso me desvincular da ótica anterior?`,
                            answer: `O proprietário ou gerente da ótica anterior, através da opção <strong>Gestão de Papéis</strong>, poderá desvinculá-lo da loja. Neste caso, da próxima vez que você entrar no <strong>Essilor Conecta</strong>, precisará informar o CNPJ da nova loja onde trabalha, e posteriormente o proprietário ou gerente da nova loja precisará alterar seu perfil, para que tenha acesso aos módulos do <strong>Essilor Conecta</strong>.`,
                            position: 6
                        },
                        {
                            question: `Estou vinculado a mais de uma empresa. Como posso adicionar mais de um CNPJ ao meu usuário?`,
                            answer: `Para vincular outras empresas ao seu usuário, basta clicar no menu superior <strong>Minha Conta</strong> (ícone do avatar) e ir na opção <strong>Meu Perfil</strong>.
                            Dentro de <strong>Meu Perfil</strong>, clique na opção <strong>Empresas vinculadas</strong> no menu lateral (ou role a página para baixo, até chegar nesta seção), informe o novo <strong>CNPJ</strong> e clique no botão <strong>Adicionar Empresa</strong>.
                            Por uma questão de segurança, um usuário sempre entra na empresa com o perfil <strong>Novo Usuário</strong>. Desta forma, na loja recém adicionada, você terá acesso limitado aos módulos disponíveis no Essilor Conecta, até que o proprietário/gerente da loja mude seu perfil de acesso através do <strong>Gestão de Papéis.</strong>`,
                            position: 7
                        },
                    ]
                },
                {
                    session: "Conecta Pedidos",
                    position: 3,
                    questions: [
                        {
                            question: `Como faço para realizar um pedido?`,
                            answer: `Basta clicar no módulo <strong>Conecta Pedidos</strong> e escolher o laboratório de sua preferência. A partir daí, é só seguir as etapas e preencher os campos do formulário de pedido. Ao final, você será apresentado a uma tela onde estará disponível o resumo do seu pedido.
                            É importante que você revise atentamente os dados do pedido, pois uma vez concluído, o pedido será enviado para produção e não será possível cancelá-lo dentro do <strong>Essilor Conecta.</strong>
                            Caso ainda tenha alguma dúvida, baixe nosso <strong>
                            <a
                            type="button"
                            href=${guiaPedidos}
                            download
                            target="_blank" rel="noopener noreferrer"
                            >
                            Guia do Conecta Pedidos
                            </a>
                            </strong>.`,
                            position: 1
                        },
                        {
                            question: `Como posso adicionar um novo laboratório?`,
                            answer: `Para poder comprar de um laboratório Essilor via Conecta Pedidos, é necessário realizar o cadastro prévio diretamente com o laboratório desejado, onde é feito o processo de abertura de crédito junto ao mesmo. Para conferir a lista de laboratórios participantes, clique aqui.`,
                            position: 2
                        },
                        {
                            question: `Como posso configurar laboratórios preferenciais para minha ótica?`,
                            answer: `O sistema do Essilor Conecta reconhece todos os laboratórios com os quais uma ótica tem relacionamento. Entretanto, uma ótica pode configurar um ou mais laboratórios de sua preferência.
                            Quando esses laboratórios são configurados, eles ganham destaque na página de escolha do laboratório, dentro do Conecta Pedidos. Ainda assim, todos os laboratórios podem ser visualizados clicando-se no botão “+” ao lado dos laboratórios preferenciais que aparecem.
                            Para configurar um ou mais laboratórios de preferência, basta clicar no menu superior <strong>Minha Conta</strong> (ícone do avatar) e ir na opção <strong>Minha Ótica</strong>. Ao entrar na página de edição da ótica, clique na opção <strong>Laboratórios de Preferência</strong> no menu lateral (ou role a página para baixo, até chegar nesta seção), escolha seus laboratórios preferenciais e clique em <strong>Salvar alterações</strong> ao final da página.`,
                            position: 3
                        },
                        {
                            question: `Como faço para alterar um pedido?`,
                            answer: `Ao término da digitação do pedido, você terá uma tela de revisão onde terá a oportunidade de analisar todos os itens de um pedido. Uma vez que confirme, o pedido é transmitido diretamente para o laboratório e o processo produtivo é iniciado.
                            Portanto, não é possível realizar alterações em um pedido depois de confirmá-lo.`,
                            position: 4
                        },
                        {
                            question: `Como posso cancelar um pedido?`,
                            answer: `Para cancelar um pedido, é necessário entrar em contato diretamente com o laboratório o mais rapidamente possível. Normalmente o pedido entra em processo produtivo logo após a transmissão, portanto o cancelamento pode acarretar em custos a serem confirmados pelo laboratório.
                            Para localizar o telefone de contato do laboratório, 
                            <a 
                                href=${process.env.REACT_APP_GA_ID === "dev" ? "http://dev.static.essilorlabs.com.br/labs" :
                                    process.env.REACT_APP_GA_ID === "homol" ? "http://homolog.static.essilorlabs.com.br/labs" :
                                        process.env.REACT_APP_GA_ID === "prod" ? "https://beta.essilorconecta.com.br/labs" : "http://dev.static.essilorlabs.com.br/labs"
                                } target="_blank" rel="noopener noreferrer">
                                Clique Aqui
                                </a></strong>.`,
                            position: 5
                        },
                        {
                            question: `Como posso passar um pedido para várias óticas do grupo?`,
                            answer: `Se sua ótica estiver cadastrada dentro do laboratório em um grupo de óticas, o campo <strong>Empresa de Faturamento</strong>, na primeira etapa do formulário de pedido do <strong>Conecta Pedidos</strong>, estará aberto para que você possa escolher para qual ótica quer fazer o pedido.
                            Caso essa opção não esteja disponível, entre em contato com o laboratório. A configuração de óticas em grupo pode estar com alguma pendência para a sua ótica.
                            Para localizar o telefone de contato do laboratório, 
                            <a 
                                href=${process.env.REACT_APP_GA_ID === "dev" ? "http://dev.static.essilorlabs.com.br/labs" :
                                    process.env.REACT_APP_GA_ID === "homol" ? "http://homolog.static.essilorlabs.com.br/labs" :
                                        process.env.REACT_APP_GA_ID === "prod" ? "https://beta.essilorconecta.com.br/labs" : "http://dev.static.essilorlabs.com.br/labs"
                                } target="_blank" rel="noopener noreferrer">
                                Clique Aqui
                                </a></strong>.`,

                            position: 6
                        },
                        {
                            question: `Como faço para colocar um pedido com cupom promocional?`,
                            answer: `Para passar um pedido que contenha uma promoção <strong>Essilor</strong>, será necessário primeiramente criar um cupom no site <strong> <a href=https://www.essilorpromo.com.br>Essilor Promo</a> </strong>. Depois de criado, basta copiar o código do cupom e inseri-lo no campo <strong>Cupom Promocional</strong>, na primeira etapa do pedido no <strong>Conecta Pedidos</strong>.`,
                            position: 7
                        },
                        {
                            question: `Estou tendo problemas de “cupom inválido”. O que devo fazer?`,
                            answer: `É possível que você tenha criado um cupom no <strong>Essilor Promo</strong> para ser utilizado em um laboratório, mas tenha clicado em um laboratório diferente no momento da escolha no <strong>Conecta Pedidos</strong>.
                            Se você verificou que o cupom é de fato aplicável no laboratório escolhido, observe se você está passando o pedido para a ótica certa. É possível, por exemplo, que você tenha criado um cupom para uma ótica, mas esteja fazendo um pedido para outra ótica do grupo.`,
                            position: 8
                        },
                        {
                            question: `Como sei o prazo de entrega do meu pedido?`,
                            answer: `Ao final do preenchimento do formulário de pedidos do <strong>Conecta Pedidos</strong>, você será apresentado a uma tela de validação, onde será informado o prazo para a entrega do seu pedido. Você pode acompanhar o status do seu pedido através do <strong>Conecta Tracking</strong>.`,
                            position: 9
                        },
                        {
                            question: `Como posso fazer pedidos para um laboratório que não está disponível para mim?`,
                            answer: `Para poder comprar de um laboratório <strong>Essilor</strong> via <strong>Conecta Pedidos</strong>, é necessário realizar o cadastro prévio diretamente com o laboratório desejado, onde é feito o processo de abertura de crédito.
                            Se o laboratório no qual você já possui cadastro não está disponível na tela de seleção de laboratórios, entre em contato com ele para entender o motivo. É possível que haja alguma pendência no seu cadastro.
                            Para localizar o telefone de contato do laboratório, <strong>
                            <a 
                                href=${process.env.REACT_APP_GA_ID === "dev" ? "http://dev.static.essilorlabs.com.br/labs" :
                                    process.env.REACT_APP_GA_ID === "homol" ? "http://homolog.static.essilorlabs.com.br/labs" :
                                        process.env.REACT_APP_GA_ID === "prod" ? "https://beta.essilorconecta.com.br/labs" : "http://dev.static.essilorlabs.com.br/labs"
                                } target="_blank" rel="noopener noreferrer">
                                Clique Aqui
                                </a></strong>.`,
                            position: 10
                        },
                    ]
                },
                {
                    session: "Conecta Tracking",
                    position: 4,
                    questions: [
                        {
                            question: `Como faço para acompanhar um pedido?`,
                            answer: `Para acompanhar um pedido, você precisa acessar o módulo <strong>Conecta Tracking</strong> e especificar o laboratório para o qual você realizou este pedido, o número do pedido no laboratório ou número do pedido da ótica (apenas um dos dois campos) e o período em que esse pedido foi realizado.
                            Caso ainda tenha alguma dúvida, baixe nosso <strong>
                            <a
                            type="button"
                            href=${guiaTracking}
                            download
                            target="_blank" rel="noopener noreferrer"
                            >
                            Guia do Conecta Tracking
                            </a>
                            </strong>.`,
                            file: "",
                            position: 1
                        },
                        {
                            question: `Como posso ver mais de um pedido feito para um laboratório?`,
                            answer: `Basta preencher os campos de data no <strong>Conecta Tracking</strong> com o período no qual os pedidos foram realizados, sem preencher o número do pedido no laboratório nem o número do pedido na ótica. O sistema mostrará todos os pedidos feitos para aquele laboratório no período especificado.`,
                            position: 2
                        },
                        {
                            question: `Como sei qual é o número do pedido no laboratório?`,
                            answer: `Ao final do preenchimento do formulário de pedido através do <strong>Conecta Pedidos</strong>, você será apresentado a uma tela de conclusão do pedido, onde constará o número do pedido no laboratório.`,
                            position: 3
                        },
                    ]
                }

            ]
        }

        window.scrollTo({ top: 0 })
    }

    render() {
        return (
            <main className=".x-main" id="main-body">
                <Header />
                <section className="x-faq-intro">
                    <div className="x-faq-intro__container text-center">
                        <h1 className="x-faq-intro__title align-middle">Como podemos ajudar?</h1>
                    </div>
                </section>
                <Questions faq={this.state.faq} />
                <Footer />
            </main>
        );
    }
}

export default Faq;
