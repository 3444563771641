import React, { Component } from "react";

//Import CSS

//Import internal components
import AppContext from "../../appContext";
import HeaderLogo from "./header-logo";
import Logout from "./logout";

//Import external components
import { Link } from "react-router-dom";
import UserMenu from "./user-menu/user-menu";
import OticaMenu from "./otica-menu/otica-menu";
import ReactLoading from "react-loading";

//Import image

class HeaderDesktop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lock: false,
      redirect: false,
      disabled: false,
      modulos: [],
      especialista: "",
      load: true,
      oticaLoad: true,
    };

    HeaderDesktop.contextType = AppContext;
    this.showMenu = this.showMenu.bind(this);
    this.hideMenu = this.hideMenu.bind(this);
    this.compare = this.compare.bind(this);
    this.acessaGestaoPapeis = this.acessaGestaoPapeis.bind(this);
    this.hasMinhaOtica = this.hasMinhaOtica.bind(this);
  }

  isEspecialistaPremiere(modulos) {
    if (this.context.especialistaPremiere === "VARILUX ESPECIALISTA PREMIER" || this.context.especialistaPremiere === "VARILUX ESPECIALISTA PLATINE") {
      return modulos;
    } else if (this.context.userSession.conta === "30260871000105" && this.context.userSession.papel === "Dev") {
      return modulos;
    } else {
      var modulosAux = modulos.filter((item, i) => {
        return item !== "Relacionamento Digital";
      });
      return modulosAux;
    }
  }

  componentDidMount() {
    if (localStorage.getItem("token") != null) {
      var modulos = this.isEspecialistaPremiere(this.context.userSession.modulos);
      // if (process.env.REACT_APP_GA_ID !== "dev") {
      //   modulos = modulos.filter((item, i) => {
      //     return item !== "Financeiro";
      //   });
      // }
      var modulosAux = modulos;
      var especialista = this.context.especialistaPremiere && this.context.especialistaPremiere.includes("VARILUX") ? true : false;

      var mods = [];

      const requestOptions1 = {
        method: "POST",
        body: JSON.stringify({
          modulos: modulosAux,
          especialista: especialista,
        }),
      };

      fetch(process.env.REACT_APP_LABS_API + "get_modules_header.php", requestOptions1)
        .then(response => {
          return response.json();
        })
        .then(json => {
          if (json.items) {
            for (var i = 0; i < json.items.length; i++) {
              var pushObj = {
                nome: json.items[i].RecordName,
                pos: json.items[i].Posicao_c,
                url: json.items[i].URL_c,
                tab: json.items[i].NovaAba_c,
              };

              if (!json.items[i].OticasPilotos_c) {
                mods.push(pushObj);
              } else {
                const contas = json.items[i].OticasPilotos_c.split(";");
                if (contas.some(elem => elem.includes(this.context.userSession.conta))) {
                  mods.push(pushObj);
                }
              }
            }

            mods.sort(this.compare);
            this.setState({ modulos: mods, load: false });
          }
        });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.lista_multi_contas !== null) {
      this.setState({ oticaLoad: false });
    }
  }

  compare(a, b) {
    if (a.pos < b.pos) {
      return -1;
    }
    if (a.pos > b.pos) {
      return 1;
    }
    return 0;
  }

  showMenu(event) {
    event.preventDefault();
    if (!this.state.lock) {
      var btnList = document.getElementsByClassName("header__menu-list")[0];
      btnList.classList.toggle("is--active");
      document.addEventListener("click", this.hideMenu);
      this.setState({ lock: true });
    }
  }

  hideMenu() {
    var btnList = document.getElementsByClassName("header__menu-list")[0];
    if (this.state.lock && btnList) {
      btnList.classList.toggle("is--active");
      document.removeEventListener("click", this.hideMenu);
      this.setState({ lock: false });
    }
  }

  acessaGestaoPapeis() {
    return this.context.userSession.modulos.includes("Gestão Papéis");
  }

  hasMinhaOtica() {
    return this.context.userSession.modulos.includes("Minha Ótica");
  }

  render() {
    if (this.state.redirect) {
      window.location.href = "/";
    }

    return (
      <div className="header__container is--desk">
        <div className="row">
          <div className="col-1">
            <HeaderLogo />
          </div>
          <div className="col-8">
            <ul className="header__items">
              {this.state.modulos.map((item, index) => {
                return (
                  <li key={index} className="header__item">
                    <Link className="header__link" target={item.tab === true ? "_blank" : ""} to={item.url}>
                      {item.nome}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="col-3">
            <div className="row">
              <div className="col-9">
                <div className="float-end otica-menu">
                  {this.state.oticaLoad ? (
                    <ReactLoading type="bubbles" color="#00518b" height={65} width={65} />
                  ) : (
                    <OticaMenu lista_multi_contas={this.props.lista_multi_contas} />
                  )}
                </div>
              </div>
              <div className="col-3">
                <UserMenu hasMinhaOtica={this.hasMinhaOtica} acessaGestaoPapeis={this.acessaGestaoPapeis} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HeaderDesktop;
