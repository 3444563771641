import React, { Component } from "react";
import Card from "react-bootstrap/Card";
import "bootstrap/dist/css/bootstrap.min.css";
import Barcode from "react-barcode";

class OrderSummaryPDF extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { order, parametros } = this.props;
    const that = this;

    function CustomToggle(props) {
      return (
        <li className="pdf-pedido__summary--item">
          <a className={`pdf-pedido__summary--link`}>{props.children}</a>
        </li>
      );
    }

    return (
      <div>
        <div className={`pdf-pedido__summary`}>
          <div className="pdf-pedido__summary--container">
            <div className="pdf-pedido__summary--intro">
              <div className="pdf-pedido__summary--header">
                {/* <img src={pedidoslogo} width="178" height="120" className="pdf-pedido__summary--icon" alt="Logo Pedidos" /> */}
                <div className="pdf-pedido__summary--barcode">
                  <Barcode value={this.props.pedido} height="70" text={"Nº do Pedido: " + this.props.pedido} />
                </div>
              </div>

              {/*removido TK-83-000037 <h1 className="pdf-pedido__summary--title">Resumo do pedido</h1>*/}

              <p className="pdf-pedido__summary--paragraph">
                <b>Laboratório:</b> <br />
                {order.labName}
              </p>
            </div>

            <ul className="pdf-pedido__summary--body">
              <Card style={{ borderLeft: 0, borderRight: 0, borderBottom: "1px solid", borderBottomColor: "#D8D8DA" }}>
                <CustomToggle>Dados do pedido</CustomToggle>

                <Card.Body>
                  <div className="pdf-pedido__summary--group">
                    <div className="pdf-pedido__summary--side-left">
                      {order.promo_code && (
                        <ul className="pdf-pedido__summary--card left">
                          <li className="pdf-pedido__summary--subitem">Cupom Promocional</li>
                          <li className="pdf-pedido__summary--subitem right">{order.promo_code || "-"}</li>
                        </ul>
                      )}

                      <ul className="pdf-pedido__summary--card left">
                        <li className="pdf-pedido__summary--subitem">Nº do pedido da ótica</li>
                        <li className="pdf-pedido__summary--subitem right">{order.dados_num_pedido || "-"}</li>
                      </ul>

                      {order.dados_nome_cliente && (
                        <ul className="pdf-pedido__summary--card left">
                          <li className="pdf-pedido__summary--subitem">Nome do Cliente</li>
                          <li className="pdf-pedido__summary--subitem right">{order.dados_nome_cliente}</li>
                        </ul>
                      )}

                      {order.dados_nome_medico && (
                        <ul className="pdf-pedido__summary--card left">
                          <li className="pdf-pedido__summary--subitem">Nome do Médico</li>
                          <li className="pdf-pedido__summary--subitem right">{order.dados_nome_medico}</li>
                        </ul>
                      )}
                    </div>

                    <div className="pdf-pedido__summary--side-right">
                      {order.dados_promo_nome && (
                        <ul className="pdf-pedido__summary--card right">
                          <li className="pdf-pedido__summary--subitem">Nome da promoção</li>
                          <li className="pdf-pedido__summary--subitem right">{order.dados_promo_nome || "-"}</li>
                        </ul>
                      )}

                      <ul className="pdf-pedido__summary--card right">
                        <li className="pdf-pedido__summary--subitem">Empresa de faturamento</li>
                        <li className="pdf-pedido__summary--subitem right">{order.dados_empresa_faturamento || "-"}</li>
                      </ul>

                      {order.dados_cpf_cliente && (
                        <ul className="pdf-pedido__summary--card right">
                          <li className="pdf-pedido__summary--subitem">CPF do Cliente</li>
                          <li className="pdf-pedido__summary--subitem right">{order.dados_cpf_cliente_mask}</li>
                        </ul>
                      )}

                      {order.dados_crm_medico && (
                        <ul className="pdf-pedido__summary--card right">
                          <li className="pdf-pedido__summary--subitem">CRM do Médico</li>
                          <li className="pdf-pedido__summary--subitem right">{order.dados_crm_medico}</li>
                        </ul>
                      )}
                    </div>
                  </div>
                </Card.Body>
              </Card>

              <Card style={{ borderLeft: 0, borderRight: 0, borderBottom: "1px solid", borderBottomColor: "#D8D8DA" }}>
                <CustomToggle>Especificações da lente</CustomToggle>

                <Card.Body>
                  <div className="pdf-pedido__summary--group">
                    <div className="pdf-pedido__summary--side-left">
                      <h3 className="pdf-pedido__summary--text">Produto</h3>

                      <ul className="pdf-pedido__summary--card left">
                        <li className="pdf-pedido__summary--subitem">Tipo de produção</li>
                        <li className="pdf-pedido__summary--subitem right">{order.lente_tp_producao_value || "-"}</li>
                      </ul>

                      <ul className="pdf-pedido__summary--card left">
                        <li className="pdf-pedido__summary--subitem">Tipo de produto</li>
                        <li className="pdf-pedido__summary--subitem right">{order.lente_tp_produto_value || "-"}</li>
                      </ul>

                      <ul className="pdf-pedido__summary--card left">
                        <li className="pdf-pedido__summary--subitem">Produto escolhido</li>
                        <li className="pdf-pedido__summary--subitem right">{order.lente_esc_produto_nome || "-"}</li>
                      </ul>
                    </div>

                    <div className="pdf-pedido__summary--side-right">
                      <h3 className="pdf-pedido__summary--text">Serviços Adicionais</h3>

                      <ul className="pdf-pedido__summary--card right">
                        <li className="pdf-pedido__summary--subitem">AR</li>
                        <li className="pdf-pedido__summary--subitem right">{order.lente_servicos_value || "-"}</li>
                      </ul>

                      <ul className="pdf-pedido__summary--card right">
                        <li className="pdf-pedido__summary--subitem">Coloração</li>
                        <li className="pdf-pedido__summary--subitem right">{order.lente_coloracao_value || "-"}</li>
                      </ul>

                      <ul className="pdf-pedido__summary--card right">
                        <li className="pdf-pedido__summary--subitem">Cor</li>
                        <li className="pdf-pedido__summary--subitem right">{order.cor_value || "-"}</li>
                      </ul>

                      <ul className="pdf-pedido__summary--card right">
                        <li className="pdf-pedido__summary--subitem">Corte/Montagem</li>
                        <li className="pdf-pedido__summary--subitem right">{order.lente_corte_value || "-"}</li>
                      </ul>
                    </div>
                  </div>
                </Card.Body>
              </Card>

              <Card style={{ borderLeft: 0, borderRight: 0, borderBottom: "1px solid", borderBottomColor: "#D8D8DA" }}>
                <CustomToggle>Prescrição</CustomToggle>

                <Card.Body>
                  <h3 className="pdf-pedido__summary--text">Receita</h3>

                  {order.prescricao_olhos === "ambos" && (
                    <div className="pdf-pedido__summary--group">
                      <div className="pdf-pedido__summary--side-left">
                        <h3 className="pdf-pedido__summary--text">Direito</h3>

                        <ul className="pdf-pedido__summary--card left">
                          <li className="pdf-pedido__summary--subitem">Esférico</li>
                          <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_direito_esferico || "-"}</li>
                        </ul>

                        <ul className="pdf-pedido__summary--card left">
                          <li className="pdf-pedido__summary--subitem">Cilíndrico</li>
                          <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_direito_cilindrico || "-"}</li>
                        </ul>

                        <ul className="pdf-pedido__summary--card left">
                          <li className="pdf-pedido__summary--subitem">Eixo</li>
                          <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_direito_eixo || "-"}</li>
                        </ul>

                        <ul className="pdf-pedido__summary--card left">
                          <li className="pdf-pedido__summary--subitem">Adição</li>
                          <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_direito_adicao || "-"}</li>
                        </ul>
                      </div>

                      <div className="pdf-pedido__summary--side-right">
                        <h3 className="pdf-pedido__summary--text">Esquerdo</h3>

                        <ul className="pdf-pedido__summary--card right">
                          <li className="pdf-pedido__summary--subitem">Esférico</li>
                          <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_esferico || "-"}</li>
                        </ul>

                        <ul className="pdf-pedido__summary--card right">
                          <li className="pdf-pedido__summary--subitem">Cilíndrico</li>
                          <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_cilindrico || "-"}</li>
                        </ul>

                        <ul className="pdf-pedido__summary--card right">
                          <li className="pdf-pedido__summary--subitem">Eixo</li>
                          <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_eixo || "-"}</li>
                        </ul>

                        <ul className="pdf-pedido__summary--card right">
                          <li className="pdf-pedido__summary--subitem">Adição</li>
                          <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_adicao || "-"}</li>
                        </ul>
                      </div>
                    </div>
                  )}

                  {order.prescricao_olhos === "direito" && (
                    <div>
                      <h3 className="pdf-pedido__summary--text">Direito</h3>

                      <ul className="pdf-pedido__summary--card shape">
                        <li className="pdf-pedido__summary--subitem">Esférico</li>
                        <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_direito_esferico || "-"}</li>
                      </ul>

                      <ul className="pdf-pedido__summary--card shape">
                        <li className="pdf-pedido__summary--subitem">Cilíndrico</li>
                        <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_direito_cilindrico || "-"}</li>
                      </ul>

                      <ul className="pdf-pedido__summary--card shape">
                        <li className="pdf-pedido__summary--subitem">Eixo</li>
                        <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_direito_eixo || "-"}</li>
                      </ul>

                      <ul className="pdf-pedido__summary--card shape">
                        <li className="pdf-pedido__summary--subitem">Adição</li>
                        <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_direito_adicao || "-"}</li>
                      </ul>
                    </div>
                  )}

                  {order.prescricao_olhos === "esquerdo" && (
                    <div>
                      <h3 className="pdf-pedido__summary--text">Esquerdo</h3>

                      <ul className="pdf-pedido__summary--card shape">
                        <li className="pdf-pedido__summary--subitem">Esférico</li>
                        <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_esferico || "-"}</li>
                      </ul>

                      <ul className="pdf-pedido__summary--card shape">
                        <li className="pdf-pedido__summary--subitem">Cilíndrico</li>
                        <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_cilindrico || "-"}</li>
                      </ul>

                      <ul className="pdf-pedido__summary--card shape">
                        <li className="pdf-pedido__summary--subitem">Eixo</li>
                        <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_eixo || "-"}</li>
                      </ul>

                      <ul className="pdf-pedido__summary--card shape">
                        <li className="pdf-pedido__summary--subitem">Adição</li>
                        <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_adicao || "-"}</li>
                      </ul>
                    </div>
                  )}

                  {order.prescricao_prisma === "S" && <h3 className="pdf-pedido__summary--text">Prisma</h3>}

                  {order.prescricao_prisma === "S" && order.prescricao_olhos === "ambos" && (
                    <div className="pdf-pedido__summary--group">
                      <div className="pdf-pedido__summary--side-left">
                        <h3 className="pdf-pedido__summary--text">Direito</h3>

                        <ul className="pdf-pedido__summary--card left">
                          <li className="pdf-pedido__summary--subitem left">Horizontal</li>

                          <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_direito_pris_h || "-"}</li>
                        </ul>

                        <ul className="pdf-pedido__summary--card left">
                          <li className="pdf-pedido__summary--subitem">Base</li>

                          <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_direito_base_h || "-"}</li>
                        </ul>

                        <ul className="pdf-pedido__summary--card left">
                          <li className="pdf-pedido__summary--subitem">Vertical</li>

                          <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_direito_pris_v || "-"}</li>
                        </ul>

                        <ul className="pdf-pedido__summary--card left">
                          <li className="pdf-pedido__summary--subitem">Base</li>

                          <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_direito_base_v || "-"}</li>
                        </ul>
                      </div>

                      <div className="pdf-pedido__summary--side-right">
                        <h3 className="pdf-pedido__summary--text">Esquerdo</h3>

                        <ul className="pdf-pedido__summary--card right">
                          <li className="pdf-pedido__summary--subitem">Horizontal</li>

                          <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_pris_h || "-"}</li>
                        </ul>

                        <ul className="pdf-pedido__summary--card right">
                          <li className="pdf-pedido__summary--subitem">Base</li>

                          <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_base_h || "-"}</li>
                        </ul>

                        <ul className="pdf-pedido__summary--card right">
                          <li className="pdf-pedido__summary--subitem">Vertical</li>

                          <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_pris_v || "-"}</li>
                        </ul>

                        <ul className="pdf-pedido__summary--card right">
                          <li className="pdf-pedido__summary--subitem">Base</li>

                          <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_base_v || "-"}</li>
                        </ul>
                      </div>
                    </div>
                  )}

                  {order.prescricao_prisma === "S" && order.prescricao_olhos === "esquerdo" && (
                    <div>
                      <h3 className="pdf-pedido__summary--text">Esquerdo</h3>

                      <ul className="pdf-pedido__summary--card shape">
                        <li className="pdf-pedido__summary--subitem">Horizontal</li>

                        <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_pris_h || "-"}</li>
                      </ul>

                      <ul className="pdf-pedido__summary--card shape">
                        <li className="pdf-pedido__summary--subitem">Base</li>

                        <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_base_h || "-"}</li>
                      </ul>

                      <ul className="pdf-pedido__summary--card shape">
                        <li className="pdf-pedido__summary--subitem">Vertical</li>

                        <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_pris_v || "-"}</li>
                      </ul>

                      <ul className="pdf-pedido__summary--card shape">
                        <li className="pdf-pedido__summary--subitem">Base</li>

                        <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_base_v || "-"}</li>
                      </ul>
                    </div>
                  )}
                  {order.prescricao_prisma === "S" && order.prescricao_olhos === "direito" && (
                    <div>
                      <h3 className="pdf-pedido__summary--text">Direito</h3>

                      <ul className="pdf-pedido__summary--card shape">
                        <li className="pdf-pedido__summary--subitem">Horizontal</li>

                        <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_direito_pris_h || "-"}</li>
                      </ul>

                      <ul className="pdf-pedido__summary--card shape">
                        <li className="pdf-pedido__summary--subitem">Base</li>

                        <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_direito_base_h || "-"}</li>
                      </ul>

                      <ul className="pdf-pedido__summary--card shape">
                        <li className="pdf-pedido__summary--subitem">Vertical</li>

                        <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_direito_pris_v || "-"}</li>
                      </ul>

                      <ul className="pdf-pedido__summary--card shape">
                        <li className="pdf-pedido__summary--subitem">Base</li>

                        <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_direito_base_v || "-"}</li>
                      </ul>
                    </div>
                  )}

                  {parametros && (parametros.receita.dnp.preenchimento !== "NAO_ACEITA" || parametros.receita.altura.preenchimento !== "NAO_ACEITA") && (
                    <div>
                      <h3 className="pdf-pedido__summary--text">Medidas do cliente</h3>

                      {order.prescricao_olhos === "ambos" && (
                        <div className="pdf-pedido__summary--group">
                          <div className="pdf-pedido__summary--side-left">
                            <h3 className="pdf-pedido__summary--text">Direito</h3>

                            {parametros.receita.dnp.preenchimento !== "NAO_ACEITA" && (
                              <ul className="pdf-pedido__summary--card left">
                                <li className="pdf-pedido__summary--subitem">DNP</li>
                                <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_direito_dnp || "-"}</li>
                              </ul>
                            )}

                            {parametros.receita.altura.preenchimento !== "NAO_ACEITA" && (
                              <ul className="pdf-pedido__summary--card left">
                                <li className="pdf-pedido__summary--subitem">Altura</li>
                                <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_direito_altura || "-"}</li>
                              </ul>
                            )}

                            {order.control_dnpPerto && (
                              <ul className="pdf-pedido__summary--card left">
                                <li className="pdf-pedido__summary--subitem">DNP de perto</li>
                                <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_direito_dnp_perto || "-"}</li>
                              </ul>
                            )}

                            {order.control_prescricao_cro_direito && (
                              <ul className="pdf-pedido__summary--card left">
                                <li className="pdf-pedido__summary--subitem">CRO</li>
                                <li className="pdf-pedido__summary--subitem right">{order.control_prescricao_cro_direito || "-"}</li>
                              </ul>
                            )}

                            {order.control_prescricao_dis_vert_direito && (
                              <ul className="pdf-pedido__summary--card right">
                                <li className="pdf-pedido__summary--subitem">Distância vértice direito</li>
                                <li className="pdf-pedido__summary--subitem right">{order.prescricao_dis_vert_direito || "-"}</li>
                              </ul>
                            )}
                          </div>

                          <div className="pdf-pedido__summary--side-right">
                            <h3 className="pdf-pedido__summary--text">Esquerdo</h3>

                            {parametros.receita.dnp.preenchimento !== "NAO_ACEITA" && (
                              <ul className="pdf-pedido__summary--card right">
                                <li className="pdf-pedido__summary--subitem">DNP</li>
                                <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_dnp || "-"}</li>
                              </ul>
                            )}

                            {parametros.receita.altura.preenchimento !== "NAO_ACEITA" && (
                              <ul className="pdf-pedido__summary--card right">
                                <li className="pdf-pedido__summary--subitem">Altura</li>
                                <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_altura || "-"}</li>
                              </ul>
                            )}

                            {order.control_dnpPerto && (
                              <ul className="pdf-pedido__summary--card right">
                                <li className="pdf-pedido__summary--subitem">DNP de perto</li>
                                <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_dnp_perto || "-"}</li>
                              </ul>
                            )}

                            {order.control_prescricao_cro_esquerdo && (
                              <ul className="pdf-pedido__summary--card left">
                                <li className="pdf-pedido__summary--subitem">CRO</li>
                                <li className="pdf-pedido__summary--subitem right">{order.control_prescricao_cro_esquerdo || "-"}</li>
                              </ul>
                            )}

                            {order.control_prescricao_dis_vert_esquerdo && (
                              <ul className="pdf-pedido__summary--card right">
                                <li className="pdf-pedido__summary--subitem">Distância vértice esquerdo</li>
                                <li className="pdf-pedido__summary--subitem right">{order.prescricao_dis_vert_esquerdo || "-"}</li>
                              </ul>
                            )}
                          </div>
                        </div>
                      )}

                      {order.prescricao_olhos === "esquerdo" && (
                        <div>
                          <h3 className="pdf-pedido__summary--text">Esquerdo</h3>

                          {parametros.receita.dnp.preenchimento !== "NAO_ACEITA" && (
                            <ul className="pdf-pedido__summary--card shape">
                              <li className="pdf-pedido__summary--subitem">DNP</li>
                              <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_dnp || "-"}</li>
                            </ul>
                          )}

                          {parametros.receita.altura.preenchimento !== "NAO_ACEITA" && (
                            <ul className="pdf-pedido__summary--card shape">
                              <li className="pdf-pedido__summary--subitem">Altura</li>
                              <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_altura || "-"}</li>
                            </ul>
                          )}

                          {order.control_dnpPerto && (
                            <ul className="pdf-pedido__summary--card shape">
                              <li className="pdf-pedido__summary--subitem">DNP de perto</li>
                              <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_esquerdo_dnp_perto || "-"}</li>
                            </ul>
                          )}

                          {order.control_prescricao_cro_esquerdo && (
                            <ul className="pdf-pedido__summary--card left">
                              <li className="pdf-pedido__summary--subitem">CRO</li>
                              <li className="pdf-pedido__summary--subitem right">{order.control_prescricao_cro_esquerdo || "-"}</li>
                            </ul>
                          )}

                          {order.control_prescricao_dis_vert_esquerdo && (
                            <ul className="pdf-pedido__summary--card right">
                              <li className="pdf-pedido__summary--subitem">Distância vértice esquerdo</li>
                              <li className="pdf-pedido__summary--subitem right">{order.prescricao_dis_vert_esquerdo || "-"}</li>
                            </ul>
                          )}
                        </div>
                      )}

                      {order.prescricao_olhos === "direito" && (
                        <div>
                          <h3 className="pdf-pedido__summary--text">Direito</h3>
                          {parametros.receita.dnp.preenchimento !== "NAO_ACEITA" && (
                            <ul className="pdf-pedido__summary--card shape">
                              <li className="pdf-pedido__summary--subitem">DNP</li>
                              <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_direito_dnp || "-"}</li>
                            </ul>
                          )}

                          {parametros.receita.altura.preenchimento !== "NAO_ACEITA" && (
                            <ul className="pdf-pedido__summary--card shape">
                              <li className="pdf-pedido__summary--subitem">Altura</li>
                              <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_direito_altura || "-"}</li>
                            </ul>
                          )}

                          {order.control_dnpPerto && (
                            <ul className="pdf-pedido__summary--card shape">
                              <li className="pdf-pedido__summary--subitem">DNP de perto</li>
                              <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_direito_dnp_perto || "-"}</li>
                            </ul>
                          )}

                          {order.control_prescricao_cro_direito && (
                            <ul className="pdf-pedido__summary--card left">
                              <li className="pdf-pedido__summary--subitem">CRO</li>
                              <li className="pdf-pedido__summary--subitem right">{order.control_prescricao_cro_direito || "-"}</li>
                            </ul>
                          )}

                          {order.control_prescricao_dis_vert_direito && (
                            <ul className="pdf-pedido__summary--card right">
                              <li className="pdf-pedido__summary--subitem">Distância vértice</li>
                              <li className="pdf-pedido__summary--subitem right">{order.prescricao_dis_vert_direito || "-"}</li>
                            </ul>
                          )}
                        </div>
                      )}
                    </div>
                  )}

                  {(order.control_prescricao_ini_clientes ||
                    order.control_prescricao_dis_leitura ||
                    order.control_prescricao_cvp ||
                    order.control_prescricao_ang_pantoscopico ||
                    order.control_prescricao_curva_arm ||
                    order.control_prescricao_coeficiente ||
                    order.control_prescricao_estabilidade ||
                    order.control_prescricao_dis_prog ||
                    order.control_prescricao_olho_dom) && (
                    <div>
                      <h3 className="pdf-pedido__summary--text">Medidas personalizadas</h3>

                      <div className="pdf-pedido__summary--group">
                        <div className="pdf-pedido__summary--side-left">
                          {order.control_prescricao_ini_clientes && (
                            <ul className="pdf-pedido__summary--card left">
                              <li className="pdf-pedido__summary--subitem">Iniciais do cliente</li>
                              <li className="pdf-pedido__summary--subitem right">{order.prescricao_ini_clientes || "-"}</li>
                            </ul>
                          )}

                          {order.control_prescricao_dis_leitura && (
                            <ul className="pdf-pedido__summary--card left">
                              <li className="pdf-pedido__summary--subitem">Distância de Leitura</li>
                              <li className="pdf-pedido__summary--subitem right">{order.prescricao_dis_leitura || "-"}</li>
                            </ul>
                          )}

                          {order.control_prescricao_cvp && (
                            <ul className="pdf-pedido__summary--card left">
                              <li className="pdf-pedido__summary--subitem">CVP</li>
                              <li className="pdf-pedido__summary--subitem right">{order.prescricao_cvp || "-"}</li>
                            </ul>
                          )}

                          {order.control_prescricao_ang_pantoscopico && (
                            <ul className="pdf-pedido__summary--card left">
                              <li className="pdf-pedido__summary--subitem">Ângulo pantoscópico</li>
                              <li className="pdf-pedido__summary--subitem right">{order.prescricao_ang_pantoscopico || "-"}</li>
                            </ul>
                          )}

                          {order.control_prescricao_curva_arm && (
                            <ul className="pdf-pedido__summary--card left">
                              <li className="pdf-pedido__summary--subitem">Curvatura da armação</li>
                              <li className="pdf-pedido__summary--subitem right">{order.prescricao_curva_arm || "-"}</li>
                            </ul>
                          )}
                        </div>

                        <div className="pdf-pedido__summary--side-right">
                          {order.control_prescricao_coeficiente && (
                            <ul className="pdf-pedido__summary--card right">
                              <li className="pdf-pedido__summary--subitem">Coeficiente cabeça-olhos</li>
                              <li className="pdf-pedido__summary--subitem right">{order.prescricao_coeficiente || "-"}</li>
                            </ul>
                          )}

                          {order.control_prescricao_estabilidade && (
                            <ul className="pdf-pedido__summary--card right">
                              <li className="pdf-pedido__summary--subitem">Estabilidade</li>
                              <li className="pdf-pedido__summary--subitem right">{order.prescricao_estabilidade || "-"}</li>
                            </ul>
                          )}

                          {order.control_prescricao_dis_prog && (
                            <ul className="pdf-pedido__summary--card right">
                              <li className="pdf-pedido__summary--subitem">Distância de progressão</li>
                              <li className="pdf-pedido__summary--subitem right">{order.prescricao_dis_prog || "-"}</li>
                            </ul>
                          )}

                          {order.control_prescricao_olho_dom && (
                            <ul className="pdf-pedido__summary--card right">
                              <li className="pdf-pedido__summary--subitem">Olho dominante</li>
                              <li className="pdf-pedido__summary--subitem right">{order.prescricao_olho_dom || "-"}</li>
                            </ul>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </Card.Body>
              </Card>
              <Card style={{ borderLeft: 0, borderBottom: "1px solid", borderRight: 0 }}>
                <CustomToggle>Dados da Armação</CustomToggle>
                <Card.Body>
                  {/* <h3 className="pdf-pedido__summary--text no--gap">Material da armação</h3>
                                    <p className="pdf-pedido__summary--text-2">{order.frame_tipo_material_value || '-'}</p> */}
                  <div className="pdf-pedido__summary--group">
                    <div className="pdf-pedido__summary--side-left">
                      <ul className="pdf-pedido__summary--card left">
                        <li className="pdf-pedido__summary--subitem">Tipo de armação</li>
                        <li className="pdf-pedido__summary--subitem right">{order.frame_tipo_arm_value || "-"}</li>
                      </ul>

                      <ul className="pdf-pedido__summary--card left">
                        <li className="pdf-pedido__summary--subitem">Modelo da armação</li>
                        <li className="pdf-pedido__summary--subitem right">{order.frame_modelo || "-"}</li>
                      </ul>

                      <ul className="pdf-pedido__summary--card left">
                        <li className="pdf-pedido__summary--subitem">Referência da Armação</li>
                        <li className="pdf-pedido__summary--subitem right">{order.frame_modelo_v2 || "-"}</li>
                      </ul>

                      <ul className="pdf-pedido__summary--card left">
                        <li className="pdf-pedido__summary--subitem">Observação</li>
                        <li className="pdf-pedido__summary--subitem right">{order.descricao || "-"}</li>
                      </ul>
                    </div>
                    <div className="pdf-pedido__summary--side-right">
                      <ul className="pdf-pedido__summary--card right">
                        <li className="pdf-pedido__summary--subitem">Vertical</li>
                        {!order.remoteEdgeDisabled && <li className="pdf-pedido__summary--subitem right">{order.frame_info_arm_vertical || "-"}</li>}

                        {order.remoteEdgeDisabled && <li className="pdf-pedido__summary--subitem right">Informado via arquivo</li>}
                      </ul>

                      <ul className="pdf-pedido__summary--card right">
                        <li className="pdf-pedido__summary--subitem">Horizontal</li>
                        {!order.remoteEdgeDisabled && <li className="pdf-pedido__summary--subitem right">{order.frame_info_arm_horizontal || "-"}</li>}

                        {order.remoteEdgeDisabled && <li className="pdf-pedido__summary--subitem right">Informado via arquivo</li>}
                      </ul>

                      <ul className="pdf-pedido__summary--card right">
                        <li className="pdf-pedido__summary--subitem">Diagonal maior</li>
                        {!order.remoteEdgeDisabled && <li className="pdf-pedido__summary--subitem right">{order.frame_info_arm_diametro || "-"}</li>}

                        {order.remoteEdgeDisabled && <li className="pdf-pedido__summary--subitem right">Informado via arquivo</li>}
                      </ul>

                      <ul className="pdf-pedido__summary--card right">
                        <li className="pdf-pedido__summary--subitem">Ponte</li>
                        {!order.remoteEdgeDisabled && <li className="pdf-pedido__summary--subitem right">{order.frame_info_arm_ponte || "-"}</li>}

                        {order.remoteEdgeDisabled && <li className="pdf-pedido__summary--subitem right">Informado via arquivo</li>}
                      </ul>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </ul>
          </div>
        </div>
        <div className="x-order-confirmed__list__pdf">
          <p className="x-order-confirmed__item__pdf">
            O número do seu pedido no laboratório {order.labName} é <small className="x-order-confirmed__item--number">{this.props.pedido}</small>
          </p>
          <p style={{ textAlign: "center" }} className="x-order-confirmed__item__pdf js--paragraph">
            A data de saída estimada é <small className="x-order-confirmed__item--number">{this.props.prazo}</small>
          </p>
        </div>
      </div>
    );
  }
}

export default OrderSummaryPDF;
