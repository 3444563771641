import React, { Component } from 'react'
import './media-center-modal.css'
import {
	Modal,
	Button,
	Container,
	Row,
	Col,
	OverlayTrigger,
	Tooltip
} from 'react-bootstrap'
import { Files } from 'react-bootstrap-icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import MergeImageLogo from '../../main/merge-image-logo/merge-image-logo'
import { triggerBase64Download } from 'react-base64-downloader'
import AppContext from '../../appContext'

class MediaCenterModal extends Component {
	constructor(props) {
		super(props)

		this.state = {
			show: false,
			copied: false,
			base64: '',
			logo_otica: ''
		}

		MediaCenterModal.contextType = AppContext
		this.setBase64DownloadImage = this.setBase64DownloadImage.bind(this)
		this.obterLogo = this.obterLogo.bind(this)
	}


	componentDidMount() {
		this.obterLogo(this.context.userSession.conta)
	}

	setBase64DownloadImage(b64) {
		this.setState({ base64: b64 })
	}

	obterLogo(cnpj) {
		fetch(process.env.REACT_APP_LABS_API + `getLogoOtica.php?cnpjOtica=${cnpj}`)
			.then((response) => response.json())
			.then((json) => {
				if (json.success) {
					this.setState({
						logo_otica: true,
					})
				} else {
					this.setState({
						logo_otica: false,
					})
				}
			})
	}

	render() {
		const { show, handleClose, nome, image, customizavel, text } = this.props
		const cleanText = text ? decodeURIComponent(escape(window.atob(text))).replace(/<\/?[^>]+(>|$)/g, "") : "";

		return (
			<div>
				<Modal
					show={show}
					onHide={handleClose}
					size="lg"
					aria-labelledby="contained-modal-title-vcenter"
					centered
				>
					<Modal.Header closeButton onClick={() => this.setState({ copied: false })}>
						<Modal.Title id="contained-modal-title-vcenter">{nome}</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<Container>
							<Row>
								<div className="modal-mobile-only">
									<Col sm={12} md={6}>
										{window.sessionStorage.getItem('@logo') ? <MergeImageLogo
											imagem={image}
											widthCanva={600}
											heightCanva={600}
											widthLogo={300}
											heigthLogo={200}
											setBase64DownloadImage={this.setBase64DownloadImage}
											customizavel={customizavel}
										/> :
										<img
											ref="imagem"
											src={this.props.image}
											style={{ width: "350px" }}
											alt=""
										/>}
									</Col>

									<Col sm={12} md={6}>
										<div className="media-center-modal-flex">
											<div className="media-center-legenda">
												<h3>Legenda:</h3>


												<div dangerouslySetInnerHTML={{
													__html: this.props.text != null
														? decodeURIComponent(
															escape(window.atob(this.props.text)),
														)
														: ''
												}} />

												{/* <p>
													{}
												</p> */}
											</div>
											<div className="media-center-modal-copy-clipboard">
												<div>
													{!this.state.copied ? (
														<CopyToClipboard
															text={this.props.text != null
																? cleanText
																: ''}
															onCopy={() => this.setState({ copied: true })}
														>
															<span className="modal-copiar" style={{ color: '#4169E1' }} >Copiar</span>
														</CopyToClipboard>
													) : null}
													{this.state.copied ? (
														<span style={{ color: 'red' }} >Copiado</span>
													) : null}
												</div>
												<div>
													<OverlayTrigger
														placement="right"
														delay={{ show: 250, hide: 400 }}
														overlay={<Tooltip id="button-tooltip">Copiar</Tooltip>}
													>
														<CopyToClipboard
															text={this.props.text != null
																? cleanText
																: ''}
															onCopy={() => this.setState({ copied: true })}
														>
															<Button className="float-end" variant="link">
																<Files color="royalblue" size={24} />
															</Button>
														</CopyToClipboard>
													</OverlayTrigger>
												</div>
											</div>

											<div className="media-center-download-image">

												{window.sessionStorage.getItem('@logo') ? <OverlayTrigger
													placement="right"
													delay={{ show: 250, hide: 400 }}
													overlay={<Tooltip id="button-tooltip">Baixar</Tooltip>}
												>
													<Button className="media-center-card-button float-left" variant="link" onClick={() => triggerBase64Download(this.state.base64, nome)}>
														<small className="media-center-card-text is-text--lg">
															Download
														</small>
														<svg
															className="media-center-card-svg"
															xmlns="http://www.w3.org/2000/svg"
															width="30"
															height="30"
															viewBox="0 0 30 30"
														>
															<g
																id="Group_90"
																data-name="Group 90"
																transform="translate(30) rotate(90)"
															>
																<circle
																	id="Ellipse_9"
																	data-name="Ellipse 9"
																	cx="15"
																	cy="15"
																	r="15"
																	fill="#fff"
																/>
																<path
																	id="np_arrow_2957554_000000"
																	d="M6.479,10.7l4.832-9.431A.876.876,0,0,0,9.993.188L6.246,3.134a.822.822,0,0,1-1.059,0L1.414.188A.877.877,0,0,0,.1,1.273L4.928,10.7a.879.879,0,0,0,1.551,0Z"
																	transform="translate(10.48 21.5) rotate(-90)"
																	fill="#0394db"
																/>
															</g>
														</svg>
													</Button>
												</OverlayTrigger> : <OverlayTrigger
													placement="right"
													delay={{ show: 250, hide: 400 }}
													overlay={<Tooltip id="button-tooltip">Baixar</Tooltip>}
												>
													<Button className="media-center-card-button float-left" variant="link" onClick={() => window.open(this.props.image, '_blank')}>
														<small className="media-center-card-text is-text--lg">
															Download
														</small>
														<svg
															className="media-center-card-svg"
															xmlns="http://www.w3.org/2000/svg"
															width="30"
															height="30"
															viewBox="0 0 30 30"
														>
															<g
																id="Group_90"
																data-name="Group 90"
																transform="translate(30) rotate(90)"
															>
																<circle
																	id="Ellipse_9"
																	data-name="Ellipse 9"
																	cx="15"
																	cy="15"
																	r="15"
																	fill="#fff"
																/>
																<path
																	id="np_arrow_2957554_000000"
																	d="M6.479,10.7l4.832-9.431A.876.876,0,0,0,9.993.188L6.246,3.134a.822.822,0,0,1-1.059,0L1.414.188A.877.877,0,0,0,.1,1.273L4.928,10.7a.879.879,0,0,0,1.551,0Z"
																	transform="translate(10.48 21.5) rotate(-90)"
																	fill="#0394db"
																/>
															</g>
														</svg>
													</Button>
												</OverlayTrigger>}

											</div>
										</div>
									</Col>
								</div>

								<Col sm={12} md={6}>
									<div className="media-center-share-image">
										Para compartilhar essa imagem, pressione a imagem por 2 segundos e escolha a opção "Compartilhar Imagem" em seu smartphone.
									</div>
								</Col>

							</Row>
						</Container>
					</Modal.Body>
				</Modal>
			</div>
		)
	}
}

export default MediaCenterModal
