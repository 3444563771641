async function getArmacao(laboratorio) {
    
    let requestOptions = {
        method: "GET",
        headers: {
          client_id: localStorage.getItem("clientid"),
          access_token: localStorage.getItem("token"),
          laboratorio: laboratorio,
        },
      };
  
    return fetch(process.env.REACT_APP_ESSILOR_API + "gestao-produtos-laboratorio/v1/armacao/marcas", requestOptions).then(response => {
        if (response.status === 200) {
          let transformed = [];
          return response.json().then(json => {
            if (Array.isArray(json)) {
              transformed = json.map(item => ({
                label: item.descricao,
                value: item.codigo,
              }));
              return transformed;
            }
          });
        }else{
          return new Response(null, { status: 400 }); 
        }
      });
  }
  export { getArmacao };
  