import "./resumo-lateral.css";
import React, { Component } from "react";

class Financeiro extends Component {
  constructor(props) {
    super(props);

    this.state = {
        open: "",
        arrow: "",
        lab: null,
    };
    this.openSummary = this.openSummary.bind(this);

  }
  openSummary(e) {
    this.setState({ [e.target.id]: e.target.value }, () => {
      if (this.state.open) {
        this.setState({ open: "", arrow: "" });
      } else {
        this.setState({ open: "show-summary", arrow: "active-arrow" });
      }
    });
  }

  render() {
    return ( 

    <div className={`resumo-lateral_main ${this.state.arrow}`}>
          <div className={`resumo-lateral_summary ${this.state.open}`} >
            <div className={`resumo-lateral_summary--arrowContainer ${this.state.arrow}`} onClick={this.openSummary}>
            <span className={`resumo-lateral_summary--arrow ${this.state.arrow}`} onClick={this.openSummary}></span>
            
            </div>
            <div className={`resumo-lateral_summary--container ${this.state.arrow}`}>
              <div className="resumo-lateral_summary--intro">
                <img
                  src={this.props.logoTransparente}
                  width="60%"
                  className="resumo-lateral_summary--icon"
                  alt="Logo Financeiro"
                />

               { this.props.lab && (
                  <>
                    <div className="row">
                      <div className="col-12"></div>
                      <div className="logolabTracking col-12">
                        <img ref="imagem" src={this.props.lab_logo} width="178" height="120" />
                      </div>
                      <div className="col-12 mt-4">
                        <p className="resumo-lateral_summary--paragraph">
                          <b>Dúvidas? Entre em contato com o laboratório:</b>
                          {!this.props.lab && (
                            <>
                              <br /> Selecione um laboratório
                            </>
                          )}
                          {this.props.lab && this.props.lab_phone && (
                            <>
                              <br />
                              Telefone: {this.props.lab_phone}
                            </>
                          )}
                          {this.props.lab && this.props.lab_email && (
                            <>
                              <br />
                              Email: {this.props.lab_email}
                            </>
                          )}
                          {this.props.lab && this.props.lab_site && (
                            <>
                              <br />
                              Site: {this.props.lab_site}
                            </>
                          )}
                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
          );
        }
      }
      
      export default Financeiro;