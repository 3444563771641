import React, { Component } from 'react'

class Content extends Component {
  	render(){
    	return (
            <section className='login-middle'>
                <div className='login-information'>
                    <div className='login-information__container'>
                        <h1 className='login-information__title'>Por que se <strong className='login-information__title-accent'>conectar com a gente?</strong></h1>
                        <div className='login-information__card'>
                            <div className='login-information__card-container'>
                                <div className='login-information__card-top'>
                                    <div className='login-information__card-icon'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="102.024" height="66.823" viewBox="0 0 102.024 66.823">
                                            <path className="a"
                                                d="M68.728,22.59A33.412,33.412,0,1,0,102.139,56,33.447,33.447,0,0,0,68.728,22.59Zm0,61.8A28.383,28.383,0,1,1,97.11,56,28.414,28.414,0,0,1,68.728,84.388Z"
                                                transform="translate(-0.115 -22.59)" 
                                                fill="#0066b3"
                                                />
                                            <path className="a"
                                                d="M68.362,51.635,54.326,43.458A2.514,2.514,0,0,0,51.8,47.8l14.036,8.177a2.514,2.514,0,0,0,2.529-4.346Z"
                                                transform="translate(1.513 -20.395)" 
                                                fill="#0066b3"
                                                />
                                            <path className="a"
                                                d="M9.729,38.608H32.975a2.214,2.214,0,0,0,0-4.428H9.729a2.214,2.214,0,1,0,0,4.428Z"
                                                transform="translate(-3.088 -21.351)" 
                                                fill="#0066b3"
                                                />
                                            <path className="a"
                                                d="M5.729,49.264H28.975a2.214,2.214,0,1,0,0-4.428H5.729a2.214,2.214,0,1,0,0,4.428Z"
                                                transform="translate(-3.516 -20.211)" 
                                                fill="#0066b3"
                                                />
                                            <path className="a"
                                                d="M31.189,57.7a2.213,2.213,0,0,0-2.214-2.214H5.73a2.214,2.214,0,1,0,0,4.428H28.975A2.215,2.215,0,0,0,31.189,57.7Z"
                                                transform="translate(-3.516 -19.072)" 
                                                fill="#0066b3"
                                                />
                                            <path className="a"
                                                d="M35.189,68.355a2.213,2.213,0,0,0-2.214-2.214H9.73a2.214,2.214,0,1,0,0,4.428H32.975A2.215,2.215,0,0,0,35.189,68.355Z"
                                                transform="translate(-3.088 -17.933)" 
                                                fill="#0066b3"
                                                />
                                        </svg>
                                    </div>
                                    <p className='login-information__card-text'>Mais Rápido</p>
                                </div>
                                <div className='login-information__card-bottom'>
                                    <div className='login-information__card-bottom--paragraph'>
                                        <strong className='login-information__card-bottom--accent'>
                                            Tecnologia é sempre <br />
                                            muito bem-vinda!</strong>
                                        <p className='login-information__card-bottom--text'> Através de nosso sistema inteligente, você terá acesso a todas as soluções Essilor na velocidade de um clique.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='login-information__card-container'>
                                <div className='login-information__card-top'>
                                    <div className='login-information__card-icon'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="37.014" height="58.341" viewBox="0 0 37.014 58.341">
                                            <path className="a"
                                                d="M66.353,43.817a5.9,5.9,0,0,0-3.236.965,5.935,5.935,0,0,0-8.363-1.968,5.93,5.93,0,0,0-7.526-2.421V30.66a5.961,5.961,0,1,0-11.923,0V64.533a18.507,18.507,0,1,0,37.014,0V49.774a5.967,5.967,0,0,0-5.965-5.957Zm2.406,20.717a14.947,14.947,0,0,1-29.894,0V30.66a2.4,2.4,0,1,1,4.8,0V56.416a1.78,1.78,0,1,0,3.559,0V45.839a2.4,2.4,0,1,1,4.8,0V56.416a1.78,1.78,0,1,0,3.559,0V47.807a2.4,2.4,0,0,1,4.8,0v8.609a1.78,1.78,0,1,0,3.559,0V49.774a2.4,2.4,0,1,1,4.8,0Z"
                                                transform="translate(-35.305 -24.699)" 
                                                fill="#0066b3"
                                                />
                                        </svg>
                                    </div>
                                    <p className='login-information__card-text'>Mais Fácil</p>
                                </div>
                                <div className='login-information__card-bottom'>
                                    <div className='login-information__card-bottom--paragraph'>
                                        <strong className='login-information__card-bottom--accent'>
                                            Simplicidade para facilitar <br />
                                            sua vida!</strong>
                                        <p className='login-information__card-bottom--text'> Tenha acesso aos benefícios Essilor desenvolvidos especialmente para você e seu negócio, de maneira muito mais fácil, dinâmica, e que se adapta perfeitamente à sua rotina.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='login-information__card-container'>
                                <div className='login-information__card-top'>
                                    <div className='login-information__card-icon'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="62.3" height="80.189" viewBox="0 0 62.3 80.189">
                                            <path className="a"
                                                d="M73.238,33.908a6.5,6.5,0,1,0-6.507-6.5,6.4,6.4,0,0,0,.84,3.129L54.18,44.561a8.2,8.2,0,0,0-8.408-.727L30.333,20.409a7.019,7.019,0,0,0,1.785-4.654,7.147,7.147,0,1,0-4.108,6.409l15.4,23.376a8.249,8.249,0,0,0-2.2,4.3l-11.462,0a6,6,0,1,0,0,2.907H41.219a8.29,8.29,0,0,0,6.691,6.691V73.45a7.527,7.527,0,1,0,2.907,0V59.427a8.271,8.271,0,0,0,4.628-2.542L66.372,64.68a4.538,4.538,0,0,0-.23,1.363A4.637,4.637,0,1,0,70.778,61.4a4.587,4.587,0,0,0-2.73.907l-11.033-7.87a8.256,8.256,0,0,0-.8-7.806L69.5,32.715a6.462,6.462,0,0,0,3.735,1.194Zm-.723,32.135a1.734,1.734,0,1,1-1.736-1.736A1.736,1.736,0,0,1,72.515,66.043ZM73.238,23.8a3.6,3.6,0,1,1-3.6,3.6A3.609,3.609,0,0,1,73.238,23.8ZM20.852,15.755a4.183,4.183,0,1,1,4.183,4.183A4.186,4.186,0,0,1,20.852,15.755Zm3.1,38.626a3.1,3.1,0,1,1,3.1-3.1A3.1,3.1,0,0,1,23.951,54.381ZM53.988,80.827A4.624,4.624,0,1,1,49.364,76.2,4.63,4.63,0,0,1,53.988,80.827ZM49.364,56.66a5.377,5.377,0,1,1,5.377-5.377A5.385,5.385,0,0,1,49.364,56.66Z"
                                                transform="translate(-17.695 -8.418)" 
                                                fill="#0066b3"
                                                />
                                        </svg>
                                    </div>
                                    <p className='login-information__card-text'>Tudo num só lugar!</p>
                                </div>
                                <div className='login-information__card-bottom'>
                                    <div className='login-information__card-bottom--paragraph'>
                                        <strong className='login-information__card-bottom--accent'>
                                            Tudo que você precisa <br />
                                            está aqui!</strong>
                                        <p className='login-information__card-bottom--text'> Unimos o que há de melhor para o seu negócio em um só lugar, para que sua experiência com a Essilor seja completa.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    	);
  	}
}

export default Content;