import React, { Component } from 'react'
import AppContext from '../appContext';

class Welcome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nome: ''
        }

        Welcome.contextType = AppContext;
    }

    componentDidMount() {
        
    }

    render() {
        const nome = this.context.userSession.nome;
        return (
            <div className="x-welcome">
                <h2 className="x-welcome__title">Bem-vindo ao Essilor Conecta</h2>
                <span className="x-welcome__square"></span>
                <p className="x-welcome__desc is--desk">
                    Olá, <span className="x-welcome__desc-bold">{nome}</span>!<br />
                    Acesse os ambientes e descubra uma Essilor 100% conectada com você e seu negócio!
                </p>
                <p className="x-welcome__desc is--mobile">
                    Olá, <span className="x-welcome__desc-bold">{nome}</span>!<br />
                    Acesse os ambientes e descubra uma Essilor 100% conectada com você e seu negócio!
                </p>
            </div>
        );
    }
}

export default Welcome;