import React, { Component } from "react";

//Import CSS

//Import internal components
import AppContext from "../../appContext";
import HeaderLogo from "./header-logo";
import Logout from "./logout";

// Menu
import OticaMenu from "./otica-menu/otica-menu";
import UserMenu from "./user-menu/user-menu";
import ReactLoading from "react-loading";

//Import external components
import { Link } from "react-router-dom";

//Import image

class HeaderMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lock: false,
      open: "",
      redirect: false,
      modulos: [],
      especialista: "",
      load: true,
      oticaLoad: true,
    };

    HeaderMobile.contextType = AppContext;
    this.showMenu = this.showMenu.bind(this);
    this.hideMenu = this.hideMenu.bind(this);
    this.openMenu = this.openMenu.bind(this);
    this.acessaGestaoPapeis = this.acessaGestaoPapeis.bind(this);
    this.hasMinhaOtica = this.hasMinhaOtica.bind(this);
  }

  componentDidMount() {
    if (localStorage.getItem("token") != null) {
      var modulos = this.context.userSession.modulos;
      if (process.env.REACT_APP_GA_ID !== "dev") {
        modulos = modulos.filter((item, i) => {
          return item !== "Financeiro";
        });
      }
      var mods = [];
      var especialista = this.context.especialista && this.context.especialista.includes("VARILUX") ? true : false;

      const requestOptions1 = {
        method: "POST",
        body: JSON.stringify({
          modulos: modulos,
          especialista: especialista,
        }),
      };

      fetch(process.env.REACT_APP_LABS_API + "get_modules_header.php", requestOptions1)
        .then(response => {
          return response.json();
        })
        .then(json => {
          if (json.items) {
            for (var i = 0; i < json.items.length; i++) {
              var pushObj = {
                nome: json.items[i].RecordName,
                pos: json.items[i].Posicao_c,
                url: json.items[i].URL_c,
                tab: json.items[i].NovaAba_c,
              };

              if (!json.items[i].OticasPilotos_c) {
                mods.push(pushObj);
              } else {
                const contas = json.items[i].OticasPilotos_c.split(";");
                if (contas.some(elem => elem.includes(this.context.userSession.conta))) {
                  mods.push(pushObj);
                }
              }
            }

            mods.sort(this.compare);
            this.setState({ modulos: mods, load: false });
          }
        });
    }
  }

  compare(a, b) {
    if (a.pos < b.pos) {
      return -1;
    }
    if (a.pos > b.pos) {
      return 1;
    }
    return 0;
  }

  openMenu(e) {
    this.setState({ [e.target.id]: e.target.value }, () => {
      if (this.state.menu) {
        this.setState({ menu: "" });
      } else {
        this.setState({ menu: "is--active" });
      }
    });
  }

  showMenu(event) {
    event.preventDefault();
    if (!this.state.lock) {
      var btnList = document.getElementsByClassName("header__menu-list")[1];
      btnList.classList.toggle("is--active");
      document.addEventListener("click", this.hideMenu);
      this.setState({ lock: true });
    }
  }

  hideMenu() {
    if (this.state.lock) {
      var btnList = document.getElementsByClassName("header__menu-list")[1];
      btnList.classList.toggle("is--active");
      document.removeEventListener("click", this.hideMenu);
      this.setState({ lock: false });
    }
  }

  acessaGestaoPapeis() {
    return this.context.userSession.modulos.includes("Gestão Papéis");
  }

  hasMinhaOtica() {
    return this.context.userSession.modulos.includes("Minha Ótica");
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.lista_multi_contas !== null) {
      this.setState({ oticaLoad: false });
    }
  }

  render() {
    if (this.state.redirect) {
      window.location.href = "/";
    }

    return (
      <div className="header__container is--mobile">
        <button className="header__open-menu" onClick={this.openMenu}>
          <svg xmlns="http://www.w3.org/2000/svg" id="np_menu_2958756_000000" width="19" height="14.778" viewBox="0 0 19 14.778">
            <path id="Path_41" data-name="Path 41" d="M59.5,11.5H50v2.111h9.5Z" transform="translate(-50 -11.5)" fill="#424b4a" />
            <path id="Path_42" data-name="Path 42" d="M19.5,44.5H.5v2.111h19Z" transform="translate(-0.5 -38.167)" fill="#424b4a" />
            <path id="Path_43" data-name="Path 43" d="M37.278,77.5H22.5v2.111H37.278Z" transform="translate(-22.5 -64.833)" fill="#424b4a" />
          </svg>
        </button>
        <HeaderLogo />
        <div className="header__menu">
          <div className="header_menu-btn-mobile" id="headerBtn1">
            {this.state.oticaLoad ? (
              <ReactLoading type="bubbles" color="#00518b" height={65} width={65} />
            ) : (
              <OticaMenu lista_multi_contas={this.props.lista_multi_contas} reloadMultiContas={this.props.reloadMultiContas}/>
            )}
            {/* <OticaMenu /> */}
            <UserMenu acessaGestaoPapeis={this.acessaGestaoPapeis} hasMinhaOtica={this.hasMinhaOtica} />
          </div>
        </div>
        <div className={`header__side-menu ${this.state.menu}`}>
          <button className="header__close-menu" onClick={this.openMenu} id="headerBtn">
            <svg xmlns="http://www.w3.org/2000/svg" width="12.011" height="12.007" viewBox="0 0 12.011 12.007">
              <path
                id="np_close_1551654_000000"
                d="M11.877,10.944l4.88-4.88a.668.668,0,0,0,0-.933.656.656,0,0,0-.933,0l-4.88,4.88-4.88-4.88a.668.668,0,0,0-.933,0,.656.656,0,0,0,0,.933l4.88,4.88-4.88,4.88a.668.668,0,0,0,0,.933.633.633,0,0,0,.467.186.691.691,0,0,0,.467-.186l4.88-4.88,4.88,4.88a.633.633,0,0,0,.467.186.691.691,0,0,0,.467-.186.668.668,0,0,0,0-.933Z"
                transform="translate(-4.936 -4.936)"
              />
            </svg>
          </button>
          <ul className="header__side-items" onClick={this.openMenu}>
            {this.state.modulos.map((item, index) => {
              return (
                <li key={index} className="header__side-item">
                  <Link className="header__side-link" target={item.tab === true ? "_blank" : ""} to={item.url}>
                    {item.nome}
                  </Link>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="header__background"></div>
      </div>
    );
  }
}

export default HeaderMobile;
