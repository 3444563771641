import React, { Component } from "react";
import AppContext from "../appContext";
import Back from "../main/back";
import CadastralBusca from "./cadastral/cadastralBusca";
import CadastralGraficos from "./cadastral/cadastralGraficos";
import Product from "./product/product";
import { getLabs } from "../Functions/Laboratorio/getLabs";
import MenuHorizontal from "../components/menu/menu-horizontal/index.js";
import "./product-management.css";
class ProductManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected_option: "Produtos",
      openProduto: true,
      openCadastral: false,
      openLSA: false,
      openBase: false,
      isConsultor: false,
      isDisabled: false,
      labs: [],
      TableDataRPL: [],
      laboratorios: [],
    };

    ProductManagement.contextType = AppContext;
    this.openView = this.openView.bind(this);
    this.getLabs = this.getLabs.bind(this);
  }

  componentDidMount() {
    this.state.selected_option = "Produtos";
    if (this.context.userSession.modulos.includes("Produtos")) {
      this.setState({ isConsultor: true });
      this.getLabs();
    } else {
      window.location.href = "/home";
    }
  }

  getLabs = async () => {
    const body = {
      laboratorio: this.context.userSession.laboratorios,
      papel: this.context.userSession.papel,
      conta: this.context.userSession.conta,
      tracking: true,
      removeItens: ["Farol"],
      papelCategoria: this.context.userSession.papelCategoria,
    };

    try {
      const result = await getLabs(body);

      if (result) {
        let labs = result;
        this.setState({ laboratorios: labs });
      }
    } catch (error) {
      console.error("Erro ao usar a função getRedes:", error.message);
    }
  };

  BaseDataTable = (valor, posicao) => {
    this.setState({ TableDataRPL: valor });
    this.setState({ selected_option: posicao });
  };

  handleChangeOption(event, option) {
    
    if (option !== this.state?.selected_option) {
      this.setState({selected_option: event});
    }
  }

  openView(openProduto, openCadastral, openLSA, openBase) {
    this.setState({
      openProduto: openProduto,
      openCadastral: openCadastral,
      openLSA: openLSA,
      openBase: openBase,
    });
  }

  render() {
    const menuOptions = [
      {
        id: 1,
        label: "Consultar Produto",
        component: <Product handleChangeOption={(event) => this.handleChangeOption(event)} laboratorios={this.state.laboratorios} selected_option={this.state.selected_option}/>,
      },
      {
        id: 2,
        label: "Situação Cadastral",
        component: <CadastralBusca BaseDataTable={this.BaseDataTable} laboratorios={this.state.laboratorios} handleChangeOption={(event) => this.handleChangeOption(event)} selected_option={this.state.selected_option}/>,
      },
    ];
    return this.state.isConsultor ? (
      <>
        <main id="main-product-management">
          <div className="product-management-container">
            <div className="mt-5">
              <Back />
            </div>
            <div className="col-12">
              <div className="title-container">
                <h1 className="main-title">Conecta Produtos</h1>
                <span className="title-shape"></span>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <MenuHorizontal menuOptions={menuOptions}>
                  <div className="subtitle-container">
                    <p className="subtitle mt-3">Busque informações e confira a situação cadastral de produtos dentro do laboratório.</p>
                  </div>
                </MenuHorizontal>

                <div className="row"></div>
                {this.state.selected_option === "dados_encontrados" && (
                  <div className="row">
                    <div className="col-12">
                      <CadastralGraficos TableDataRPL={this.state.TableDataRPL} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </main>
      </>
    ) : (
      <></>
    );
  }
}

export default ProductManagement;
