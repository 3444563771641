import React, { Component } from 'react'
import { Accordion, Card, useAccordionButton, Button } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import "./faq.css"

class Questions extends Component {

    state = {
        activeKey: 0,
    }

    render() {

        function CustomToggle({ children, eventKey, handleClick, classToggle }) {
            const decoratedOnClick = useAccordionButton(eventKey, () => {
                handleClick();
            });

            return (
                <li className={`x-faq-category__item ${classToggle}`} onClick={decoratedOnClick}>
                    <a href="javascript:;" className='x-faq-category__item-link'  >{children}</a>
                </li>
            );
        }

        function redirectLink(locale, destiny) {
            switch (locale) {
                case 'dev':
                    return `http://dev.static.essilorlabs.com.br/${destiny}`
                case 'homol':
                    return `http://homolog.static.essilorlabs.com.br/${destiny}`
                case 'prod':
                    return `https://beta.essilorconecta.com.br/${destiny}`
                default:
                    return `http://dev.static.essilorlabs.com.br/${destiny}`
            }
        }

        const { faq } = this.props;

        return (
            <section className="x-faq-category">

                <ul className="x-faq-category__container">
                    <Accordion>

                        {faq.map((elem, index) => {
                            let header = (
                                <Card className="text-center" style={{ border: 0 }}>
                                    <h2 className="x-faq-category__title gap" style={{ margin: 0 }}>{elem.session}</h2>
                                </Card>
                            );
                            let body = [];
                            elem.questions.map((el, indexRow,) => {
                                let key = `${elem.position}${el.position}`;
                                body.push(
                                    <Card>
                                        <div className='btn-header-accordion' variant="light" eventKey="0">
                                            <CustomToggle
                                                as={Card.Header}
                                                eventKey={key}
                                                handleClick={() => { this.state.activeKey === key ? this.setState({ activeKey: null }) : this.setState({ activeKey: key }) }}
                                                classToggle={this.state.activeKey === key ? "sign-show" : "sign-hiden"}
                                            >{el.question}</CustomToggle>
                                        </div>
                                        <Accordion.Collapse eventKey={key}>
                                            <Card.Body className="x-card-body border-top" >
                                                <div dangerouslySetInnerHTML={{ __html: el.answer }} />
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                )
                            })

                            return (
                                [
                                    header,
                                    body
                                ]
                            )
                        })}

                    </Accordion>
                </ul>
            </section>
        );
    }
}

export default Questions;
