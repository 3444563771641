import React, { Component } from "react";
import AppContext from "../../appContext";
import TextArea from "../../components/inputs/text-area";
import "./../pedido-atacado.css";
import { Badge, Button } from "react-bootstrap";
import { PlusCircleFill } from "react-bootstrap-icons";

class PedidoAtacadoEtapaQuatro extends Component {
  constructor(props) {
    super(props);

    this.state = { observacao: "" };

    PedidoAtacadoEtapaQuatro.contextType = AppContext;
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {}

  handleChange = evt => {
    this.props.onChange({ [evt.name]: evt.value });
  };

  render() {
    const { disableFields, observacao } = this.props;

    return (
      <>
        <p className="titulo-sessao">
          <Badge fill className="me-2">
            3
          </Badge>
          Dados adicionais
        </p>
        <div className="row">
          <div className="col-12" style={{ paddingRight: "0" }}>
            <TextArea
              name="observacao"
              label="Observação"
              width="100%"
              aria="Observação"
              tooltip="Observações gerais não especificadas no formulário."
              disabled={disableFields}
              readOnly={disableFields}
              value={observacao}
              onChange={e => this.handleChange(e.target)}
            />
          </div>
        </div>
      </>
    );
  }
}

export default PedidoAtacadoEtapaQuatro;
