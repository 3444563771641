import React, { Component } from "react";

class PDFOrderDadosAdicionais extends Component {
  render() {
    const { dados } = this.props;

    return (
      <div className="pdf-order-armacao row">
        <div className="col-12">
          <h1 className="pdf-title">Dados adicionais</h1>
        </div>
        <div className="row pdf-order-dados-adicionais">
          <div className="col">
            <b>Observação</b>
            <br />
            <p>{dados.observacao ? dados.observacao : ""}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default PDFOrderDadosAdicionais;
