export const ESFERICO = [
  { value: "-26.00", label: "-26.00" },
  { value: "-25.75", label: "-25.75" },
  { value: "-25.50", label: "-25.50" },
  { value: "-25.25", label: "-25.25" },
  { value: "-25.00", label: "-25.00" },
  { value: "-24.75", label: "-24.75" },
  { value: "-24.50", label: "-24.50" },
  { value: "-24.25", label: "-24.25" },
  { value: "-24.00", label: "-24.00" },
  { value: "-23.75", label: "-23.75" },
  { value: "-23.50", label: "-23.50" },
  { value: "-23.25", label: "-23.25" },
  { value: "-23.00", label: "-23.00" },
  { value: "-22.75", label: "-22.75" },
  { value: "-22.50", label: "-22.50" },
  { value: "-22.25", label: "-22.25" },
  { value: "-22.00", label: "-22.00" },
  { value: "-21.75", label: "-21.75" },
  { value: "-21.50", label: "-21.50" },
  { value: "-21.25", label: "-21.25" },
  { value: "-21.00", label: "-21.00" },
  { value: "-20.75", label: "-20.75" },
  { value: "-20.50", label: "-20.50" },
  { value: "-20.25", label: "-20.25" },
  { value: "-20.00", label: "-20.00" },
  { value: "-19.75", label: "-19.75" },
  { value: "-19.50", label: "-19.50" },
  { value: "-19.25", label: "-19.25" },
  { value: "-19.00", label: "-19.00" },
  { value: "-18.75", label: "-18.75" },
  { value: "-18.50", label: "-18.50" },
  { value: "-18.25", label: "-18.25" },
  { value: "-18.00", label: "-18.00" },
  { value: "-17.75", label: "-17.75" },
  { value: "-17.50", label: "-17.50" },
  { value: "-17.25", label: "-17.25" },
  { value: "-17.00", label: "-17.00" },
  { value: "-16.75", label: "-16.75" },
  { value: "-16.50", label: "-16.50" },
  { value: "-16.25", label: "-16.25" },
  { value: "-16.00", label: "-16.00" },
  { value: "-15.75", label: "-15.75" },
  { value: "-15.50", label: "-15.50" },
  { value: "-15.25", label: "-15.25" },
  { value: "-15.00", label: "-15.00" },
  { value: "-14.75", label: "-14.75" },
  { value: "-14.50", label: "-14.50" },
  { value: "-14.25", label: "-14.25" },
  { value: "-14.00", label: "-14.00" },
  { value: "-13.75", label: "-13.75" },
  { value: "-13.50", label: "-13.50" },
  { value: "-13.25", label: "-13.25" },
  { value: "-13.00", label: "-13.00" },
  { value: "-12.75", label: "-12.75" },
  { value: "-12.50", label: "-12.50" },
  { value: "-12.25", label: "-12.25" },
  { value: "-12.00", label: "-12.00" },
  { value: "-11.75", label: "-11.75" },
  { value: "-11.50", label: "-11.50" },
  { value: "-11.25", label: "-11.25" },
  { value: "-11.00", label: "-11.00" },
  { value: "-10.75", label: "-10.75" },
  { value: "-10.50", label: "-10.50" },
  { value: "-10.25", label: "-10.25" },
  { value: "-10.00", label: "-10.00" },
  { value: "-9.75", label: "-9.75" },
  { value: "-9.50", label: "-9.50" },
  { value: "-9.25", label: "-9.25" },
  { value: "-9.00", label: "-9.00" },
  { value: "-8.75", label: "-8.75" },
  { value: "-8.50", label: "-8.50" },
  { value: "-8.25", label: "-8.25" },
  { value: "-8.00", label: "-8.00" },
  { value: "-7.75", label: "-7.75" },
  { value: "-7.25", label: "-7.25" },
  { value: "-7.50", label: "-7.50" },
  { value: "-7.00", label: "-7.00" },
  { value: "-6.75", label: "-6.75" },
  { value: "-6.50", label: "-6.50" },
  { value: "-6.25", label: "-6.25" },
  { value: "-6.00", label: "-6.00" },
  { value: "-5.75", label: "-5.75" },
  { value: "-5.50", label: "-5.50" },
  { value: "-5.25", label: "-5.25" },
  { value: "-5.00", label: "-5.00" },
  { value: "-4.75", label: "-4.75" },
  { value: "-4.50", label: "-4.50" },
  { value: "-4.25", label: "-4.25" },
  { value: "-4.00", label: "-4.00" },
  { value: "-3.75", label: "-3.75" },
  { value: "-3.50", label: "-3.50" },
  { value: "-3.25", label: "-3.25" },
  { value: "-3.00", label: "-3.00" },
  { value: "-2.75", label: "-2.75" },
  { value: "-2.50", label: "-2.50" },
  { value: "-2.25", label: "-2.25" },
  { value: "-2.00", label: "-2.00" },
  { value: "-1.75", label: "-1.75" },
  { value: "-1.50", label: "-1.50" },
  { value: "-1.25", label: "-1.25" },
  { value: "-1.00", label: "-1.00" },
  { value: "-0.75", label: "-0.75" },
  { value: "-0.50", label: "-0.50" },
  { value: "-0.25", label: "-0.25" },
  { value: "+0.00", label: "+0.00" },
  { value: "+0.25", label: "+0.25" },
  { value: "+0.50", label: "+0.50" },
  { value: "+0.75", label: "+0.75" },
  { value: "+1.00", label: "+1.00" },
  { value: "+1.25", label: "+1.25" },
  { value: "+1.50", label: "+1.50" },
  { value: "+1.75", label: "+1.75" },
  { value: "+2.00", label: "+2.00" },
  { value: "+2.25", label: "+2.25" },
  { value: "+2.50", label: "+2.50" },
  { value: "+2.75", label: "+2.75" },
  { value: "+3.00", label: "+3.00" },
  { value: "+3.25", label: "+3.25" },
  { value: "+3.50", label: "+3.50" },
  { value: "+3.75", label: "+3.75" },
  { value: "+4.00", label: "+4.00" },
  { value: "+4.25", label: "+4.25" },
  { value: "+4.50", label: "+4.50" },
  { value: "+4.75", label: "+4.75" },
  { value: "+5.00", label: "+5.00" },
  { value: "+5.25", label: "+5.25" },
  { value: "+5.50", label: "+5.50" },
  { value: "+5.75", label: "+5.75" },
  { value: "+6.00", label: "+6.00" },
  { value: "+6.25", label: "+6.25" },
  { value: "+6.50", label: "+6.50" },
  { value: "+6.75", label: "+6.75" },
  { value: "+7.00", label: "+7.00" },
  { value: "+7.25", label: "+7.25" },
  { value: "+7.50", label: "+7.50" },
  { value: "+7.75", label: "+7.75" },
  { value: "+8.00", label: "+8.00" },
  { value: "+8.25", label: "+8.25" },
  { value: "+8.50", label: "+8.50" },
  { value: "+8.75", label: "+8.75" },
  { value: "+9.00", label: "+9.00" },
  { value: "+9.25", label: "+9.25" },
  { value: "+9.50", label: "+9.50" },
  { value: "+9.75", label: "+9.75" },
  { value: "+10.00", label: "+10.00" },
  { value: "+10.25", label: "+10.25" },
  { value: "+10.50", label: "+10.50" },
  { value: "+10.75", label: "+10.75" },
  { value: "+11.00", label: "+11.00" },
  { value: "+11.25", label: "+11.25" },
  { value: "+11.50", label: "+11.50" },
  { value: "+11.75", label: "+11.75" },
  { value: "+12.00", label: "+12.00" },
  { value: "+12.25", label: "+12.25" },
  { value: "+12.50", label: "+12.50" },
  { value: "+12.75", label: "+12.75" },
  { value: "+13.00", label: "+13.00" },
  { value: "+13.25", label: "+13.25" },
  { value: "+13.50", label: "+13.50" },
  { value: "+13.75", label: "+13.75" },
  { value: "+14.00", label: "+14.00" },
  { value: "+14.25", label: "+14.25" },
  { value: "+14.50", label: "+14.50" },
  { value: "+14.75", label: "+14.75" },
  { value: "+15.00", label: "+15.00" },
  { value: "+15.25", label: "+15.25" },
  { value: "+15.50", label: "+15.50" },
  { value: "+15.75", label: "+15.75" },
  { value: "+16.00", label: "+16.00" },
  { value: "+16.25", label: "+16.25" },
  { value: "+16.50", label: "+16.50" },
  { value: "+16.75", label: "+16.75" },
  { value: "+17.00", label: "+17.00" },
  { value: "+17.25", label: "+17.25" },
  { value: "+17.50", label: "+17.50" },
  { value: "+17.75", label: "+17.75" },
  { value: "+18.00", label: "+18.00" },
  { value: "+18.25", label: "+18.25" },
  { value: "+18.50", label: "+18.50" },
  { value: "+18.75", label: "+18.75" },
  { value: "+19.00", label: "+19.00" },
  { value: "+19.25", label: "+19.25" },
  { value: "+19.50", label: "+19.50" },
  { value: "+19.75", label: "+19.75" },
  { value: "+20.00", label: "+20.00" },
  { label: "+20.25", value: "+20.25" },
  { label: "+20.50", value: "+20.50" },
  { label: "+20.75", value: "+20.75" },
  { label: "+21.00", value: "+21.00" },
  { label: "+21.25", value: "+21.25" },
  { label: "+21.50", value: "+21.50" },
  { label: "+21.75", value: "+21.75" },
  { label: "+22.00", value: "+22.00" },
  { label: "+22.25", value: "+22.25" },
  { label: "+22.50", value: "+22.50" },
  { label: "+22.75", value: "+22.75" },
  { label: "+23.00", value: "+23.00" },
  { label: "+23.25", value: "+23.25" },
  { label: "+23.50", value: "+23.50" },
  { label: "+23.75", value: "+23.75" },
  { label: "+24.00", value: "+24.00" },
  { label: "+24.25", value: "+24.25" },
  { label: "+24.50", value: "+24.50" },
  { label: "+24.75", value: "+24.75" },
  { label: "+25.00", value: "+25.00" },
  { label: "+25.25", value: "+25.25" },
  { label: "+25.50", value: "+25.50" },
  { label: "+25.75", value: "+25.75" },
  { label: "+26.00", value: "+26.00" },
];

export const CILINDRICO = [
  { value: "-0.25", label: "-0.25" },
  { value: "-0.50", label: "-0.50" },
  { value: "-0.75", label: "-0.75" },
  { value: "-1.00", label: "-1.00" },
  { value: "-1.25", label: "-1.25" },
  { value: "-1.50", label: "-1.50" },
  { value: "-1.75", label: "-1.75" },
  { value: "-2.00", label: "-2.00" },
  { value: "-2.25", label: "-2.25" },
  { value: "-2.50", label: "-2.50" },
  { value: "-2.75", label: "-2.75" },
  { value: "-3.00", label: "-3.00" },
  { value: "-3.25", label: "-3.25" },
  { value: "-3.50", label: "-3.50" },
  { value: "-3.75", label: "-3.75" },
  { value: "-4.00", label: "-4.00" },
  { value: "-4.25", label: "-4.25" },
  { value: "-4.50", label: "-4.50" },
  { value: "-4.75", label: "-4.75" },
  { value: "-5.00", label: "-5.00" },
  { value: "-5.25", label: "-5.25" },
  { value: "-5.50", label: "-5.50" },
  { value: "-5.75", label: "-5.75" },
  { value: "-6.00", label: "-6.00" },
  { value: "-6.25", label: "-6.25" },
  { value: "-6.50", label: "-6.50" },
  { value: "-6.75", label: "-6.75" },
  { value: "-7.00", label: "-7.00" },
  { value: "-7.25", label: "-7.25" },
  { value: "-7.50", label: "-7.50" },
  { value: "-7.75", label: "-7.75" },
  { value: "-8.00", label: "-8.00" },
];

export const CILINDRICOAtacado = [
  { value: "-0.00", label: "-0.00" },
  { value: "-0.25", label: "-0.25" },
  { value: "-0.50", label: "-0.50" },
  { value: "-0.75", label: "-0.75" },
  { value: "-1.00", label: "-1.00" },
  { value: "-1.25", label: "-1.25" },
  { value: "-1.50", label: "-1.50" },
  { value: "-1.75", label: "-1.75" },
  { value: "-2.00", label: "-2.00" },
  { value: "-2.25", label: "-2.25" },
  { value: "-2.50", label: "-2.50" },
  { value: "-2.75", label: "-2.75" },
  { value: "-3.00", label: "-3.00" },
  { value: "-3.25", label: "-3.25" },
  { value: "-3.50", label: "-3.50" },
  { value: "-3.75", label: "-3.75" },
  { value: "-4.00", label: "-4.00" },
  { value: "-4.25", label: "-4.25" },
  { value: "-4.50", label: "-4.50" },
  { value: "-4.75", label: "-4.75" },
  { value: "-5.00", label: "-5.00" },
  { value: "-5.25", label: "-5.25" },
  { value: "-5.50", label: "-5.50" },
  { value: "-5.75", label: "-5.75" },
  { value: "-6.00", label: "-6.00" },
  { value: "-6.25", label: "-6.25" },
  { value: "-6.50", label: "-6.50" },
  { value: "-6.75", label: "-6.75" },
  { value: "-7.00", label: "-7.00" },
  { value: "-7.25", label: "-7.25" },
  { value: "-7.50", label: "-7.50" },
  { value: "-7.75", label: "-7.75" },
  { value: "-8.00", label: "-8.00" },
];

export const EIXO = [
  { value: "0", label: "0" },
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
  { value: "13", label: "13" },
  { value: "14", label: "14" },
  { value: "15", label: "15" },
  { value: "16", label: "16" },
  { value: "17", label: "17" },
  { value: "18", label: "18" },
  { value: "19", label: "19" },
  { value: "20", label: "20" },
  { value: "21", label: "21" },
  { value: "22", label: "22" },
  { value: "23", label: "23" },
  { value: "24", label: "24" },
  { value: "25", label: "25" },
  { value: "26", label: "26" },
  { value: "27", label: "27" },
  { value: "28", label: "28" },
  { value: "29", label: "29" },
  { value: "30", label: "30" },
  { value: "31", label: "31" },
  { value: "32", label: "32" },
  { value: "33", label: "33" },
  { value: "34", label: "34" },
  { value: "35", label: "35" },
  { value: "36", label: "36" },
  { value: "37", label: "37" },
  { value: "38", label: "38" },
  { value: "39", label: "39" },
  { value: "40", label: "40" },
  { value: "41", label: "41" },
  { value: "42", label: "42" },
  { value: "43", label: "43" },
  { value: "44", label: "44" },
  { value: "45", label: "45" },
  { value: "46", label: "46" },
  { value: "47", label: "47" },
  { value: "48", label: "48" },
  { value: "49", label: "49" },
  { value: "50", label: "50" },
  { value: "51", label: "51" },
  { value: "52", label: "52" },
  { value: "53", label: "53" },
  { value: "54", label: "54" },
  { value: "55", label: "55" },
  { value: "56", label: "56" },
  { value: "57", label: "57" },
  { value: "58", label: "58" },
  { value: "59", label: "59" },
  { value: "60", label: "60" },
  { value: "61", label: "61" },
  { value: "62", label: "62" },
  { value: "63", label: "63" },
  { value: "64", label: "64" },
  { value: "65", label: "65" },
  { value: "66", label: "66" },
  { value: "67", label: "67" },
  { value: "68", label: "68" },
  { value: "69", label: "69" },
  { value: "70", label: "70" },
  { value: "71", label: "71" },
  { value: "72", label: "72" },
  { value: "73", label: "73" },
  { value: "74", label: "74" },
  { value: "75", label: "75" },
  { value: "76", label: "76" },
  { value: "77", label: "77" },
  { value: "78", label: "78" },
  { value: "79", label: "79" },
  { value: "80", label: "80" },
  { value: "81", label: "81" },
  { value: "82", label: "82" },
  { value: "83", label: "83" },
  { value: "84", label: "84" },
  { value: "85", label: "85" },
  { value: "86", label: "86" },
  { value: "87", label: "87" },
  { value: "88", label: "88" },
  { value: "89", label: "89" },
  { value: "90", label: "90" },
  { value: "91", label: "91" },
  { value: "92", label: "92" },
  { value: "93", label: "93" },
  { value: "94", label: "94" },
  { value: "95", label: "95" },
  { value: "96", label: "96" },
  { value: "97", label: "97" },
  { value: "98", label: "98" },
  { value: "99", label: "99" },
  { value: "100", label: "100" },
  { value: "101", label: "101" },
  { value: "102", label: "102" },
  { value: "103", label: "103" },
  { value: "104", label: "104" },
  { value: "105", label: "105" },
  { value: "106", label: "106" },
  { value: "107", label: "107" },
  { value: "108", label: "108" },
  { value: "109", label: "109" },
  { value: "110", label: "110" },
  { value: "111", label: "111" },
  { value: "112", label: "112" },
  { value: "113", label: "113" },
  { value: "114", label: "114" },
  { value: "115", label: "115" },
  { value: "116", label: "116" },
  { value: "117", label: "117" },
  { value: "118", label: "118" },
  { value: "119", label: "119" },
  { value: "120", label: "120" },
  { value: "121", label: "121" },
  { value: "122", label: "122" },
  { value: "123", label: "123" },
  { value: "124", label: "124" },
  { value: "125", label: "125" },
  { value: "126", label: "126" },
  { value: "127", label: "127" },
  { value: "128", label: "128" },
  { value: "129", label: "129" },
  { value: "130", label: "130" },
  { value: "131", label: "131" },
  { value: "132", label: "132" },
  { value: "133", label: "133" },
  { value: "134", label: "134" },
  { value: "135", label: "135" },
  { value: "136", label: "136" },
  { value: "137", label: "137" },
  { value: "138", label: "138" },
  { value: "139", label: "139" },
  { value: "140", label: "140" },
  { value: "141", label: "141" },
  { value: "142", label: "142" },
  { value: "143", label: "143" },
  { value: "144", label: "144" },
  { value: "145", label: "145" },
  { value: "146", label: "146" },
  { value: "147", label: "147" },
  { value: "148", label: "148" },
  { value: "149", label: "149" },
  { value: "150", label: "150" },
  { value: "151", label: "151" },
  { value: "152", label: "152" },
  { value: "153", label: "153" },
  { value: "154", label: "154" },
  { value: "155", label: "155" },
  { value: "156", label: "156" },
  { value: "157", label: "157" },
  { value: "158", label: "158" },
  { value: "159", label: "159" },
  { value: "160", label: "160" },
  { value: "161", label: "161" },
  { value: "162", label: "162" },
  { value: "163", label: "163" },
  { value: "164", label: "164" },
  { value: "165", label: "165" },
  { value: "166", label: "166" },
  { value: "167", label: "167" },
  { value: "168", label: "168" },
  { value: "169", label: "169" },
  { value: "170", label: "170" },
  { value: "171", label: "171" },
  { value: "172", label: "172" },
  { value: "173", label: "173" },
  { value: "174", label: "174" },
  { value: "175", label: "175" },
  { value: "176", label: "176" },
  { value: "177", label: "177" },
  { value: "178", label: "178" },
  { value: "179", label: "179" },
  { value: "180", label: "180" },
];

export const ADICAO = [
  { label: "+0.50", value: "+0.50" },
  { label: "+0.75", value: "+0.75" },
  { label: "+1.00", value: "+1.00" },
  { label: "+1.25", value: "+1.25" },
  { label: "+1.50", value: "+1.50" },
  { label: "+1.75", value: "+1.75" },
  { label: "+2.00", value: "+2.00" },
  { label: "+2.25", value: "+2.25" },
  { label: "+2.50", value: "+2.50" },
  { label: "+2.75", value: "+2.75" },
  { label: "+3.00", value: "+3.00" },
  { label: "+3.25", value: "+3.25" },
  { label: "+3.50", value: "+3.50" },
  { label: "+3.75", value: "+3.75" },
  { label: "+4.00", value: "+4.00" },
];

export const PRISMA = [
  { label: "0.25", value: "0.25" },
  { label: "0.50", value: "0.50" },
  { label: "0.75", value: "0.75" },
  { label: "1.00", value: "1.00" },
  { label: "1.25", value: "1.25" },
  { label: "1.50", value: "1.50" },
  { label: "1.75", value: "1.75" },
  { label: "2.00", value: "2.00" },
  { label: "2.25", value: "2.25" },
  { label: "2.50", value: "2.50" },
  { label: "2.75", value: "2.75" },
  { label: "3.00", value: "3.00" },
  { label: "3.25", value: "3.25" },
  { label: "3.50", value: "3.50" },
  { label: "3.75", value: "3.75" },
  { label: "4.00", value: "4.00" },
  { label: "4.25", value: "4.25" },
  { label: "4.50", value: "4.50" },
  { label: "4.75", value: "4.75" },
  { label: "5.00", value: "5.00" },
  { label: "5.25", value: "5.25" },
  { label: "5.50", value: "5.50" },
  { label: "5.75", value: "5.75" },
  { label: "6.00", value: "6.00" },
  { label: "6.25", value: "6.25" },
  { label: "6.50", value: "6.50" },
  { label: "6.75", value: "6.75" },
  { label: "7.00", value: "7.00" },
  { label: "7.25", value: "7.25" },
  { label: "7.50", value: "7.50" },
  { label: "7.75", value: "7.75" },
  { label: "8.00", value: "8.00" },
  { label: "8.25", value: "8.25" },
  { label: "8.50", value: "8.50" },
  { label: "8.75", value: "8.75" },
  { label: "9.00", value: "9.00" },
  { label: "9.25", value: "9.25" },
  { label: "9.50", value: "9.50" },
  { label: "9.75", value: "9.75" },
  { label: "10.00", value: "10.00" },
  { label: "10.25", value: "10.25" },
  { label: "10.50", value: "10.50" },
  { label: "10.75", value: "10.75" },
  { label: "11.00", value: "11.00" },
  { label: "11.25", value: "11.25" },
  { label: "11.50", value: "11.50" },
  { label: "11.75", value: "11.75" },
  { label: "12.00", value: "12.00" },
];

export const DIAMETRO = [
  { value: "58", label: "58" },
  { value: "59", label: "59" },
  { value: "60", label: "60" },
  { value: "61", label: "61" },
  { value: "62", label: "62" },
  { value: "63", label: "63" },
  { value: "64", label: "64" },
  { value: "65", label: "65" },
  { value: "66", label: "66" },
  { value: "67", label: "67" },
  { value: "68", label: "68" },
  { value: "69", label: "69" },
  { value: "70", label: "70" },
  { value: "71", label: "71" },
  { value: "72", label: "72" },
  { value: "73", label: "73" },
  { value: "74", label: "74" },
  { value: "75", label: "75" },
  { value: "76", label: "76" },
  { value: "77", label: "77" },
  { value: "78", label: "78" },
  { value: "79", label: "79" },
  { value: "80", label: "80" },
  { value: "81", label: "81" },
  { value: "82", label: "82" },
  { value: "83", label: "83" },
  { value: "84", label: "84" },
  { value: "85", label: "85" },
];

export const BASE = [
  { label: "0.25", value: "0.25" },
  { label: "0.50", value: "0.50" },
  { label: "0.75", value: "0.75" },
  { label: "1.00", value: "1.00" },
  { label: "1.25", value: "1.25" },
  { label: "1.50", value: "1.50" },
  { label: "1.75", value: "1.75" },
  { label: "2.00", value: "2.00" },
  { label: "2.25", value: "2.25" },
  { label: "2.50", value: "2.50" },
  { label: "2.75", value: "2.75" },
  { label: "3.00", value: "3.00" },
  { label: "3.25", value: "3.25" },
  { label: "3.50", value: "3.50" },
  { label: "3.75", value: "3.75" },
  { label: "4.00", value: "4.00" },
  { label: "4.25", value: "4.25" },
  { label: "4.50", value: "4.50" },
  { label: "4.75", value: "4.75" },
  { label: "5.00", value: "5.00" },
  { label: "5.25", value: "5.25" },
  { label: "5.50", value: "5.50" },
  { label: "5.75", value: "5.75" },
  { label: "6.00", value: "6.00" },
  { label: "6.25", value: "6.25" },
  { label: "6.50", value: "6.50" },
  { label: "6.75", value: "6.75" },
  { label: "7.00", value: "7.00" },
  { label: "7.25", value: "7.25" },
  { label: "7.50", value: "7.50" },
  { label: "7.75", value: "7.75" },
  { label: "8.00", value: "8.00" },
  { label: "8.25", value: "8.25" },
  { label: "8.50", value: "8.50" },
  { label: "8.75", value: "8.75" },
  { label: "9.00", value: "9.00" },
  { label: "9.25", value: "9.25" },
  { label: "9.50", value: "9.50" },
  { label: "9.75", value: "9.75" },
  { label: "10.00", value: "10.00" },
  { label: "10.25", value: "10.25" },
  { label: "10.50", value: "10.50" },
  { label: "10.75", value: "10.75" },
  { label: "11.00", value: "11.00" },
  { label: "11.25", value: "11.25" },
  { label: "11.50", value: "11.50" },
  { label: "11.75", value: "11.75" },
  { label: "12.00", value: "12.00" },
  { label: "12.25", value: "12.25" },
  { label: "12.50", value: "12.50" },
  { label: "12.75", value: "12.75" },
  { label: "13.00", value: "13.00" },
  { label: "13.25", value: "13.25" },
  { label: "13.50", value: "13.50" },
  { label: "13.75", value: "13.75" },
  { label: "14.00", value: "14.00" },
  { label: "14.25", value: "14.25" },
  { label: "14.50", value: "14.50" },
  { label: "14.75", value: "14.75" },
  { label: "15.00", value: "15.00" },
  { label: "15.25", value: "15.25" },
  { label: "15.50", value: "15.50" },
  { label: "15.75", value: "15.75" },
  { label: "16.00", value: "16.00" },
  { label: "16.25", value: "16.25" },
  { label: "16.50", value: "16.50" },
  { label: "16.75", value: "16.75" },
  { label: "17.00", value: "17.00" },
  { label: "17.25", value: "17.25" },
  { label: "17.50", value: "17.50" },
  { label: "17.75", value: "17.75" },
  { label: "18.00", value: "18.00" },
  { label: "18.25", value: "18.25" },
  { label: "18.50", value: "18.50" },
  { label: "18.75", value: "18.75" },
  { label: "19.00", value: "19.00" },
  { label: "19.25", value: "19.25" },
  { label: "19.50", value: "19.50" },
  { label: "19.75", value: "19.75" },
  { label: "20.00", value: "20.00" },
];
