import React, { Component } from 'react'

//Import CSS

//Import internal components
import { Link } from "react-router-dom";
//Import external components

//Import image
import logoconecta from './img/logo-banner-2021.png'

class HeaderLogo extends Component {

    constructor(props) {
        super(props);

        this.state = {
            link: '/'
        }
    }

    componentDidMount() {
        if (localStorage.getItem("token") !== null) {
            this.setState({ link: "/home" });
        }
    }



    render() {
        const { link } = this.state;

        return (
            <div className="header__logo">
                <Link
                    className='login-banner__logo-link'
                    to={link}
                >
                    <img src={logoconecta} alt="Estilos conecta" className="header__logo-img" />
                </Link>
            </div>
        );
    }
}

export default HeaderLogo;