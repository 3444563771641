import React, { Component } from "react";
import { Link } from "react-router-dom";
import AlertNews from "../main/alert-news/alert-news";
import Swal from "sweetalert2";
import AppContext from "../appContext";

class Module extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    Module.contextType = AppContext;
    this.clickPromo = this.clickPromo.bind(this);
  }

  clickPromo(url, target) {
    let cnpj = this.context.userSession.conta.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
    let urlPadrao = this.props.urlPadrao;
    let urlOk = urlPadrao.replace("/login/sso", "/logoff");
    Swal.fire({
      html: `<p>Deseja ser redirecionado para o site Essilor Promo já autenticado com o CNPJ <strong>${cnpj}</strong>?</p>`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00518b",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then(result => {
      if (result.value) {
        window.open(url, "_blank");
      } else {
        window.open(urlOk !== "" ? urlOk : url, "_blank");
      }
    });
  }

  render() {
    return (
      <li className="module" key={this.props.index}>
        <div className="module_logo">
          <AlertNews data={this.props.dataLimiteNovidade} mensagem={this.props.mensagemNovidade} />
          <img src={this.props.logo} alt="" className="module_logo-img" />
        </div>

        {this.props.nome === "Essilor Promo" ? (
          <>
            <button className="module_button" onClick={() => this.clickPromo(this.props.url, this.props.target)}>
              <span className="module_button-text">{this.props.message}</span>

              <svg xmlns="http://www.w3.org/2000/svg" className="module_svg" width="21" height="21" viewBox="0 0 21 21">
                <g id="Group_13" data-name="Group 13" transform="translate(-117.064 -5.315)">
                  <circle id="Ellipse_9" data-name="Ellipse 9" cx="10.5" cy="10.5" r="10.5" transform="translate(117.064 5.315)" fill="#fff" />
                  <path
                    className="x-my-essilor__button-arrow"
                    id="np_arrow_2957554_000000"
                    d="M4.026,6.652l3-5.861A.544.544,0,0,0,6.209.117L3.882,1.947a.511.511,0,0,1-.658,0L.879.117A.545.545,0,0,0,.06.791l3,5.861a.546.546,0,0,0,.964,0Z"
                    transform="translate(124.214 19.131) rotate(-90)"
                    fill="#0066a0"
                  />
                </g>
              </svg>
            </button>
          </>
        ) : (
          <>
            <a href={this.props.url} target={this.props.target}>
              <button className="module_button">
                <span className="module_button-text">{this.props.message}</span>

                <svg xmlns="http://www.w3.org/2000/svg" className="module_svg" width="21" height="21" viewBox="0 0 21 21">
                  <g id="Group_13" data-name="Group 13" transform="translate(-117.064 -5.315)">
                    <circle id="Ellipse_9" data-name="Ellipse 9" cx="10.5" cy="10.5" r="10.5" transform="translate(117.064 5.315)" fill="#fff" />
                    <path
                      className="x-my-essilor__button-arrow"
                      id="np_arrow_2957554_000000"
                      d="M4.026,6.652l3-5.861A.544.544,0,0,0,6.209.117L3.882,1.947a.511.511,0,0,1-.658,0L.879.117A.545.545,0,0,0,.06.791l3,5.861a.546.546,0,0,0,.964,0Z"
                      transform="translate(124.214 19.131) rotate(-90)"
                      fill="#0066a0"
                    />
                  </g>
                </svg>
              </button>
            </a>
          </>
        )}
      </li>
    );
  }
}

export default Module;
