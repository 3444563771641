async function getDioptrias(produto, tipoProduto, lab) {
  try {
    const requestOptions = {
      method: "GET",
      headers: {
        access_token: localStorage.getItem("token"),
        client_id: localStorage.getItem("clientid"),
        laboratorio: lab,
      },
    };
    var status = "";
    return fetch(
      process.env.REACT_APP_ESSILOR_API + "gestao-produtos-laboratorio/v1/produtos-atacado/" + tipoProduto + "/parametros?tipoProduto=" + produto,
      requestOptions,
    )
      .then(response => {
        status = response.status;
        return response.json();
      })
      .then(json => {
        var novoArray = { status: status, esferico: [], base: [], adicao: [], cilindrico: [], diametro: [] };
    
        if (status === 200) {
            for (let i = 0; i < json.esferico.length; i++) {
                const numero = parseFloat(json.esferico[i]);
                const label = numero >= 0 && String(numero).charAt(0) !== "+" ? "+" + numero.toFixed(2) : numero.toFixed(2);
                const value = numero >= 0 && String(numero).charAt(0) !== "+" ? "+" + numero.toFixed(2) : numero.toFixed(2);
                const objeto = { label, value };
    
                novoArray.esferico.push(objeto);
            }
    
            for (let i = 0; i < json.adicao.length; i++) {
                const numero = parseFloat(json.adicao[i]);
                const label = numero >= 0 && String(numero).charAt(0) !== "+" ? "+" + numero.toFixed(2) : numero.toFixed(2);
                const value = numero >= 0 && String(numero).charAt(0) !== "+" ? "+" + numero.toFixed(2) : numero.toFixed(2);
                const objeto = { label, value };
    
                novoArray.adicao.push(objeto);
            }
    
            for (let i = 0; i < json.base.length; i++) {
                const numero = parseFloat(json.base[i]);
                const label = numero >= 0 && String(numero).charAt(0) !== "+" ? "+" + numero.toFixed(2) : numero.toFixed(2);
                const value = numero >= 0 && String(numero).charAt(0) !== "+" ? "+" + numero.toFixed(2) : numero.toFixed(2);
                const objeto = { label, value };
    
                novoArray.base.push(objeto);
            }
    
            for (let i = 0; i < json.cilindrico.length; i++) {
                const numero = parseFloat(json.cilindrico[i]);
                const label = numero >= 0 && String(numero).charAt(0) !== "-" ? "-" + numero.toFixed(2) : numero.toFixed(2);
                const value = numero >= 0 && String(numero).charAt(0) !== "-" ? "-" + numero.toFixed(2) : numero.toFixed(2);
                const objeto = { label, value };
    
                novoArray.cilindrico.push(objeto);
            }
    
            for (let i = 0; i < json.diametro.length; i++) {
                const numero = parseFloat(json.diametro[i]);
                const label = numero >= 0 && String(numero).charAt(0) !== "+" ? "+" + numero.toFixed(2) : numero.toFixed(2);
                const value = numero >= 0 && String(numero).charAt(0) !== "+" ? "+" + numero.toFixed(2) : numero.toFixed(2);
                const objeto = { label, value };
    
                novoArray.diametro.push(objeto);
            }
    
            // Ordenando os arrays
            novoArray.esferico.sort((a, b) => parseFloat(a.value) - parseFloat(b.value));
            novoArray.adicao.sort((a, b) => parseFloat(a.value) - parseFloat(b.value));
            novoArray.base.sort((a, b) => parseFloat(a.value) - parseFloat(b.value));
            novoArray.cilindrico.sort((a, b) => parseFloat(a.value) - parseFloat(b.value));
            novoArray.diametro.sort((a, b) => parseFloat(a.value) - parseFloat(b.value));
    
            return novoArray;
        } else return status;
    });
  } catch (error) {
    throw error;
  }
}

export { getDioptrias };
