import { isThisHour } from "date-fns/esm";
import React, { Component } from "react";
import { Badge } from "react-bootstrap";
import AppContext from "../../appContext";
import AutoCompleteSelect from "../../components/autocomplete-select/autocomplete-select";
import Select from "../../components/selects/select";
import CRMSwitch from "../../crm/crm-commons/crm-switch";
import Swal from "sweetalert2";
import { getDioptrias } from "../../Functions/dioptrias/getDioptrias";

import "./../pedido-atacado.css";

class PedidoAtacadoEtapaDois extends Component {
  constructor(props) {
    super(props);
    this.autoRef = React.createRef();
    this.state = {
      tipo_produto: "",
      tipoProdutos: [],
      produtos: [],
      produto: "",
      produto_cod: 0,
      placeholder: "Nenhum resultado encontrado",
    };

    PedidoAtacadoEtapaDois.contextType = AppContext;
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeAutocomplete = this.handleChangeAutocomplete.bind(this);
    this.getProdutos = this.getProdutos.bind(this);
    this.getTipoProdutoAtacado = this.getTipoProdutoAtacado.bind(this);
  }

  getTipoProdutoAtacado = async IdLab => {
    if (IdLab !== "") {
      var myHeaders = new Headers({
        Authorization: "Bearer " + localStorage.getItem("session_token"),
      });

      const requestOptions = {
        method: "POST",
        body: JSON.stringify({ laboratorio: IdLab }),
        headers: myHeaders,
      };

      await fetch(process.env.REACT_APP_LABS_API + "get_produtos_atacado.php", requestOptions)
        .then(response => {
          if (response.status === 200) {
            return response.json();
          } else {
            return [];
          }
        })
        .then(json => {
          if (json && json.items) {
            this.setState({
              tipoProdutos: json.items,
            });
            this.props.onChange({
              adicao: [],
              base: [],
              cilindrico: [],
              diametro: [],
              esferico: [],
              dioptrias: [],
            });
          } else {
            this.context.hideLoading();

            this.setState({
              tipoProdutos: [],
              placeholder: "Nenhum resultado encontrado",
            });
          }
        })
        .catch(error => {
          this.context.hideLoading();

          this.setState({
            tipoProdutos: [],
            placeholder: "Nenhum resultado encontrado",
          });
        });

      this.context.hideLoading();
    }
    this.context.hideLoading();
  };

  componentDidMount() {
    this.props.cleanFields();
    this.context.showLoading("Carregando informações...");
    this.getTipoProdutoAtacado(this.props.labId);
  }

  clearProduto() {
    const node = this.autoRef.current;
    node.clearValue();
  }

  async handleChangeAutocomplete(item, aux) {
    this.context.showLoading();

    if (item) {
     
      this.props.validateFields();
        this.setState({ produtoBefore: item.value });
      
      let produto = {
        codigo: item.value,
        nome: item.label,
        quantidade: 0,
      };

      this.props.onChange({ produto: produto });
      this.setState({ produto_cod: item.value });

      const dioptrias = await getDioptrias(this.state.tipo_produto, item.value, this.props.labcnpj);
        if (dioptrias?.status === 200) {
        if (this.props.tipo_produtosOEC) {
          var dioptriaOEC = this.props.tipo_produtosOEC.split(",");
        }
        this.props.onChange({ adicao: [], base: [], cilindrico: [], diametro: [], esferico: [] }, () => {
          const dioptriasPreenchidas = Object.keys(dioptrias).filter(key => dioptrias[key].length > 0);

          const existePreenchido = dioptriaOEC.some(campos => {
            const campo = campos;
            var resultado = dioptriasPreenchidas.includes(campo.toLowerCase());
            return resultado;
          });
          if (existePreenchido) {
            this.props.onChange({
              adicao: dioptrias.adicao,
              base: dioptrias?.base,
              cilindrico: dioptrias.cilindrico,
              diametro: dioptrias.diametro,
              esferico: dioptrias.esferico,
              dioptrias: true,
            });
          } else {
            Swal.fire({
              title: "Erro",
              text: "O produto selecionado não possui dioptrias válidas cadastradas no laboratório.",
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Ok",
            });
            this.props.onChange({
              dioptrias: false,
              produto: [],
            });
            const node = this.autoRef.current;
            node.clearValue();
          }
        });
      }
      if (this.props.refToFirstField.current?.selectAuto) {
        this.props.refToFirstField.current.selectAuto.current.select.focus();
      }
    }
    this.context.hideLoading();
  }

  handleChange(e) {
   
    
    if (e.target.name === "tipo_produto") {
      this.props.cleanFields('produto');
      this.props.onChange({ adicao: [], base: [], cilindrico: [], diametro: [], esferico: [] });
      this.setState({ produto: "", produtos: [] });
      const node = this.autoRef.current;
      node.clearValue();

      if (this.state.tipoProdutos.length > 0) {
        this.props.changeTipoProdutosOEC(this.state.tipoProdutos.filter(item => item.Codigo_c === e.target.value)[0]);
      }

      this.setState({ [e.target.name]: e.target.value }, () => {
        this.props.changeTipoProduto(this.state);
      });
      this.getProdutos(e.target.value);
    }
  }

  getProdutos = async tipo => {
    this.context.showLoading("");
    if (tipo !== "") {
      this.setState({ placeholder: "Carregando..." });
      var url = `${process.env.REACT_APP_ESSILOR_API}gestao-produtos-laboratorio/v1/linhas-produtos-atacado?tipoProduto=${tipo}`;

      let requestOptions = [];
      if ((this.props?.redes?.length > 0 && this.context.userSession.redeOticas)) {
        requestOptions = {
          method: "GET",
          headers: {
            access_token: localStorage.getItem("token"),
            client_id: localStorage.getItem("clientid"),
            laboratorio: this.props.labcnpj,
            otica: this.props?.dados_empresa_faturamento_cnpj,
          },
        };
      } else {
        requestOptions = {
          method: "GET",
          headers: {
            access_token: localStorage.getItem("token"),
            client_id: localStorage.getItem("clientid"),
            laboratorio: this.props.labcnpj,
          },
        };
      }
      await fetch(url, requestOptions)
        .then(response => {
          if (response.status === 200) {
            return response.json();
          } else {
            return [];
          }
        })
        .then(json => {
          this.setState({
            produtos: json,
            placeholder: `${json.length} resultados encontrados`,
          });
        })
        .catch(error => {
          if (error.response) {
            // erros 50x e 4xx com resposta do servidor
            this.setState({
              produtos: [],
              placeholder: "Nenhum resultado encontrado",
            });
          } else {
            // erros 50x sem resposta do servidor
            this.setState({
              produtos: [],
              placeholder: "Nenhum resultado encontrado",
            });
            this.context.hideLoading();
            Swal.fire({
              title: "Aviso",
              text: "O serviço está temporariamente indisponível para este laboratório.",
              icon: "warning",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Ok",
            }).then(result => {});
          }
        });
    } else {
      this.setState({
        produtos: [],
        placeholder: "Nenhum resultado encontrado",
      });
    }
    this.context.hideLoading();
  };

  componentWillReceiveProps(nextProps) {
    if (this.state.tipo_produto !== "" && nextProps.tipo_produto === "") {
      this.setState({
        tipo_produto: "",
        produto: "",
        produtos: [],
        placeholder: "Nenhum resultado encontrado",
      });
      const node = this.autoRef.current;
      node.clearValue();
    }
  }

  render() {
    const { disableFields } = this.props;

    return (
      <>
        <p className="titulo-sessao mb-4">
          <Badge fill className="me-2">
            2
          </Badge>
          Produtos
        </p>
        <div className="row mb-3">
          <div className="col-12 col-md-4">
            <Select
              label="Tipo do produto*"
              width="100%"
              name="tipo_produto"
              aria="Selecione o tipo de produto"
              itens={this.state.tipoProdutos.map((item, index) => {
                return { label: item.Produto_c, value: item.Codigo_c, key: index };
              })}
              value={this.state.tipo_produto}
              placeholder="Selecione"
              onChange={this.handleChange}
              disabled={disableFields}
              required
            />
          </div>
          <div className="col-12 col-md-8 " style={{ alignSelf: "center", justifyContent: "center" }}>
            <AutoCompleteSelect
              className="inputProduto"
              width="100%"
              name="produto"
              id="inputProduto"
              ref={this.autoRef}
              options={this.state.produtos}
              onChange={this.handleChangeAutocomplete}
              defaultValue={this.state.produto}
              placeholder={this.state.placeholder}
              autocomplete="lente_esc_produto"
              tooltip="A consulta pode ser feita pelo código ou pelo nome do produto."
              label="Escolha o produto*"
              disabled={disableFields}
              readOnly={disableFields}
              style={{ marginRight: "0" }}></AutoCompleteSelect>
            {/* <CRMSwitch
                            label="GROWNMOÇÃO + WEB"
                            name="growmocao"
                            id="growmocao"
                            disabled={disableFields}
                            readOnly={disableFields}
                        /> */}
          </div>
        </div>
      </>
    );
  }
}

export default PedidoAtacadoEtapaDois;
