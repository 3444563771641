import Swal from "sweetalert2";
import { getRedes } from "../../Functions/redes/getRedes";

async function ModalVerificaRedes(laboratorio, otica, context) {
  const body = {
    laboratorio: laboratorio.cnpj,
    otica: otica,
    consultaRede: true,
    usuario: context,
  };

  try {
    const result = await getRedes(body);
    if (result) {
      let redes = result.redes;
      if (result.status === 200) {
        var RedesDisponiveis = `<p class="p-modal-verifica-redes">Estas são as óticas da rede cadastradas no laboratório <b>${
          laboratorio.descricao.split(" ")[0]
        }</b><br><br>`;

        for (const rede of redes) {
          RedesDisponiveis += `- C NPJ <b>${rede.value}:</b> ${rede.label} <br>`;
        }
        RedesDisponiveis += "</p>";
        Swal.fire({
          html: RedesDisponiveis,
          confirmButtonColor: "#00518b",
          width: "600px",
          customClass: {
            confirmButton: "x-follow-order__filter-btn",
          },
        });
      } else {
        Swal.fire({
          html: `<p class="p-modal-sem-redes">Esta ótica não  está cadastrada em nenhuma rede no laboratório <b>${laboratorio.descricao.split(" ")[0]}</p>`,
          confirmButtonColor: "#00518b",
          width: "600px",
          height: "600px",
          customClass: {
            confirmButton: "x-follow-order__filter-btn",
            popup: "custom-swal-height",
          },
        });
      }
    }
  } catch (error) {
    console.error("Erro ao usar a função getRedes:", error.message);
  }

  return true;
}

export { ModalVerificaRedes };
