import React, { Component } from "react";
import "../index/index.css";
// import qrEssilor from "./qrcode/QR_Essilor.png";
// import qrLab from "./qrcode/QR_Lab.png";
// import qrOtica from "./qrcode/QR_Otica.png";
import AppContext from "../appContext";

class CarouselConecta extends Component {
  constructor(props) {
    super(props);

    this.state = {
      banner: [],
      url: "",
      qrCode: null,
    };

    this.getBanner = this.getBanner.bind(this);
    CarouselConecta.contextType = AppContext;
  }

  componentDidMount() {
    this.getBanner();
    // this.transformQrCode();
  }

  transformQrCode() {
    // let url = "";
    // let qrCode = "";
    // if (this.context.userSession?.papelCategoria !== "") {
    //   if (this.context.userSession?.papelCategoria === "ESSILOR") {
    //     url = "https://drive.google.com/drive/folders/1lINZff7F-0cUAnODNFy3FVutdhkGgiG3";
    //     qrCode = qrEssilor;
    //   } else if (this.context.userSession?.papelCategoria === "LABORATORIO") {
    //     url = "https://drive.google.com/drive/folders/1euLzZQMoSRG00A9HLaiW0tkd-jQ2s4FK";
    //     qrCode = qrLab;
    //   } else if (this.context.userSession?.papelCategoria === "OTICA") {
    //     url = "https://drive.google.com/drive/folders/16upXoaxhGB0NEBbPtyyku3gDrhdfZCe-";
    //     qrCode = qrOtica;
    //   }
    // }
    // this.setState({
    //   qrCode: qrCode,
    //   url: url,
    // });
  }

  getBanner() {
    var token = localStorage.getItem("token");

    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );

    var jwt = JSON.parse(jsonPayload);

    let banner_array = [];

    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        papel: jwt.papel,
      }),
    };

    fetch(process.env.REACT_APP_LABS_API + "get_banner_home.php", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.status == "200") {
          for (var i = 0; i < json.items.length; i++) {
            var pushObj = {
              abrir_nova_pagina: json.items[i].abrir_nova_pagina_c,
              url_banner: json.items[i].url_banner_c,
              url_destino: json.items[i].url_destino_c,
            };
            banner_array.push(pushObj);
          }
          this.setState({
            banner: banner_array,
          });
        }
      });
  }

  render() {
    return (
      <div className="x-conecta">
        <h2 className="x-conecta__title">Conecta aí</h2>
        <span className="x-conecta__square"></span>

        {this.state.banner.map((item, index) => {
          return (
            <a href={item.url_destino} key={index} target={item.abrir_nova_pagina ? "_blank" : "_self"}>
              <div>
                <img className="banner-home" src={item.url_banner} alt="" />
              </div>
            </a>
          );
        })}
        {/* {this.state.url !== "" && (
          <div className="x-conecta-guides">
            <h2 className="x-qrcode-title">
              Acesse o Guia Conecta{" "}
              <a href={this.state.url} target="_blank" className="x-qrcode-link">
                neste link
              </a>{" "}
              ou pelo QR Code:
            </h2>
            <img className="x-qrcode-home-guides" src={this.state.qrCode} alt="" />
          </div>
        )} */}
      </div>
    );
  }
}

export default CarouselConecta;
