import React, { Component } from "react";

import { RxPedidos, TotalPorCanal, DadosGraphCanal } from "../data/index.js";
import MultipleSelect from "../../components/selects/select-chip";
import BarGraph from "../../components/graph";
import Table from "../table";
import Filtros from "../filtros";
import "./index.css";
import DataModule from "../../components/modulo/modulo-dados";
import AppContext from "../../appContext";
import { filterByCriteria } from "../../components/utils/filter";
import Swal from "sweetalert2";

class RX extends Component {
  constructor(props) {
    super(props);
    this.childRefs = [];
    this.state = {
      selected_option: 1,
      cnpj: "",
      filterAno: [],
      filterLab: [],
      filterCanal: [],
      filterMes: [],
      processedData: [],
    };
    this.childRef = React.createRef();
    this.filter = this.filter.bind(this);
    this.cleanFilters = this.cleanFilters.bind(this);
    RX.contextType = AppContext;
  }
  async componentDidMount() {
    let request = "";
    if (this.context.userSession.papelCategoria === "LABORATORIO") {
      request = {
        laboratorio: this.context.userSession.conta,
      };
    }
    this.context.showLoading("Carregando dados");

    if (this.state.processedData.length == 0) {
      const result = await RxPedidos(request);
      if (result == false) {
        Swal.fire({
          html: "Não foi possível recuperar as informações dos pedidos. Tente novamente mais tarde.",
          width: "auto",
          icon: "warning",
          title: "Aviso",
          confirmButtonColor: "#00518b",
          customClass: {
            confirmButton: "x-follow-order__filter-btn",
          },
        }).then(result => {});
      }
      this.setState({ processedData: result });
    }
    this.context.hideLoading();
  }
  cleanFilters = () => {
    this.childRefs.forEach(ref => {
      if (ref?.state.personName?.length >= 1) {
        ref.handleClear();
      }
    });

    this.setState(
      {
        processedData: {
          filterData: this.state.processedData.dadosAPI,
          dadosAPI: this.state.processedData.dadosAPI,
          canalLabel: this.state.processedData.canalLabel,
          anoLabel: this.state.processedData.anoLabel,
          mes: this.state.processedData.mes,
          titulo: this.state.processedData.titulo,
        },
        filterAno: null,
        filterLab: null,
        filterCanal: null,
        filterMes: null,
      },
      () => {
        this.applyFilters();
      },
    );
  };

  filter(data, filter) {
    if (filter === "Ano" && data) {
      this.setState({ filterAno: data }, this.applyFilters);
    } else if (filter === "Laboratório" && data) {
      this.setState({ filterLab: data }, this.applyFilters);
    } else if (filter === "Origem" && data) {
      this.setState({ filterCanal: data }, this.applyFilters);
    } else if (filter === "Mês" && data) {
      this.setState({ filterMes: data }, this.applyFilters);
    }
  }

  async applyFilters() {
    const { filterLab, filterAno, filterCanal, filterMes, processedData, selected_option } = this.state;
    const labCNPJs = filterLab
      ? filterLab
          .map(labName => {
            const lab = this.props.laboratorios.find(l => l.label.toLowerCase() === labName.toLowerCase());
            return lab ? lab.value : null;
          })
          .filter(cnpj => cnpj !== null)
      : [];

    const combinedFilters = [
      ...labCNPJs.map(value => ({ laboratorio: value })),
      ...(filterAno || []).map(value => ({ data: value })),
      ...(filterCanal || []).map(value => ({ canal: value })),
      ...(filterMes || []).map(value => ({ data: value })),
    ];

    var filtro = filterByCriteria(processedData.dadosAPI, combinedFilters);
    var total = await TotalPorCanal(filtro);
    var grafico = await DadosGraphCanal(filtro);

    this.setState({
      processedData: {
        filterData: filtro,
        dadosAPI: processedData.dadosAPI,
        filterChartData: grafico,
        total: total.total,
        canalLabel: processedData.canalLabel,
        anoLabel: processedData.anoLabel,
        mes: processedData.mes,
        titulo: processedData.titulo,
      },
    });
  }

  render() {
    const dataModule = [
      {
        id: 1,
        image: "image/modulos/logo_ConectaPedidos_dashboard.png",
        value: this.state.processedData?.total?.Conecta?.toLocaleString("pt-BR"),
        color: "10,50,100",
        alt: "Modulo Pedidos",
      },
      {
        id: 1,
        image: "image/modulos/logo_Optisoul_dashboard.png",
        value: this.state.processedData?.total?.Optisoul?.toLocaleString("pt-BR"),
        color: "60,90,300",
        alt: "OptiSoul",
      },
      {
        id: 1,
        image: "image/modulos/logo_Shop9_dashboard.png",
        value: this.state.processedData?.total?.Shop9?.toLocaleString("pt-BR"),
        color: "237,125,49",
        alt: "Shop9",
      },
    ];
    return (
      <div>
        <div className="row">
          <div className="col-2 mx-auto d-flex align-items-end">
            <Filtros
              filterCanal={this.state.filterCanal}
              filterMes={this.state.filterMes}
              filterLab={this.state.filterLab}
              filter={this.filter}
              laboratorio={this.props.laboratoriosNames}
              mes={this.state.processedData?.mes}
              canal={this.state.processedData?.canalLabel}
              cleanFilters={this.cleanFilters}
              anoLabel={this.state.processedData?.anoLabel}
              origem
            />
          </div>
          <div className="col-10">
            <div className="row mb-3 containerDados">
              <div className="col-7 dataModule-dashboardLab">
                <DataModule selected_option={this.state.selected_option} dataModule={dataModule} />
              </div>
            </div>
            <div className="graph-container">
              {this.state.processedData?.filterChartData?.length > 0 && (
                <BarGraph titulo={this.state.processedData?.titulo} data={this.state.processedData?.filterChartData} />
              )}
            </div>
          </div>

          <div className="total">
            <p>
              <strong>Total: </strong>
              {(this.state.processedData?.total?.Shop9 + this.state.processedData?.total?.Optisoul + this.state.processedData?.total?.Conecta).toLocaleString(
                "pt-BR",
              )}
            </p>
          </div>
        </div>
        <div className="mt-5">
          <Table selected_option={this.props.selected_option} data={this.state.processedData?.filterData} titulo={this.state.processedData?.titulo} />
        </div>
      </div>
    );
  }
}

export default RX;
