import React, { Component } from "react";

//Import CSS
import "./media-upload.css";

//Import internal components

//Import external components
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ImageUploader from "react-images-upload";
import Swal from "sweetalert2";
import AppContext from "../appContext";

//Import imagesMediaUploadFile

class MediaUploadFile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      preview: "",
      img_name: "",
      pictures: [],
    };

    this.onDrop = this.onDrop.bind(this);
    this.remove = this.remove.bind(this);
    this.uploadLogo = this.uploadLogo.bind(this);
    this.sendURL = this.sendURL.bind(this);

    MediaUploadFile.contextType = AppContext;
  }

  componentDidMount() {
    // essa função só poderá ser feita para carregar o preview da miniatura quando for possível passar o parâmetro
    // this.obterMinuatura(this.props.recordname)
  }

  onDrop(pictureFiles, pictureDataURLs) {
    this.setState(
      {
        img_name: this.props.recordname,
        pictures: pictureFiles,
        preview: pictureDataURLs,
      },
      () => {
        this.uploadLogo(process.env.REACT_APP_LABS_API + "upload_media_img.php", this.state.pictures[0]);
      },
    );
  }

  remove() {
    this.setState({
      pictures: [],
      preview: "",
    });
  }

  uploadLogo(url, file) {
    if (this.props.recordname !== undefined) {
      this.context.showLoading();
      let data = new FormData();

      const imageUploadName = [this.state.img_name, "arquivo", ""];
      data.append(imageUploadName, file);

      fetch(url, {
        method: "POST",
        body: data,
      })
        .then(response => {
          return response.json();
        })

        .then(json => {
          if (json.success) {
            this.sendURL();
          } else {
            Swal.fire({
              text: "Não foi possível atualizar o arquivo, tente novamente ou entre em contato com o administrador.",
              icon: "warning",
              title: "Ops! Algo deu errado!",
              allowOutsideClick: false,
              showCancelButton: false,
              confirmButtonColor: "#00518b",
              confirmButtonText: "Ok!",
            });
            this.context.hideLoading();
          }
        });
    } else {
      this.setState({
        pictures: [],
        preview: "",
      });
      Swal.fire({
        text: "Preencha o campo com o código da mídia.",
        icon: "warning",
        title: "Ops! Algo deu errado!",
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonColor: "#00518b",
        confirmButtonText: "Ok!",
      });
    }
  }

  sendURL() {
    var img_extension = this.state.preview.toString().split(";")[1].split(".")[1];

    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        record_name: this.state.img_name,
        img_extension: img_extension,
        url: process.env.REACT_APP_LABS_ROOT,
        field: "url_midia_c",
        folder: "arquivo",
      }),
    };

    fetch(process.env.REACT_APP_LABS_API + "update_img_url.php", requestOptions).then(response => {
      Swal.fire({
        text: "Arquivo da mídia carregada com sucesso!",
        icon: "success",
        title: "Pronto!",
        allowOutsideClick: false,
        showCancelButton: false,
        confirmButtonColor: "#00518b",
        confirmButtonText: "Ok!",
      });
      this.context.hideLoading();
      return response.json();
    });
  }

  render() {
    return (
      <div className="media-upload-img-container">
        <div className="media-upload-input">
          {!this.state.preview && (
            <ImageUploader
              withIcon={false}
              buttonText="Carregar arquivo"
              onChange={this.onDrop}
              imgExtension={[".jpg", ".png", ".gif", ".txt", ".mp4", ".pdf", ".doc", ".docx", ".ppt", ".pptx", ".zip", ".rar"]}
              accept="image/*,video/mp4,video/x-m4v,video/*.txt,.mp4,.pdf,.doc,.docx,.ppt,.pptx,.zip,.rar"
              maxFileSize={500242880}
              withPreview={true}
              withLabel={false}
              className="media-upload-box"
              buttonClassName="btn-upload"
            />
          )}

          {this.state.preview && (
            <div className="picturesWrapper">
              <div className="pictureContent">
                <OverlayTrigger placement="right" delay={{ show: 250, hide: 400 }} overlay={<Tooltip id="button-tooltip">Remover mídia</Tooltip>}>
                  <div className="media-upload-removeFile" onClick={this.remove}>
                    X
                  </div>
                </OverlayTrigger>

                <div className="pictureContainer">
                  <img className="pictureFile" src={this.state.preview} alt="" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default MediaUploadFile;
