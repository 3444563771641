import React, { Component } from 'react'
import PDFOrderP from './pdf-order-p';

class PDFOrderReceita extends Component {

    render() {
        const { receita, hasOlhoDireito, hasOlhoEsquerdo } = this.props;

        return (
            <div className="row">
                <div className="col-12">
                    <h3 className="pdf-subtitle-bold">Receita</h3>
                </div>

                {hasOlhoDireito &&
                    (
                        <div className="col">
                            <h3 className="pdf-subtitle-bold">Direito</h3>
                            <PDFOrderP label='Esférico' value={receita.direito.esferico} />
                            <PDFOrderP label='Cilíndrico' value={receita.direito.cilindrico} />
                            <PDFOrderP label='Eixo' value={receita.direito.eixo} />
                            <PDFOrderP label='Adição' value={receita.direito.adicao} />
                        </div>
                    )
                }

                {
                    hasOlhoEsquerdo &&
                    (
                        <div className='col'>
                            <h3 className="pdf-subtitle-bold">Esquerdo</h3>
                            <PDFOrderP label='Esférico' value={receita.esquerdo.esferico} />
                            <PDFOrderP label='Cilíndrico' value={receita.esquerdo.cilindrico} />
                            <PDFOrderP label='Eixo' value={receita.esquerdo.eixo} />
                            <PDFOrderP label='Adição' value={receita.esquerdo.adicao} />
                        </div>
                    )
                }

            </div >
        )
    }
}

export default PDFOrderReceita