import React, { Component } from "react";
//Import CSS
import "./dashboard-lab.css";

//Import internal components
import AppContext from "../appContext";
import Back from "../main/back";

//Import external components
import MenuHorizontal from "../components/menu/menu-horizontal/index.js";
import { getLabs } from "../Functions/Laboratorio/getLabs";
import { Redirect } from "react-router-dom";
import RX from "./rx";
import PedidosAtacado from "./pedidos-atacado";
import AprovacaoCupom from "./aprovacao-cupom";

class DashboardLab extends Component {
  constructor(props) {
    super(props);
    this.childRefs = [];
    this.state = {
      selected_option: 1,
      redirectL: false,
      cnpj: "",
      filterAno: [],
      filterLab: [],
      filterCanal: [],
      filterMes: [],
      processedData: {},
      laboratorios: {},
      laboratoriosNames: [
        "Prime Optical",
        "Grown",
        "Tecnolens",
        "Ceditop",
        "Technopark",
        "Repro",
        "Orgalent",
        "Unilab",
        "Visolab",
        "Riachuelo",
        "RX",
        "Optilab",
        "Optiminas",
        "Comprol",
        "Lab Minas",
      ],
    };
    this.warningFather = this.warningFather.bind(this);
    if (localStorage.getItem("token") !== null) {
      DashboardLab.contextType = AppContext;
      let role = false;
      role = DashboardLab.contextType._currentValue.userSession.modulos.find(element => element === "Dashboard Pedidos");
      if (!role) {
        this.state = { redirect: true };
      }
    } else {
      this.state = { redirectL: true };
    }
  }
  async componentDidMount() {
    if (
      !this.context.userSession.modulos.includes("Dashboard Pedidos") &&
      (!localStorage.getItem("token") || this.context.userSession.papelCategoria === "OTICA" || this.context.userSession.papelCategoria === "LAB_INDEPENDENTE")
    ) {
      this.setState({ redirectL: true });
    } else if (this.context.userSession.papelCategoria === "LABORATORIO") {
      this.setState({ cnpj: this.context.userSession.conta });
    }

    const body = {
      laboratorio: this.context.userSession.laboratorios,
      papel: this.context.userSession.papel,
      conta: this.context.userSession.conta,
      papelCategoria: this.context.userSession.papelCategoria,
    };

    try {
      const Lab = await getLabs(body);

      if (Lab) {
        this.setState({ laboratorios: Lab });
      }
    } catch (error) {
      console.error(error.message);
    }
  }
  warningFather(option) {
    if (this.state.selected_option !== option) {
      this.setState({ selected_option: option });
    }
  }

  render() {
    const { redirectL } = this.state;

    if (this.state.redirect) {
      return <Redirect to="/home" />;
    }

    if (redirectL) {
      return <Redirect to="/" />;
    }
    const menuOptions = [
      {
        id: 1,
        label: "Pedidos RX",
        component: <RX laboratorios={this.state.laboratorios} selected_option={this.state.selected_option} laboratoriosNames={this.state.laboratoriosNames} />,
      },
      {
        id: 2,
        label: "Pedidos Atacado",
        component: (
          <PedidosAtacado
            laboratorios={this.state.laboratorios}
            selected_option={this.state.selected_option}
            laboratoriosNames={this.state.laboratoriosNames}
          />
        ),
      },
      {
        id: 3,
        label: "Aprovação de Cupons",
        component: (
          <AprovacaoCupom
            laboratorios={this.state.laboratorios}
            selected_option={this.state.selected_option}
            laboratoriosNames={this.state.laboratoriosNames}
          />
        ),
      },
    ];

    return (
      <main className="container my-4 px-2 dash">
        <Back />
        <div class="title-container">
          <h1 class="main-title">Conecta Dashboard</h1>
          <span class="title-shape"></span>
        </div>
        <div className="row ">
          <div className="col-12">
            <MenuHorizontal
              menuOptions={menuOptions}
              ref={this.childRef}
              warningFatherFunction={this.warningFather}
              warningFather={true}
              margin={"20px"}
              width={"750px"}
            />
          </div>
        </div>
      </main>
    );
  }
}

export default DashboardLab;
