import React, { Component } from 'react'
import './media-center-no-file.css'

class MediaCenterNoFile extends Component {
  constructor(props) {
    super(props)

  }

  render() {
    return (
      <div className="x-downloads-not-files__body">
        <div className="x-downloads-not-files__body--container">
          <svg
            className="x-downloads-not-files__body--svg"
            xmlns="http://www.w3.org/2000/svg"
            width="147.296"
            height="133.294"
            viewBox="0 0 147.296 133.294"
          >
            <g
              id="Grupo_106"
              data-name="Grupo 106"
              transform="translate(-598.704 -387)"
            >
              <circle
                id="Elipse_14"
                data-name="Elipse 14"
                cx="60.5"
                cy="60.5"
                r="60.5"
                transform="translate(625 387)"
                fill="#0066b3"
                opacity="0.07"
              />
              <path
                id="Caminho_89"
                data-name="Caminho 89"
                d="M463.015,2274H406.786l-10.905,32.9v37.917h78.791V2306.9Z"
                transform="translate(205 -1827)"
                fill="#fff"
              />
              <g
                id="Grupo_105"
                data-name="Grupo 105"
                transform="translate(-210)"
              >
                <g
                  id="Grupo_104"
                  data-name="Grupo 104"
                  transform="translate(173.31 194.452)"
                >
                  <g id="Grupo_104-2" data-name="Grupo 104">
                    <path
                      id="Caminho_90"
                      data-name="Caminho 90"
                      d="M716.721,287.716v-.007a.481.481,0,0,0-.008-.075c0-.023,0-.05-.008-.074v-.012l-29.885,0L685.1,290.9H668.42l-1.854-3.345-29.892,0v.007c0,.024,0,.051-.008.075a.449.449,0,0,0-.008.074v36.352a.783.783,0,0,0,.781.781h78.5a.784.784,0,0,0,.782-.781V287.717Zm-29.7,25.208H666.358v-7.606h20.664Z"
                      fill="none"
                      stroke="#0066b3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                    />
                  </g>
                </g>
                <line
                  id="Linha_6"
                  data-name="Linha 6"
                  x1="11"
                  y2="33"
                  transform="translate(809.969 448.5)"
                  fill="none"
                  stroke="#0066b3"
                  stroke-linecap="round"
                  stroke-width="2"
                />
                <line
                  id="Linha_7"
                  data-name="Linha 7"
                  x2="11"
                  y2="33"
                  transform="translate(879.031 448.5)"
                  fill="none"
                  stroke="#0066b3"
                  stroke-linecap="round"
                  stroke-width="2"
                />
                <line
                  id="Linha_2"
                  data-name="Linha 2"
                  y1="13.385"
                  x2="10.896"
                  transform="translate(860.962 423.849)"
                  fill="none"
                  stroke="#0066b3"
                  stroke-linecap="round"
                  stroke-width="4"
                />
                <line
                  id="Linha_3"
                  data-name="Linha 3"
                  x1="10.896"
                  y1="13.385"
                  transform="translate(825.642 423.849)"
                  fill="none"
                  stroke="#0066b3"
                  stroke-linecap="round"
                  stroke-width="4"
                />
                <line
                  id="Linha_4"
                  data-name="Linha 4"
                  y1="20.29"
                  transform="translate(850.065 412.577)"
                  fill="none"
                  stroke="#0066b3"
                  stroke-linecap="round"
                  stroke-width="4"
                />
                <line
                  id="Linha_5"
                  data-name="Linha 5"
                  x1="33"
                  transform="translate(833.5 447.5)"
                  fill="none"
                  stroke="#0066b3"
                  stroke-linecap="round"
                  stroke-width="3"
                />
              </g>
            </g>
          </svg>
          <h2 className="x-downloads-not-files__body--subtitle">
            Não há arquivos disponíveis
          </h2>
          <p className="x-downloads-not-files__body--paragraph">
            Tente mudar o tipo de arquivo e/ou sua marca.
          </p>
        </div>
      </div>
    )
  }
}

export default MediaCenterNoFile
