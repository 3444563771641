import React from 'react'
import ReactDOM from 'react-dom'
import ReactGA from 'react-ga'
import './index.css'
import App from './App';
import * as serviceWorker from './serviceWorker'
import { BrowserRouter } from 'react-router-dom'

var element = document.getElementById('ie-error');
element.parentNode.removeChild(element);

switch (process.env.REACT_APP_GA_ID){
	case 'dev':
		console.log('GA dev')
		ReactGA.initialize('UA-171425936-1');
		break;
	case 'homol':
		console.log('GA homol')
		ReactGA.initialize('UA-171425936-1');
		break;
	case 'prod':
		console.log('GA prod')
		ReactGA.initialize('UA-165626627-3');
		break;
}

ReactGA.pageview(window.location.pathname + window.location.search)

ReactDOM.render(<BrowserRouter><App /></BrowserRouter>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
