import React, { Component } from "react";
import { Badge, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { DashCircleFill, PlusCircleFill } from "react-bootstrap-icons";
import AppContext from "../../appContext";
import Input from "../../components/inputs/input";
import RadioButton from "../../components/inputs/radio-button";
import SelectAuto from "../../components/selects/select-auto";
import "./../pedido-atacado.css";
import * as valueList from "../../web-ordering/order/3_order_perscription/order-perscription-lists";
import ModalMatrizDioptrias from "./ModalMatrizDioptrias";
class PedidoAtacadoEtapaTres extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: 1,
      grau: 0,
      adicao: 0,
      quantidade: 1,
      base: 0,
      olho: "",
      diametro: 0,
      cilindrico: 0,
      esferico: 0,
      borderRed: "",
      cilindricos: [],
      esfericos: [],
    };

    PedidoAtacadoEtapaTres.contextType = AppContext;
    this.removeDioptria = this.removeDioptria.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckBox = this.handleCheckBox.bind(this);

    this.refToSecondField = React.createRef();
  }

  componentDidMount() {
    this.setState({ id: this.props.idDioptria });
  }
  componentDidUpdate(prevProps) {
    if (prevProps.tipo_produto !== this.props.tipo_produto || prevProps.produto !== this.props.produto) {
      this.props.cleanFields("dioptrias");
      this.removeDioptria();
    }
  }
  removeDioptria() {
    this.setState({
      id: 1,
      grau: "",
      adicao: "",
      quantidade: 1,
      base: "",
      olho: "",
      diametro: "",
      cilindrico: "",
      esferico: "",
      borderRed: "",
    });
    //this.props.cleanFields("dioptrias");
  }

  handleChange(e, name, next) {
    if (name === "olho") {
      this.setState({ [name]: e.target.value }, () => {
        this.props.changeDioptria(this.state);
      });
    } else {
      this.setState({ [name]: { label: e.label, value: e.value } }, () => {
        this.props.changeDioptria(this.state);
      });
    }
    if (next && next.current !== null) {
      if (next.current.selectAuto) {
        next.current.selectAuto.current.select.focus();
      }
    }
  }

  handleCheckBox(e) {
    const { value } = e.target;
    this.setState({ olho: value });
  }

  /* componentWillReceiveProps(nextProps) {
    if (nextProps.resetarDioptria) {
      this.setState({
        id: 1,
        grau: 0,
        adicao: 0,
        quantidade: 1,
        base: 0,
        olho: "",
        diametro: 0,
        cilindrico: 0,
        esferico: 0,
        borderRed: "",
      });
      this.props.changeResetarDioptria(false);
    }
  }*/
  componentDidUpdate(prevProps) {
    if (
      (Boolean(prevProps.cilindrico) &&
        Boolean(prevProps.esferico) &&
        prevProps.cilindrico?.length !== 0 &&
        prevProps.cilindrico?.length !== this.state.cilindricos?.length &&
        prevProps.esferico?.length !== 0 &&
        prevProps.esferico?.length !== this.state.esfericos?.length) && prevProps.produto.codigo === this.props.produto.codigo
    ) {
    
      this.setState({ cilindricos: this.props.cilindrico, esfericos: this.props.esferico });
    } else if (
      prevProps.produto.codigo !== this.props.produto.codigo &&
      prevProps.cilindrico?.length === 0 &&
      this.state.cilindricos?.length === 0 &&
      prevProps.esferico?.length === 0 &&
      this.state.esfericos?.length === 0
    ) {
      this.setState({ cilindricos: valueList.CILINDRICOAtacado, esfericos: valueList.ESFERICO });
    }
  }
  render() {
    const esferico = this.state.esfericos;
    const cilindricoAtacado = this.state.cilindricos;
    const adicao = this.props.adicao.length === 0 ? valueList.ADICAO : this.props.adicao;
    const diametro = this.props.diametro.length === 0 ? valueList.DIAMETRO : this.props.diametro;
    const base = this.props.base.length === 0 ? valueList.BASE : this.props.base;
    const { disableFields, border, tipo_produto, tipo_produtosOEC } = this.props;
    const { olho } = this.state;
    let ordemCampos = tipo_produtosOEC?.OrdemCampos_c;
    let ordemCamposSplit = ordemCampos?.split(",");
    return (
      <div className="row mb-3">
        {tipo_produto !== "" && ordemCamposSplit?.length > 0 ? (
          <>
            {ordemCamposSplit.map((item, index) => {
              let ref, next;
              if (index === 0) {
                ref = this.props.refToFirstField;
                next = this.refToSecondField;
              } else if (index === 1) {
                ref = this.refToSecondField;
                next = null;
              } else {
                ref = null;
                next = null;
              }

              let campo = item.toLowerCase().trim();
              if (campo === "olhos") {
                return (
                  <div className="divAtacado col-12 col-md-6">
                    {tipo_produtosOEC?.Olhos_c && (
                      <div className="atacado-receita-olho">
                        <label className="atacado-label-olho">Olho</label>
                        <div className="atacado-container-olho-radios">
                          <RadioButton
                            label="Esquerdo"
                            name="olho"
                            id="ESQUERDO"
                            value="ESQUERDO"
                            checked={olho == "ESQUERDO" ? true : false}
                            onChange={event => this.handleChange(event, "olho")}
                          />
                          <RadioButton
                            label="Direito"
                            name="olho"
                            id="DIREITO"
                            value="DIREITO"
                            checked={olho == "DIREITO" ? true : false}
                            onChange={event => this.handleChange(event, "olho")}
                          />
                          <RadioButton
                            label="Todos"
                            name="olho"
                            id="AMBOS"
                            value=""
                            checked={olho == "" ? true : false}
                            onChange={event => this.handleChange(event, "olho")}
                          />
                          <div>
                            <OverlayTrigger
                              placement="bottom"
                              delay={{ show: 250, hide: 400 }}
                              style={{ zIndex: "11" }}
                              overlay={
                                <Tooltip id="button-tooltip">
                                  A opção "Todos" insere os produtos para Olho Esquerdo, Olho Direito e Ambos os Olhos. Verifique os produtos incluídos e, caso
                                  necessário, remova os não desejados antes de finalizar o pedido.
                                </Tooltip>
                              }>
                              <span className="tooltip-shape auto-complete-tooltip-shape"></span>
                            </OverlayTrigger>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              } else if (campo === "esferico") {
                return (
                  <>
                    {tipo_produtosOEC?.Esferico_c && (this.props.esferico.length !== 0 || this.props.dioptrias || this.props.dioptrias === 422) && (
                      <div className="col-12 col-md-4">
                        <SelectAuto
                          ref={ref}
                          className={border}
                          label="Esférico*"
                          width="100%"
                          name="esferico"
                          id="esferico"
                          aria="Selecione a opção do esférico"
                          required
                          options={esferico}
                          placeholder="Selecione"
                          onlyDot
                          onChange={event => this.handleChange(event, "esferico", next)}
                          value={this.state.esferico || ""}
                          disabled={disableFields}
                          readOnly={disableFields}
                          tabIndex="1"
                        />
                      </div>
                    )}
                  </>
                );
              } else if (campo === "cilindrico") {
                return (
                  <>
                    {tipo_produtosOEC?.Cilindrico_c && (this.props.cilindrico.length !== 0 || this.props.dioptrias || this.props.dioptrias === 422) && (
                      <div className="col-12 col-md-4">
                        <SelectAuto
                          ref={ref}
                          className={border}
                          label="Cilíndrico*"
                          width="100%"
                          name="cilindrico"
                          aria="Selecione a opção do cilíndrico"
                          id="cilindrico"
                          options={cilindricoAtacado}
                          placeholder="Selecione"
                          onlyDot
                          onChange={event => this.handleChange(event, "cilindrico", next)}
                          value={this.state.cilindrico || ""}
                          disabled={disableFields}
                          readOnly={disableFields}
                          tabIndex="2"
                        />
                      </div>
                    )}
                    {this.props.tipo_produtosOEC.Codigo_c === "LENTE_SIMPLES" &&
                      Array.isArray(cilindricoAtacado) &&
                      Array.isArray(esferico) &&
                      (this.state.cilindricos.length !== 0 || this.props.dioptrias || this.props.dioptrias === 422) &&
                      (this.state.esfericos.length !== 0 || this.props.dioptrias || this.props.dioptrias === 422) && (
                        <div className="col-12 col-md-4 mt-3 ancor-matriz-dioptrias">
                          <ModalMatrizDioptrias
                            laboratorio={this.props.laboratorio}
                            redes={this.props.redes}
                            cilindrico={cilindricoAtacado}
                            esferico={esferico}
                            produto={this.props.produto}
                            tipoProduto={this.props.tipo_produto}
                            produto_nome={this.props.produto_nome}
                            produtosTabela={this.props.produtosTabela}
                            handleOrderChange={this.props.handleOrderChange}
                            validateFields={this.props.validateFields}
                            dados_empresa_faturamento_cnpj={this.props.dados_empresa_faturamento_cnpj}
                            cleanFields={() => this.props.cleanFields("dioptrias")}></ModalMatrizDioptrias>
                        </div>
                      )}
                  </>
                );
              } else if (campo === "grau") {
                return (
                  <>
                    {tipo_produtosOEC?.Grau_c && (
                      <div className="col-12 col-md-4">
                        <SelectAuto
                          ref={ref}
                          className={border}
                          label="Grau*"
                          width="100%"
                          name="grau"
                          id="grau"
                          aria="Selecione a opção do Grau"
                          required
                          options={esferico}
                          placeholder="Selecione"
                          onlyDot
                          onChange={event => this.handleChange(event, "grau", next)}
                          value={this.state.grau || ""}
                          disabled={disableFields}
                          readOnly={disableFields}
                        />
                      </div>
                    )}
                  </>
                );
              } else if (campo === "base") {
                return (
                  <>
                    {tipo_produtosOEC?.Base_c && (this.props.base.length !== 0 || this.props.dioptrias || this.props.dioptrias === 422) && (
                      <div className="col-12 col-md-3">
                        <SelectAuto
                          ref={ref}
                          className={border}
                          label="Base*"
                          width="100%"
                          name="base"
                          id="base"
                          aria="Selecione a opção da Base"
                          required
                          options={base}
                          placeholder="Selecione"
                          onlyDot
                          onChange={event => this.handleChange(event, "base", next)}
                          value={this.state.base || ""}
                          disabled={disableFields}
                          readOnly={disableFields}
                        />
                      </div>
                    )}
                  </>
                );
              } else if (campo === "adicao") {
                return (
                  <>
                    {tipo_produtosOEC?.Adicao_c && (this.props.adicao.length !== 0 || this.props.dioptrias || this.props.dioptrias === 422) && (
                      <div className="col-12 col-md-3">
                        <SelectAuto
                          ref={ref}
                          className={border}
                          label="Adição*"
                          width="100%"
                          name="Adição"
                          aria="Selecione a opção da Adição"
                          id="Adição"
                          options={adicao}
                          placeholder="Selecione"
                          onlyDot
                          onChange={event => this.handleChange(event, "adicao", next)}
                          value={this.state.adicao || ""}
                          disabled={disableFields}
                          readOnly={disableFields}
                        />
                      </div>
                    )}
                  </>
                );
              } else if (campo === "diametro") {
                return (
                  <>
                    {tipo_produtosOEC?.Diametro_c && (this.props.diametro.length !== 0 || this.props.dioptrias || this.props.dioptrias === 422) && (
                      <div className="col-12 col-md-4">
                        <SelectAuto
                          ref={ref}
                          className={border}
                          label="Diâmetro*"
                          width="100%"
                          name="diametro"
                          aria="Selecione a opção do diâmetro"
                          id="diametro"
                          options={diametro}
                          placeholder="Selecione"
                          onlyDot
                          onChange={event => this.handleChange(event, "diametro", next)}
                          value={this.state.diametro || ""}
                          disabled={disableFields}
                          readOnly={disableFields}
                        />
                      </div>
                    )}
                  </>
                );
              }
            })}
          </>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default PedidoAtacadoEtapaTres;
