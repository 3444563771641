//retorna a ID correta de acordo com a url
export function IDS(atributo) {
  if (process.env.REACT_APP_GA_ID == "dev" || process.env.REACT_APP_GA_ID == "homol") {
    let valoresIDS = [
      { "Novo Usuário Óptica": "300000002756260" },
      { "Recepcionista": "300000001794074" },
      { "Novo Usuário Laboratório": "300000001794078" },
      { "Novo Usuário Lab Independente": "300000009986129" },
    ];
    const buscaValor = atributo => (valoresIDS.find(item => Object.keys(item)[0] === atributo) || {})[atributo] || null;
    var resultado = buscaValor(atributo);
    return resultado;
  } else {
    let valoresIDS = [
      { "Novo Usuário Óptica": "300000002723416" },
      { "Recepcionista": "300000001770073" },
      { "Novo Usuário Laboratório": "300000001770115" },
      { "Novo Usuário Lab Independente": "300000011673051" },
    ];
    const buscaValor = atributo => (valoresIDS.find(item => Object.keys(item)[0] === atributo) || {})[atributo] || null;
    var resultado = buscaValor(atributo);
    return resultado;
  }
}
