import React, { Component } from 'react'

//Import CSS

//Import internal components

//Import external components

//Import images

class HeadingsDoc extends Component {

  	render(){
    	return (
			<div>

				<p>Padronização de headings utilizados no Conecta de h1 até h3.</p>

				<p>h1 com dot</p>

				<div className='conecta-h1-container'>
					<h1 className='conecta-h1-with-dot'>Teste de título h1 com dot</h1>
				</div>

				<p>h1 sem dot</p>

				<h1 className='conecta-h1'>Teste de título h1 sem dot</h1>

				<p>h2</p>

				<h2 className='conecta-h2'>Teste de título h2</h2>

				<p>h3</p>

				<h3 className='conecta-h3'>Teste de título h3</h3>
			</div>
    	)
  	}
}

export default HeadingsDoc