import React, { Component } from 'react'
import AppContext from "../appContext";

//Import CSS
import './profile.css'

//Import internal components
import Back from '../main/back'
import ProfileMessage from './profile-message'
import ProfileContent from './profile-content'
import { redirectPage } from '../components/utils/redirectpage'

//Import external components

//Import images

class MyAcc extends Component {
	constructor(props) {
		super(props);
		MyAcc.contextType = AppContext
    }
componentDidMount(){
	setTimeout(() => {
		this.context.hideLoading();
	}, 10000);
}
	render() {
		if(!localStorage.getItem('token')){
			return redirectPage(localStorage.getItem('token'), '/profile')
		} else {
			return (
				<div id="main-body">
					<div className="back">
						<Back />
					</div>
					<main className="my-account__main">
						<div className="my-account__container">
							<ProfileMessage/>
							<ProfileContent reloadMultiContas={this.props.reloadMultiContas}/>
						</div>
					</main>
				</div>
			)
		}
  	}
}

export default MyAcc