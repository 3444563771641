import React, { Component } from 'react'
import './media-center-banner.css'

class MediaCenterBanner extends Component {

    constructor(props) {
		super(props)
    }

	render() {

		return (
		<div className="media-center-banner" onClick={this.props.handleChangeBanner}>
			<img className="desktop" src={this.props.url_banner_desktop} alt="" />
            <img className="mobile" src={this.props.url_banner_mobile} alt="" />
		</div>
		)
	}
}

export default MediaCenterBanner