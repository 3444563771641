import React, { Component } from 'react'

//Import CSS
import './button-yn.css'

//Import internal components

//Import external components

//Import images

class ButtonYN extends Component {
	render() {

		return (
			<div className="buttonyn_radio--container">
				<p className="buttonyn_label">
					{this.props.label}
				</p>
				<input
					name={this.props.name}
					id={this.props.id_yes}
					className="buttonyn_radio--input"
					type='radio'
					value='S'
					onClick={this.props.onClick}
					defaultChecked={this.props.defaultChecked_yes}
					data-tooltip
					aria-label='Escolha se deseja montagem'
				/>
				<label
					htmlFor={this.props.id_yes}
					className="buttonyn_radio--label radio--left is-radio--label-mobile"
				>
					Sim
				</label>
				<input
					name={this.props.name}
					id={this.props.id_no}
					className="buttonyn_radio--input"
					type='radio'
					value='N'
					onClick={this.props.onClick}
					defaultChecked={this.props.defaultChecked_no}
				/>
				<label
					htmlFor={this.props.id_no}
					className="buttonyn_radio--label radio--right is-radio--label-mobile"
				>
					Não
				</label>
			</div>
		)
	}
}

export default ButtonYN
