import React, { Component } from "react";
import "./roles-management.css";
import Back from "../main/back";
import RolesManagementDescription from "./description/roles-management-description";
import RolesManagementTable from "./table/roles-management-table";
import RolesManagementForm from "./form/roles-management-form";
import RolesManagementTableAccordion from "./accordion/roles-management-accordion";
import AppContext from "../appContext";
import Swal from "sweetalert2";
import { Redirect } from "react-router-dom";
import { redirectPage } from "../components/utils/redirectpage";
import { getMultiContas } from "../service/multi-contas/multi-contas-service";
import MenuHorizontal from "../components/menu/menu-horizontal/index.js";

class RolesManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected_option: 1,
      menuOptions: [],
      cnpj_consulta: "",
      tipo_consulta: "CPF",
      tipo_cnpj: "",
      listaUsuarios: [],
      usuariosTemp: [],
      isProprietario: false,
      isColaboradorEssilor: false,
      isInativar: false,
      handleRolesManagementChange: this.handleChange,
      buscarUsuariosPorCnpj: this.buscarUsuariosPorCnpj,
      buscarUsuariosPorEmail: this.buscarUsuariosPorEmail,
      buscarUsuariosPorCpf: this.buscarUsuariosPorCpf,
      buscarTodosUsuarios: this.buscarTodosUsuarios,
      listaPapeis: [],
      listaPapeisAux: [],
      redirectL: false,
      papelNivel: null,
    };

    RolesManagement.contextType = AppContext;

    if (!localStorage.getItem("token")) {
      redirectPage(localStorage.getItem("token"), "/roles");
    }

    this.hasConsultaColaborador = this.hasConsultaColaborador.bind(this);
    this.checkRelacionamentoOtica = this.checkRelacionamentoOtica.bind(this);
  }
  componentDidMount() {
    this.validarTipoUsuario();
    this.listarPapeis()
    if (this.context.userSession.modulos.includes("Meus Colaboradores") && this.context.userSession.modulos.includes("Consulta Colaborador")) {
      this.setState({ listaUsuarios: [] }, () => {
       
        this.setState({ selected_option: 1, menuOptions: true });
      });
    } else if (this.context.userSession.modulos.includes("Meus Colaboradores")) {
      this.setState({ selected_option: 2, menuOptions: [] }, () => {
        if (localStorage.getItem("token")) {
            this.buscarUsuariosPorProprietario();
        }})


    } else {
      this.setState({ selected_option: 1, menuOptions: [] });
    }
  }

  handleChange = obj => {
    this.setState(obj);
  };
  handleOptionChange = option => {
    if (option !== this.state.selected_option) {
      this.setState({
        selected_option: option,
      });
    }
    if (option == 1) {
      this.setState({ listaUsuarios: [] });
    } else {
      this.setState({ listaUsuarios: [] }, () => {
        if (localStorage.getItem("token")) {
            this.buscarUsuariosPorProprietario();
        }
      });
    }
  };

  validarTipoUsuario(callback) {
    let isColaboradorEssilor = this.context.userSession.papelCategoria === "ESSILOR" ? true : false;
    let isProprietario = this.context.userSession.papel === "Proprietário de Óptica" ? true : false;
    this.getFlagsPapel();

    this.setState(
      {
        isProprietario: isProprietario,
        isColaboradorEssilor: isColaboradorEssilor,
      },
      callback,
    );
  }

  getFlagsPapel = () => {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        papel: this.context.userSession.papel,
      }),
    };

    fetch(process.env.REACT_APP_LABS_API + "get_flags_roles.php", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.status === "sucesso") {
          if (json.items) {
            this.setState({ isInativar: json.items.podeInativar });
          }
        }
      });
  };

  obterTipoCnpj = (cnpj, callback) => {
    let users = this.state.usuariosTemp;

    const requestOptions3 = {
      method: "POST",
      body: JSON.stringify({ cnpj: cnpj }),
    };

    fetch(process.env.REACT_APP_LABS_API + "get_account_data.php", requestOptions3)
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.status === 200) {
          if (json.tipo.includes("NAO DEFINIDO")) {
            const cnpjFormatado = json.cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");

            Swal.fire({
              html: `Existe uma pendência no cadastro do cliente <b>${cnpjFormatado}</b> no Referencial Essilor.
              Para que seja realizado o ajuste, favor entrar em contato com o suporte através da Ferramenta <strong><a href='https://essilorluxottica.requestia.com/' target='_blank'>Requestia (https://essilorluxottica.requestia.com/).<a/><strong/> `,
              icon: "warning",
              confirmButtonColor: "#00518b",
              confirmButtonText: "OK",
            });
            this.setState({ listaUsuarios: [] });
            this.context.hideLoading();

            return 
          }
          if (
            !json.tipo ||
            json.tipo.includes("REDE") ||
            json.tipo.includes("BALCONISTA") ||
            json.tipo.includes("OTICA") ||
            json.tipo.includes("FORNECEDOR") ||
            json.tipo.includes("CONSUMIDORES") ||
            json.tipo.includes("DISTRIBUIDOR")
          ) {
            this.setState({ tipo_cnpj: "OTICA" }, callback);
          } else if (json.tipo === "AFILIADOS" || json.tipo.includes("EMPREGADOS")) {
            this.setState({ tipo_cnpj: "ESSILOR" }, callback);
          } else if (json.tipo.includes("LAB PARTICIPACAO")) {
            this.setState({ tipo_cnpj: "LABORATORIO" }, callback);
          } else if (json.tipo.includes("LABORATORIOS") || json.tipo.includes("LAB ALLIANCE")) {
            this.setState({ tipo_cnpj: "LAB_INDEPENDENTE" }, callback);
          } else if (json.tipo.includes("MEDICO") || json.tipo.includes("CLINICAS")) {
            this.setState({ tipo_cnpj: "MEDICO" }, callback);
          }

       
            if (
              this.context.userSession.papelCategoria === "LABORATORIO" &&
              this.context.userSession.papel === "Consultor Laboratório" &&
              this.state.tipo_cnpj !== "OTICA"
            ) {
              /// nova categoria 
              if (this.state.tipo_cnpj === "LABORATORIO" || this.state.tipo_cnpj === "LAB_INDEPENDENTE" || this.state.tipo_cnpj === "ESSILOR" || this.context.userSession.conta === cnpj) {
                Swal.fire({
                  text: "Você não tem autorização para realizar esta busca.",
                  icon: "warning",
                  title: "Aviso",
                  confirmButtonColor: "#00518b",
                  customClass: {
                    confirmButton: "x-follow-order__filter-btn",
                  },
                });
                this.setState({ listaUsuarios: [] });
                this.context.hideLoading();
              }
          } else if (
            (this.context.userSession.papelCategoria === "LABORATORIO" &&
              this.context.userSession.papel === "Consultor Laboratório" &&
              this.state.tipo_cnpj === "OTICA") ||
            (this.context.userSession.papelCategoria === "LAB_INDEPENDENTE" &&
              this.context.userSession.papel === "Consultor Lab Independente" &&
              this.state.tipo_cnpj === "OTICA")
          ) {
            this.checkRelacionamentoOtica(cnpj, users, callback);
          } else {
            this.setState({ listaUsuarios: users }, callback);
          }
          this.context.hideLoading();
        } else {
          if (this.state.tipo_consulta === "CNPJ") {
            this.cnpjNaoEncontrado();
          } else {
            this.setState({ listaUsuarios: users }, callback);
          }
          this.context.hideLoading();
        }
      });
  };

  cnpjNaoEncontrado = () => {
    Swal.fire({
      text: "CNPJ não encontrado.",
      icon: "warning",
      title: "Aviso",
      confirmButtonColor: "#00518b",
      customClass: {
        confirmButton: "x-follow-order__filter-btn",
      },
    });
  };

  buscarUsuarios = (requestOptions, campoErro = "CNPJ", cnpj = null) => {
    this.setState({ listaUsuarios: [] });
    this.context.showLoading("Buscando usuários...");

    requestOptions.headers = new Headers({
      Authorization: "Bearer " + localStorage.getItem("session_token"),
    });

    const usuarios = [];

    fetch(process.env.REACT_APP_LABS_API + "get_users.php", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.status === "sucesso") {
          if (json.items) {
            if (json.items.length === 0) {
              this.context.hideLoading();
              Swal.fire({
                text: `Nenhum colaborador cadastrado neste ${campoErro}.`,
                icon: "warning",
                title: "Aviso",
                confirmButtonColor: "#00518b",
                customClass: {
                  confirmButton: "x-follow-order__filter-btn",
                },
              });
            } else {
              for (var i = 0; i < json.items.length; i++) {
                var usuario = {
                  id: json.items[i].id,
                  nome: json.items[i].nome,
                  sobrenome: json.items[i].sobrenome,
                  email: json.items[i].email,
                  cpf: json.items[i].cpf,
                  id_perfil: json.items[i].id_papel,
                  perfil_acesso: json.items[i].papel,
                  cnpj: json.items[i].cnpj,
                  inativo: json.items[i].inativo,
                  userLogado: false,
                  erro: "",
                };

                if (json.items[i].inativo) {
                  usuario.erro = "Usuário Inativo";
                }

                if (!json.items[i].inativo) {
                  if (this.validarHierarquiaPapel(json.items[i].papel)) {
                    usuario.userLogado = usuario.inativo = true;
                    usuario.erro = "Usuário com mais privilégios";
                  }
                }

                if (!json.items[i].inativo && !usuario.userLogado) {
                  if (json.items[i].cpf === this.context.userSession.cpf || json.items[i].email === this.context.userSession.email) {
                    usuario.userLogado = usuario.inativo = true;
                    usuario.erro = "Usuário logado";
                  }
                }

                if (!json.items[i].inativo && !usuario.userLogado) {
                  if (!json.items[i].cpf) {
                    usuario.userLogado = usuario.inativo;
                    usuario.erro = "Usuário sem CPF";
                  }
                }

                if (!json.items[i].inativo && !usuario.userLogado) {
                  if (!json.items[i].cnpj) {
                    usuario.userLogado = usuario.inativo;
                    usuario.erro = "Usuário sem CNPJ";
                  }
                }

                usuarios.push(usuario);
              }

              var usuariosAux = usuarios;

              if (cnpj || campoErro === "CPF") {
                usuariosAux = usuarios.filter((item, index, self) => index === self.findIndex(t => t.id === item.id && t.cnpj === item.cnpj));
              } else {
                usuariosAux = usuarios.filter((item, index, self) => index === self.findIndex(t => t.cnpj === item.cnpj));
              }
              this.setState({ usuariosTemp: usuariosAux });
              let cnpjAux = cnpj ? cnpj : usuariosAux[0] ? usuariosAux[0].cnpj : this.context.userSession.conta;
              this.obterTipoCnpj(cnpjAux, this.filtrarPapeis);
            }
          }
        } else if (json.status === 401) {
          Swal.fire({
            text: json.message,
            icon: "warning",
            title: "Aviso",
            confirmButtonColor: "#00518b",
            customClass: {
              confirmButton: "x-follow-order__filter-btn",
            },
          });
          this.context.hideLoading();
        } else {
          Swal.fire({
            text: `Verifique o ${campoErro} e tente novamente.`,
            icon: "warning",
            title: "Aviso",
            confirmButtonColor: "#00518b",
            customClass: {
              confirmButton: "x-follow-order__filter-btn",
            },
          });
          this.context.hideLoading();
        }
      });
  };

  buscarTodosUsuarios = () => {
    const requestOptions = {
      method: "GET",
    };

    this.buscarUsuarios(requestOptions);
  };

  buscarUsuariosPorProprietario = () => {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({ cnpj: this.context.userSession.conta }),
    };
    
    this.buscarUsuarios(requestOptions, "CNPJ", this.context.userSession.conta);
  };

  buscarUsuariosPorCnpj = cnpj => {
    this.setState({ listaUsuarios: [] });
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({ cnpj: cnpj }),
    };
    this.buscarUsuarios(requestOptions, "CNPJ", cnpj);
  };

  checkRelacionamentoOtica = (cnpj = null, users = [], callback) => {
    if (cnpj) {
      this.context.showLoading("");
      let labsa = [];

      const requestOptions = {
        method: "GET",
        headers: {
          access_token: localStorage.getItem("token"),
          client_id: localStorage.getItem("clientid"),
          otica: cnpj,
          laboratorio: this.context.userSession.conta,
        },
      };

      fetch(process.env.REACT_APP_ESSILOR_API + `gestao-laboratorios/v1/relacionamento`, requestOptions)
        .then(response => {
          if (response.status === 200) {
            return response.json();
          } else {
            return response.json().then(json => {
              this.context.hideLoading();
              if (json[0] && json[0].mensagem) {
                Swal.fire({
                  html: json[0].mensagem,
                  icon: "warning",
                  title: "Aviso",
                  width: "600px",
                  confirmButtonColor: "#00518b",
                  customClass: {
                    confirmButton: "x-follow-order__filter-btn",
                  },
                });
              }
            });
          }
        })
        .then(json => {
          if (json && json[0]?.mensagem) {
            this.context.hideLoading();
            Swal.fire({
              html: json[0].mensagem,
              icon: "warning",
              title: "Aviso",
              confirmButtonColor: "#00518b",
              customClass: {
                confirmButton: "x-follow-order__filter-btn",
              },
            });
          }

          if (json && json.relacionamento === true) {
            this.context.hideLoading();
            this.setState({ listaUsuarios: users }, callback);
          } else {
            this.context.hideLoading();
            Swal.fire({
              text: "Você não tem autorização para realizar esta busca.",
              icon: "warning",
              title: "Aviso",
              confirmButtonColor: "#00518b",
              customClass: {
                confirmButton: "x-follow-order__filter-btn",
              },
            });
            this.setState({ listaUsuarios: [] });
          }
        });
    }
  };

  buscarUsuariosPorCpf = cpf => {
    this.setState({ listaUsuarios: [] });
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({ cpf: cpf }),
    };
    this.buscarUsuarios(requestOptions, "CPF");
  };

  buscarUsuariosPorEmail = email => {
    this.setState({ listaUsuarios: [] });
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({ email: email }),
    };
    this.buscarUsuarios(requestOptions, "e-mail");
  };

  validarPapelEssilor = (cnpj, callback) => {
    this.obterTipoCnpj(cnpj, () => {
      if (
        this.context.userSession.papelCategoria !== "ESSILOR" ||
        this.context.userSession.papel === "Administrador Essilor" ||
        this.state.tipo_cnpj !== "ESSILOR"
      ) {
        this.setState({ consulta_liberada: true }, callback);
      } else {
        Swal.fire({
          text: "Seu perfil não tem permissão para alterar papéis na empresa Essilor.",
          icon: "warning",
          title: "Aviso",
          confirmButtonColor: "#00518b",
          customClass: {
            confirmButton: "x-follow-order__filter-btn",
          },
        });
      }
    });
  };

  AtualizarListaUsuarios = () => {
    if (this.state.cnpj_consulta) {
      switch (this.state.tipo_consulta) {
        case "CPF":
          this.buscarUsuariosPorCpf(this.state.cnpj_consulta);
          break;
        case "E-mail":
          this.buscarUsuariosPorEmail(this.state.cnpj_consulta);
          break;
        case "CNPJ":
          this.buscarUsuariosPorCnpj(this.state.cnpj_consulta);
          break;
      }
    } else {
      this.buscarUsuariosPorProprietario();
    }
  };

  alterarPerfilUsuario = (id, id_perfil, perfil, cnpj) => {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        idOEC: id,
        idPapel: id_perfil,
        papel: perfil,
        removerCnpj: false,
        cnpj: cnpj ? cnpj : this.context.userSession.conta,
      }),
    };

    this.AlterarRemoverUsuario(requestOptions);
  };

  AlterarRemoverUsuario = requestOptions => {
    this.context.showLoading();

    requestOptions.headers = new Headers({
      Authorization: "Bearer " + localStorage.getItem("session_token"),
    });

    fetch(process.env.REACT_APP_LABS_API + "update_user_role.php", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(json => {
        this.context.hideLoading();
        if (json.status === 200) {
          Swal.fire({
            text: "Informações atualizadas com sucesso!",
            icon: "success",
            title: "Pronto!",
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonColor: "#00518b",
            confirmButtonText: "Ok!",
          }).then(result => {
            this.AtualizarListaUsuarios();
          });
        } else {
          Swal.fire({
            text: json.message,
            icon: "error",
            title: "Tivemos um problema!",
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonColor: "#00518b",
            confirmButtonText: "Ok",
          }).then(result => {});
        }
      });
  };

  removerUsuario = (id, cnpj) => {
    Swal.fire({
      html: "<p>Este colaborador será desvinculado desta empresa. <br>Deseja continuar?</p> <br><br>",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00518b",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar",
    }).then(result => {
      if (result.value) {
        const requestOptions = {
          method: "POST",
          body: JSON.stringify({
            idOEC: id,
            removerCnpj: true,
            tipoCnpj: this.state.tipo_cnpj,
            cnpj: cnpj ? cnpj : this.context.userSession.conta,
          }),
        };

        this.AlterarRemoverUsuario(requestOptions);
      }
    });
  };

  inativarUsuario = (id, email) => {
    Swal.fire({
      html: `<p>Este usuário será inativado na base do Conecta. <br>Deseja continuar?</p> <br><br>`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#00518b",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim",
      cancelButtonText: "Cancelar",
    }).then(result => {
      if (result.value) {
        this.context.showLoading();
        const requestOptions = {
          method: "POST",
          body: JSON.stringify({
            idOEC: id,
            email: email,
            inativar: true,
          }),
          headers: new Headers({
            Authorization: "Bearer " + localStorage.getItem("session_token"),
          }),
        };

        fetch(process.env.REACT_APP_LABS_API + "inactivateUser.php", requestOptions)
          .then(response => {
            return response.json();
          })
          .then(json => {
            this.context.hideLoading();
            if (json.status === 200) {
              Swal.fire({
                text: "Usuário inativado com sucesso!",
                icon: "success",
                title: "Pronto!",
                allowOutsideClick: false,
                showCancelButton: false,
                confirmButtonColor: "#00518b",
                confirmButtonText: "Ok!",
              }).then(result => {
                this.AtualizarListaUsuarios();
              });
            } else {
              Swal.fire({
                text: json.message,
                icon: "warning",
                title: "Atenção!",
                allowOutsideClick: false,
                showCancelButton: false,
                confirmButtonColor: "#00518b",
                confirmButtonText: "Ok!",
              }).then(result => {});
            }
          });
      }
    });
  };

  listarPapeis = () => {
    const papeis = [];
    var papelNivel = [];
    const requestOptions = {
      method: "GET",
    };

    fetch(process.env.REACT_APP_LABS_API + "get_roles.php", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.status === "sucesso") {
          if (json.items) {
            for (var i = 0; i < json.items.length; i++) {
              var papel = {
                id: json.items[i].id,
                nome: json.items[i].recordName,
                editadoUsuario: json.items[i].EditadoUsuario,
                nivel: json.items[i].Nivel,
                categoria: json.items[i].Categoria,
                disabled: false,
              };

              papeis.push(papel);
            }
            var novaListaPapeis = papeis.filter(item => {
              return item.categoria === this.context.userSession.papelCategoria;
            });
            if (novaListaPapeis) {
              novaListaPapeis.map((item, index) => {
                if (this.context.userSession.papel === item.nome) {
                  papelNivel = item.nivel;
                }
              });
            }
          }

          this.setState({ listaPapeisAux: papeis, papelNivel: papelNivel });
        }
      });
  };

  validarHierarquiaPapel(papel) {
    var nivel = 0;
    var categoria = 0;
    this.state.listaPapeisAux.map((item, index) => {
      if (papel === item.nome) {
        nivel = item.nivel;
        categoria = item.categoria;
      }
    });

    if (this.context.userSession.papelCategoria === "ESSILOR" || this.context.userSession.papel === "Consultor Laboratório") {
      if (nivel < this.state.papelNivel && this.context.userSession.papelCategoria === categoria) {
        return true;
      }
    } else {
      if (nivel < this.state.papelNivel || this.context.userSession.papelCategoria !== categoria) {
        return true;
      }
    }
    return false;
  }

  filtrarPapeis = () => {
    let papelInicial = 0;
    var novaListaPapeis = this.state.listaPapeisAux.filter(item => {
      return item.categoria === this.state.tipo_cnpj;
    });

    novaListaPapeis.map((item, index) => {
      if (this.context.userSession.papel === item.nome) {
        papelInicial = item.nivel;
      }
    });

    novaListaPapeis.map(itemAux => {
      if (itemAux.nivel < papelInicial) {
        itemAux.disabled = true;
      }
    });

    novaListaPapeis.sort(this.compare);
    this.setState({ listaPapeis: novaListaPapeis });
    this.context.hideLoading();
  };

  compare(a, b) {
    if (a.nivel < b.nivel) {
      return -1;
    }
    if (a.nivel > b.nivel) {
      return 1;
    }
    return 0;
  }

  hasConsultaColaborador() {
    return this.context.userSession.modulos.includes("Consulta Colaborador");
  }

  render() {

    if (!this.state.redirectL && !this.context.userSession.modulos.includes("Gestão Papéis")) {
      return <Redirect to="/home" />;
    }
    const menuOptions = [
      {
        id: 1,
        label: "Clientes",
        component: this.hasConsultaColaborador() && (
          <div className="d-flex justify-content-center flex-column align-items-center">
            <RolesManagementForm rolesManagement={this.state} />
            <RolesManagementTable
              listaUsuarios={this.state.listaUsuarios}
              listaPapeis={this.state.listaPapeis}
              removerUsuario={this.removerUsuario}
              inativarUsuario={this.inativarUsuario}
              alterarPerfilUsuario={this.alterarPerfilUsuario}
              isInativar={this.state.isInativar}
            />
          </div>
        ),
      },
      {
        id: 2,
        label: "Minha Empresa",
        component: (
          <div className="d-flex justify-content-center flex-column align-items-center">
            <RolesManagementTable
              listaUsuarios={this.state.listaUsuarios}
              listaPapeis={this.state.listaPapeis}
              removerUsuario={this.removerUsuario}
              inativarUsuario={this.inativarUsuario}
              alterarPerfilUsuario={this.alterarPerfilUsuario}
              isInativar={this.state.isInativar}
            />
          </div>
        ),
      },
    ];
    return (
      <div id="main-body">
        <div className="container gestao-papeis">
          <div className="mt-5">
            <Back />
          </div>
          <div className="row">
            <div className="col-12">
              <RolesManagementDescription />
            </div>
            {this.state.menuOptions==true && (
              <div>
                <MenuHorizontal menuOptions={menuOptions} ChangeOption={this.handleOptionChange} />
              </div>
            )}

            <>
              {this.state.menuOptions.length === 0 && this.state.selected_option === 1 && (
                <div className="d-flex justify-content-center flex-column align-items-center">
                  <RolesManagementForm rolesManagement={this.state} />
                  <RolesManagementTable
                  listaPapeis={this.state.listaPapeis}
                  listaUsuarios={this.state.listaUsuarios}
                  inativarUsuario={this.inativarUsuario}
                  removerUsuario={this.removerUsuario}
                  alterarPerfilUsuario={this.alterarPerfilUsuario}
                  isInativar={this.state.isInativar}
                  />
                </div>
              )}
              {this.state.menuOptions.length === 0 && this.state.selected_option === 2 && (
                <div className="d-flex justify-content-center flex-column align-items-center">
                  <RolesManagementTable
                    listaUsuarios={this.state.listaUsuarios}
                    listaPapeis={this.state.listaPapeis}
                    removerUsuario={this.removerUsuario}
                    inativarUsuario={this.inativarUsuario}
                    alterarPerfilUsuario={this.alterarPerfilUsuario}
                    isInativar={this.state.isInativar}
                  />
                </div>
              )}
            </>
          </div>
        </div>
      </div>
    );
  }
}

export default RolesManagement;
