import React, { Component } from 'react'

//Import CSS
import './button.css'

//Import internal components

//Import external components

//Import images

class CustomButton extends Component {

	render() {

		let icon_search = <svg xmlns="http://www.w3.org/2000/svg" width="22.75" height="22.745" viewBox="0 0 22.75 22.745">
			<path
				id="np_search_860389_000000"
				d="M21.938,20.3l5.8,5.8a1.16,1.16,0,0,1-1.64,1.64l-5.8-5.8A9.281,9.281,0,1,1,21.938,20.3Zm-2.411-.77a6.961,6.961,0,1,0-9.845,0A6.961,6.961,0,0,0,19.528,19.528Z"
				transform="translate(-5.324 -5.324)"
				fill="#fff"
				fillRule="evenodd"
			/>
		</svg>

		let icon_arrow_right = <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
			<g
				id="Group_13"
				data-name="Group 13"
				transform="translate(-117.064 -5.315)"
			>
				<circle
					id="Ellipse_9"
					data-name="Ellipse 9"
					cx="10.5"
					cy="10.5"
					r="10.5"
					transform="translate(117.064 5.315)"
					fill="#fff"
				/>
				<path
					id="np_arrow_2957554_000000"
					d="M4.026,6.652l3-5.861A.544.544,0,0,0,6.209.117L3.882,1.947a.511.511,0,0,1-.658,0L.879.117A.545.545,0,0,0,.06.791l3,5.861a.546.546,0,0,0,.964,0Z"
					transform="translate(124.214 19.131) rotate(-90)"
					fill="#0066a0"
				/>
			</g>
		</svg>

		let icon_arrow_right_orange = <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
			<g
				id="Group_13"
				data-name="Group 13"
				transform="translate(-117.064 -5.315)"
			>
				<circle
					id="Ellipse_9"
					data-name="Ellipse 9"
					cx="10.5"
					cy="10.5"
					r="10.5"
					transform="translate(117.064 5.315)"
					fill="#fff"
				/>
				<path
					id="np_arrow_2957554_000000"
					d="M4.026,6.652l3-5.861A.544.544,0,0,0,6.209.117L3.882,1.947a.511.511,0,0,1-.658,0L.879.117A.545.545,0,0,0,.06.791l3,5.861a.546.546,0,0,0,.964,0Z"
					transform="translate(124.214 19.131) rotate(-90)"
					fill="#0394db"
				/>
			</g>
		</svg>

		let icon_arrow_left = <svg className="x-button__slick--prev-icon" id="Group_13" data-name="Group 13" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
			<circle
				id="Ellipse_9"
				data-name="Ellipse 9"
				cx="12.5"
				cy="12.5"
				r="12.5"
				fill="#fff"
			/>
			<path
				id="np_arrow_2957554_000000"
				d="M4.831.346l3.6,7.033a.653.653,0,0,1-.983.81l-2.794-2.2a.613.613,0,0,0-.79,0l-2.813,2.2a.654.654,0,0,1-.983-.81L3.675.346a.656.656,0,0,1,1.156,0Z"
				transform="translate(8.091 16.179) rotate(-90)"
				fill="#0066a0"
			/>
		</svg>

		let icon_arrow_left_orange = <svg className="x-button__slick--prev-icon" id="Group_13" data-name="Group 13" xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
			<circle
				id="Ellipse_9"
				data-name="Ellipse 9"
				cx="12.5"
				cy="12.5"
				r="12.5"
				fill="#fff"
			/>
			<path
				id="np_arrow_2957554_000000"
				d="M4.831.346l3.6,7.033a.653.653,0,0,1-.983.81l-2.794-2.2a.613.613,0,0,0-.79,0l-2.813,2.2a.654.654,0,0,1-.983-.81L3.675.346a.656.656,0,0,1,1.156,0Z"
				transform="translate(8.091 16.179) rotate(-90)"
				fill="#0394db"
			/>
		</svg>

		return (
			<button
				className={this.props.orange_button ? 'crm-button-orange' : 'crm-button-blue'}
				type="button"
				style={{
					width: this.props.width,
					height: this.props.height ? this.props.height : '3.125rem',
					marginTop: this.props.marginTop ? this.props.marginTop : "1.75rem"
				}}
				onClick={this.props.onClick}
				disabled={this.props.disabled}
			>

				{this.props.icon_search && icon_search}

				{!this.props.orange_button && this.props.icon_arrow_left && icon_arrow_left}
				{this.props.orange_button && this.props.icon_arrow_left && icon_arrow_left_orange}

				<p className="crm-button-label">
					{this.props.label}
				</p>

				{!this.props.orange_button && this.props.icon_arrow_right && icon_arrow_right}
				{this.props.orange_button && this.props.icon_arrow_right && icon_arrow_right_orange}

			</button>
		)
	}
}

export default CustomButton
