import React, { Component } from 'react'

//Import CSS
import './documentation.css'

//Import internal components
import DocInputs from './doc-inputs'
import HeadingsDoc from './headings-doc'
import DocDropdown from './doc-dropdown'
import DocButtons from './doc-buttons'

//Import external components

//Import images

class Documentation extends Component {

	constructor(props) {
		super(props)
		this.state = {
			inputs: true,
		}

		this.handleChangeMenu = this.handleChangeMenu.bind(this)
	}

	handleChangeMenu(evt) {

		this.setState ({
			inputs: false,
			dropdown: false,
			buttons: false,
			headings: false,
			file_upload: false,
		})

		this.setState ({
			[evt.target.id]: true
		})
	}

  	render(){
    	return (
			<div className='doc-main'>

				<div className='doc-menu'>

					<h2 className='conecta-h2'>Front-end<br /> documentation</h2>

					<p className='doc-paragraph'>
						Here you will find a description of all global components available on Essilor Conecta.
						All newly created components should be listed here for future references.
					</p>

					<ul>
						<li id='inputs' onClick={this.handleChangeMenu}>Inputs</li>
						<li id='dropdown' onClick={this.handleChangeMenu}>Dropdown</li>
						<li id='buttons' onClick={this.handleChangeMenu}>Buttons</li>
						<li id='headings' onClick={this.handleChangeMenu}>Headings</li>
						<li id='file_upload' onClick={this.handleChangeMenu}>File Upload</li>
					</ul>
				</div>

				<div className='doc-body'>
					{this.state.inputs && <DocInputs />}
					{this.state.dropdown && <DocDropdown />}
					{this.state.buttons && <DocButtons />}
					{this.state.headings && <HeadingsDoc />}
				</div>

			</div>
    	)
  	}
}

export default Documentation