import React, { Component, useContext } from "react";
import AppContext from "../../appContext";
import DataTable from "react-data-table-component";
import { Accordion, Card, useAccordionButton } from "react-bootstrap";
import { Dash, Plus } from "react-bootstrap-icons";
import AccordionContext from "react-bootstrap/AccordionContext";
import Download from "../../components/utils/download-file/tag-a/automatic-download-user-machine/CSV";
import "./index.css";
class Table extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: [],
    };
    this.createColumnsFromData = this.createColumnsFromData.bind(this);
    this.childRefs = [];
    Table.contextType = AppContext;
  }

  createColumnsFromData(dataArray) {
    const uniqueKeys = new Set();
    const columns = [];
    if (dataArray) {
      dataArray.forEach(dataObject => {
        Object.keys(dataObject).forEach(key => {
          if (!uniqueKeys.has(key)) {
            uniqueKeys.add(key);
            columns.push({
              name: key.charAt(0).toUpperCase() + key.slice(1),
              selector: key,
              sortable: true,
              reorder: true,
            });
          }
        });
      });
    }

    return columns;
  }
  render() {
    function ContextAwareToggle({ children, eventKey, callback }) {
      const currentEventKey = useContext(AccordionContext);
      const decoratedOnClick = useAccordionButton(eventKey, () => callback && callback(eventKey));
      return (
        <div className="row">
          <div className="col-12 headerColapse" onClick={decoratedOnClick}>
            <div className="col-10">{children}</div>
            {currentEventKey.activeEventKey && (
              <span className="float-end" onClick={decoratedOnClick}>
                {" "}
                <Dash color="royalblue" size={24} />{" "}
              </span>
            )}
            {!currentEventKey.activeEventKey && (
              <span className="float-end" onClick={decoratedOnClick}>
                {" "}
                <Plus color="royalblue" size={24} />{" "}
              </span>
            )}
          </div>
        </div>
      );
    }
    function formatarQuantidade(dados) {
      return dados?.map(item => {
        if (item?.quantidade) {
          const quantidadeFormatada = item.quantidade.toLocaleString();
          return { ...item, quantidade: quantidadeFormatada };
        } else {
          const quantidadePedidosPromocionais = item.quantidadePedidosPromocionais.toLocaleString();
          const quantidadeVouchersAprovados = item.quantidadeVouchersAprovados.toLocaleString();
          return { ...item, quantidadePedidosPromocionais: quantidadePedidosPromocionais, quantidadeVouchersAprovados: quantidadeVouchersAprovados };
        }
      });
    }
    const columns = this.createColumnsFromData(this.props.data);
    const actions = <Download data={this.props.data} linkName={"Exportar Lista"} className={"dashlabLink"} fileName={this.props.titulo} />;
    const data = this.props.data;

    const dadosFormatados = formatarQuantidade(data);
    return (
   

        <Accordion defaultActiveKey="1">
          <Card>
            <Card.Header>
              <ContextAwareToggle eventKey="1">{this.props.titulo}</ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="1">
              <Card.Body>
                <DataTable
                  data={dadosFormatados}
                  dense={true}
                  pagination={true}
                  striped={true}
                  noDataComponent={<h4>Sem Resultados</h4>}
                  defaultExpanded={true}
                  columns={columns}
                  actions={actions}
                />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
   
    );
  }
}

export default Table;
