import React, { Component } from "react";

// import "../suporte.css";

import Input from "../../components/inputs/input";
import ConsultarLabChoose from "./consultar-labchoose";
import ConsultarInputCNPJ from "./consultar-cnpj";
import ConsultarSearchButton from "./consultar-searchbutton";
import ResultCard from "./result-card";

import AppContext from "../../appContext";
import Swal from "sweetalert2";
import TabelaPares from "./consultar-tabela-pares";

class ConsultarCupom extends Component {
  constructor() {
    super();

    this.state = {
      cupom_promocional: "",
      lab: "",
      cnpj_otica: "",
      voucher_data: "",
    };

    ConsultarCupom.contextType = AppContext;

    this.handleChange = this.handleChange.bind(this);
    this.searchVoucher = this.searchVoucher.bind(this);
  }

  handleChange(event) {
    let state = {};
    const name = event.target.getAttribute("name");
    const { value } = event.target;

    //   if (e.target.getAttribute("name") === "pedido_laboratorio") {
    // 	  if (!Number(value) && value !== "") {
    // 		  return;
    // 	  }
    //  }

    if (value !== state[name]) {
      state[name] = value ? value : "";
      this.setState(state);
    }
  }

  searchVoucher() {
    this.context.showLoading("Carregando...");

    const requestOptions = {
      method: "GET",
      headers: {
        access_token: localStorage.getItem("token"),
        client_id: localStorage.getItem("clientid"),
        laboratorio: this.state.lab,
        otica: this.state.cnpj_otica.replace(/[^0-9]/g, ""),
      },
    };

    const voucherId = this.state.cupom_promocional;

    this.setState({
      voucher_data: "",
    });

    fetch(process.env.REACT_APP_ESSILOR_API + `gestao-pedidos-laboratorio/v1/vouchers/${voucherId}`, requestOptions)
      .then(response => {
        this.context.hideLoading();

        return response.json();
      })
      .then(json => {
        if (json[0] && json[0]?.codigo !== 200) {
          Swal.fire({
            text: json[0]?.mensagem,
            icon: "error",
            title: "Ocorreu um erro na solicitação",
            confirmButtonColor: "#00518b",
            customClass: {
              confirmButton: "x-follow-order__filter-btn",
            },
          });
        } else {
          this.setState({
            voucher_data: json,
          });
        }
      });
  }

  render() {
    return (
      <div className="consultar-cupom__container">
        <div id="main-tabela">
          <div className="title-container">
            <h1 className="main-title">Consultar Cupom</h1>
            <span className="title-shape"></span>
          </div>
          <div className="row d-flex justify-content-center">
            <div className="col-xs-12 col-md-3">
              <Input
                name="cupom_promocional"
                type="text"
                label="Cupom Promocional"
                aria="Cupom Promocional"
                value={this.state.cupom_promocional}
                onChange={this.handleChange}
                tooltip=""
              />
            </div>
            <div className="col-xs-12 col-md-3">
              <ConsultarInputCNPJ
                name="cnpj_otica"
                value={this.state.cnpj_otica}
                onChange={this.handleChange}
                // disabled={!this.state.campoCnpjLiberado}
                disabled={false}
                // redes={this.state.redes}
              />
            </div>
            <div className="col-xs-12 col-md-3">
              <ConsultarLabChoose onChange={this.handleChange} />
            </div>
          </div>

          <div className="row">
            <ConsultarSearchButton onClick={this.searchVoucher} />
          </div>
          {this.state.voucher_data && <ResultCard data={this.state.voucher_data} />}
          {this.state.voucher_data && <TabelaPares lab={this.state.lab} data={this.state.voucher_data} />}
        </div>
      </div>
    );
  }
}

export default ConsultarCupom;
