import React, { Component } from 'react'

class Privacy extends Component {

  	render(){
    	return (
            <section className='signup-popup' >
                <div className='signup-popup__container js--popup-privacy' id='popup'>
                    <div className='signup-popup__close js--popup-privacy-close' onClick={this.props.onClick}>
                        <svg xmlns="http://www.w3.org/2000/svg" className='close-mobile js--popup-close-mobile' width="21.31" height="14.994" viewBox="0 0 21.31 14.994">
                            <path className="a"
                            d="M39,44.133l6.709-5.525a.807.807,0,0,0,.1-1.116.824.824,0,0,0-1.116-.1L39.287,41.84V23.786a.789.789,0,0,0-1.579,0V41.84l-5.414-4.452a.827.827,0,0,0-1.116.1.8.8,0,0,0,.1,1.116l6.709,5.525a.825.825,0,0,0,1.011,0Z"
                            transform="translate(44.307 -31.001) rotate(90)"
                            fill="#0066b3"
                            />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" className='close-desk' width="17.089" height="17.119" viewBox="0 0 17.089 17.119">

                            <path className="a" d="M20.862,36.982a1.453,1.453,0,0,0,1.012.434,1.4,1.4,0,0,0,1.012-.434l6.072-6.072,6.072,6.072a1.453,1.453,0,0,0,1.012.434,1.4,1.4,0,0,0,1.012-.434,1.432,1.432,0,0,0,0-2.053l-6.072-6.072,6.072-6.072A1.452,1.452,0,0,0,35,20.731L28.929,26.8l-6.043-6.014a1.452,1.452,0,1,0-2.053,2.053L26.9,28.915l-6.043,6.043a1.4,1.4,0,0,0,0,2.024Z"
                            transform="translate(-20.399 -20.297)"
                            fill="#b5b5b5"
                            />
                        </svg>
                    </div>
                    <h3 className='signup-popup__title'>POLÍTICA DE PRIVACIDADE</h3>
                    <div className='signup-popup__text'>
                        <div className='signup-popup__paragraph text-left'>
                        <p>Bem-vindo ao Portal <a href="www.essilorconecta.com.br">“www.essilorconecta.com.br”</a>. Ao escolher acessar esta página, você concorda em aceitar os termos e condições aqui presentes, que regem o uso do mesmo.</p>
                        <p>O objetivo deste Portal é disponibilizar um ambiente de relacionamento entre a Essilor e as óticas parceiras, com acesso aos dados do programas de relacionamento Varilux Especialista e Conecta Vantagens.</p>
                        <p>Este documento, denominado Política de Privacidade, tem por finalidade estabelecer as regras sobre a obtenção, uso e armazenamento dos dados e informações coletadas dos usuários, em conformidade com a Lei nº 13.709, de 14 de agosto de 2018, além do registro de suas atividades.</p>

                        <p>A Essilor informa aos Usuários a sua política de proteção de dados de caráter pessoal (&quot;Dados Pessoais&quot;) para que os Usuárioss determinem livre e voluntariamente se desejam fornecer a Essilor seus Dados Pessoais, os quais são solicitados na contratação ou cancelamento de determinados serviços oferecidos.</p>

                        <p>Na presente política serão apresentadas a forma como coletamos, usamos, divulgamos, transferimos e armazenamos as informações e dados pessoais dos Usuários .</p>

                        <p>A presente Política tem como objetivo: (a) Garantir que o Usuário compreenda quais os dados pessoais do cliente recolhidos pela Essilor, qual a finalidade do recolhimento dos referidos dados e quando compartilhamos; (b) Apresentar o modo de utilização dos dados pessoais de modo a proporcionar-lhe uma experiência completa na qualidade de Usuário do Conecta; e (c) Apresentar de forma clara ao Usuário os respetivos direitos e opções em relação aos seus dados pessoais que recolhemos e tratamos e de que forma protegeremos a sua privacidade.</p>

                        <p>A Essilor declara que o Usuário, caso não concorde com o compartilhamento dos Dados Pessoais e com o conteúdo da presente Política, poderá requerer o cancelamento de sua conta Conecta.</p>

                        <p>Esta política de privacidade cobre o tratamento que a Essilor dá às informações capazes de identificar o Usuário, coletadas quando ele está no Portal Conecta.</p>

                        <p>Esta política cobre, também, o tratamento dado pela Essilor quanto ao compartilhamento das informações e dados dos Usuários com os nossos parceiros de negócio.</p>

                        <p>A Essilor proporcionará aos Usuários os recursos técnicos adequados para que estes possam, com caráter prévio, concordar com esta Política de Privacidade ou com qualquer outra informação relevante antes de concederem seu consentimento sobre o armazenamento dos respectivos Dados Pessoais, podendo, a qualquer tempo, requerer que seus dados deixem de ser utilizados pela Essilor e pelos parceiros de negócio, bem como serem apagados da base do Conecta, ocasião em que determinados serviços não poderão ser utilizados pois necessitam das referidas informações.</p>

                        <p>Para requerer o cancelamento e exclusão das informações e dados pessoais dos Usuários, este poderá realizar pelo site, no chat, e pela central de atendimento da Essilor.</p>

                        <p>O Usuário terá direitos e garantias estipulados em leis e regulamentos, conforme abaixo: (a) Direito de acesso – o direito a ser informado e solicitar o acesso aos dados pessoais que processamos sobre o Usuário; (b) Direito de retificação – o direito a solicitar alteração ou atualização dos dados pessoais do Usuário sempre que estiverem incorretos ou incompletos; (c) Direito à eliminação da informação – o direito a solicitar-nos para apagar os seus dados pessoais; (d) Direito a restringir – o direito de pedir que temporária ou permanentemente interrompamos o processamento de todos ou alguns dos seus dados pessoais; (e) Direito de oposição: o direito de, a qualquer momento, se opor ao processamento dos seus dados pessoais por motivos relacionados com a sua situação em particular; direito de oposição a que os seus dados pessoais sejam processados para fins de marketing direto (neste último caso o Usuário poderá se opor no recebimento do marketing direto em todas as comunicações encaminhadas pela Essilor “Desejo não receber mais as comunicações” e/ou “descadastra-se”); (f) Direito a não estar sujeito a decisões automatizadas – o direito de não estar sujeito a uma decisão baseada apenas numa decisão automatizada, incluindo a elaboração de perfis, onde a decisão poderá ter um efeito jurídico para si ou produzir um efeito igualmente significativo.</p>

                        <h2>1 – Definições</h2>

                        <p>
                        1.1. Para fins desta Política de Privacidade, aplicam-se as seguintes definições:

                        <b>Cookies</b>: Arquivos enviados pelo servidor do Portal para o computador dos usuários, com a finalidade de identificar o computador e obter dados de acesso, como páginas navegadas ou links clicados, permitindo, desta forma, personalizar a navegação dos usuários no Portal, de acordo com o seu perfil. Essilor: Sociedades direta ou indiretamente controladas pela Essilor International SAS.<br/>
                        <b>IP</b>: Abreviatura de Internet Protocol. É um conjunto de números que identifica o computador dos e usuários na Internet.<br/>
                        <b>Logs</b>: Registros de atividades dos usuários efetuadas no Portal.<br/>
                        <b>Portal</b>: Designa o endereço eletrônico www.essilorconecta.com.br e seus subdomínios.<br/>
                        <b>Usuários</b>: Pessoas que acessam ou interagem com as atividades oferecidas pelo Portal.<br/>
                        <b>Web beacons</b>: Linhas de programação em páginas HTML que têm como finalidade obter detalhes da navegação do usuário, a exemplo de quanto tempo ficou com o site aberto, qual endereço visitado em seguida, dentre outros.<br/>
                        <b>Websites</b>: Designa os endereços eletrônicos em geral, com exceção do Portal.<br/>
                        </p>

                        <h2>2 – Obtenção dos dados e informações</h2>

                        <p>
                        2.1. Os dados e informações serão obtidos quando os usuários inserirem informações voluntariamente no Portal, a exemplo de cadastro no programa Conecta Vantagens, fechamento de um pedido, envio de mensagem para contato, ou por meio de Cookies de navegação quando os usuários navegam no Portal.<br/>
                        2.2. Os Dados Pessoais recolhidos pela Essilor como parte desta Política serão objeto de tratamento, sendo incorporados aos correspondentes bancos de dados pessoais (&quot;Registro&quot;), dos quais a Essilor será titular e responsável e sempre à disposição dos respectivos Usuários. O Usuário garante que os Dados Pessoais fornecidos a Essilor como parte do programa Conecta são verdadeiros, bem como que comunicará a Essilor qualquer modificação destes. Uma vez registrado e utilizando nossos serviços, o Usuário passa a não ser anônimo para a Essilor. A Essilor poderá realizar algumas perguntas adicionais com o intuito de refinar e aprimorar as comunicações, porém não será obrigatório e mandatório para a utilização da ferramenta.<br/>
                        </p>

                        <h2>3 – Armazenamento dos Dados e Informações</h2>

                        <p>
                        3.1. A Essilor tem o compromisso de respeitar a privacidade e o sigilo das informações pessoais, cadastrais, financeiras e dos demais dados acessados ou compartilhados pelo Usuário, inclusive por meio do site www.essilorconecta.com.br, nos termos desta Política de Privacidade.<br/>
                        3.2. Ao solicitar o cadastramento no Portal, o Usuário expressamente concorda que os dados pessoais coletados possam ser armazenadas e utilizadas pela Essilor e suas parceiras comerciais e parceiros financeiros, com vistas ao oferecimento de produtos ou serviços, ao desenvolvimento de campanhas promocionais, à análise e processamento de dados e estatísticas, à detecção e tratamento de fraudes e outras atividades ilegais, inclusive como parte de investigações. A autorização constante deste item alcança as subsidiárias, coligadas, escritórios de representação e terceiros selecionados pela Essilor e suas parceiras comerciais e parceiros financeiros, no Brasil ou no exterior.<br/>
                        3.3. Os dados e informações coletados estarão armazenados em ambiente seguro, observado o estado da técnica disponível, e somente poderão ser acessadas por pessoas qualificadas e autorizadas pela Essilor.<br/>
                        3.4. Considerando que nenhum sistema de segurança é absolutamente seguro, a Essilor se exime de quaisquer responsabilidades por eventuais danos e/ou prejuízos decorrentes de falhas, vírus ou invasões do banco de dados do Portal, salvo nos casos em que tiver dolo ou culpa.<br/>
                        3.5. As informações referidas acima poderão ser acessadas, retidas ou compartilhadas em resposta a uma solicitação de órgão judicial, administrativo ou regulador, ou, ainda, para uso em processo legal ou administrativo, no Brasil ou no exterior.<br/>
                        3.6. A senha utilizada no Portal é de uso pessoal e exclusivo do Usuário, que deve mantê-la em segurança, sigilo e confidencialidade, não a revelando, divulgando ou fornecendo a terceiros.<br/>
                        3.7. A Essilor expressamente recomenda que o Usuário não acesse sua conta no Portal por meio de computadores, celulares, tablets e demais equipamentos eletrônicos em ambientes eletrônicos não seguros, suspeitos, abertos, compartilhados ou que de qualquer forma possam representar riscos à transação ou facilitar a ocorrência de fraudes.<br/>
                        3.8. A Essilor também recomenda expressamente que o Usuário adote medidas de segurança nos computadores, celulares, tablets e demais equipamentos eletrônicos utilizados para acesso ao Portal, tais como, mas não se limitando, a instalação e uso de programas antivírus, anti-spywares e adwares.<br/>
                        3.9. A Essilor não será responsável pelo mau uso da senha do Usuário, incluindo, mas não se limitando, às hipóteses de perda, furto ou extravio, por qualquer meio ou forma. O uso indevido dos dados de acesso será de exclusiva responsabilidade do Usuário, incluindo a responsabilidade por prejuízos em decorrência da utilização indevida por terceiros, eximindo a Essilor de qualquer responsabilidade por tais atos.<br/>
                        </p>

                        <h2>4. Uso dos Dados e Informações</h2>

                        <p>
                        4.1. Os dados e informações coletados dos usuários poderão ser utilizados para as seguintes finalidades:<br/>
                        - Cumprimento de contrato firmado entre Essilor e Usuário ao aceitar os termos do Regulamento do Portal Conecta;<br/>
                        - Responder a eventuais dúvidas e solicitações dos Usuários;<br/>
                        - Cumprimento de ordem legal ou judicial;<br/>
                        - Constituir, defender ou exercer regularmente direitos em âmbito judicial ou administrativo;<br/>
                        - Garantir a segurança dos visitantes e Usuários;<br/>
                        - Manter atualizados os cadastros dos visitantes e Usuários para fins de contato por telefone, correio eletrônico, SMS, mala direta ou por outros meios de comunicação;<br/>
                        - Aperfeiçoar o uso e a experiência interativa durante a navegação do Usuário no Portal.<br/>
                        4.2. Os dados adquiridos somente poderão ser acessados por profissionais devidamente autorizados pela Essilor, respeitando a necessidade a que serão submetidos, a relevância para os objetivos do Portal e os interesses dos Usuários, além de preservar sua privacidade.<br/>
                        4.3. O usuário poderá exigir da Essilor os dados registrados que lhe dizem respeito, da mesma forma que poderá solicitar sua alteração ou exclusão. O Usuário deve entrar em contato através dos meios disponibilizados pelo Portal para solicitar essas providências caso o Portal não ofereça a funcionalidade correspondente.<br/>
                        4.4. O Portal poderá fazer o uso de Cookies, cabendo aos Usuários configurarem o seu navegador de Internet, caso deseje bloqueá-los. Nesta hipótese, algumas funcionalidades do Portal poderão ser limitadas.<br/>
                        4.5. Também, o Portal poderá utilizar Web beacons para coletar dados de comportamento dos visitantes das páginas, onde a instalação de arquivos nos equipamentos dos visitantes não é necessária.<br/>
                        4.6. Outras tecnologias poderão ser utilizadas para a obtenção de dados de navegação pelo Usuário, no entanto, respeitarão sempre os termos desta política e as opções do usuário a respeito de sua coleta e armazenamento.<br/>
                        4.7. A Essilor não irá comercializar ou ceder quaisquer das informações pessoais do Usuário a qualquer terceiro. A Essilor poderá enviar informações capazes de identificar o Usuário a pessoas, físicas ou jurídicas, quando: (a) A Essilor obtiver o seu consentimento para compartilhar tais informações; (b) A Essilor necessitar compartilhar suas informações para fornecer o produto ou serviço solicitado pelo Usuário, garantindo, entretanto, que os fornecedores observem as regras aqui dispostas; (c) A Essilor necessitar enviar informações para empresas que trabalham em parceria com a Essilor provendo um serviço ou produto; (d) houver necessidade em resposta a demandas judiciais; ou (e) A Essilor acreditar que as atitudes do Usuário possam ter violado alguma das diretrizes de uso para produtos ou serviços específicos. Exceto se informado de outra forma, estas empresas não têm direito em usar as informações enviadas sobre o Usuário além do que for necessário para atender à Essilor e o Usuário<br/>
                        4.8. A pedido do Usuário, a Essilor poderá eliminar ou manter inativos os Dados Pessoais de modo que não identifiquem o Usuário, exceto se for legalmente permitido ou obrigatório manter determinados Dados Pessoais, incluindo situações como as seguintes: (a) se existir um problema não resolvido relativamente à sua conta; (b) se a Essilor for obrigada a manter os dados pessoais para fins de obrigações jurídicas, fiscais, de auditoria e contabilidade; (c) durante o prazo prescricional para uma possível reclamação; e/ou, (c) sempre que necessário para os legítimos interesses comerciais da Essilor, como a prevenção contra fraudes ou para manter a segurança dos Usuários.<br/>
                        </p>

                        <h2>5. Propriedade Intelectual</h2>

                        <p>
                        5.1. Este site é uma obra intelectual protegida pela legislação de propriedade intelectual.<br/>
                        5.2. Exceto se disposto de outra forma, os direitos de propriedade intelectual ligados aos documentos contidos neste Portal e cada um dos elementos criados para este fim são de inteira e exclusiva propriedade da Essilor, que não autoriza nada além de permissão de acesso ao Portal.<br/>
                        5.3. Este Portal respeita os Direitos Autorais. Todos os direitos dos autores desta obra veiculada neste site são reservados. Qualquer uso não autorizado deste material além de consulta privada e individual é proibida, bem como sua reprodução.<br/>
                        5.4. A reprodução de qualquer documento publicado neste website é autorizada somente para fins de informação, em contexto de utilização pessoal e privada; qualquer reprodução e/ou uso de cópias feitas para outros propósitos é expressamente proibida.<br/>
                        5.5. As marcas, patentes, logos e outros bens cobertos pelos direitos de propriedade intectual já mencionados neste Portal são de propriedade da Essilor ou tiveram permissão para seu uso. Nenhum direito ou licença pode ser concedida a qualquer um dos elementos supracitados sem que hajaautorização da Essilor ou da terceira parte detentora dos direitos pertinentes.<br/>
                        </p>

                        <h2>6. Disposições Gerais</h2>

                        <p>
                        6.1. A Essilor, proprietária deste Portal, busca garantir que a informação aqui disponibilizada esteja atualizada quando reproduzida online, entretanto:<br/>
                        - não oferece qualquer garantia quanto à natureza exata, precisa ou completa dessa informação;<br/>
                        - reserva-se ao direito de modificar e corrigir, a qualquer tempo e sem aviso prévio, o conteúdo dos documentos publicados no Portal. Conseqüentemente, a Essilor não pode aceitar qualquer responsabilidade sobre:<br/>
                        - qualquer imprecisão, inexatidão e omissão que afete a informação disponibilizada neste site;<br/>
                        - qualquer prejuízo causado pela intromissão ilegal de terceiros, incluindo quando levar à modificação de tal informação, ou, de modo geral, por qualquer prejuízo, direto ou indireto, e independentemente da causa, origem, natureza e consequências daí resultantes, causado pelo acesso ou impossibilidade de acesso a este Portal por qualquer pessoa.<br/>
                        6.2. Links para outros Websites possuem apenas a finalidade de informação.<br/>
                        6.3. A Essilor não se responsabiliza por links para Websites que não sejam de sua propriedade, consequentemente não sendo responsável por produtos, propagandas ou qualquer artigo ou serviço, ou, em geral, qualquer conteúdo incluído nos Websites para onde o link direcionar.<br/>
                        6.4. Nenhuma garantia é fornecida em relação à segurança dos dados dos Websites para as quais alguns links neste Portal podem direcionar. É importante ter todas as precauções nestes casos, e a Essilor não se responsabilizará por qualquer prejuízo, direto ou indireto, sofrido em resultado de acesso à outros Websites, independente da causa, origem, natureza ou suas consequências.<br/>
                        6.5. Lembre-se que os demais Websites estão sujeitos às suas próprias condições de uso.<br/>
                        6.6. As disposições constantes desta Política de Privacidade poderão ser atualizadas ou modificadas a qualquer momento, cabendo aos usuários verificá-la sempre que efetuarem o acesso ao Portal.<br/>
                        6.7. Os Usuários deverão entrar em contato em caso de qualquer dúvida com relação às disposições constantes desta Política de Privacidade.<br/>
                        6.8. A presente Política de Privacidade será interpretada segundo a legislação brasileira, no idioma português, sendo eleito o Foro da Comarca da cidade do usuário e em seu Estado para dirimir qualquer litígio.<br/>
                        6.9. A Essilor reserva-se o direito de alterar a presente Política de tempos em tempos, ocasião na qual disponibilizará uma comunicação visível no Portal ou através do envio de um e-mail ao Usuário.<br/>

                        </p>

                        <p>Atualização: 26 de junho de 2019.</p>
                        </div>
                    </div>
                </div>
            </section>
    	);
  	}
}

export default Privacy;