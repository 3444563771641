import React, { Component } from "react";
import "./otica.css";
import Back from "../main/back";
import AppContext from "../appContext";
import Swal from "sweetalert2";
import Form from "react-bootstrap/Form";
import FormCheck from "react-bootstrap/FormCheck";
import UploadLogoOtica from "../main/upload-logo-otica/upload-logo-otica";
import CustomButton from "../components/buttons/button";
import Input from "../components/inputs/input";

class Otica extends Component {
  constructor(props) {
    super(props);

    this.state = {
      labs: [],
      labsPref: [],
      nome_conta: "",
      papel_usuario: "",
      apelido: "",
      nome: "",
      cnpj: "",
      hasAccess: false,
    };

    this.buscarLabs = this.buscarLabs.bind(this);
    this.getOtica = this.getOtica.bind(this);
    this.updateLaboratorioPreferido = this.updateLaboratorioPreferido.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangePreferencia = this.handleChangePreferencia.bind(this);
    this.handleChange = this.handleChange.bind(this);

    Otica.contextType = AppContext;
  }

  componentDidMount() {
    if (!this.context.userSession?.modulos?.includes("Minha Ótica")) {
      window.location = "/home";
    } else {
      this.context.showLoading("");
      this.getOtica(this.buscarLabs);
      this.setState({ papel_usuario: this.context.userSession.papel, hasAccess: true });
    }
  }

  getOtica(callback) {
    var session_token = localStorage.getItem("session_token");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: "Bearer " + session_token,
      },
      body: JSON.stringify({ cnpj: this.context.userSession.conta }),
    };

    fetch(process.env.REACT_APP_LABS_API + "get_optics.php", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.status === "sucesso") {
          this.setState({ nome_conta: json.items.nome, apelido: json.items.apelido, nome: json.items.nome, cnpj: json.items.cnpj });

          if (json.items.laboratoriosPreferidos) {
            this.setState(
              {
                labsPref: json.items.laboratoriosPreferidos.split(";"),
                apelido: json.items.apelido,
                nome: json.items.nome,
                cnpj: json.items.cnpj,
              },
              callback,
            );
          } else {
            this.setState({ labsPref: [] }, callback);
          }
        }
      });
  }

  buscarLabs() {
    this.context.showLoading("");
    let labsa = [];

    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        cnpjs: this.context.userSession.laboratorios,
        papel: this.context.userSession.papel,
        conta: this.context.userSession.conta,
      }),
    };

    fetch(process.env.REACT_APP_LABS_API + "get_labs.php", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.items) {
          for (var i = 0; i < json.items.length; i++) {
            if (json.items[i].Pedido_c === true) {
              var checked = this.state.labsPref.some(elem => {
                return elem === json.items[i].CNPJ_c;
              });
              var pushObj = {
                nome: json.items[i].Nome_c,
                telefone: json.items[i].Telefone_c,
                site: json.items[i].Site_c,
                email: json.items[i].Email_c,
                cnpj: json.items[i].CNPJ_c,
                logo: json.items[i].URL_Logo_c,
                promo: json.items[i].Promocao_c,
                corteRemoto: json.items[i].RemoteEdge_c,
                detailOrder: json.items[i].DetalhesDoPedido_c,
                IsCor_AR: json.items[i].IsCor_AR_c,
                IsGrowmocao: json.items[i].Growmocao_c,
                checked: json.items[i].IsPreferido ? json.items[i].IsPreferido : false,
                colorirNaOtica: json.items[i].ColorirNaOtica_c ? json.items[i].ColorirNaOtica_c : false,
                // checked: checked,
                link: "/order",
              };
              labsa.push(pushObj);
            }
          }
        }

        if (labsa.length === 0) {
          var empty = {
            nome: "Sua Empresa ainda não tem cadastro ativo em nenhum laboratório habilitado.",
            link: "/home",
          };
          labsa.push(empty);
        }

        this.setState({
          loading: false,
          labs: labsa,
        });
        this.context.hideLoading();
      });
  }

  handleChangePreferencia(e) {
    const name = e.target.name;
    const value = e.target.value;
    const checked = e.target.checked;

    var listAux = this.state.labsPref;

    if (checked) {
      listAux.push(e.target.value);
    } else {
      listAux = listAux.filter(item => {
        return item !== e.target.value;
      });
    }

    this.setState({ labsPref: listAux });
  }

  updateLaboratorioPreferido() {
    const requestOptions = {
      method: "POST",
      body: JSON.stringify({
        cnpj: this.context.userSession.conta,
        onlyLabs: true,
        labPreferido: this.state.labsPref.join(";"),
        apelido: this.state.apelido,
        onlyApelido: true,
      }),
    };

    fetch(process.env.REACT_APP_LABS_API + "update_optics.php", requestOptions)
      .then(response => {
        return response.json();
      })
      .then(json => {
        if (json.success) {
          this.context.reloadApelido(this.state.apelido);
          Swal.fire({
            text: "Alterações da Empresa realizada com sucesso",
            icon: "success",
            title: "Pronto!",
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonColor: "#00518b",
            confirmButtonText: "Ok!",
          });
        } else {
          Swal.fire({
            text: json.message,
            icon: "error",
            title: "Aviso!",
            allowOutsideClick: false,
            showCancelButton: false,
            confirmButtonColor: "#00518b",
            confirmButtonText: "Ok!",
          });
        }

        this.context.hideLoading();
      });
  }

  handleSubmit() {
    this.context.showLoading("");
    this.updateLaboratorioPreferido();
  }

  handleChange(e) {
    const { name, value } = e.target;

    this.setState({ [name]: value });
  }

  render() {
    const { nome_conta, papel_usuario } = this.state;
    return !this.state.hasAccess ? (
      <></>
    ) : (
      <main className="main-otica container mt-4">
        <Back />
        <div className="row mt-4 pt-4">
          <div className="col-12 col-lg-4">
            <div className="my-account__info">
              <div className="conecta-h1-container">
                <h1 className="conecta-h1-with-dot">Editar Minha Empresa</h1>
              </div>

              <p className="my-account__paragraph">
                Você está editando as informações da empresa <b>{nome_conta}</b>.
              </p>

              <div className="my-account__menu-anchor">
                <a href="#lab_logo">Logo da Empresa</a>
                <a href="#lab_apelido">Informação da Empresa</a>
                {(this.context.userSession.papelCategoria === "OTICA" || this.context.userSession.papelCategoria == "LAB_INDEPENDENTE")
                  && (  <a href="#lab_pef">Laboratórios de Preferência</a>)}
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8">
            <div className="row">
              <div className="col-12 mb-5 pb-5" id="lab_logo">
                <h2 className="order-title">Logo da Empresa</h2>
                <span className="x-web-ordering-pedido__lens intro__form--subtitle title-mobile">
                  Personalize conteúdos para redes sociais adicionando a logo da sua empresa!
                </span>
                <UploadLogoOtica />
              </div>
              <div className="col-12 mt-5 pt-3" id="lab_apelido">
                <h2 className="order-title">Informação da Empresa</h2>
                <span className="x-web-ordering-pedido__lens intro__form--subtitle title-mobile">
                  Informe um apelido para facilitar a identificação da sua Empresa.
                </span>
                <div className="AccordionSummary row">
                  <div className="col-12 col-xl-7 col-lg-7 col-md-6 profileInput">
                    <Input label="Empresa" width="100%" type="text" name="otica" value={this.state.nome} disabled={true} readOnly={true} />
                  </div>
                  <div className="col-12 col-xl-5 col-lg-5 col-md-4 profileInput">
                    <Input
                      label="CNPJ"
                      width="100%"
                      type="text"
                      name="otica"
                      value={this.state.cnpj?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5") || ""}
                      disabled={true}
                      readOnly={true}
                    />
                  </div>
                  <div className="col-12 col-xl-12 col-lg-12 col-md-10 profileInput">
                    <Input
                      label="Apelido"
                      width="100%"
                      type="text"
                      name="apelido"
                      value={this.state.apelido}
                      onChange={this.handleChange}
                      placeholder="Empresa sem apelido"
                    />
                  </div>
                </div>
              </div>
              {(this.context.userSession.papelCategoria === "OTICA" || this.context.userSession.papelCategoria == "LAB_INDEPENDENTE")
                  &&
                ( (
                  <div>
                    <div className=" col-12 mt-5 pt-3" id="lab_pef">
                      <h2 className="order-title">Laboratórios de Preferência</h2>
                      <span className="x-web-ordering-pedido__lens intro__form--subtitle title-mobile">Selecione os laboratórios de preferência</span>
                      <Form>
                        <div key={`inline-radio`} className="mb-3 checkboxOne">
                          {this.state.labs.map((item, index) => {
                            return (
                              <Form.Check inline type="checkbox" id={item.nome} className="mt-4">
                                <Form.Check.Input
                                  type="checkbox"
                                  name={item.nome}
                                  value={item.cnpj}
                                  defaultChecked={item.checked}
                                  bsPrefix="colorInputCheck"
                                  onChange={this.handleChangePreferencia}
                                />
                                <Form.Check.Label bsPrefix="colorLabelCheck">
                                  {item.link !== "/home" ? <img src={item.logo} alt={item.nome} /> : <p>{item.nome}</p>}
                                </Form.Check.Label>
                              </Form.Check>
                            );
                          })}
                        </div>
                      </Form>
                    </div>
                    <div className="col-12">
                      <CustomButton label="Salvar alterações" disabled={this.state.disabled} onClick={this.handleSubmit} />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </main>
    );
  }
}

export default Otica;
