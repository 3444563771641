import React, { Component } from "react";
import { Card } from "react-bootstrap";
import AppContext from "../../../appContext";
import "./product-card-result.css";

class ProductCardResult extends Component {
  constructor(props) {
    super(props);

    this.state = {};

    ProductCardResult.contextType = AppContext;
  }

  render() {
    const { data, error } = this.props;
    return (
      <div className="row card-result">
        <div className="col-12">
          <div className="mt-4 d-flex justify-content-center">
            {data ? (
              <>
                <table class="table table-sm table-striped">
                  <thead>
                    <tr>
                      <th colSpan="2" className="text-center">
                        Resultado
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="text-end">Tipo Produção:</td>
                      <td className="text-start">
                        <strong>{data.tipoProducao}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-end">Tipo Produto:</td>
                      <td className="text-start">
                        <strong>{data.tipoProduto}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-end">Descrição:</td>
                      <td className="text-start">
                        <strong>{data.nome}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-end">Código:</td>
                      <td className="text-start">
                        <strong>{data.codigo}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td className="text-end">RPL:</td>
                      <td className="text-start">
                        <strong>{data.rpl}</strong>
                      </td>
                    </tr>
                    {data.servico &&
                      data.servico.map((element, index) => {
                        return (
                          <tr>
                            {index === 0 && (
                              <td className="text-end" scope="row" rowspan={data.servico.length}>
                                Serviços:
                              </td>
                            )}
                            {element.codigoRef && element.tipo === "MONTAGEM" ? (
                              ""
                            ) : (
                              <td className="text-start">
                                <strong className={`${!element.codigoRef ? "strong-codigo-vazio" : ""}`}>{`${element.codigoRef ? element.codigoRef : ""} - ${
                                  element.descricao
                                }`}</strong>
                              </td>
                            )}
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </>
            ) : (
              <>
                <table class="table table-sm table-striped">
                  <thead>
                    <tr>
                      <th colSpan="2" className="text-center">
                        Resultado
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan="2" className="text-center">
                        <strong>{error}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ProductCardResult;
