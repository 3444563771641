import React from "react";

const AppContext = React.createContext({
  isReady: false,
  loading: false,
  message: null,
  load_img: "",
  snackMessage: "",
  snackSeverity: "",
  openSnack: false,
  user_id: null,
  avatar: "",
  especialista: null,
  especialistaPremiere: null,
  apelidoConta: null,
  userSession: {
    nome: null,
    sobrenome: null,
    cpf: null,
    email: null,
    papel: null,
    modulos: null,
    laboratorios: null,
    conta: null,
    papelCategoria: null,
    redeOticas: false,
  },
  showSnack: (snackMessage, snackSeverity) => {},
  hideSnack: () => {},
  showLoading: message => {},
  hideLoading: () => {},
  reloadJwt: () => {},
  reloadApelido: apelido => {},
});

export default AppContext;
