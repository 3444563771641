import React, { Component } from "react";
import Input from "../../components/inputs/input";

class InputCNPJ extends Component {
  constructor(props) {
    super(props);
  }

  setPosition(e) {
    let val = e.target.value.replace(/\D/g, "");
    if (e.type == "click") {
      if (val.length == 0) e.target.setSelectionRange(0, 0);
    }
    if (e.type == "paste") {
      let ctrl = e.target;
      if (ctrl.setSelectionRange) {
        ctrl.setSelectionRange(0, 0);

        // IE8 and below
      } else if (ctrl.createTextRange) {
        var range = ctrl.createTextRange();
        range.collapse(true);
        range.moveStart("character", 0);
        range.select();
      }
    }
    if (e.type == "keypress") {
      if (val.length == 0) {
        e.target.setSelectionRange(0, 0);
        e.target.value = val;
      }
    }
  }

  render() {
    return (
      <div className="d-flex justify-content-center">
        <Input
          type="text"
          label={this.props.label}
          width={this.props.width || "100%"}
          mask={[/\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/]}
          name={this.props.name}
          id={this.props.name}
          value={this.props.value}
          onChange={this.props.onChange}
          disabled={this.props.disabled}
          onClick={this.setPosition}
          onPaste={this.setPosition}
          onKeyPress={this.setPosition}
          tooltip={this.props.tooltip}
        />
      </div>
    );
  }
}

export default InputCNPJ;
